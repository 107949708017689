import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "react-i18next";
import { InView } from "react-intersection-observer";
import axios from "axios";
import classnames from "classnames";
import { GETREVIEW_API_HOST } from "../constants";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CustomInput,
  Input,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderOpen,
  faFolder,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

const FoldersContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const FolderContainer = styled.div`
  position: relative;
  height: 40px;
  background: white;
  border-radius: 10px;
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ececec;
  user-select: none;
  transition: border-color 0.2s ease-out;

  &:hover {
    border-color: #8c8c8c;
  }

  ${(props) =>
    props.selected && {
      borderColor: "#8c8c8c",
    }}

  @media (max-width: 568px) {
    max-width: 100%;
    width: 100%;
  }
`;

const FolderName = styled.div`
  flex-grow: 1;
  line-height: 14px;
  max-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  margin-right: 15px;
`;

const FolderIcon = styled.div`
  font-size: 14px;
  color: #3a89ff;
  margin-right: 10px;
  margin-left: 15px;
`;

const Folder = (props) => {
  return (
    <FolderContainer onClick={props.onClick}>
      <FolderIcon>
        <FontAwesomeIcon icon={faFolder} />
      </FolderIcon>

      <FolderName>{props.name}</FolderName>
    </FolderContainer>
  );
};

const Video = (props) => {
  const [checked, setChecked] = useState(props.checked === true);
  const createdAt = format(new Date(props.created_at), "yyyy.MM.dd");

  useEffect(() => {
    props.onCheckChange(props.id, checked);
  }, [checked]);

  const classNames = classnames({
    "video-selector-video": true,
    checked: props.checked,
    disabled: props.disabled,
  });

  return (
    <div
      className={classNames}
      onClick={() => {
        props.disabled === false && setChecked(!checked);
      }}
    >
      <div className="video-selector-checkbox">
        <CustomInput
          id={`video-selector-checkbox-${props.token}`}
          type="checkbox"
          checked={props.checked}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {}}
        />
      </div>

      <div
        className="video-selector-thumbnail"
        style={{ backgroundImage: `url(${props.thumbnail_url})` }}
      >
        <span>{props.duration_human}</span>
      </div>

      <div className="video-selector-metadata">
        <div className="video-selector-name">{props.name}</div>

        <div className="video-selector-date">{createdAt}</div>

        {props.disabled === true && (
          <div className="video-selector-already-added">
            <Trans>video_selector.video_already_added</Trans>
          </div>
        )}
      </div>
    </div>
  );
};

const VideoSelector = (props) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);
  const [videos, setVideos] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedVideoIDs, setSelectedVideoIDs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const disabledVideoIDs = props.disabledVideoIDs || [];
  const disableMultipleSelect = props.disableMultipleSelect === true;

  useEffect(() => {
    if (opened === true && selectedFolder == null) {
      fetchFolders();
    }
  }, [opened, selectedFolder]);

  useEffect(() => {
    if (opened === true) {
      reloadVideos();
    }
  }, [searchQuery, selectedFolder]);

  const reloadVideos = () => {
    fetchVideos({ reload: true });

    document.querySelector(".main").scrollIntoView({ behavior: "smooth" });
  };

  const fetchVideos = (options) => {
    let pageToLoad = page;

    if (options && options.reload === true) {
      setLastPage(false);
      setPage(1);
      pageToLoad = 1;
    } else if (lastPage) {
      return;
    }

    axios
      .get(`${GETREVIEW_API_HOST}/api/videos`, {
        params: {
          folder: selectedFolder,
          page: pageToLoad,
          page_size: 24,
          sort_by: "created_at",
          order_by: "desc",
          query: searchQuery,
          flatten: searchQuery != null && searchQuery !== "" ? 1 : null,
        },
        withCredentials: true,
      })
      .then((json) => {
        const currentVideos = [...videos];
        let newVideos = json.data.videos;

        if (options && options.append === true) {
          newVideos = currentVideos.concat(...newVideos);
        }

        setPage((prevPage) => prevPage + 1);
        setLastPage(json.data.last_page);
        setVideos(newVideos);
      })
      .catch((error) => {
        alert(t("video_selector.errors.fetch"));
      });
  };

  const fetchFolders = () => {
    axios
      .get(`${GETREVIEW_API_HOST}/api/folders`, {
        params: {
          section: "videos",
        },
        withCredentials: true,
      })
      .then((json) => {
        setFolders(json.data.folders);
      })
      .catch((error) => {
        alert(t("video_selector.errors.fetch"));
      });
  };

  const close = () => {
    setOpened(false);
    setVideos([]);
    setSelectedVideoIDs([]);
    setSearchQuery("");
  };

  const complete = () => {
    const selectedVideos = videos.filter((v) =>
      selectedVideoIDs.includes(v.id)
    );

    close();

    if (props.onComplete != null) {
      props.onComplete(selectedVideos);
    }
  };

  const onCheckChange = (videoID, checked) => {
    const newSelectedIDs = [...selectedVideoIDs];
    const selectedID = selectedVideoIDs.find((id) => id === videoID);

    if (checked === true) {
      if (selectedID == null) {
        newSelectedIDs.push(videoID);
      }
    } else {
      if (selectedID != null) {
        let elementIndex = newSelectedIDs.indexOf(videoID);
        newSelectedIDs.splice(elementIndex, 1);
      }
    }

    if (disableMultipleSelect === true) {
      if (checked === true) {
        setSelectedVideoIDs([videoID]);
      } else {
        setSelectedVideoIDs([]);
      }
    } else {
      setSelectedVideoIDs(newSelectedIDs);
    }
  };

  const onSearchInputChange = (event) => {
    const query = event.target.value;

    setSearchQuery(query);
  };

  const classNames = classnames({
    "video-selector": true,
  });

  return (
    <div className="video-selector-container">
      <Button color="light" onClick={() => setOpened(true)}>
        <FontAwesomeIcon icon={faFolderOpen} className="mr-3" />
        <Trans>video_selector.choose_from_gallery</Trans>
      </Button>

      {opened === true && (
        <Modal
          isOpen={true}
          size="lg"
          centered
          className="modal-popup"
          scrollable
          toggle={() => setOpened(false)}
        >
          <ModalHeader cssModule={{ "modal-title": "w-100 text-center" }}>
            <div className="d-flex justify-content-between">
              <div>
                <Trans>video_selector.choose_video</Trans>
              </div>
              <div>
                <Input
                  type="text"
                  name="name"
                  value={searchQuery}
                  onChange={onSearchInputChange}
                  placeholder={t("video_selector.search")}
                  className="video-selector-search"
                />
              </div>
            </div>
          </ModalHeader>

          <ModalBody style={{ paddingRight: 0 }}>
            <div className={classNames}>
              <FoldersContainer>
                {selectedFolder ? (
                  <>
                    <FolderContainer onClick={() => setSelectedFolder(null)}>
                      <FolderIcon>
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </FolderIcon>

                      <FolderName>Назад</FolderName>
                    </FolderContainer>

                    <FolderContainer selected>
                      <FolderIcon>
                        <FontAwesomeIcon icon={faFolderOpen} />
                      </FolderIcon>

                      <FolderName>
                        {folders.find((f) => f.token === selectedFolder).name}
                      </FolderName>
                    </FolderContainer>
                  </>
                ) : (
                  <>
                    {folders.map((folder, index) => (
                      <Folder
                        key={`folder_${index}`}
                        {...folder}
                        onClick={() => setSelectedFolder(folder.token)}
                      />
                    ))}
                  </>
                )}
              </FoldersContainer>

              <div className="video-selector-list">
                {videos.map((video, index) => (
                  <React.Fragment key={index}>
                    <Video
                      key={index}
                      {...video}
                      onCheckChange={onCheckChange}
                      checked={selectedVideoIDs.includes(video.id)}
                      disabled={disabledVideoIDs.includes(video.id)}
                    />
                  </React.Fragment>
                ))}
              </div>

              <div>
                <InView
                  as="div"
                  rootMargin="100px"
                  onChange={(inView) => {
                    if (inView === true) {
                      fetchVideos({ append: true });
                    }
                  }}
                ></InView>
              </div>

              <div>
                {videos.length === 0 && (
                  <span>
                    <Trans>video_selector.video_not_found</Trans>
                  </span>
                )}
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <div className="d-flex w-100 justify-content-between">
              <div className="align-self-center">
                {selectedVideoIDs.length > 0 && (
                  <div>
                    <b>{selectedVideoIDs.length}</b>{" "}
                    <Trans>video_selector.videos_count_label</Trans>
                  </div>
                )}
              </div>
              <div>
                <Button color="light" className="mr-2" onClick={close}>
                  <Trans>video_selector.cancel_button</Trans>
                </Button>

                <Button
                  color="primary"
                  disabled={selectedVideoIDs.length === 0}
                  onClick={complete}
                >
                  <Trans>video_selector.choose_button</Trans>
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default VideoSelector;
