import React, { useState } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../../constants";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  CustomInput,
} from "reactstrap";

const CancelSubscriptionModalWindow = (props) => {
  const [cancelling, setCancelling] = useState(false);
  const [checked, setChecked] = useState(false);

  const cancelSubscription = () => {
    setCancelling(true);

    axios
      .delete(`${GETREVIEW_API_HOST}/api/billing/cancel_subscription`, {
        withCredentials: true,
      })
      .then((json) => {
        if (json.data.status === "ok") {
          toastr.success("Внимание!", "Подписка была успешно отменена");
          props.onCancel();
        } else {
          throw new Error("BadStatus");
        }
      })
      .catch((error) => {
        setCancelling(false);

        if (error.unknownError) {
          toastr.error(
            "",
            "Произошла ошибка во время отмены подписки. Пожалуйста, обратитесь в чат поддержки"
          );
        }
      });
  };

  return (
    <Modal
      isOpen={true}
      toggle={props.onClose}
      size="md"
      centered
      className="modal-popup"
    >
      <ModalHeader toggle={props.onClose}>Отмена подписки</ModalHeader>
      <ModalBody>
        <h4 className="mb-4">Что произойдет при отмене подписки?</h4>

        <ul className="mb-4">
          <li className="mb-2">
            Автосписание денежных средств по вашей карте будет приостановлено
          </li>
          <li className="mb-2">
            Доступ к сервису сохранится до {props.accessEndDate}
          </li>
          <li className="mb-2">
            Все ваши видео, видеовиджеты, видеоотзывы и проекты{" "}
            <b>будут удалены</b> через 30 дней после даты окончания доступа к
            сервису
          </li>
        </ul>

        <hr className="mb-4" />

        <CustomInput
          id={`video-selector-checkbox`}
          type="checkbox"
          checked={checked}
          label="Я согласен потерять все свои видео, видеовиджеты, видеоотзывы и проекты"
          onChange={(event) => setChecked(event.target.checked)}
        />
      </ModalBody>
      <ModalFooter>
        <div className="d-flex w-100 justify-content-end flex-sm-row flex-column mt-2">
          <Button color="light" className="mr-2 mb-2" onClick={props.onClose}>
            Закрыть окно
          </Button>

          <Button
            color="danger"
            className="mb-2"
            style={{ position: "relative", width: "200px" }}
            disabled={checked === false}
            onClick={cancelSubscription}
          >
            Отменить подписку
            {cancelling && (
              <Spinner
                color="white"
                size="sm"
                className="mr-2"
                style={{ position: "absolute", right: "5px", top: "12px" }}
              />
            )}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default CancelSubscriptionModalWindow;
