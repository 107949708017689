import * as types from "../constants";
import axios from "axios";
import { GETREVIEW_API_HOST } from "../../constants";

export function loadAccountSuccess(account) {
  return {
    type: types.LOAD_ACCOUNT_SUCCESS,
    payload: account,
  };
}

export function loadAccountError() {
  return {
    type: types.LOAD_ACCOUNT_ERROR,
  };
}

// export function loadCurrentAccount() {
//   return function (dispatch) {
//     return axios
//       .get(`${GETREVIEW_API_HOST}/api/identity`, { withCredentials: true })
//       .then((json) => {
//         const projects = json.data.account.projects;
//         let project;

//         const currentProjectToken = localStorage.getItem(
//           "_gr_selected_project_token"
//         );

//         if (currentProjectToken != null) {
//           project = projects.find((p) => p.token === currentProjectToken);
//         }

//         project = project || projects[0];

//         dispatch({
//           type: "PROJECTS_SELECT_PROJECT",
//           project: project,
//         });

//         if (projects.length > 0) {
//           dispatch({
//             type: "PROJECTS_LOAD_LIST",
//             projects: projects,
//           });
//         }

//         setTimeout(() => {
//           dispatch(loadAccountSuccess(json.data.account));
//         }, 500);
//       })
//       .catch((error) => {
//         console.error(error);
//         dispatch(loadAccountError());
//       });
//   };
// }
