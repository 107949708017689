import React from "react";
import WorkspaceBillingSettingsForm from "./WorkspaceBillingSettingsForm";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";

const WorkspaceBillingSettingsModalWindow = (props) => {
  return (
    <Modal isOpen={true} size="md" centered className="modal-popup">
      <ModalHeader>
        <FontAwesomeIcon icon={faPenAlt} style={{ color: "#3b89ff" }} />
        &nbsp;&nbsp; Заполните реквизиты организации
      </ModalHeader>
      <ModalBody>
        <WorkspaceBillingSettingsForm
          forModalWindow={true}
          onSuccess={props.onSuccess}
          onClose={props.onClose}
        />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default WorkspaceBillingSettingsModalWindow;
