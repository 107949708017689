import React from "react";
import axios from 'axios';
import { toastr } from "react-redux-toastr";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  Table
} from "reactstrap";

class Invoices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-4">
          <Col xs="auto" className="d-none d-sm-block">
            <h3>История платежей</h3>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card>
              <Table responsive bordered hover>
                <thead>
                  <tr>
                    <th>Дата</th>
                    <th>Тариф</th>
                    <th>Срок</th>
                  </tr>
                </thead>
                <tbody>
                  
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
};

export default Invoices;
