import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import { GETREVIEW_API_HOST } from "../../constants";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Button,
  Spinner,
  Label,
  CustomInput,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ProjectSettingsForm from "../../components/ProjectSettingsForm";

const Project = (props) => {
  const dispatch = useDispatch();

  let { project_token: projectToken } = useParams();

  const { project, projects } = useSelector((state) => state.identity);

  const [projectIsSaving, setProjectIsSaving] = useState(false);
  const [projectName, setProjectName] = useState(project.name);
  const [projectDomain, setProjectDomain] = useState(project.domain);

  useEffect(() => {
    setProjectName(project.name);
    setProjectDomain(project.domain);
  }, [projectToken, project]);

  const saveProject = () => {
    setProjectIsSaving(true);

    axios
      .put(
        `${GETREVIEW_API_HOST}/api/projects/${project.token}`,
        { name: projectName, domain: projectDomain },
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success(
          "Поздравляем!",
          "Настройки проекта были успешно сохранены"
        );

        dispatch({
          type: "PROJECTS_UPDATE_PROJECT",
          project: json.data.project,
        });
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error(
            "",
            "Возникла неизвестная ошибка при сохранении проекта"
          );
        }
      })
      .finally(() => {
        setProjectIsSaving(false);
      });
  };

  const deleteProject = () => {
    const confirmBlock = (
      <div>
        Вы уверены, что хотите удалить проект и все его содержимое? Будут
        удалены все <b>видеовиджеты</b> и все <b>видеофайлы</b> из галереи
        видео, которые относятся к данному проекту. Аналитика по проекту также
        перестанет быть доступна.
      </div>
    );

    toastr.confirm(confirmBlock, {
      onOk: () => {
        axios
          .delete(`${GETREVIEW_API_HOST}/api/projects/${project.token}`, {
            withCredentials: true,
          })
          .then((json) => {
            window.location.href = "/widgets/all";
          })
          .catch((error) => {
            alert("Ошибка при удалении проекта");
          });
      },
    });
  };

  return (
    <Container fluid className="p-0">
      <Row className="mb-4">
        <Col xs="auto">
          <div style={{ opacity: 0.5 }}>
            <span className="mr-2">Проекты</span>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>

          <h3>{project.name}</h3>
        </Col>
      </Row>

      <Row>
        <Col md="8" xl="6">
          <Card>
            <CardBody>
              <ProjectSettingsForm project={project} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="8" xl="6">
          <Card>
            <CardBody>
              <h4>Удаление проекта</h4>
              <p>
                При удалении проекта будут также удалены все <b>видеовиджеты</b>{" "}
                и все <b>видеофайлы</b> из галереи видео, которые относятся к
                данному проекту. Аналитика по проекту также перестанет быть
                доступна.
              </p>
              <Form>
                <Button
                  disabled={projects.length <= 1}
                  color="danger"
                  outline
                  onClick={deleteProject}
                >
                  Удалить проект
                </Button>

                {projects.length <= 1 && (
                  <p className="mt-3 text-muted text-sm">
                    Вы не можете удалить данный проект, так как он является
                    вашим единственным проектом. Создайте новый проект и кнопка
                    удаления станет активной.
                  </p>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Project;
