import React, { useState, useEffect } from "react";
import SettingsBlock from "./SettingsBlock";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSlidersH,
  faPlusCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const CustomProperty = ({
  name,
  value,
  onChangeName,
  onChangeValue,
  onDelete,
}) => (
  <div style={{ display: "flex", marginBottom: "10px" }}>
    <Input
      type="text"
      value={name}
      name="name"
      placeholder="Название"
      onChange={(event) => onChangeName(event.target.value)}
      style={{ width: "200px", marginRight: "10px" }}
    />
    <Input
      type="text"
      value={value}
      name="value"
      placeholder="Значение"
      onChange={(event) => onChangeValue(event.target.value)}
      style={{ width: "500px" }}
    />

    <div>
      <FontAwesomeIcon
        onClick={onDelete}
        icon={faTimesCircle}
        style={{
          fontSize: "14px",
          color: "#d9534f",
          margin: "7px 0 0 10px",
          cursor: "pointer",
        }}
      />
    </div>
  </div>
);

const CustomInternalsSettings = ({ onChange, widgetType, widgetSettings }) => {
  const [properties, setProperties] = useState(
    Object.entries(widgetSettings.widgetCustomInternals)
  );

  useEffect(() => {
    let newProperties = {};

    properties.forEach((property) => {
      newProperties[property[0]] = property[1];
    });

    onChange("widgetCustomInternals", newProperties);
  }, [properties]);

  const addProperty = () => {
    const newProperties = [...properties];
    newProperties.push(["position", ""]);
    setProperties(newProperties);
  };

  const deleteProperty = (index) => {
    const newProperties = [...properties];
    newProperties.splice(index, 1);
    setProperties(newProperties);
  };

  const changeName = (value, index) => {
    const newProperties = [...properties];
    newProperties[index][0] = value;
    setProperties(newProperties);
  };

  const changeValue = (value, index) => {
    const newProperties = [...properties];
    newProperties[index][1] = value;
    setProperties(newProperties);
  };

  return (
    <div>
      <SettingsBlock
        title="Кастомные свойства для API"
        description="Свойства виджета, доступные в JavaScript API"
        icon={faSlidersH}
        isVisible={true}
      >
        {properties.map((property, index) => (
          <CustomProperty
            key={index}
            name={property[0]}
            value={property[1]}
            onChangeName={(value) => changeName(value, index)}
            onChangeValue={(value) => changeValue(value, index)}
            onDelete={() => deleteProperty(index)}
          />
        ))}

        <div
          style={{
            fontSize: "11px",
            margin: "15px 3px",
            cursor: "pointer",
            display: "inline-block",
          }}
          onClick={addProperty}
        >
          Добавить свойство
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{
              fontSize: "14px",
              marginLeft: "7px",
              position: "relative",
              top: "2px",
              color: "#3A89FF",
              cursor: "pointer",
            }}
          />
        </div>
      </SettingsBlock>
    </div>
  );
};

export default CustomInternalsSettings;
