import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Trans, useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, FormGroup, Spinner } from "reactstrap";
import { GETREVIEW_API_HOST } from "../../constants";

const SignIn = (props) => {
  const { t } = useTranslation();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSending, setIsSending] = useState(false);

  const submitForm = async (googleAuthResponse) => {
    let recaptchaToken = null;

    if (executeRecaptcha) {
      recaptchaToken = await executeRecaptcha("accounts/sign_in");
    }

    if (recaptchaToken == null || recaptchaToken === "") {
      toastr.error("", t("auth.errors.recaptcha_error"));
      return;
    }

    setIsSending(true);

    const formData = {
      email,
      password,
      recaptcha: recaptchaToken,
    };

    if (googleAuthResponse != null) {
      formData.account.email = googleAuthResponse.email;
      formData.account.google_auth_token = googleAuthResponse.googleAuthToken;
    }

    axios
      .post(`${GETREVIEW_API_HOST}/accounts/sign_in`, formData, {
        withCredentials: true,
      })
      .then((json) => {
        if (process.env.NODE_ENV === "production") {
          window.dataLayer.push({
            event: "eventTracking",
            category: "Account",
            action: "signInDone",
          });
        }

        window.location.reload();
      })
      .catch((error) => {
        let errorMessage = t("auth.errors.sign_in_unknown_error");

        if (error.response) {
          if (error.response.data.error === "access_denied") {
            errorMessage = t("auth.errors.wrong_credentials");
          }
        }

        toastr.error("", errorMessage);

        setIsSending(false);
      });
  };

  return (
    <Card>
      <CardBody>
        <div className="m-sm-4">
          <p
            className="lead text-center"
            style={{ fontSize: "20px", padding: "0 10px" }}
          >
            <Trans>auth.sign_in.title</Trans>
          </p>

          <br />

          <AvForm onValidSubmit={() => submitForm()}>
            <FormGroup className="from-group-email">
              <div
                style={{
                  marginBottom: "3px",
                  fontWeight: "600",
                  opacity: 0.7,
                }}
              >
                <Trans>auth.forms.email_label</Trans>
              </div>

              <AvField
                required
                disabled={isSending}
                errorMessage={t("auth.errors.email_invalid")}
                type="email"
                bsSize="md"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormGroup>

            <FormGroup className="from-group-password">
              <div
                style={{
                  marginBottom: "3px",
                  fontWeight: "600",
                  opacity: 0.7,
                }}
              >
                <Trans>auth.forms.password_label</Trans>
              </div>

              <AvField
                required
                disabled={isSending}
                validate={{
                  minLength: {
                    value: 6,
                    errorMessage: t("auth.errors.password_length_error"),
                  },
                }}
                errorMessage={t("auth.errors.required_field")}
                type="password"
                bsSize="md"
                name="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <div className="text-center mt-3">
                <Button
                  id="submit"
                  disabled={isSending}
                  color="primary"
                  size="lg"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div style={{ marginLeft: "20px" }}>
                    {t("auth.forms.sign_in")}
                  </div>
                  <div
                    style={{
                      marginLeft: "15px",
                      width: "25px",
                      height: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {isSending ? (
                      <Spinner
                        color="white"
                        size="sm"
                        className="mr-2"
                        style={{}}
                      />
                    ) : (
                      <FontAwesomeIcon icon={faArrowRight} />
                    )}
                  </div>
                </Button>
              </div>
            </FormGroup>

            {process.env.REACT_APP_GETREVIEW_REGION === "RU" && (
              <div className="d-flex">
                <div className="flex-fill pr-1">
                  {/* <GoogleButton
                    failIfNotExists
                    text={t("auth.google_button.sign_in_text")}
                    onSuccess={submitForm}
                  /> */}
                </div>
              </div>
            )}
          </AvForm>

          <FormGroup>
            <div className="text-center mt-3">
              <p style={{ fontSize: "12px" }}>
                <Link
                  to="/account/reset_password"
                  style={{ color: "#000a3b", fontWeight: "600" }}
                >
                  {t("auth.forms.forgot_password")}
                </Link>

                <br />

                <Link
                  to="/account/sign_up"
                  style={{ color: "#000a3b", fontWeight: "600" }}
                >
                  {t("auth.forms.sign_up")}
                </Link>
              </p>
            </div>
          </FormGroup>
        </div>
      </CardBody>
    </Card>
  );
};

export default SignIn;
