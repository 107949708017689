import {
  faEdit,
  faFolder,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { Trans, useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { GETREVIEW_API_HOST } from "../constants";
import MenuDropdown from "../pages/widgets/MenuDropdown";

const FolderContainer = styled.div`
  position: relative;
  width: 220px;
  max-width: 260px;
  min-width: 120px;
  height: 50px;
  background: white;
  border-radius: 12px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 1px 2px 10px 0 rgb(53, 64, 82, 0.1);
  border: 2px solid white;
  user-select: none;

  ${(props) =>
    props.isActive && {
      opacity: 0.5,
    }}

  ${(props) =>
    props.isDraggable && {
      boxShadow: "1px 2px 15px 0 rgb(53, 64, 82, 0.2)",
    }}

  ${(props) =>
    props.isHighlighted && {
      borderColor: "#b5d3ff",
    }}

  ${(props) =>
    props.isDroppable && {
      borderColor: "#3a89ff",
    }}

  .link-button {
    transition: opacity 0.3s ease-out;
    background: transparent;
    min-width: 20px;
    opacity: 0;

    &:hover {
      .link-button-icon {
        color: #464d6c;
      }
    }
  }

  &:hover {
    .link-button {
      opacity: 1;
    }

    .folder-icon {
      color: #1472ff;
    }
  }

  @media (max-width: 568px) {
    max-width: 100%;
    width: 100%;

    .link-button {
      opacity: 1;
    }
  }
`;

const FolderDraggable = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const FolderName = styled.div`
  flex-grow: 1;
  line-height: 14px;
  max-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  > input {
    border: none;
    padding: 0;
    background: white;
    outline: none;
    width: 100%;
  }
`;

const FolderIcon = styled.div`
  font-size: 22px;
  color: #3a89ff;
  margin-right: 15px;
  margin-left: 15px;
  transition: color 0.3s ease-out;
`;

const Folder = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [name, setName] = useState(props.name);
  const [isEditing, setIsEditing] = useState(props.isEditing || false);
  const savedName = useRef(props.name);

  const history = useHistory();

  const [{ highlighted, isOver }, drop] = useDrop({
    accept: ["widget_item", "video"],
    drop: (item, monitor) => {
      return { folderToken: props.token };
    },
    collect: (monitor) => ({
      highlighted: monitor.canDrop(),
      isOver: !!monitor.isOver(),
    }),
  });

  useEffect(() => {
    if (isEditing === false) {
      if (savedName.current !== name) {
        saveName(name);
      }

      savedName.current = name;
    }
  }, [isEditing]);

  const openFolder = () => {
    const sectionName = props.section === "widgets" ? "widgets" : "videos";
    history.push(`/${sectionName}/folder/${props.token}`);
  };

  const saveName = () => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/folders/${props.token}`,
        {
          section: props.section,
          name,
        },
        { withCredentials: true }
      )
      .then(() => {
        if (props.onUpdate) {
          props.onUpdate(props.token, name);
        }
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error("", "Error occurred!");
        }

        setName(props.name);
      });
  };

  const deleteFolder = () => {
    const entityLabel =
      props.section === "widgets"
        ? t("dashboard.widgets.folder_entity_videowidgets")
        : t("dashboard.widgets.folder_entity_videos");

    const confirmBlock = (
      <div>
        <Trans values={{ label: entityLabel }}>
          dashboard.widgets.folder_delete_confirm
        </Trans>
      </div>
    );

    toastr.confirm(confirmBlock, {
      onOk: () => {
        axios
          .delete(`${GETREVIEW_API_HOST}/api/folders/${props.token}`, {
            data: {
              section: props.section,
            },
            withCredentials: true,
          })
          .then(() => {
            toastr.success("", t("dashboard.widgets.folder_delete_success"));

            if (props.onDelete) {
              props.onDelete(props.token);
            }
          })
          .catch((error) => {
            if (error.unknownError) {
              toastr.error("", "Error occurred!");
            }
          });
      },
    });
  };

  const handleKeyPress = (event) => {
    switch (event.keyCode) {
      case 13:
        setIsEditing(false);
        break;
      case 27:
        setName(savedName.current);
        setIsEditing(false);
        break;
      default:
    }
  };

  let draggableProps = {};

  if (isEditing === false) {
    draggableProps = { ...props.attributes, ...props.listeners };
  }

  return (
    <FolderContainer
      ref={ref}
      isActive={props.isActive}
      isDraggable={props.isDraggable}
      isDroppable={isOver}
      isHighlighted={highlighted}
      style={props.style}
      className="folder-element-block"
      onClick={openFolder}
    >
      <FolderDraggable ref={drop} {...draggableProps}>
        <FolderIcon className="folder-icon">
          <FontAwesomeIcon icon={faFolder} />
        </FolderIcon>

        <FolderName>
          {isEditing ? (
            <input
              type="text"
              value={name}
              autoFocus
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.target.select()}
              onBlur={(event) => setIsEditing(false)}
              onChange={(event) => setName(event.target.value)}
              onKeyDown={handleKeyPress}
            />
          ) : (
            <span>{name}</span>
          )}
        </FolderName>
      </FolderDraggable>

      <MenuDropdown
        vertical
        items={[
          {
            className: "folder-dropdown-rename-button",
            type: "item",
            label: t("dashboard.widgets.rename_folder"),
            icon: faEdit,
            onClick: () => setIsEditing(true),
          },
          { type: "separator" },
          {
            className: "folder-dropdown-delete-button",
            type: "item",
            label: t("dashboard.widgets.delete_folder"),
            icon: faTrashAlt,
            colorType: "danger",
            onClick: deleteFolder,
          },
        ]}
      />
    </FolderContainer>
  );
});

export default Folder;
