import React from "react";
import SettingsBlock from "./SettingsBlock";
import { useTranslation, Trans } from "react-i18next";
import { FormGroup, Input, Label } from "reactstrap";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";

const DisplayByTrigger = ({
  requiredPlans,
  widgetType,
  widgetSettings,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <SettingsBlock
      requiredPlans={requiredPlans}
      title={t("dashboard.widget_form.trigger.title")}
      description={t("dashboard.widget_form.trigger.desc")}
      icon={faDotCircle}
      isVisible={widgetType === "VideoBubble"}
    >
      <div>
        <Label>
          <Trans>dashboard.widget_form.trigger.trigger_to_dispay_widget</Trans>
        </Label>

        <div className="ml-3">
          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="triggerShowWidgetType"
                value="pageload"
                checked={widgetSettings.triggerShowWidgetType === "pageload"}
                onChange={() => {
                  onChange("triggerShowWidgetType", "pageload");
                }}
              />
              <Trans>dashboard.widget_form.trigger.trigger_pageload</Trans>
            </Label>
          </FormGroup>

          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="triggerShowWidgetType"
                value="time_delay"
                checked={widgetSettings.triggerShowWidgetType === "time_delay"}
                onChange={() => {
                  onChange("triggerShowWidgetType", "time_delay");
                }}
              />
              Через определенное время
            </Label>
          </FormGroup>

          {widgetSettings.triggerShowWidgetType === "time_delay" && (
            <div
              style={{
                marginBottom: "10px",
                marginLeft: "20px",
                display: "flex",
              }}
            >
              <Input
                type="text"
                name="triggerShowWidgetTimeDelayValue"
                value={widgetSettings.triggerShowWidgetTimeDelayValue}
                onChange={(event) => {
                  onChange(
                    "triggerShowWidgetTimeDelayValue",
                    parseInt(event.target.value)
                  );
                }}
                style={{ width: "70px", marginRight: "10px" }}
              />
              <div style={{ marginTop: "5px" }}>секунд</div>
            </div>
          )}

          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="triggerShowWidgetType"
                value="scroll"
                checked={widgetSettings.triggerShowWidgetType === "scroll"}
                onChange={() => {
                  onChange("triggerShowWidgetType", "scroll");
                }}
              />
              <Trans>dashboard.widget_form.trigger.trigger_scroll</Trans>
            </Label>
          </FormGroup>

          {widgetSettings.triggerShowWidgetType === "scroll" && (
            <div
              style={{
                marginBottom: "10px",
                marginLeft: "20px",
                display: "flex",
              }}
            >
              <Input
                type="text"
                name="triggerShowWidgetScrollPixels"
                value={widgetSettings.triggerShowWidgetScrollPixels}
                onChange={(event) => {
                  onChange(
                    "triggerShowWidgetScrollPixels",
                    parseInt(event.target.value)
                  );
                }}
                style={{ width: "70px", marginRight: "10px" }}
              />
              <div style={{ marginTop: "5px" }}>
                <Trans>dashboard.widget_form.trigger.trigger_scroll_px</Trans>
              </div>
            </div>
          )}

          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="triggerShowWidgetType"
                value="element_in_view"
                checked={
                  widgetSettings.triggerShowWidgetType === "element_in_view"
                }
                onChange={() => {
                  onChange("triggerShowWidgetType", "element_in_view");
                }}
              />
              <Trans>
                dashboard.widget_form.trigger.trigger_element_in_view
              </Trans>
            </Label>
          </FormGroup>

          {widgetSettings.triggerShowWidgetType === "element_in_view" && (
            <div
              style={{
                marginBottom: "10px",
                marginLeft: "20px",
              }}
            >
              <div>
                <span className="text-muted text-sm">
                  <Trans>
                    dashboard.widget_form.trigger.trigger_element_in_view_selector
                  </Trans>
                </span>
              </div>
              <Input
                type="text"
                name="triggerShowWidgetSelector"
                value={widgetSettings.triggerShowWidgetSelector}
                onChange={(event) => {
                  onChange("triggerShowWidgetSelector", event.target.value);
                }}
                style={{ width: "200px" }}
              />
            </div>
          )}
        </div>
      </div>
    </SettingsBlock>
  );
};

export default DisplayByTrigger;
