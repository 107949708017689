import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { GETREVIEW_API_HOST } from "../../constants";
import { Button, Card, CardHeader, CardTitle } from "reactstrap";

const WidgetWrapperContainer = styled.div`
  margin: 0 auto;
  width: ${(props) => props.containerWidth}px;
  background: #eeeeee;
  border-radius: 10px;
  transition: width 0.3s ease-out;
`;

const WidgetWrapperBlockWidthSelectorButton = styled.div`
  margin-left: 25px;
  margin-bottom: 20px;
  background: white;
  border-radius: 5px;
  padding: 3px 5px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease-out, color 0.3s ease-out;

  &:hover {
    background-color: #000a3b;
    color: white;
  }

  ${(props) =>
    props.selected && {
      backgroundColor: "#000a3b",
      color: "white",
    }}
`;

const WidgetWrapperBlock = styled.div`
  margin: 0 auto;
  padding: 20px 0 30px 0;
`;

const Preview = (props) => {
  let { widget_id: widgetToken } = useParams();
  const [widget, setWidget] = useState(null);

  const injectWidgetCode = useCallback(() => {
    if (typeof window.GetReviewSettings === "object") {
      window.GetReviewSettings.previewWidgetToken = widget.token;
    } else {
      window.GetReviewSettings = {
        previewWidgetToken: widget.token,
      };
    }

    (function (g, e) {
      var s = g.createElement("script");
      s.async = true;
      s.src = `${GETREVIEW_API_HOST}/tags/${widget.site_token}/sdk.js`;
      if (g.head) {
        g.head.appendChild(s);
      }
    })(document, window);
  }, [widget]);

  useEffect(() => {
    const fetchWidget = () => {
      axios
        .get(`${GETREVIEW_API_HOST}/api/widgets/${widgetToken}`, {
          withCredentials: true,
        })
        .then((json) => {
          setWidget(json.data.widget);
        })
        .catch((error) => {
          console.log(error);
          alert("Error getting widget");
        });
    };

    fetchWidget();
  }, [widgetToken]);

  useEffect(() => {
    if (widget == null) return;

    injectWidgetCode();
  }, [widget, injectWidgetCode]);

  if (widget == null) {
    return <b>Загрузка...</b>;
  }

  const WidgetWrapperBlockWidthSelector = ({ containerWidth, onClick }) => {
    return (
      <div>
        <div className="d-flex">
          <div
            style={{ fontSize: "12px", marginLeft: "25px", marginTop: "3px" }}
          >
            Block size
          </div>
          <WidgetWrapperBlockWidthSelectorButton
            selected={containerWidth === 320}
            onClick={() => onClick(320)}
          >
            Small
          </WidgetWrapperBlockWidthSelectorButton>
          <WidgetWrapperBlockWidthSelectorButton
            selected={containerWidth === 450}
            onClick={() => onClick(450)}
          >
            Medium
          </WidgetWrapperBlockWidthSelectorButton>
          <WidgetWrapperBlockWidthSelectorButton
            selected={containerWidth === 900}
            onClick={() => onClick(900)}
          >
            Large
          </WidgetWrapperBlockWidthSelectorButton>
        </div>
      </div>
    );
  };

  const WidgetWrapper = ({ children }) => {
    const [containerWidth, setContainerWidth] = useState(450);

    if (widget.type === "VideoGrid") {
      return (
        <WidgetWrapperContainer containerWidth={containerWidth}>
          <WidgetWrapperBlock>
            <WidgetWrapperBlockWidthSelector
              containerWidth={containerWidth}
              onClick={(value) => setContainerWidth(value)}
            />
            {children}
          </WidgetWrapperBlock>
        </WidgetWrapperContainer>
      );
    } else {
      return <div style={{ margin: "60px 30px" }}>{children}</div>;
    }
  };

  return (
    <div>
      <div style={{ margin: "30px" }}>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <CardTitle>Предпросмотр видеовиджета</CardTitle>
                <h2>{widget.name}</h2>
              </div>

              <div>
                <a href="/widgets/all">
                  <Button color="secondary" outline>
                    ← Вернуться к списку виджетов
                  </Button>
                </a>
                &nbsp;&nbsp;
                <a href={"/widgets/" + widget.token + "/edit"}>
                  <Button color="secondary">Редактировать виджет</Button>
                </a>
              </div>
            </div>
          </CardHeader>
        </Card>
      </div>

      <WidgetWrapper>
        <div>
          <div className="getreview-widget" data-widget-id={widget.token}></div>
        </div>
      </WidgetWrapper>
    </div>
  );
};

export default Preview;
