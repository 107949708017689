import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import { subDays, format } from "date-fns";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../../constants";
import Breadcrumbs from "../../components/Breadcrumbs";
import DateTimePicker from "../../components/DateTimePicker";
import AnalyticsChart from "../../components/AnalyticsChart";
import AnalyticsForWidgets from "./AnalyticsForWidgets";
import Tabs from "../../components/Tabs";
import ItemsAnalytics from "./ItemsAnalytics";

import {
  Card,
  CardHeader,
  CardBody,
  Progress,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import Select from "react-select";

const Loader = () => (
  <div style={{ height: "26px", opacity: 0.3, fontSize: "14px" }}>
    <Spinner color="secondary" size="sm" />
  </div>
);

const Analytics = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const widgetToken = queryParams.get("widget");

  const [counters, setCounters] = useState(null);
  const [dates, setDates] = useState(null);
  const [series, setSeries] = useState(null);
  const [widgets, setWidgets] = useState([]);
  const [deviceType, setDeviceType] = useState("all");
  const [dateFrom, setDateFrom] = useState(subDays(new Date(), 13));
  const [dateTo, setDateTo] = useState(new Date());
  const [activeTab, setActiveTab] = useState("by_date");
  const dateTimePickerRef = useRef();

  const { workspaces, workspace, projects } = useSelector(
    (state) => state.identity
  );

  const selectedWidget = useMemo(() => {
    return widgets.find((w) => w.token === widgetToken);
  }, [widgetToken, widgets]);

  useEffect(() => {
    fetchAnalytics();
    fetchWidgets();
  }, [deviceType, dateFrom, dateTo, widgetToken]);

  const fetchAnalytics = () => {
    axios
      .get(`${GETREVIEW_API_HOST}/api/analytics/data`, {
        params: {
          widget_id: widgetToken,
          device_type: deviceType,
          date_from: format(dateFrom, "yyyy-MM-dd"),
          date_to: format(dateTo, "yyyy-MM-dd"),
          series: 1,
        },
        withCredentials: true,
      })
      .then((json) => {
        setCounters(json.data.counters);
        setDates(json.data.dates);
        setSeries(json.data.series);
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error(
            "",
            "Возникла неизвестная ошибка при получении аналитики"
          );
        }
      });
  };

  const fetchWidgets = () => {
    return axios
      .get(`${GETREVIEW_API_HOST}/api/widgets/widgets_for_select`, {
        params: {
          flatten: 1,
        },
        withCredentials: true,
      })
      .then((json) => {
        setWidgets(json.data.widgets);

        // const options = widgets.map((widget) => {
        //   return { value: widget.token, label: widget.name };
        // });
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error(
            "",
            "Возникла неизвестная ошибка при получении списка виджетов"
          );
        }
      });
  };

  const onDateTimeChanged = () => {
    const { startDate, endDate } = dateTimePickerRef.current.state.newRange;

    setDateFrom(startDate);
    setDateTo(endDate);
  };

  const calculatePercentage = (percentFor, percentOf) => {
    if (percentFor == null || percentOf == null || percentOf === 0) {
      return 0;
    }

    return ((percentFor / percentOf) * 100).toFixed(2);
  };

  const eventValue = (eventName, index) => {
    return series[eventName][index];
  };

  const eventValueOutput = (eventName, index) => {
    let output;
    let value = eventValue(eventName, index);

    if (value === 0) {
      output = <span style={{ opacity: 0.3 }}>0</span>;
    } else {
      output = <span>{value}</span>;
    }

    return output;
  };

  const eventPercentage = (firstEventName, secondEventName, index) => {
    let output;
    let value = calculatePercentage(
      eventValue(firstEventName, index),
      eventValue(secondEventName, index)
    );

    if (value === 0) {
      output = <span style={{ opacity: 0.3 }}>0%</span>;
    } else {
      output = <span>{value}%</span>;
    }

    return output;
  };

  return (
    <Container fluid className="p-0">
      <Row className="mb-4">
        <Col xs="auto" className="d-none d-sm-block">
          <Breadcrumbs />

          <h3>
            <Trans>dashboard.title</Trans>
          </h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Row>
                <Col xl="3" lg="4" xs="12">
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={widgets.map((widget) => ({
                      value: widget.token,
                      label: widget.name,
                    }))}
                    placeholder={<Trans>dashboard.all_widgets</Trans>}
                    isLoading={false}
                    isClearable
                    onChange={(option) => {
                      if (option) {
                        history.push(`?widget=${option.value}`);
                      } else {
                        history.push("");
                      }
                    }}
                    value={
                      selectedWidget
                        ? {
                            value: selectedWidget.token,
                            label: selectedWidget.name,
                          }
                        : null
                    }
                  />
                </Col>
                <Col xl="3" lg="4" xs="12" className="mt-3 mt-sm-auto">
                  &nbsp;
                </Col>
                <Col
                  xl="6"
                  lg="4"
                  xs="12"
                  className="mt-3 mt-sm-auto text-right"
                >
                  <DateTimePicker
                    ref={dateTimePickerRef}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    onSubmitHandler={onDateTimeChanged}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          <Tabs
            tabs={[
              {
                name: "all",
                title: t("dashboard.analytics.all_devices"),
              },
              {
                name: "desktop",
                title: t("dashboard.analytics.desktop_devices"),
              },
              {
                name: "mobile",
                title: t("dashboard.analytics.mobile_devices"),
              },
            ]}
            activeTab={deviceType}
            onChange={(tabName) => setDeviceType(tabName)}
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12" lg="4" xl="2">
          <Card className="flex-fill">
            <CardHeader>
              <h5 className="card-title mb-0 text-nowrap">
                <Trans>dashboard.analytics.widget_uniq_loads</Trans>
              </h5>
            </CardHeader>
            <CardBody className="my-2">
              <Row className="d-flex align-items-center mb-4">
                <Col xs="6">
                  <h2 className="d-flex align-items-center mb-0 font-weight-light">
                    {counters ? counters.widget_load_uniq_count : <Loader />}
                  </h2>
                </Col>
                <Col xs="6" className="text-right">
                  <span className="text-muted text-nowrap">100%</span>
                </Col>
              </Row>

              <Progress
                color="primary"
                value={100}
                className="progress-sm shadow-sm mb-1"
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" lg="4" xl="2">
          <Card className="flex-fill">
            <CardHeader>
              <h5 className="card-title mb-0 text-nowrap">
                <Trans>dashboard.analytics.widget_opens</Trans>
              </h5>
            </CardHeader>
            <CardBody className="my-2">
              <Row className="d-flex align-items-center mb-4">
                <Col xs="6">
                  <h2 className="d-flex align-items-center mb-0 font-weight-light">
                    {counters ? counters.widget_open_count : <Loader />}
                  </h2>
                </Col>
                <Col xs="6" className="text-right"></Col>
              </Row>

              <Progress
                color="info"
                value={
                  counters
                    ? calculatePercentage(
                        counters.widget_open_count,
                        counters.widget_load_uniq_count
                      )
                    : 0
                }
                className="progress-sm shadow-sm mb-1"
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" lg="4" xl="2">
          <Card className="flex-fill">
            <CardHeader>
              <h5 className="card-title mb-0 text-nowrap">
                <Trans>dashboard.analytics.widget_views</Trans>
              </h5>
            </CardHeader>
            <CardBody className="my-2">
              <Row className="d-flex align-items-center mb-4">
                <Col xs="6">
                  <h2 className="d-flex align-items-center mb-0 font-weight-light">
                    {counters ? (
                      counters.video_progress_start_count
                    ) : (
                      <Loader />
                    )}
                  </h2>
                </Col>
                <Col xs="6" className="text-right"></Col>
              </Row>

              <div className="progress-sm" />
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" lg="4" xl="2">
          <Card className="flex-fill">
            <CardHeader>
              <h5 className="card-title mb-0 text-nowrap">
                <Trans>dashboard.analytics.widget_midpoints</Trans>
              </h5>
            </CardHeader>
            <CardBody className="my-2">
              <Row className="d-flex align-items-center mb-4">
                <Col xs="6">
                  <h2 className="d-flex align-items-center mb-0 font-weight-light">
                    {counters ? (
                      counters.video_progress_midpoint_count
                    ) : (
                      <Loader />
                    )}
                  </h2>
                </Col>
                <Col xs="6" className="text-right">
                  <span className="text-muted text-nowrap">
                    {counters &&
                      calculatePercentage(
                        counters.video_progress_midpoint_count,
                        counters.video_progress_start_count
                      )}
                    %
                  </span>
                </Col>
              </Row>

              <Progress
                color="warning"
                value={
                  counters
                    ? calculatePercentage(
                        counters.video_progress_midpoint_count,
                        counters.video_progress_start_count
                      )
                    : 0
                }
                className="progress-sm shadow-sm mb-1"
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" lg="4" xl="2">
          <Card className="flex-fill">
            <CardHeader>
              <h5 className="card-title mb-0 text-nowrap">
                <Trans>dashboard.analytics.widget_completes</Trans>
              </h5>
            </CardHeader>
            <CardBody className="my-2">
              <Row className="d-flex align-items-center mb-4">
                <Col xs="6">
                  <h2 className="d-flex align-items-center mb-0 font-weight-light">
                    {counters ? (
                      counters.video_progress_complete_count
                    ) : (
                      <Loader />
                    )}
                  </h2>
                </Col>
                <Col xs="6" className="text-right">
                  <span className="text-muted text-nowrap">
                    {counters &&
                      calculatePercentage(
                        counters.video_progress_complete_count,
                        counters.video_progress_start_count
                      )}
                    %
                  </span>
                </Col>
              </Row>

              <Progress
                color="success"
                value={
                  counters
                    ? calculatePercentage(
                        counters.video_progress_complete_count,
                        counters.video_progress_start_count
                      )
                    : 0
                }
                className="progress-sm shadow-sm mb-1"
              />
            </CardBody>
          </Card>
        </Col>
        <Col xs="12" lg="4" xl="2">
          <Card className="flex-fill">
            <CardHeader>
              <h5 className="card-title mb-0 text-nowrap">
                <Trans>dashboard.analytics.widget_clicks</Trans>
              </h5>
            </CardHeader>
            <CardBody className="my-2">
              <Row className="d-flex align-items-center mb-4">
                <Col xs="6">
                  <h2 className="d-flex align-items-center mb-0 font-weight-light">
                    {counters ? (
                      counters.call_to_action_button_click_count
                    ) : (
                      <Loader />
                    )}
                  </h2>
                </Col>
                <Col xs="6" className="text-right">
                  <span className="text-muted text-nowrap">
                    {counters &&
                      calculatePercentage(
                        counters.call_to_action_button_click_count,
                        counters.widget_open_count
                      )}
                    %
                  </span>
                </Col>
              </Row>

              <Progress
                color="secondary"
                value={
                  counters
                    ? calculatePercentage(
                        counters.call_to_action_button_click_count,
                        counters.widget_open_count
                      )
                    : 0
                }
                className="progress-sm shadow-sm mb-1"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {widgetToken && (
        <Row>
          <Col>
            <Card>
              <ItemsAnalytics
                widgetToken={widgetToken}
                dateFrom={dateFrom}
                dateTo={dateTo}
                deviceType={deviceType}
              />
            </Card>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Card>
            <CardBody>
              {false && (
                <div style={{ height: "300px" }}>
                  <center>
                    <Spinner
                      color="secondary"
                      style={{ opacity: 0.3, marginTop: "130px" }}
                    />
                  </center>
                </div>
              )}

              {dates && series && (
                <div className="chart">
                  <AnalyticsChart dates={dates} series={series} />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          <Tabs
            tabs={[
              {
                name: "by_date",
                title: t("dashboard.analytics.by_date"),
              },
              {
                name: "by_widgets",
                title: t("dashboard.analytics.by_widget"),
              },
            ]}
            activeTab={activeTab}
            onChange={(tabName) => setActiveTab(tabName)}
          />
        </Col>
      </Row>

      {dates && series && (
        <Row>
          <Col xs="12">
            <Card>
              {activeTab === "by_widgets" && (
                <AnalyticsForWidgets
                  widgets={widgets}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                  widgetToken={selectedWidget}
                  deviceType={deviceType}
                />
              )}

              {activeTab === "by_date" && (
                <div>
                  {dates && (
                    <Table responsive bordered hover>
                      <thead>
                        <tr>
                          <th className="text-nowrap">
                            <Trans>dashboard.analytics.date</Trans>
                          </th>
                          <th className="text-nowrap">
                            <Trans>
                              dashboard.analytics.widget_uniq_loads_short
                            </Trans>
                          </th>
                          <th className="text-nowrap">
                            <Trans>
                              dashboard.analytics.widget_opens_short
                            </Trans>
                          </th>
                          <th className="text-nowrap">
                            <Trans>
                              dashboard.analytics.widget_opens_percentage
                            </Trans>
                          </th>
                          <th className="text-nowrap">
                            <Trans>
                              dashboard.analytics.video_progress_start_count_short
                            </Trans>
                          </th>
                          <th className="text-nowrap">
                            <Trans>
                              dashboard.analytics.widget_midpoints_short
                            </Trans>
                          </th>
                          <th className="text-nowrap">
                            <Trans>
                              dashboard.analytics.widget_midpoints_percentage
                            </Trans>
                          </th>
                          <th className="text-nowrap">
                            <Trans>
                              dashboard.analytics.widget_completes_short
                            </Trans>
                          </th>
                          <th className="text-nowrap">
                            <Trans>
                              dashboard.analytics.widget_completes_percentage
                            </Trans>
                          </th>
                          <th className="text-nowrap">
                            <Trans>
                              dashboard.analytics.widget_clicks_short
                            </Trans>
                          </th>
                          <th className="text-nowrap">
                            <Trans>
                              dashboard.analytics.widget_clicks_percentage
                            </Trans>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {dates.map((date, index) => (
                          <tr key={date}>
                            <td className="text-nowrap">{date}</td>
                            <td>
                              {eventValueOutput(
                                "widget_load_uniq_count",
                                index
                              )}
                            </td>
                            <td>
                              {eventValueOutput("widget_open_count", index)}
                            </td>
                            <td>
                              {eventPercentage(
                                "widget_open_count",
                                "widget_load_uniq_count",
                                index
                              )}
                            </td>
                            <td>
                              {eventValueOutput(
                                "video_progress_start_count",
                                index
                              )}
                            </td>
                            <td>
                              {eventValueOutput(
                                "video_progress_midpoint_count",
                                index
                              )}
                            </td>
                            <td>
                              {eventPercentage(
                                "video_progress_midpoint_count",
                                "video_progress_start_count",
                                index
                              )}
                            </td>
                            <td>
                              {eventValueOutput(
                                "video_progress_complete_count",
                                index
                              )}
                            </td>
                            <td>
                              {eventPercentage(
                                "video_progress_complete_count",
                                "video_progress_start_count",
                                index
                              )}
                            </td>
                            <td>
                              {eventValueOutput(
                                "call_to_action_button_click_count",
                                index
                              )}
                            </td>
                            <td>
                              {eventPercentage(
                                "call_to_action_button_click_count",
                                "widget_open_count",
                                index
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              )}
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Analytics;
