import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../../../constants";
import Form from "./Form";

const ReviewsCampaignsNew = (props) => {
  const history = useHistory();

  const newCampaign = {
    name: "Сбор видеоотзывов",
  };

  const createCampaign = (campaign) => {
    axios
      .post(
        `${GETREVIEW_API_HOST}/api/campaigns`,
        { name: campaign.name, recorded_video_id: campaign.video.id },
        { withCredentials: true }
      )
      .then((json) => {
        const { campaign } = json.data;
        toastr.success("", "Кампания была успешно создана");
        history.push(`/reviews/campaigns/${campaign.token}/edit?tab=link`);
      })
      .catch(() => {
        toastr.error("", "Ошибка при создании кампании");
      });
  };

  return <Form campaign={newCampaign} onFormSubmit={createCampaign} />;
};

export default ReviewsCampaignsNew;
