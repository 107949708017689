import React from "react";
import { Trans } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ProjectSettingsForm from "./ProjectSettingsForm";

const ProjectSettingsFormModalWindow = (props) => {
  return (
    <Modal isOpen={true} size="md" centered className="modal-popup">
      <ModalHeader>
        <Trans>project.new_project</Trans>
      </ModalHeader>
      <ModalBody>
        <ProjectSettingsForm forModalWindow onClose={props.onClose} />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ProjectSettingsFormModalWindow;
