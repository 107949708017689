import axios from "axios";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { GETREVIEW_API_HOST } from "../constants";

const FolderSelector = ({ section, onClose, onComplete }) => {
  const [selectedFolderOption, setSelectedFolderOption] = useState(null);
  const [folders, setFolders] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(`${GETREVIEW_API_HOST}/api/folders`, {
        params: {
          section,
        },
        withCredentials: true,
      })
      .then((json) => {
        setFolders(json.data.folders);
      })
      .catch((error) => {
        alert("Error occurred!");
      });
  }, []);

  const selectFolderOptions = folders.map((folder) => {
    return {
      value: folder.token,
      label: folder.name,
    };
  });

  const selectFolderOptionsGrouped = [
    {
      label: t("move_to_folder_modal.root_folder"),
      options: [
        {
          value: "root",
          label:
            section === "widgets"
              ? t("move_to_folder_modal.video_widgets")
              : t("move_to_folder_modal.video_library"),
        },
      ],
    },
    {
      label: t("move_to_folder_modal.subfolders"),
      options: selectFolderOptions,
    },
  ];

  return (
    <Modal isOpen={true} toggle={onClose} size="md" className="modal-popup">
      <ModalHeader toggle={onClose}>
        {section === "widgest" ? (
          <Trans>move_to_folder_modal.move_widgets</Trans>
        ) : (
          <Trans>move_to_folder_modal.move_videos</Trans>
        )}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            options={selectFolderOptionsGrouped}
            placeholder={t("move_to_folder_modal.choose_folder")}
            isClearable
            value={selectedFolderOption}
            onChange={(optionData) => setSelectedFolderOption(optionData)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onClose}>
          <Trans>move_to_folder_modal.cancel</Trans>
        </Button>

        <Button
          color="primary"
          disabled={selectedFolderOption == null}
          onClick={() => onComplete(selectedFolderOption.value)}
        >
          <Trans>move_to_folder_modal.move</Trans>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FolderSelector;
