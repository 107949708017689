import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import MultiTab from "../../components/MultiTab";
import { Plans } from "./Pricing";
import CloudPaymentsWidget from "./CloudPaymentsWidget";
import PayBoxWidget from "./PayBoxWidget";
import InvoiceWidget from "./InvoiceWidget";
import WorkspaceBillingSettingsModalWindow from "../../components/WorkspaceBillingSettingsModalWindow";
import DownloadInvoiceModalWindow from "../../components/DownloadInvoiceModalWindow";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";

import iconCardRu from "../../assets/img/payment/card_ru.svg";
import iconCardNotRu from "../../assets/img/payment/card_not_ru.svg";
import iconInvoice from "../../assets/img/payment/invoice.svg";
import iconVisa from "../../assets/img/payment/visa.svg";
import iconMasterCard from "../../assets/img/payment/mastercard.svg";
import iconMir from "../../assets/img/payment/mir.svg";
import iconUnionPay from "../../assets/img/payment/unionpay.svg";
import iconSubscriptionEnabled from "../../assets/img/payment/subscription_enabled.svg";

const PaymentMethodBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  font-size: 12px;
  background: white;
  border: 1px solid #cfd4d9;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 12px;
  transition: border-color 0.3s ease-out, transform 0.2s ease-out;

  ${(props) =>
    props.isLoading && {
      opacity: 0.5,
    }}

  &:hover {
    transform: translateY(-2px);
    border-color: #80849c;
  }

  @media (max-width: 568px) {
    flex-direction: column;
  }
`;

const PaymentMethodIcon = styled.div`
  width: 35px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 568px) {
    display: none;
  }
`;

const PaymentMethodType = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const PaymentMethodDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;

  @media (max-width: 568px) {
    margin-top: 15px;
    align-items: flex-start;
    text-align: left;
  }
`;

const PaymentMethod = (props) => {
  // const [fetching, setFetching] = useState(false);

  const onClick = () => {
    // setFetching(true);

    const callback = () => {
      // setFetching(false);
    };

    props.onClick(callback);
  };

  return (
    <PaymentMethodBlock
      id={props.id}
      onClick={onClick}
      isLoading={props.isLoading}
    >
      <PaymentMethodIcon>
        <img src={props.icon} alt="" />
      </PaymentMethodIcon>
      <PaymentMethodType>
        <div>
          <div style={{ fontSize: "14px", fontWeight: "600" }}>
            {props.title}

            {props.isLoading && (
              <Spinner
                color="dark"
                size="sm"
                className="ml-3"
                style={{
                  width: "0.8rem",
                  height: "0.8rem",
                  position: "relative",
                  bottom: "3px",
                }}
              />
            )}
          </div>
          <div style={{ fontSize: "12px", color: "#686868" }}>
            {props.subtitle}
          </div>
        </div>
      </PaymentMethodType>
      <PaymentMethodDescription>{props.children}</PaymentMethodDescription>
    </PaymentMethodBlock>
  );
};

const PricingModalForm = (props) => {
  const [period, setPeriod] = useState(props.period);
  const [
    workspaceBillingSettingsModalWindowIsOpen,
    setWorkspaceBillingSettingsModalWindowIsOpen,
  ] = useState(false);
  const [downloadInvoicePdfUrl, setDownloadInvoicePdfUrl] = useState(null);
  const [invoiceIsGenerating, setInvoiceIsGenerating] = useState(false);
  const { workspace, account } = useSelector((state) => state.identity);

  useEffect(() => {
    (function (p, a, y, b, o, x) {
      o = p.createElement(a);
      x = p.getElementsByTagName(a)[0];
      o.async = 1;
      o.src =
        "https://widget.paybox.money/v1/paybox/pbwidget.js?" + 1 * new Date();
      x.parentNode.insertBefore(o, x);
    })(document, "script");

    if (process.env.NODE_ENV === "production") {
      window.dataLayer.push({
        event: "eventTracking",
        category: "Purchase",
        action: "planChosen",
      });
    }
  }, []);

  const priceForPlan = (withDiscount) => {
    const planOptions = Plans[props.plan];

    if (period === 12) {
      return withDiscount
        ? planOptions.annually
        : planOptions.annually_without_discount;
    } else {
      return period * planOptions.monthly;
    }
  };

  return (
    <>
      {workspaceBillingSettingsModalWindowIsOpen && (
        <WorkspaceBillingSettingsModalWindow
          onSuccess={() => {
            setWorkspaceBillingSettingsModalWindowIsOpen(false);
            InvoiceWidget(props.plan, period, (invoicePdfUrl) => {
              setDownloadInvoicePdfUrl(invoicePdfUrl);
            });
          }}
          onClose={() => {
            setWorkspaceBillingSettingsModalWindowIsOpen(false);
          }}
        />
      )}

      {downloadInvoicePdfUrl != null && (
        <DownloadInvoiceModalWindow
          onClick={() => {
            window.open(downloadInvoicePdfUrl, "_blank");
            setDownloadInvoicePdfUrl(null);
            props.onClose();
          }}
          onClose={() => {
            setDownloadInvoicePdfUrl(null);
          }}
        />
      )}

      <Modal
        isOpen={true}
        toggle={props.onClose}
        size="md"
        centered
        className="modal-popup"
      >
        <ModalHeader>
          Оплата подписки на тариф {Plans[props.plan].name}
        </ModalHeader>
        <ModalBody>
          <div
            className="d-flex flex-sm-row flex-column justify-content-between"
            style={{ minHeight: "85px" }}
          >
            <div style={{ marginTop: "3px" }}>
              <MultiTab
                tabs={[
                  { name: 1, title: "1 месяц" },
                  { name: 3, title: "3 месяца" },
                  { name: 6, title: "6 месяцев" },
                  { name: 12, title: "12 месяцев", extraTitle: "−20%" },
                ]}
                activeTab={period}
                onChange={(newPeriod) => setPeriod(newPeriod)}
              />
            </div>

            <div className="text-sm-left text-md-right">
              Сумма к оплате
              {period === 12 && (
                <div
                  style={{
                    textDecoration: "line-through",
                    color: "#D9D9D9",
                    fontWeight: "600",
                    fontSize: "22px",
                  }}
                >
                  {priceForPlan(false)} ₽
                </div>
              )}
              <div
                style={{
                  color: "#020A38",
                  fontWeight: "600",
                  fontSize: "22px",
                  marginTop: "-5px",
                }}
              >
                {priceForPlan(true)} ₽
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "20px", marginTop: "10px" }}>
            Способ оплаты
          </div>

          <PaymentMethod
            id="pay_with_card_button_ru"
            icon={iconCardRu}
            title="Банковская карта РФ"
            subtitle="Если ваша карта выпущена в РФ"
            onClick={(callback) =>
              CloudPaymentsWidget(props.plan, period, account, true, callback)
            }
          >
            <div>
              <img src={iconVisa} alt="" />
              <img src={iconMasterCard} alt="" style={{ marginLeft: "8px" }} />
              <img src={iconMir} alt="" style={{ marginLeft: "8px" }} />
            </div>
            <div style={{ color: "#3BA336", marginTop: "5px" }}>
              <img
                src={iconSubscriptionEnabled}
                alt=""
                style={{
                  position: "relative",
                  top: "-2px",
                  marginRight: "1px",
                }}
              />{" "}
              С автопродлением
            </div>
          </PaymentMethod>

          <PaymentMethod
            id="pay_with_card_button_not_ru"
            icon={iconCardNotRu}
            title="Банковская карта НЕ РФ"
            subtitle="Если ваша карта выпущена за пределами РФ"
            onClick={(callback) =>
              CloudPaymentsWidget(props.plan, period, account, false, callback)
            }
          >
            <div>
              <img src={iconVisa} alt="" />
              <img src={iconMasterCard} alt="" style={{ marginLeft: "8px" }} />
            </div>
            <div style={{ color: "#686868", marginTop: "5px" }}>
              Без автопродления
            </div>
          </PaymentMethod>

          <PaymentMethod
            id="pay_with_card_button_invoice"
            icon={iconInvoice}
            title="Оплата по счету"
            subtitle="Для юридических лиц и ИП"
            isLoading={invoiceIsGenerating}
            onClick={() => {
              if (invoiceIsGenerating === true) {
                return;
              } else {
                setInvoiceIsGenerating(true);
              }

              if (props.plan === "light" && period === 1) {
                toastr.error(
                  "",
                  "Тариф Лайт доступен к оплате по счету от 3-х месяцев"
                );
                return;
              }

              if (
                workspace.billing_company_inn == null ||
                workspace.billing_company_inn === "" ||
                workspace.billing_company_name == null ||
                workspace.billing_company_name === ""
              ) {
                setWorkspaceBillingSettingsModalWindowIsOpen(true);
              } else {
                InvoiceWidget(props.plan, period, (invoicePdfUrl) => {
                  setInvoiceIsGenerating(false);
                  setDownloadInvoicePdfUrl(invoicePdfUrl);
                });
              }
            }}
          >
            <div style={{ color: "#686868" }}>
              Закрывающие документы
              <br />
              отправляем в ЭДО
            </div>
          </PaymentMethod>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
};

export default PricingModalForm;
