import React, { useState, useEffect } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useSelector, useDispatch } from "react-redux";
import { GETREVIEW_API_HOST } from "../../constants";
import * as Icon from "react-feather";
import { loadIdentity } from "../../redux/actions/identityActions";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CustomInput,
  Button,
  Form,
  FormGroup,
  UncontrolledAlert,
} from "reactstrap";

const Facebook = () => {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.identity);

  const [facebookPixelEnabled, setFacebookPixelEnabled] = useState(
    project.facebook_pixel_enabled
  );
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    const changed = project.facebook_pixel_enabled !== facebookPixelEnabled;
    setFormChanged(changed);
  }, [project, facebookPixelEnabled]);

  const saveSettings = () => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/projects/${project.token}`,
        { facebook_pixel_enabled: facebookPixelEnabled },
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success("", "Настройки были успешно сохранены");
        setFormChanged(false);
        dispatch(loadIdentity());
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error(
            "",
            "Возникла неизвестная ошибка при сохранении настроек"
          );
        }
      });
  };

  return (
    <Container fluid className="p-0">
      <Row className="mb-2 mb-xl-4">
        <Col xs="auto" className="d-none d-sm-block">
          <h3>Интеграция с Facebook Pixel</h3>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <CustomInput
                    type="switch"
                    id="exampleCustomSwitch"
                    name="customSwitch"
                    label="Отправлять события в Facebook Pixel"
                    checked={facebookPixelEnabled}
                    onChange={(event) =>
                      setFacebookPixelEnabled(event.target.checked)
                    }
                  />
                </FormGroup>

                <Button
                  color="primary"
                  disabled={formChanged === false}
                  onClick={saveSettings}
                >
                  Сохранить настройки
                </Button>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              Данный механизм позволяет настроить автоматическую передачу
              событий из видеовиджета в рекламный кабинет{" "}
              <b className="text-primary">
                Facebook{" "}
                <Icon.Facebook size={16} style={{ marginTop: "-3px" }} />
              </b>
              <br />
              Используя события из видеовиджета вы можете создавать{" "}
              <mark>кастомные аудитории</mark> в Facebook Ads, строить{" "}
              <mark>похожие аудитории</mark> (look-alike) и т.п.
              <br />К примеру, вы можете настроить <mark>ретаргетинг</mark> на
              пользователей, которые посмотрели определенное видео в
              видеовиджете.
              <br />
              <br />
              Список событий, которые можно передавать:
              <br />
              <br />
              <ul>
                <li>
                  <code>GetReview_Widget_Open</code>
                  <br />
                  Событие отправляется при <mark>клике</mark> пользователя на
                  видеовиджет, то есть в тот момент,
                  <br />
                  когда начинается непосредственный <mark>просмотр</mark> видео
                  ролика.
                  <br />
                  <br />
                </li>
                <li>
                  <code>GetReview_Widget_Midpoint</code>
                  <br />
                  Событие отправляется, когда пользователь посмотрел{" "}
                  <mark>половину</mark> видео ролика.
                  <br />
                  <br />
                </li>
                <li>
                  <code>GetReview_Widget_Complete</code>
                  <br />
                  Событие отправляется, когда пользователь{" "}
                  <mark>полностью</mark> посмотрел видео ролик.
                  <br />
                  <br />
                </li>
                <li>
                  <code>GetReview_Widget_CTA_Click</code>
                  <br />
                  Событие отправляется при <mark>клике</mark> пользователя на{" "}
                  <mark>Call-To-Action</mark> кнопку в видеовиджете.
                  <br />
                  Если видеовиджет настроен без использования CTA, то данное
                  событие не будет отправлено.
                  <br />
                  <br />
                </li>
              </ul>
              В каждое событие в Facebook Pixel мы передаем дополнительные
              параметры:
              <br />
              <br />
              <ul>
                <li>
                  <code>WidgetID</code>
                  <br />
                  Уникальный идентификатор видеовиджета.
                  <br />
                  <br />
                </li>
                <li>
                  <code>VideoID</code>
                  <br />
                  Уникальный идентификатор видео, которое смотрел пользователь.
                  <br />
                  <br />
                </li>
              </ul>
              <UncontrolledAlert color="success">
                <div className="alert-message">
                  <h4 className="alert-heading">Внимание!</h4>
                  <p className="mb-0">
                    В рекламном кабинете Facebook в разделе Events Manager вы
                    можете найти события из видеовиджета GetReview_*. Обратите
                    внимание, что дополнительные параметры будут удалены и
                    отмечены как _removed_. Это относится только лишь к разделу
                    Events Manager. Использовать дополнительные параметры
                    GetReview_* вы можете в других разделах рекламного кабинета,
                    например, в разделе создания аудитории.
                  </p>
                </div>
              </UncontrolledAlert>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Facebook;
