import React from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Trans } from "react-i18next";

import { isMobile } from "react-device-detect";

import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Box } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { toggleSidebar } from "../redux/actions/sidebarActions";

import routes from "../routes/index";
import avatar from "../assets/img/avatars/avatar.jpg";

import logotype from "../assets/img/logo/logotype_inverted.svg";

import ProjectSelectorSidebar from "./ProjectSelectorSidebar";

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to,
  }) => {
    const getSidebarItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <div style={{ width: "30px", display: "inline-block" }}>
            <FontAwesomeIcon className="mr-3" icon={Icon} />
          </div>
          <span className="align-middle">
            <Trans>{name}</Trans>
          </span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const dispatch = useDispatch();

    const getSidebarItemClass = (path) => {
      return location.pathname === path ? "active" : "";
    };

    const hideSidebarOnMobile = () => {
      if (isMobile) {
        dispatch(toggleSidebar());
      }
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink
          to={to}
          className="sidebar-link"
          activeClassName="active"
          onClick={hideSidebarOnMobile}
        >
          {Icon ? (
            <div style={{ width: "30px", display: "inline-block" }}>
              <FontAwesomeIcon className="mr-3" icon={Icon} />
            </div>
          ) : null}
          <Trans>{name}</Trans>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    let initialState = {};

    // routes.forEach((route, index) => {
    //   route.isOpen = typeof route.isOpen === 'undefined' ? true : route.isOpen;
    // });

    this.state = initialState;
  }

  toggle = (index) => {
    routes[index].isOpen = !this.state[index];

    this.setState((state) => ({
      [index]: !state[index],
    }));
  };

  UNSAFE_componentWillMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.isOpen;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome,
      }));
    });
  }

  render() {
    const { sidebar, layout } = this.props;

    return (
      <nav
        className={
          "sidebar" +
          (!sidebar.isOpen ? " toggled" : "") +
          (sidebar.isSticky ? " sidebar-sticky" : "")
        }
      >
        <div className="sidebar-content">
          <PerfectScrollbar>
            <a className="sidebar-brand" href="/">
              <img src={logotype} alt="GetReview" />
            </a>

            <ProjectSelectorSidebar />

            <ul className="sidebar-nav">
              {routes.map((category, index) => {
                if (category.hidden == true) {
                  return false;
                }

                return (
                  <React.Fragment key={index}>
                    {category.header ? (
                      <li className="sidebar-header">
                        <Trans>{category.header}</Trans>
                      </li>
                    ) : null}

                    {category.children && category.childrenHidden !== true ? (
                      <SidebarCategory
                        name={category.name}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={this.state[index]}
                        onClick={() => this.toggle(index)}
                      >
                        {category.children
                          .filter((route) => !route.hidden)
                          .map((route, index) => (
                            <SidebarItem
                              key={index}
                              name={route.name}
                              to={route.path}
                              badgeColor={route.badgeColor}
                              badgeText={route.badgeText}
                            />
                          ))}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem
                        name={category.name}
                        to={category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </ul>

            {!layout.isBoxed && !sidebar.isSticky ? (
              <div className="sidebar-bottom d-none d-lg-block">
                <div className="media">
                  <img
                    className="rounded-circle mr-3"
                    src={avatar}
                    alt="Chris Wood"
                    width="40"
                    height="40"
                  />
                  <div className="media-body">
                    <h5 className="mb-1">Chris Wood</h5>
                    <div>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="text-success"
                      />{" "}
                      Online
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

export default withRouter(
  connect((store) => ({
    sidebar: store.sidebar,
    layout: store.layout,
  }))(Sidebar)
);
