import React from "react";
import { Trans } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const NewStoryBlock = (props) => {
  return (
    <div className="widget-new-item" onClick={props.onClick}>
      <center>
        <div className="new-item-text">
          <Trans>dashboard.widget_form.video.add_video</Trans>
        </div>
        <FontAwesomeIcon icon={faPlus} className="new-item-icon" />
      </center>
    </div>
  );
};

export default NewStoryBlock;
