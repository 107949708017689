import React from "react";
import axios from 'axios';
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";

class ChangePlan extends React.Component {
  constructor(props) {
    super(props);

    this.account = this.props.account.current;

    this.state = {
    };
  }

  componentDidMount() {
    var script = document.createElement('script');
    script.src = 'https://widget.cloudpayments.ru/bundles/cloudpayments';

    if (document.head) {
      document.head.appendChild(script);
    }
  }

  pay() {
    var widget = new window.cp.CloudPayments();

    widget.charge({ // options
      publicId: 'pk_5c042241b3dfe94d3f9dfafdbc47d',  //id из личного кабинета
      description: 'Пример оплаты (деньги сниматься не будут)', //назначение
      amount: 10, //сумма
      currency: 'RUB', //валюта
      invoiceId: '1234567', //номер заказа  (необязательно)
      accountId: 'user@example.com', //идентификатор плательщика (необязательно)
      skin: "mini", //дизайн виджета
      data: {
        myProp: 'myProp value' //произвольный набор параметров
      }
    },
    function (options) {
      alert('Успешно оплачено!');
    },
    function (reason, options) {
        alert('Оплата не прошла!');
    });
  }

  render() {
    const query = new URLSearchParams(this.props.location.search);
    const new_plan_name = query.get('plan');

    return (
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-4">
          <Col xs="auto" className="d-none d-sm-block">
            <h3>Смена тарифного плана</h3>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <p>
                  Ваш текущий тарифный план: <b>{this.account.plan.name}</b>
                </p>

                <p>
                  Новый тарифный план: <b>{new_plan_name}</b>
                </p>

                <Button color="primary" onClick={this.pay}>
                  Оплатить
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
};

const mapStateToProps = state => ({
  account: state.account,
});

export default connect(mapStateToProps)(ChangePlan);
