import React, { useState, useEffecf } from "react";
import { GETREVIEW_API_HOST } from "../constants";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

import Uppy from "@uppy/core";
import { DashboardModal, useUppy } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";
import RussianLocale from "@uppy/locales/lib/ru_RU";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

const Uploader = (props) => {
  const { t, i18n } = useTranslation();
  const uppyLocale = i18n.language === "ru" ? RussianLocale : null;

  const { workspace, project } = useSelector((state) => state.identity);
  const maxNumberOfFiles = props.disableMultipleUpload === true ? 1 : 10;

  const uppy = useUppy(() => {
    const uppyOptions = {
      autoProceed: false,
      locale: uppyLocale,
      restrictions: {
        maxFileSize: 1024 * 1024 * 2000, // 300MB
        maxNumberOfFiles: maxNumberOfFiles,
        allowedFileTypes: ["video/*"],
      },
    };

    const uppyInstance = new Uppy(uppyOptions);

    uppyInstance.use(XHRUpload, {
      endpoint: `${GETREVIEW_API_HOST}/api/videos/upload`,
      withCredentials: true,
      headers: {
        Workspace: workspace.token,
        Project: project.token,
      },
      timeout: 120 * 1000,
    });

    uppyInstance.on("file-added", (file) => {
      uppy.setFileMeta(file.id, {
        folder: props.folderToken,
      });
    });

    uppyInstance.on("complete", (result) => {
      let uploadedVideos = [];

      result.successful.forEach((uppyVideo) => {
        let recordedVideo = uppyVideo.response.body.video;

        if (recordedVideo != null) {
          uploadedVideos.push(recordedVideo);
        }
      });

      if (result.failed.length === 0) {
        uppyInstance.reset();
        setOpened(false);

        if (props.onSuccess) {
          props.onSuccess(uploadedVideos);
        }
      }
    });

    return uppyInstance;
  });

  const [opened, setOpened] = useState(false);
  const note =
    props.disableMultipleUpload === true
      ? t("video_uploader.max_file_size_single")
      : t("video_uploader.max_file_size_multiple");

  return (
    <div className="video-uploader">
      <Button
        color="secondary"
        className="mr-3"
        onClick={() => setOpened(true)}
      >
        <FontAwesomeIcon icon={faVideo} className="mr-3" />
        <Trans>video_uploader.upload_video</Trans>
      </Button>

      <DashboardModal
        uppy={uppy}
        open={opened}
        closeModalOnClickOutside
        onRequestClose={() => setOpened(false)}
        note={note}
        locale={{
          strings: {
            dropPasteFiles: t("video_uploader.drop_paste_here"),
            browse: t("video_uploader.browse"),
          },
        }}
      />
    </div>
  );
};

export default Uploader;
