import React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withTranslation, useTranslation, Trans } from "react-i18next";
import Uploader from "../../components/Uploader";
import VideoSelector from "../../components/VideoSelector";
import ColorPicker from "../../components/ColorPicker";
import RecordedVideo from "./RecordedVideo";
import DisplayPagesSettings from "./DisplayPagesSettings";
import DisplayByCustomProperties from "./DisplayByCustomProperties";

import {
  Form,
  FormGroup,
  FormText,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Spinner,
  Row,
  Col,
  PopoverBody,
  UncontrolledPopover,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import CTAColorsImg from "../../assets/img/screenshots/cta-colors.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faInfoCircle,
  faQuestionCircle,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";

const TimeDelayForm = ({
  valuePropertyName,
  dimensionPropertyName,
  state,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: "10px", marginLeft: "20px", display: "flex" }}>
      <Input
        type="text"
        name={valuePropertyName}
        value={state[valuePropertyName]}
        onChange={onChange}
        style={{ width: "50px", marginRight: "10px" }}
      />
      <Input
        type="select"
        name={dimensionPropertyName}
        value={state[dimensionPropertyName]}
        onChange={onChange}
        style={{ width: "100px" }}
      >
        <option value="hour">
          {t("dashboard.widget_form.frequency.time_delay_hour")}
        </option>
        <option value="day">
          {t("dashboard.widget_form.frequency.time_delay_day")}
        </option>
      </Input>
    </div>
  );
};

class WidgetsForm extends React.Component {
  static defaultProps = {
    hide_after_destroy: false,
    desktop_enabled: true,
    mobile_enabled: true,
    recorded_videos: [],
    cta_scope: "",
    cta_show_at: "5",
    cta_form_email: "Ваш email",
    cta_form_email_checked: true,
    cta_form_phone: "Ваш телефон",
    cta_form_phone_checked: false,
    cta_form_name: "Ваше имя",
    cta_form_name_checked: true,
    cta_form_submit_text: "Отправить",
    cta_link: "",
    cta_link_new_window: false,
    cta_scroll: "",
    cta_click: "",
    margin_side: 50,
    margin_bottom: 50,
    mobile_margin_side: 25,
    mobile_margin_bottom: 25,
    customization_form: "",
    customization_border_color: "#ffffff",
    customization_border_hover: "#0000FF",
    customization_cta_color: "#0000FF",
    customization_cta_text_color: "#ffffff",
    position: "left",
    display_pages: "all",
    display_pages_list: [],
    site_token: "",
    form_sending: false,
    show_widget_code_example: false,
    show_scroll_video_tutorial: false,
    show_trigger_video_tutorial: false,
    show_margins_block: false,
    show_frequency_block: false,

    frequencyUserInteractedOption: "every_pageload",
    frequencyUserInteractedDelayDimension: "day",
    frequencyUserInteractedDelayValue: 1,
    frequencyUserDestroyedOption: "every_pageload",
    frequencyUserDestroyedDelayDimension: "day",
    frequencyUserDestroyedDelayValue: 1,

    displayByCustomPropertiesRules: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      ...props,
    };

    if (props.name == null) {
      this.state.name = this.props.t(
        "dashboard.widget_form.default_widget_name"
      );
    }

    if (props.cta_button_text == null) {
      this.state.cta_button_text = this.props.t(
        "dashboard.widget_form.cta.cta_button_placeholder"
      );
    }
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.state.display_pages !== "all" &&
      this.state.display_pages_list.length === 0
    ) {
      this.setState({
        display_pages_list: [{ type: "equals_without_params", url: "" }],
      });
    }
  };

  onInputChangeHandler = (event) => {
    let name = event.target.name;
    let value =
      event.target.type == "checkbox"
        ? event.target.checked
        : event.target.value;

    this.setState({ [name]: value });
  };

  onCTASelectHandler = (event) => {
    let value = event.target.value;
    this.setState({ cta_scope: value });
  };

  onShowAtChangeHandler = (event) => {
    let value = parseInt(event.target.value) || 0;
    this.setState({ cta_show_at: value });
  };

  onChangeFormEmailHandler = (event) => {
    let value = event.target.checked;
    this.setState({ cta_form_email_checked: value });
  };

  onChangeFormPhoneHandler = (event) => {
    let value = event.target.checked;
    this.setState({ cta_form_phone_checked: value });
  };

  onChangeFormNameHandler = (event) => {
    let value = event.target.checked;
    this.setState({ cta_form_name_checked: value });
  };

  onBorderColorChangeHandler = (color) => {
    this.setState({ customization_border_color: color });
  };

  onBorderHoverChangeHandler = (color) => {
    this.setState({ customization_border_hover: color });
  };

  onCTAColorChangeHandler = (color) => {
    this.setState({ customization_cta_color: color });
  };

  onCTATextColorChangeHandler = (color) => {
    this.setState({ customization_cta_text_color: color });
  };

  onUploadSuccess = (uploadedVideos) => {
    const recordedVideos = [...this.state.recorded_videos];
    const updatedRecordedVideos = [...recordedVideos, ...uploadedVideos];

    this.setState({ recorded_videos: updatedRecordedVideos });
  };

  onVideoSelectorComplete = (selectedVideos) => {
    const recordedVideos = [...this.state.recorded_videos];
    const updatedRecordedVideos = [...recordedVideos, ...selectedVideos];

    this.setState({ recorded_videos: updatedRecordedVideos });
  };

  removeRecordedVideo = (id) => {
    const confirmText = this.props.t(
      "dashboard.widget_form.video.remove_confirmation"
    );
    const successText = this.props.t(
      "dashboard.widget_form.video.dont_forget_to_save"
    );

    toastr.confirm(confirmText, {
      onOk: () => {
        let filteredArray = this.state.recorded_videos.filter(
          (item) => item.id !== id
        );
        this.setState({ recorded_videos: filteredArray });
        toastr.success("", successText);
      },
    });
  };

  addPage = () => {
    const newList = [...this.state.display_pages_list];
    newList.push({ url: "", type: "equals_without_params" });
    this.setState({ display_pages_list: newList });
  };

  changePageType = (type, index) => {
    const newList = [...this.state.display_pages_list];
    newList[index].type = type;
    this.setState({ display_pages_list: newList });
  };

  changePageURL = (url, index) => {
    const newList = [...this.state.display_pages_list];
    newList[index].url = url;
    this.setState({ display_pages_list: newList });
  };

  deletePage = (index) => {
    const newList = [...this.state.display_pages_list];
    newList.splice(index, 1);
    this.setState({ display_pages_list: newList });
  };

  submitForm = (event) => {
    if (this.state.recorded_videos.length == 0) {
      toastr.error(
        "",
        this.props.t("dashboard.widget_form.errors.video_not_selected")
      );
      return;
    }

    const marginSideValue = parseInt(this.state.margin_side);
    const marginBottomValue = parseInt(this.state.margin_bottom);
    const mobileMarginSideValue = parseInt(this.state.mobile_margin_side);
    const mobileMarginBottomValue = parseInt(this.state.mobile_margin_bottom);

    if (
      isNaN(marginSideValue) ||
      marginSideValue < 0 ||
      marginSideValue > 500 ||
      isNaN(marginBottomValue) ||
      marginBottomValue < 0 ||
      marginBottomValue > 500 ||
      isNaN(mobileMarginSideValue) ||
      mobileMarginSideValue < 0 ||
      mobileMarginSideValue > 500 ||
      isNaN(mobileMarginBottomValue) ||
      mobileMarginBottomValue < 0 ||
      mobileMarginBottomValue > 500
    ) {
      toastr.error(
        "",
        this.props.t("dashboard.widget_form.errors.margins_invalid")
      );
      return;
    }

    if (this.state.cta_scope == "scroll") {
      const blockIDRegEx = /^[A-Za-z\d]+[\w\-\:\.]*$/;

      if (!blockIDRegEx.test(this.state.cta_scroll)) {
        toastr.error(
          "",
          this.props.t("dashboard.widget_form.errors.scroll_selector_invalid")
        );
        return;
      }
    }

    if (this.state.cta_scope == "click") {
      const selectorRegEx = /^[\wа-я\s\>\(\)\:\.\,\-\_\=\#\[\]\"\']+$/;

      if (!selectorRegEx.test(this.state.cta_click)) {
        toastr.error(
          "",
          this.props.t("dashboard.widget_form.errors.button_selector_invalid")
        );
        return;
      }
    }

    if (
      this.state.desktop_enabled == false &&
      this.state.mobile_enabled == false
    ) {
      toastr.error(
        "",
        this.props.t("dashboard.widget_form.errors.desktop_and_mobile_disabled")
      );
      return;
    }

    this.setState({ form_sending: true });

    const formData = {
      name: this.state.name,
      hide_after_destroy: this.state.hide_after_destroy,
      desktop_enabled: this.state.desktop_enabled,
      mobile_enabled: this.state.mobile_enabled,
      cta_scope: this.state.cta_scope,
      cta_button_text: this.state.cta_button_text,
      cta_show_at: this.state.cta_show_at,
      cta_form_email: this.state.cta_form_email,
      cta_form_email_checked: this.state.cta_form_email_checked,
      cta_form_phone: this.state.cta_form_phone,
      cta_form_phone_checked: this.state.cta_form_phone_checked,
      cta_form_name: this.state.cta_form_name,
      cta_form_name_checked: this.state.cta_form_name_checked,
      cta_form_submit_text: this.state.cta_form_submit_text,
      cta_link: this.state.cta_link,
      cta_link_new_window: this.state.cta_link_new_window,
      cta_scroll: this.state.cta_scroll,
      cta_click: this.state.cta_click,
      margin_side: this.state.margin_side,
      margin_bottom: this.state.margin_bottom,
      mobile_margin_side: this.state.mobile_margin_side,
      mobile_margin_bottom: this.state.mobile_margin_bottom,
      site_token: this.props.identity.project.token,
    };

    formData.recorded_video_ids = this.state.recorded_videos.map((item) => {
      return item.id;
    });

    if (this.state.customization_form === "visible") {
      formData.customization_border_color =
        this.state.customization_border_color;
      formData.customization_border_hover =
        this.state.customization_border_hover;
      formData.customization_cta_color = this.state.customization_cta_color;
      formData.customization_cta_text_color =
        this.state.customization_cta_text_color;
    }

    formData.display_pages = this.state.display_pages;
    formData.display_pages_list = this.state.display_pages_list;
    formData.position = this.state.position;

    formData.frequencyUserInteractedOption =
      this.state.frequencyUserInteractedOption;
    formData.frequencyUserInteractedDelayDimension =
      this.state.frequencyUserInteractedDelayDimension;
    formData.frequencyUserInteractedDelayValue =
      this.state.frequencyUserInteractedDelayValue;
    formData.frequencyUserDestroyedOption =
      this.state.frequencyUserDestroyedOption;
    formData.frequencyUserDestroyedDelayDimension =
      this.state.frequencyUserDestroyedDelayDimension;
    formData.frequencyUserDestroyedDelayValue =
      this.state.frequencyUserDestroyedDelayValue;

    formData.displayByCustomPropertiesRules =
      this.state.displayByCustomPropertiesRules;

    this.props.onFormSubmitHandler(
      formData,
      () => {
        this.setState({ form_sending: false });
      },
      (error) => {
        alert("Ошибка при сохранении видеовиджета");
        console.log("errorCallback", error);
        this.setState({ form_sending: false });
      }
    );
  };

  render() {
    const planName = this.props.identity.workspace.plan.name;

    // if (account.id === 3122) {
    //   return (
    //     <Modal
    //       isOpen={true}
    //       centered
    //       className="modal-popup"
    //     >
    //       <ModalHeader>
    //         Превышение по тарифному плану
    //       </ModalHeader>
    //       <ModalBody>
    //         В вашем аккаунте превышен лимит на количество доступных видеовиджетов. Доступное количество видеовиджетов в вашем тарифном плане: <b>100</b>.
    //         <br /><br />
    //         Пожалуйста, напишите в чат поддержки для уточнения информации.
    //         <br /><br />
    //       </ModalBody>
    //       <ModalFooter>
    //         <Link to="/widgets/all">
    //           <Button color="light">
    //             Вернуться к списку виджетов
    //           </Button>
    //         </Link>

    //         <Button color="primary" onClick={() => { window.Intercom && window.Intercom('showNewMessage', 'Здравствуйте! В моем аккаунте превышено количество доступных виджетов') }}>
    //           Написать в чат
    //         </Button>
    //       </ModalFooter>
    //     </Modal>
    //   )
    // }

    return (
      <Form>
        <FormGroup>
          <Label>
            <Trans>dashboard.widget_form.widget_name</Trans>
          </Label>
          <Input
            type="text"
            name="name"
            value={this.state.name}
            placeholder="Виджет на главной"
            onChange={this.onInputChangeHandler}
          />
        </FormGroup>

        <br />

        <FormGroup>
          <h4>
            <Trans>dashboard.widget_form.video.title</Trans>
          </h4>

          {this.state.recorded_videos.map((video, key) => (
            <RecordedVideo
              key={key}
              {...video}
              onRemoveClick={(id) => this.removeRecordedVideo(id)}
            />
          ))}

          <div>
            <Label>
              <Trans>dashboard.widget_form.video.desc</Trans>&nbsp; &nbsp;
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="tooltip-icon"
                id="add-video-rotation-icon"
              />
              <UncontrolledPopover
                placement="top"
                trigger="hover"
                target="add-video-rotation-icon"
              >
                <PopoverBody>
                  <Trans>dashboard.widget_form.video.hint</Trans>
                </PopoverBody>
              </UncontrolledPopover>
            </Label>
          </div>

          <div className="video-selector-uploader">
            <Uploader onSuccess={this.onUploadSuccess} />
            <VideoSelector
              onComplete={this.onVideoSelectorComplete}
              disabledVideoIDs={this.state.recorded_videos.map((v) => v.id)}
            />
          </div>
        </FormGroup>

        <br />

        <h4>
          <Trans>dashboard.widget_form.cta.title</Trans>
        </h4>

        <Label>
          <Trans>dashboard.widget_form.cta.choose_type</Trans>
        </Label>

        <FormGroup check className="mb-2 form-checkbox-label">
          <Label check>
            <Input
              type="radio"
              name="scope"
              value=""
              checked={this.state.cta_scope === ""}
              onChange={this.onCTASelectHandler}
            />
            <Trans>dashboard.widget_form.cta.dont_use</Trans>

            <FormText color="muted">
              <Trans>dashboard.widget_form.cta.dont_use_desc</Trans>
            </FormText>
          </Label>
        </FormGroup>

        <FormGroup check className="mb-2 form-checkbox-label">
          <Label check>
            <Input
              type="radio"
              name="scope"
              value="link"
              checked={this.state.cta_scope === "link"}
              onChange={this.onCTASelectHandler}
            />
            <Trans>dashboard.widget_form.cta.link</Trans>

            <FormText color="muted">
              <Trans>dashboard.widget_form.cta.link_desc</Trans>
            </FormText>
          </Label>
        </FormGroup>

        <FormGroup check className="mb-2 form-checkbox-label">
          <Label check>
            <Input
              type="radio"
              name="scope"
              value="click"
              checked={this.state.cta_scope === "click"}
              onChange={this.onCTASelectHandler}
            />
            <Trans>dashboard.widget_form.cta.click</Trans>
            <br />

            <FormText color="muted">
              <Trans>dashboard.widget_form.cta.click_desc</Trans>
            </FormText>
          </Label>
        </FormGroup>

        <FormGroup check className="mb-2 form-checkbox-label">
          <Label check>
            <Input
              type="radio"
              name="scope"
              value="scroll"
              checked={this.state.cta_scope === "scroll"}
              onChange={this.onCTASelectHandler}
            />
            <Trans>dashboard.widget_form.cta.scroll</Trans>

            <FormText color="muted">
              <Trans>dashboard.widget_form.cta.scroll_desc</Trans>
            </FormText>
          </Label>
        </FormGroup>

        <FormGroup check className="mb-2 form-checkbox-label">
          <Label check>
            <Input
              type="radio"
              name="scope"
              value="trigger"
              checked={this.state.cta_scope === "trigger"}
              onChange={this.onCTASelectHandler}
            />
            <Trans>dashboard.widget_form.cta.trigger</Trans>
            <br />

            <FormText color="muted">
              <Trans>dashboard.widget_form.cta.trigger_desc</Trans>
            </FormText>
          </Label>
        </FormGroup>

        {this.state.cta_scope !== "" && (
          <div
            style={{
              padding: "30px 30px",
              background: "#f5f5f5",
              borderRadius: "10px",
            }}
            className="form-checkbox-block"
          >
            <FormGroup style={{ maxWidth: "400px" }}>
              <Label>
                <Trans>dashboard.widget_form.cta.cta_button_text</Trans>
              </Label>
              <Input
                type="text"
                name="cta_button_text"
                value={this.state.cta_button_text}
                onChange={this.onInputChangeHandler}
              />
            </FormGroup>

            <FormGroup style={{ maxWidth: "600px" }}>
              <Label>
                <Trans>dashboard.widget_form.cta.cta_button_time_delay</Trans>
              </Label>
              <div className="d-flex align-items-center">
                <Input
                  style={{ width: "50px" }}
                  type="text"
                  name="itemCallToActionShowTime"
                  value={this.state.cta_show_at}
                  onChange={this.onShowAtChangeHandler}
                />
                <div className="ml-2">сек.</div>
              </div>
            </FormGroup>

            {this.state.cta_scope === "link" && (
              <React.Fragment>
                <InputGroup
                  style={{
                    maxWidth: "400px",
                    marginBottom: "10px",
                    marginTop: "25px",
                  }}
                >
                  <InputGroupAddon addonType="prepend">
                    {this.props.t("dashboard.widget_form.cta.link_url_addon")}
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="cta_link"
                    placeholder="https://example.com/my-page"
                    value={this.state.cta_link}
                    onChange={this.onInputChangeHandler}
                  />
                </InputGroup>

                <FormGroup check className="mb-2 form-checkbox-label">
                  <Label check>
                    <Input
                      type="checkbox"
                      name="cta_link_new_window"
                      checked={this.state.cta_link_new_window}
                      onChange={this.onInputChangeHandler}
                    />
                    {this.props.t(
                      "dashboard.widget_form.cta.link_open_new_window"
                    )}
                  </Label>
                </FormGroup>
              </React.Fragment>
            )}

            {this.state.cta_scope == "trigger" &&
              this.props.i18n.language === "ru" && (
                <a
                  href="https://getreview.io/help/api/widget-javascript-api"
                  target="blank"
                >
                  <Button
                    type="button"
                    color="info"
                    style={{ marginTop: "10px" }}
                  >
                    Документация по JavaScript API
                  </Button>
                </a>
              )}

            {this.state.cta_scope === "scroll" && (
              <React.Fragment>
                <Label>
                  <Trans>dashboard.widget_form.cta.scroll_block_id</Trans>
                </Label>

                <InputGroup style={{ maxWidth: "400px" }}>
                  <InputGroupAddon addonType="prepend">ID</InputGroupAddon>
                  <Input
                    type="text"
                    name="cta_scroll"
                    placeholder="pricing-block"
                    value={this.state.cta_scroll}
                    onChange={this.onInputChangeHandler}
                  />
                </InputGroup>

                <br />

                <Modal
                  isOpen={this.state.show_scroll_video_tutorial}
                  toggle={(e) =>
                    this.setState({ show_scroll_video_tutorial: false })
                  }
                  size="lg"
                  centered
                >
                  <ModalBody>
                    <div
                      style={{ height: "60vh" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          '<iframe src="https://www.loom.com/embed/5a6227b24bca499fa248d9096e25c09e" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="width: 100%; height: 100%;"></iframe>',
                      }}
                    ></div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="light"
                      onClick={(e) =>
                        this.setState({ show_scroll_video_tutorial: false })
                      }
                    >
                      Закрыть
                    </Button>
                  </ModalFooter>
                </Modal>

                {this.props.i18n.language === "ru" && (
                  <Button
                    type="button"
                    color="info"
                    onClick={(e) =>
                      this.setState({ show_scroll_video_tutorial: true })
                    }
                  >
                    <FontAwesomeIcon icon={faPlayCircle} fixedWidth /> Смотреть
                    инструкцию
                  </Button>
                )}
              </React.Fragment>
            )}

            {this.state.cta_scope === "click" && (
              <React.Fragment>
                <Label>
                  <Trans>dashboard.widget_form.cta.opening_form_selector</Trans>
                </Label>

                <InputGroup>
                  <Input
                    style={{ maxWidth: "400px" }}
                    type="text"
                    name="cta_click"
                    placeholder="#rec236523522 > div > div > a > div"
                    value={this.state.cta_click}
                    onChange={this.onInputChangeHandler}
                  />
                  {this.props.i18n.language === "ru" && (
                    <a
                      href="https://www.loom.com/share/14bd56dae73e43f5827a3879c599d93d"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        position: "relative",
                        top: "5px",
                        left: "15px",
                        fontSize: "12px",
                      }}
                    >
                      Как найти селектор за 30 секунд без помощи программиста?
                    </a>
                  )}
                </InputGroup>

                {this.props.i18n.language === "ru" && (
                  <div>
                    <br />
                    <a
                      href="https://www.loom.com/share/7ff00d6075f54f9e8f3d7a84b6e4510b"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button type="button" color="info">
                        <FontAwesomeIcon icon={faInfoCircle} fixedWidth />{" "}
                        Смотреть инструкцию
                      </Button>
                    </a>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        )}

        {this.state.cta_scope === "form" && (
          <React.Fragment>
            <br />
            <h5>Конструктор формы</h5>

            <Label>
              Выберите какие поля будут отображаться в форме и какой текст
              (placeholder) будет по умолчанию в нужных полях
            </Label>

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={{ width: "130px" }}>
                  <Input
                    addon
                    type="checkbox"
                    checked={this.state.cta_form_email_checked}
                    onChange={this.onChangeFormEmailHandler}
                    aria-label="Form email"
                  />
                  &nbsp;&nbsp;
                  <b>Email</b>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                disabled={this.state.cta_form_email_checked === false}
                name="cta_form_email"
                value={this.state.cta_form_email}
                onChange={this.onInputChangeHandler}
              />
            </InputGroup>

            <br />

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={{ width: "130px" }}>
                  <Input
                    addon
                    type="checkbox"
                    checked={this.state.cta_form_phone_checked}
                    onChange={this.onChangeFormPhoneHandler}
                    aria-label="Form phone"
                  />
                  &nbsp;&nbsp;
                  <b>Телефон</b>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                disabled={this.state.cta_form_phone_checked === false}
                name="cta_form_phone"
                value={this.state.cta_form_phone}
                onChange={this.onInputChangeHandler}
              />
            </InputGroup>

            <br />

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={{ width: "130px" }}>
                  <Input
                    addon
                    type="checkbox"
                    checked={this.state.cta_form_name_checked}
                    onChange={this.onChangeFormNameHandler}
                    aria-label="Form name"
                  />
                  &nbsp;&nbsp;
                  <b>Имя</b>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                disabled={this.state.cta_form_name_checked === false}
                name="cta_form_name"
                value={this.state.cta_form_name}
                onChange={this.onInputChangeHandler}
              />
            </InputGroup>

            <br />

            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText style={{ width: "130px" }}>
                  <Input
                    addon
                    checked
                    disabled
                    type="checkbox"
                    aria-label="Form submit"
                  />
                  &nbsp;&nbsp;
                  <b>Кнопка</b>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                name="cta_form_submit_text"
                value={this.state.cta_form_submit_text}
                onChange={this.onInputChangeHandler}
              />
            </InputGroup>
            <br />
          </React.Fragment>
        )}

        <br />

        {this.props.identity.project && this.props.identity.project.token && (
          <div style={{ maxWidth: "700px" }}>
            <DisplayPagesSettings
              scope={this.state.display_pages}
              values={this.state.display_pages_list}
              onAdd={this.addPage}
              onChangeType={this.changePageType}
              onChangeURL={this.changePageURL}
              onDelete={this.deletePage}
              onScopeChange={(scope) => this.setState({ display_pages: scope })}
            />
          </div>
        )}

        {planName !== "trial" && planName !== "light" && (
          <div style={{ maxWidth: "700px" }}>
            <DisplayByCustomProperties
              rules={this.state.displayByCustomPropertiesRules}
              onChange={(updatedRules) => {
                this.setState({ displayByCustomPropertiesRules: updatedRules });
              }}
            />
          </div>
        )}

        <h4>
          <Trans>dashboard.widget_form.margins.title</Trans>
          &nbsp;&nbsp;&nbsp;
          <span
            style={{ fontSize: "12px", color: "#3A89FF", cursor: "pointer" }}
            onClick={() =>
              this.setState((prevState) => ({
                show_margins_block: !this.state.show_margins_block,
              }))
            }
          >
            {this.state.show_margins_block ? (
              <span>
                <Trans>dashboard.widget_form.settings_block.hide</Trans>{" "}
                <FontAwesomeIcon icon={faAngleUp} />
              </span>
            ) : (
              <span>
                <Trans>dashboard.widget_form.settings_block.show</Trans>{" "}
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            )}
          </span>
        </h4>

        {this.state.show_margins_block && (
          <div style={{ marginBottom: "10px" }}>
            <Label>
              <Trans>dashboard.widget_form.margins.desktop</Trans>
            </Label>
            <div className="d-flex" style={{ marginBottom: "10px" }}>
              <div style={{ marginRight: "10px" }}>
                <InputGroup style={{ maxWidth: "400px" }}>
                  <InputGroupAddon addonType="prepend">
                    {this.props.t(
                      "dashboard.widget_form.margins.margin_bottom"
                    )}
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="margin_bottom"
                    value={this.state.margin_bottom}
                    onChange={this.onInputChangeHandler}
                    style={{ width: "60px" }}
                  />
                </InputGroup>
              </div>

              <div>
                <InputGroup style={{ maxWidth: "400px" }}>
                  <InputGroupAddon addonType="prepend">
                    {this.props.t("dashboard.widget_form.margins.margin_side")}
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="margin_side"
                    value={this.state.margin_side}
                    onChange={this.onInputChangeHandler}
                    style={{ width: "60px" }}
                  />
                </InputGroup>
              </div>
            </div>

            <Label>
              <Trans>dashboard.widget_form.margins.mobile</Trans>
            </Label>
            <div className="d-flex">
              <div style={{ marginRight: "10px" }}>
                <InputGroup style={{ maxWidth: "400px" }}>
                  <InputGroupAddon addonType="prepend">
                    {this.props.t(
                      "dashboard.widget_form.margins.margin_bottom"
                    )}
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="mobile_margin_bottom"
                    value={this.state.mobile_margin_bottom}
                    onChange={this.onInputChangeHandler}
                    style={{ width: "60px" }}
                  />
                </InputGroup>
              </div>

              <div>
                <InputGroup style={{ maxWidth: "400px" }}>
                  <InputGroupAddon addonType="prepend">
                    {this.props.t("dashboard.widget_form.margins.margin_side")}
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="mobile_margin_side"
                    value={this.state.mobile_margin_side}
                    onChange={this.onInputChangeHandler}
                    style={{ width: "60px" }}
                  />
                </InputGroup>
              </div>
            </div>
          </div>
        )}

        <br />

        <h4>
          <Trans>dashboard.widget_form.frequency.title</Trans>
          &nbsp;&nbsp;&nbsp;
          <span
            style={{ fontSize: "12px", color: "#3A89FF", cursor: "pointer" }}
            onClick={() =>
              this.setState((prevState) => ({
                show_frequency_block: !this.state.show_frequency_block,
              }))
            }
          >
            {this.state.show_frequency_block ? (
              <span>
                <Trans>dashboard.widget_form.settings_block.hide</Trans>{" "}
                <FontAwesomeIcon icon={faAngleUp} />
              </span>
            ) : (
              <span>
                <Trans>dashboard.widget_form.settings_block.show</Trans>{" "}
                <FontAwesomeIcon icon={faAngleDown} />
              </span>
            )}
          </span>
        </h4>

        {this.state.show_frequency_block && (
          <div style={{ marginBottom: "10px" }}>
            <div>
              <Label>
                <Trans>dashboard.widget_form.frequency.user_interacted</Trans>
              </Label>

              <div className="ml-3">
                <FormGroup check className="mb-2 form-checkbox-label">
                  <Label check>
                    <Input
                      type="radio"
                      name="frequencyUserInteractedOption"
                      value="every_pageload"
                      checked={
                        this.state.frequencyUserInteractedOption ===
                        "every_pageload"
                      }
                      onChange={this.onInputChangeHandler}
                    />
                    <Trans>
                      dashboard.widget_form.frequency.every_pageload
                    </Trans>
                  </Label>
                </FormGroup>

                <FormGroup check className="mb-2 form-checkbox-label">
                  <Label check>
                    <Input
                      type="radio"
                      name="frequencyUserInteractedOption"
                      value="next_session"
                      checked={
                        this.state.frequencyUserInteractedOption ===
                        "next_session"
                      }
                      onChange={this.onInputChangeHandler}
                    />
                    <Trans>dashboard.widget_form.frequency.next_session</Trans>
                  </Label>
                </FormGroup>

                <FormGroup check className="mb-2 form-checkbox-label">
                  <Label check>
                    <Input
                      type="radio"
                      name="frequencyUserInteractedOption"
                      value="time_delay"
                      checked={
                        this.state.frequencyUserInteractedOption ===
                        "time_delay"
                      }
                      onChange={this.onInputChangeHandler}
                    />
                    <Trans>dashboard.widget_form.frequency.time_delay</Trans>
                  </Label>
                </FormGroup>

                {this.state.frequencyUserInteractedOption === "time_delay" && (
                  <TimeDelayForm
                    valuePropertyName="frequencyUserInteractedDelayValue"
                    dimensionPropertyName="frequencyUserInteractedDelayDimension"
                    state={this.state}
                    onChange={this.onInputChangeHandler}
                  />
                )}

                <FormGroup check className="mb-2 form-checkbox-label">
                  <Label check>
                    <Input
                      type="radio"
                      name="frequencyUserInteractedOption"
                      value="never"
                      checked={
                        this.state.frequencyUserInteractedOption === "never"
                      }
                      onChange={this.onInputChangeHandler}
                    />
                    <Trans>dashboard.widget_form.frequency.never</Trans>
                  </Label>
                </FormGroup>
              </div>
            </div>

            <div>
              <Label>
                <Trans>dashboard.widget_form.frequency.user_destroyed</Trans>
              </Label>

              <div className="ml-3">
                <FormGroup check className="mb-2 form-checkbox-label">
                  <Label check>
                    <Input
                      type="radio"
                      name="frequencyUserDestroyedOption"
                      value="every_pageload"
                      checked={
                        this.state.frequencyUserDestroyedOption ===
                        "every_pageload"
                      }
                      onChange={this.onInputChangeHandler}
                    />
                    <Trans>
                      dashboard.widget_form.frequency.every_pageload
                    </Trans>
                  </Label>
                </FormGroup>

                <FormGroup check className="mb-2 form-checkbox-label">
                  <Label check>
                    <Input
                      type="radio"
                      name="frequencyUserDestroyedOption"
                      value="next_session"
                      checked={
                        this.state.frequencyUserDestroyedOption ===
                        "next_session"
                      }
                      onChange={this.onInputChangeHandler}
                    />
                    <Trans>dashboard.widget_form.frequency.next_session</Trans>
                  </Label>
                </FormGroup>

                <FormGroup check className="mb-2 form-checkbox-label">
                  <Label check>
                    <Input
                      type="radio"
                      name="frequencyUserDestroyedOption"
                      value="time_delay"
                      checked={
                        this.state.frequencyUserDestroyedOption === "time_delay"
                      }
                      onChange={this.onInputChangeHandler}
                    />
                    <Trans>dashboard.widget_form.frequency.time_delay</Trans>
                  </Label>
                </FormGroup>

                {this.state.frequencyUserDestroyedOption === "time_delay" && (
                  <TimeDelayForm
                    valuePropertyName="frequencyUserDestroyedDelayValue"
                    dimensionPropertyName="frequencyUserDestroyedDelayDimension"
                    state={this.state}
                    onChange={this.onInputChangeHandler}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <br />

        <h4>
          <Trans>dashboard.widget_form.display_on_devices.title</Trans>
        </h4>

        <div>
          <FormGroup
            check
            inline
            className="mr-4 form-checkbox-label"
            style={{ paddingLeft: 0 }}
          >
            <Label check>
              <Input
                type="checkbox"
                name="desktop_enabled"
                checked={this.state.desktop_enabled}
                onChange={this.onInputChangeHandler}
              />
              <Trans>dashboard.widget_form.display_on_devices.desktop</Trans>
            </Label>
          </FormGroup>

          <FormGroup
            check
            inline
            className="form-checkbox-label"
            style={{ paddingLeft: 0 }}
          >
            <Label check>
              <Input
                type="checkbox"
                name="mobile_enabled"
                checked={this.state.mobile_enabled}
                onChange={this.onInputChangeHandler}
              />
              <Trans>dashboard.widget_form.display_on_devices.mobile</Trans>
            </Label>
          </FormGroup>
        </div>

        <br />

        <h4>
          <Trans>dashboard.widget_form.position.title</Trans>
        </h4>

        <div>
          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="position"
                value="left"
                checked={this.state.position === "left"}
                onChange={this.onInputChangeHandler}
              />
              <Trans>dashboard.widget_form.position.bottom_left</Trans>
            </Label>
          </FormGroup>

          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="position"
                value="right"
                checked={this.state.position === "right"}
                onChange={this.onInputChangeHandler}
              />
              <Trans>dashboard.widget_form.position.bottom_right</Trans>
            </Label>
          </FormGroup>
        </div>

        <br />

        <h4>
          <Trans>dashboard.widget_form.colors.title</Trans>
        </h4>

        <FormGroup check className="mb-2 form-checkbox-label">
          <Label check>
            <Input
              type="radio"
              name="customization_form"
              value=""
              checked={this.state.customization_form === ""}
              onChange={this.onInputChangeHandler}
            />
            <Trans>dashboard.widget_form.colors.default_colors</Trans>
          </Label>
        </FormGroup>

        <FormGroup check className="mb-2 form-checkbox-label">
          <Label check>
            <Input
              type="radio"
              name="customization_form"
              value="visible"
              checked={this.state.customization_form === "visible"}
              onChange={this.onInputChangeHandler}
            />
            <Trans>dashboard.widget_form.colors.custom_colors</Trans>
          </Label>
        </FormGroup>

        <br />

        {this.state.customization_form === "visible" && (
          <Row>
            <Col sm="3">
              <p>
                <b>
                  <Trans>dashboard.widget_form.colors.border_color</Trans>
                </b>
              </p>

              <Trans>dashboard.widget_form.colors.border_color_on_load</Trans>
              <br />
              <ColorPicker
                color={this.state.customization_border_color}
                onChange={this.onBorderColorChangeHandler}
              />

              <br />

              <Trans>dashboard.widget_form.colors.border_color_on_hover</Trans>
              <br />
              <ColorPicker
                color={this.state.customization_border_hover}
                onChange={this.onBorderHoverChangeHandler}
              />

              <br />
              <br />
            </Col>

            <Col sm="3">
              <p>
                <Label>
                  <b>
                    <Trans>dashboard.widget_form.colors.cta</Trans>
                  </b>
                </Label>
                &nbsp;&nbsp;
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="tooltip-icon"
                  id="cta-colors-popover"
                />
              </p>

              <UncontrolledPopover
                placement="top"
                trigger="hover"
                target="cta-colors-popover"
              >
                <PopoverBody>
                  <img src={CTAColorsImg} width="200px" height="127px" />
                </PopoverBody>
              </UncontrolledPopover>

              <Trans>dashboard.widget_form.colors.cta_color</Trans>
              <br />
              <ColorPicker
                color={this.state.customization_cta_color}
                onChange={this.onCTAColorChangeHandler}
              />

              <br />

              <Trans>dashboard.widget_form.colors.cta_text_color</Trans>
              <br />
              <ColorPicker
                color={this.state.customization_cta_text_color}
                onChange={this.onCTATextColorChangeHandler}
              />

              <br />
              <br />
            </Col>
          </Row>
        )}

        <Button
          color="primary"
          disabled={this.state.form_sending}
          onClick={this.submitForm}
          style={{
            width: "220px",
            padding: "0.7rem 1rem",
            position: "relative",
          }}
        >
          <Trans>dashboard.widget_form.save_widget_button</Trans>
        </Button>

        {this.state.form_sending === true && (
          <Spinner
            color="primary"
            size="sm"
            className="mr-2"
            style={{ marginLeft: "10px" }}
          />
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  identity: state.identity,
});

export default connect(mapStateToProps)(withTranslation()(WidgetsForm));
