import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../../../constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const CampaignContainer = styled.div`
  position: relative;
  height: 40px;
  background: white;
  border-radius: 10px;
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ececec;
  user-select: none;
  transition: border-color 0.2s ease-out;

  &:hover {
    border-color: #8c8c8c;
  }

  ${(props) =>
    props.selected && {
      borderColor: "#8c8c8c",
    }}

  @media (max-width: 568px) {
    max-width: 100%;
    width: 100%;
  }
`;

const CampaignName = styled.div`
  flex-grow: 1;
  line-height: 14px;
  max-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  margin-right: 15px;
`;

const CampaignIcon = styled.div`
  font-size: 14px;
  color: #e14fbc;
  margin-right: 10px;
  margin-left: 15px;
`;

const Campaign = (props) => {
  const history = useHistory();

  return (
    <CampaignContainer
      onClick={() =>
        history.push(`/reviews/campaigns/${props.token}/edit?tab=content`)
      }
    >
      <CampaignIcon>
        <FontAwesomeIcon icon={faStar} />
      </CampaignIcon>
      <CampaignName>{props.name}</CampaignName>
    </CampaignContainer>
  );
};

const ReviewsCampaignsList = (props) => {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = () => {
    return axios
      .get(`${GETREVIEW_API_HOST}/api/campaigns`, { withCredentials: true })
      .then((json) => {
        setCampaigns(json.data.campaigns);
      })
      .catch((error) => {
        toastr.error("", "Возникла ошибка при загрузке кампаний");
      });
  };

  if (campaigns.length === 0) {
    return null;
  }

  return (
    <Container>
      {campaigns.map((campaign) => (
        <Campaign key={campaign.token} {...campaign} />
      ))}
    </Container>
  );
};

export default ReviewsCampaignsList;
