import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Intercom from "react-intercom";
import TagManager from "react-gtm-module";
import { toastr } from "react-redux-toastr";
import Routes from "./routes/Routes";
import { loadIdentity } from "./redux/actions/identityActions";
import { Spinner } from "reactstrap";
import logotype from "./assets/img/logo/logotype_normal_vertical.svg";
import { useCookies } from "react-cookie";
import initLangSupport from "./i18n";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (typeof error.response === "object") {
      if (
        error.response.status === 403 &&
        error.response.data.error_code === "Forbidden"
      ) {
        toastr.error(
          "",
          "У вас нет прав доступа для совершения данной операции. Пожалуйста, обратитесь к администратору вашей компании"
        );
      } else {
        error.unknownError = true;
      }
    } else if (error.name === "Error" && error.message === "Network Error") {
      toastr.error(
        "",
        "Возникла ошибка подключения к серверу. Пожалуйста, проверьте подключение к интернету и повторите попытку"
      );
    } else {
      error.unknownError = true;
    }

    return Promise.reject(error);
  }
);

const App = (props) => {
  const dispatch = useDispatch();
  const identity = useSelector((state) => state.identity);
  const { workspace, account } = identity;
  const history = useHistory();
  const [cookies] = useCookies();
  const [i18n, setI18n] = useState({});
  const [lang, setLang] = useState(
    process.env.REACT_APP_GETREVIEW_REGION === "RU" ? "ru" : "en"
  );
  let intercomUser = null;

  const isAdminLoggedIn = cookies["_gr_admin_logged_in"] === "true";

  useEffect(() => {
    dispatch(loadIdentity());
    initializeGoogleTagManager();
    setI18n(initLangSupport(lang));
  }, []);

  useEffect(() => {
    if (account && i18n) {
      i18n.changeLanguage(account.lang).then(() => {
        document.title =
          account.lang === "ru"
            ? "Личный кабинет GetReview"
            : "GetReview Dashboard";
        setLang(account.lang);
      });
    }
  }, [account, i18n]);

  useEffect(() => {
    redirectIfWorkspaceSuspended();
    notifyIfWorkspaceExceeded();

    const unlisten = history.listen(() => {
      if (account) {
        redirectIfWorkspaceSuspended();
        notifyIfWorkspaceExceeded();
      }
    });

    return unlisten;
  }, [account]);

  const initializeGoogleTagManager = () => {
    const tagManagerArgs = {
      gtmId: "GTM-PDTQFFG",
    };

    TagManager.initialize(tagManagerArgs);
  };

  const notifyIfWorkspaceExceeded = () => {
    if (workspace != null && workspace.status === "regular_exceeded") {
      const warningMessage =
        "Зафиксировано превышение в рамках вашего тарифного плана. Обновите настройки оплаты для активации показов видеовиджетов";

      toastr.warning("Внимание!", warningMessage);
    }
  };

  const redirectIfWorkspaceSuspended = () => {
    const pathName = history.location.pathname;
    const pricingPagePathname = "/account/billing/plans";

    if (
      workspace != null &&
      (workspace.status === "trial_expired" ||
        workspace.status === "trial_exceeded" ||
        workspace.status === "regular_suspended")
    ) {
      if (
        pathName.match(/^\/account/) == null &&
        pathName.match(/^\/workspace/) == null
      ) {
        history.push(pricingPagePathname);
        let warningMessage;

        if (workspace.status === "regular_suspended") {
          warningMessage =
            "Доступ к сервису временно ограничен. Вам необходимо оплатить тарифный план для продолжения работы сервиса";
        } else {
          warningMessage =
            "К сожалению, ваш пробный период завершился. Выберите тарифный план для продолжения работы сервиса";
        }

        toastr.warning("Внимание!", warningMessage);
      }
    }
  };

  if (identity.authenticated === null) {
    return <Loader />;
  }

  if (identity.workspace && identity.project) {
    axios.defaults.headers.common["Workspace"] = identity.workspace.token;
    axios.defaults.headers.common["Project"] = identity.project.token;
  }

  if (account && isAdminLoggedIn === false) {
    intercomUser = {
      user_id: account.id,
      email: account.email,
      name: account.first_name + " " + account.last_name,
    };
  }

  return (
    <React.Fragment>
      <Routes />
      {process.env.NODE_ENV === "production" && intercomUser && (
        <Intercom appID="tvn6fbt5" {...intercomUser} />
      )}
    </React.Fragment>
  );
};

const Loader = () => {
  return (
    <div className="global-loader">
      <img src={logotype} alt="Sign in" className="loader-logo" />
      <br />
      <center>
        <Spinner color="secondary" size="sm" />
      </center>
    </div>
  );
};

export default App;
