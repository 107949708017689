import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { GETREVIEW_API_HOST } from "../../constants";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { useDrag } from "react-dnd";
import Status from "./Status";
import LinkButton from "./LinkButton";
import CopyCodeModal from "./widget_form/CopyCodeModal";
import Thumbnails from "./Thumbnails";

import { Spinner, CustomInput } from "reactstrap";

import {
  faCode,
  faSignal,
  faCog,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";

const WidgetItem = (props) => {
  const { t, i18n } = useTranslation();

  const [copyCodeModalIsVisible, setCopyCodeModalIsVisible] = useState(false);
  const [isSelected, setIsSelected] = useState(props.isSelected === true);
  const [recordedVideos, setRecordedVideos] = useState(props.recorded_videos);
  const pollingTimers = useRef({});

  useEffect(() => {
    startVideoStatusesPolling();

    return () => {
      stopVideoStatusesPolling();
    };
  }, []);

  useEffect(() => {
    setIsSelected(props.isSelected === true);
  }, [props.isSelected]);

  useEffect(() => {
    if (props.onToggleSelected != null) {
      props.onToggleSelected(props.token, isSelected);
    }
  }, [isSelected]);

  const startVideoStatusesPolling = () => {
    recordedVideos.forEach((video, index) => {
      if (video.status === "created" || video.status === "processing") {
        pollingTimers.current[index] = setInterval(() => {
          getRecordedVideoStatus(video.token, index);
        }, 5000);
      }
    });
  };

  const stopVideoStatusPolling = (index) => {
    clearInterval(pollingTimers.current[index]);
  };

  const stopVideoStatusesPolling = () => {
    for (const [key, value] of Object.entries(pollingTimers.current)) {
      clearInterval(value);
    }
  };

  const getRecordedVideoStatus = (videoToken, videoIndex) => {
    axios
      .get(`${GETREVIEW_API_HOST}/api/videos/${videoToken}`, {
        withCredentials: true,
      })
      .then((json) => {
        const video = json.data.video;

        if (video.status === "done") {
          const updatedRecordedVideos = [...recordedVideos];
          updatedRecordedVideos[videoIndex] = video;

          stopVideoStatusPolling(videoIndex);
          setRecordedVideos(updatedRecordedVideos);
        }
      })
      .catch((error) => {
        console.log("Ошибка при получении статуса видео");
      });
  };

  const [{ isDragging }, drag] = useDrag({
    item: { widgetID: props.token, type: "widget_item" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult && dropResult.folderToken != null) {
        moveWidgetToFolder(dropResult.folderToken);
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const moveWidgetToFolder = (folderToken) => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/widgets/${props.token}/move_to_folder`,
        { folder_token: folderToken },
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success(
          "Поздравляем!",
          "Видеовиджет был успешно перенесен в папку"
        );

        if (props.onMoveToFolder != null) {
          props.onMoveToFolder(props.token, folderToken);
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Ошибка при переносе видеовиджета в папку");
      });
  };

  let widgetItemClassName = isDragging
    ? "widget-item draggable"
    : "widget-item";

  if (isSelected === true) {
    widgetItemClassName += " selected";
  }

  return (
    <tr
      ref={drag}
      className={widgetItemClassName}
      style={{
        height: "100px",
        opacity: props.enabled ? 1 : 0.5,
      }}
    >
      <td style={{ position: "relative" }}>
        <div className="table-checkbox" style={{ top: "40px" }}>
          <CustomInput
            type="checkbox"
            id={`select-widget-${props.token}`}
            checked={isSelected}
            onChange={(e) => setIsSelected(e.target.checked)}
          />
        </div>
      </td>

      <td style={{ position: "relative", minWidth: "140px" }}>
        {[
          "VideoStories",
          "VideoInline",
          "VideoBubble",
          "VideoCarousel",
          "VideoGrid",
        ].indexOf(props.type) !== -1 ? (
          <Thumbnails widgetToken={props.token} widgetItems={props.items} />
        ) : (
          <div style={{ display: "flex" }}>
            {recordedVideos.slice(0, 1).map((video) => (
              <React.Fragment key={video.token}>
                {video.status === "created" || video.status === "processing" ? (
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      background: "#222222",
                      borderRadius: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <center style={{ lineHeight: 1 }}>
                      <br />
                      <br />
                      <Spinner color="light" size="sm" />
                    </center>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      backgroundImage: `url(${video.thumbnail_url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "50% 30%",
                      borderRadius: "8px",
                      marginRight: "5px",
                      boxShadow: "1px 2px 0.875rem 0 rgba(53, 64, 82, 0.1)",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </td>

      <td>
        <Link
          to={"/widgets/" + props.token + "/edit"}
          title={t("widget.settings")}
          style={{ display: "block", textDecoration: "none" }}
          className="widget-item-link"
        >
          <span style={{ fontWeight: "600" }}>{props.name}</span>
          <br />
          <div style={{ opacity: 0.3, fontSize: "12px", fontWeight: "normal" }}>
            {props.type}
          </div>
        </Link>
      </td>

      <td style={{ textAlign: "center" }}>
        <Status isEnabled={props.enabled} onSelect={props.onStatusSelect} />
      </td>

      <td
        style={{
          textAlign: "center",
          whiteSpace: "nowrap",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <LinkButton
          label={t("widget.preview")}
          title={t("widget.preview")}
          icon={faCaretRight}
          iconSize={20}
          to={`/widgets/${props.token}/preview`}
          openInNewWindow
        />
      </td>
      <td
        style={{
          textAlign: "center",
          whiteSpace: "nowrap",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <div>
          <LinkButton
            label={t("widget.code")}
            title={t("widget.code")}
            icon={faCode}
            iconSize={14}
            outline
            onClick={() => setCopyCodeModalIsVisible(true)}
          />

          {copyCodeModalIsVisible && (
            <CopyCodeModal
              siteToken={props.site_token}
              widgetToken={props.token}
              widgetType={props.type}
              displayPages={props.display_pages}
              displayPagesList={props.display_pages_list}
              displayPositionSelector={
                props.customization.displayPositionSelector
              }
              onClose={() => setCopyCodeModalIsVisible(false)}
            />
          )}
        </div>
      </td>
      <td style={{ whiteSpace: "nowrap" }}>
        <LinkButton
          title={t("widget.analytics")}
          icon={faSignal}
          iconSize={14}
          to={`/?widget=${props.token}`}
        />

        <LinkButton
          title={t("widget.settings")}
          icon={faCog}
          iconSize={16}
          outline
          to={`/widgets/${props.token}/edit`}
        />
      </td>
    </tr>
  );
};

export default WidgetItem;
