import React, { useState, useEffect, useRef } from "react";
import { ChromePicker } from "react-color";

const convertRGBtoHEX = (r, g, b) => {
  const hexString = ((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1);

  return "#" + hexString.toUpperCase();
};

const ColorPickerRGBA = ({ onChange, ...props }) => {
  const containerRef = useRef();

  const [colorPickerVisibile, setColorPickerVisibile] = useState(false);
  const [selectedColor, setSelectedColor] = useState(props.color);

  useEffect(() => {
    if (onChange) {
      onChange(selectedColor);
    }
  }, [selectedColor]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (!containerRef.current || containerRef.current.contains(event.target)) {
      return;
    }

    setColorPickerVisibile(false);
  };

  const controlLabel =
    selectedColor.a !== 1
      ? `RGBA ${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b}, ${selectedColor.a}`
      : `${convertRGBtoHEX(selectedColor.r, selectedColor.g, selectedColor.b)}`;

  return (
    <div
      className="color-picker"
      ref={containerRef}
      onClick={() => setColorPickerVisibile(true)}
    >
      <div
        className="color-preview"
        style={{
          backgroundColor: `rgba(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b}, ${selectedColor.a})`,
        }}
      ></div>

      <input
        type="text"
        value={controlLabel}
        onChange={(event) => setSelectedColor(event.target.value)}
        className="hex-value"
        style={{ fontSize: "12px" }}
      />

      {colorPickerVisibile === true && (
        <ChromePicker
          color={selectedColor}
          onChange={(color) => setSelectedColor(color.rgb)}
        />
      )}
    </div>
  );
};

export default ColorPickerRGBA;
