import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  a {
    color: #000a3b;

    &:hover {
      color: #116af0;
      text-decoration: none;
    }
  }
`;

const Breadcrumbs = () => {
  const { project } = useSelector((state) => state.identity);

  return (
    <Container>
      <Link to="/" className="mr-2">
        {project.name}
      </Link>
      <FontAwesomeIcon icon={faAngleRight} />
    </Container>
  );
};

export default Breadcrumbs;
