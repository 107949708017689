import { useEffect, useRef } from "react";
import { GETREVIEW_API_HOST } from "../constants";
import axios from "axios";

export const useVideoStatusChecker = (videoStatus, videoToken, callback) => {
  const pollingTimer = useRef();

  const getRecordedVideoStatus = () => {
    axios
      .get(`${GETREVIEW_API_HOST}/api/videos/${videoToken}`, {
        withCredentials: true,
      })
      .then((json) => {
        const video = json.data.video;

        if (video.status === "done") {
          stopPolling();
          callback(video);
        }
      })
      .catch(() => {
        console.log("Error checking video status");
      });
  };

  const startPolling = () => {
    pollingTimer.current = setInterval(() => {
      getRecordedVideoStatus();
    }, 5000);
  };

  const stopPolling = () => {
    clearInterval(pollingTimer.current);
  };

  useEffect(() => {
    if (
      videoStatus === "done" ||
      videoStatus === "error" ||
      videoStatus == null
    ) {
      return;
    }

    startPolling();

    return () => {
      stopPolling();
    };
  }, [videoStatus]);
};
