import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const RecordedVideo = (props) => {
  return (
    <div className="form-uploaded-video">
      {props.status === "done" && (
        <div className="thumbnail">
          <a
            href={props.video_mp4_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              className="thumbnail-image"
              style={{ backgroundImage: `url(${props.thumbnail_url})` }}
            ></div>
          </a>
        </div>
      )}

      <div className="name">{props.name}</div>

      <div
        className="delete-icon"
        onClick={() => props.onRemoveClick(props.id)}
      >
        <FontAwesomeIcon icon={faTimesCircle} />
      </div>
    </div>
  );
};

export default RecordedVideo;
