import React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Col,
  Container,
  Row,
  TabContent,
  TabPane
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faCheckCircle } from "@fortawesome/free-regular-svg-icons";

class Pricing extends React.Component {
  constructor(props) {
    super(props);

    this.account = this.props.account.current;

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "monthly"
    };
  }

  componentDidMount() {
    var script = document.createElement('script');
    script.src = "https://widget.cloudpayments.ru/bundles/cloudpayments";

    if (document.head) {
      document.head.appendChild(script);
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  showPlanButton(plan) {
    let availablePlanButton = (
      <>
        <Button size="lg" color="success" onClick={() => this.makePayment(plan)}>
          Оплатить подписку<br />со скидкой 30%
        </Button>

        <br />

        <div className="text-center" style={{ marginTop: '10px', fontSize: '12px', lineHeight: '120%' }}>
          Скидка распространяется<br />на все время подписки!
        </div>
      </>
    )

    let currentPlanButton = (
      <Button size="lg" color="secondary" disabled style={{ opacity: 0.3, cursor: 'default' }}>
        Текущий тариф
      </Button>
    )

    if (this.account.status == 'regular_suspended' || this.account.status == 'regular_exceeded') {
      return availablePlanButton;
    }

    return this.account.plan.name == plan ? currentPlanButton : availablePlanButton;
  }

  currentPlanName() {
    const plans = {
      light: {
        name: 'Лайт'
      },
      standard: {
        name: 'Стандарт'
      },
      business: {
        name: 'Бизнес'
      }
    };

    return plans[this.account.plan.name].name;
  }

  makePayment = (planName) => {
    const planOptions = {
      light: {
        amount: 693,
        name: 'Лайт'
      },
      standard: {
        amount: 1393,
        name: 'Стандарт'
      },
      business: {
        amount: 4193,
        name: 'Бизнес'
      }
    }[planName];

    if (process.env.NODE_ENV == 'production') {
      window.dataLayer.push({
       'event': 'eventTracking',
       'category': 'Purchase',
       'action': 'planChosen'
      });
    }

    const itemLabel = 'Подписка на тариф "' + planOptions.name + '"';

    const cloudPaymentsOptions = {
      googlePaySupport: false
    };

    const widget = new window.cp.CloudPayments(cloudPaymentsOptions);

    const receipt = {
      Items: [
        {
          label: itemLabel, //наименование товара
          price: planOptions.amount, //цена
          quantity: 1.00, //количество
          amount: planOptions.amount, //сумма
          vat: 0, //ставка НДС
          method: 0, // тег-1214 признак способа расчета - признак способа расчета
          object: 0, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
        }
      ],
      email: this.account.email, //e-mail покупателя, если нужно отправить письмо с чеком
      phone: '', //телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
      isBso: false, //чек является бланком строгой отчётности
      amounts:
      {
        electronic: planOptions.amount, // Сумма оплаты электронными деньгами
        advancePayment: 0.00, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
        credit: 0.00, // Сумма постоплатой(в кредит) (2 знака после запятой)
        provision: 0.00 // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
      }
    };

    const data = {
      plan: planName,
      cloudPayments: {
        recurrent: {
          interval: 'Month',
          period: 1,
          customerReceipt: receipt
        },
        customerReceipt: receipt
      }
    }

    widget.charge({ // options
      publicId: 'pk_5c042241b3dfe94d3f9dfafdbc47d',  //id из личного кабинета
      description: itemLabel + ' в GetReview',
      amount: planOptions.amount,
      currency: 'RUB',
      accountId: this.account.id,
      email: this.account.email,
      skin: "mini",
      googlePaySupport: false,
      data: data
    },
    function(options) {
      toastr.success('Поздравляем!', 'Оплата тарифного плана прошла успешно');

      if (process.env.NODE_ENV == 'production') {
        window.dataLayer.push({
         'event': 'eventTracking',
         'category': 'Purchase',
         'action': 'planPaid'
        });
      }

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    function(reason, options) {
      //toastr.warning('Внимание!', 'Не удалось совершить оплату тарифного плана');
    });
  }

  render() {
    return (
      <Container fluid className="p-0">
        {this.account.plan.name !== 'trial' &&
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader>
                  <CardTitle>Ваш тарифный план</CardTitle>
                  <h2>{this.currentPlanName()}</h2>
                </CardHeader>
                <CardBody>
                  Оплачен до: {this.account.plan.period_to}<br  />
                  Дата следующего платежа: {this.account.plan.period_to}<br  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        }

        <Row>
          <Col md="10" xl="10" className="mx-auto">
            <h1 className="text-center">Тарифные планы</h1>
            <br />
            <h1 className="text-center">СКИДКА 30% NEW YEAR 2021</h1>
            <br />
            <h5 className="text-center">АКЦИЯ ДЕЙСТВУЕТ С <b style={{ color: 'rgb(75, 191, 115)' }}>21 ДЕКАБРЯ 2020</b> ПО <b style={{ color: 'rgb(75, 191, 115)' }}>31 ДЕКАБРЯ 2020</b></h5>
            <h5 className="text-center">СКИДКА 30% РАСПРОСТРАНЯЕТСЯ НА ВСЕ ВРЕМЯ ДЕЙСТВИЯ ПОДПИСКИ</h5>
            <br />

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="monthly">
                <Row className="py-4">
                  <Col sm="4" className="mb-3 mb-md-0">
                    <Card className="text-center h-100">
                      <CardBody className="d-flex flex-column">
                        <br />
                        <div className="mb-4">
                          <h5>Лайт</h5>
                          <span className="display-4"><strike>990</strike></span>

                          <br />

                          <span className="display-4" style={{ fontSize: '30px' }}>693</span>
                          <span>₽ в месяц</span>
                        </div>
                        <h6>В тариф входит</h6>
                        <ul className="list-unstyled">
                          {/*<li className="mb-2">До <b>25</b> записанных видео</li>*/}
                          <li className="mb-2"><b>5,000</b> показов виджетов в месяц</li>
                          <li className="mb-2">Неограниченная длительность видео</li>
                          <li className="mb-2">Неограниченно виджетов</li>
                          <li className="mb-2">Расширенная аналитика</li>
                          <li className="mb-2">Самостоятельная настройка</li>
                          <li className="mb-2">Поддержка по email</li>
                        </ul>

                        <h6>При перерасходе</h6>
                        <p>+190 ₽ в месяц за 1,000 показов</p>

                        <div className="mt-auto">
                          {this.showPlanButton('light')}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="4" className="mb-3 mb-md-0">
                    <Card className="text-center h-100">
                      <CardBody className="d-flex flex-column">
                        <br />
                        <div className="mb-4">
                          <h5>Стандарт</h5>
                          <span className="display-4"><strike>1990</strike></span>

                          <br />

                          <span className="display-4" style={{ fontSize: '30px' }}>1393</span>
                          <span>₽ в месяц</span>
                        </div>
                        <h6>В тариф входит</h6>
                        <ul className="list-unstyled">
                          {/*<li className="mb-2">До <b>100</b> записанных видео</li>>*/}
                          <li className="mb-2"><b>20,000</b> показов виджетов в месяц</li>
                          <li className="mb-2">Неограниченная длительность видео</li>
                          <li className="mb-2">Неограниченно виджетов</li>
                          <li className="mb-2">Расширенная аналитика</li>
                          <li className="mb-2" style={{ color: '#4BBF73' }}><b>Помощь с настройкой</b> <FontAwesomeIcon icon={faCheckCircle} /></li>
                          <li className="mb-2" style={{ color: '#4BBF73' }}><b>Поддержка по email или чат</b> <FontAwesomeIcon icon={faCheckCircle} /></li>
                        </ul>

                        <h6>При перерасходе</h6>
                        <p>+790 ₽ в месяц за 10,000 показов</p>

                        <div className="mt-auto">
                          {this.showPlanButton('standard')}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="4" className="mb-3 mb-md-0">
                    <Card className="text-center h-100">
                      <CardBody className="d-flex flex-column">
                        <br />
                        <div className="mb-4">
                          <h5>Бизнес</h5>
                          <span className="display-4"><strike>5990</strike></span>

                          <br />

                          <span className="display-4" style={{ fontSize: '30px' }}>4193</span>
                          <span>₽ в месяц</span>
                        </div>
                        <h6>В тариф входит</h6>
                        <ul className="list-unstyled">
                          {/*<li className="mb-2">До <b>500</b> записанных видео</li>>*/}
                          <li className="mb-2"><b>100,000</b> показов виджетов в месяц</li>
                          <li className="mb-2">Неограниченная длительность видео</li>
                          <li className="mb-2">Неограниченно виджетов</li>
                          <li className="mb-2">Расширенная аналитика</li>
                          <li className="mb-2" style={{ color: '#4BBF73' }}><b>Помощь с настройкой</b> <FontAwesomeIcon icon={faCheckCircle} /></li>
                          <li className="mb-2" style={{ color: '#4BBF73' }}><b>Премиум поддержка</b> <FontAwesomeIcon icon={faCheckCircle} /></li>
                          {/*<li className="mb-2" style={{ color: '#4BBF73' }}><b>A/B тестирование</b> <FontAwesomeIcon icon={faCheckCircle} /></li>*/}
                          <li className="mb-2" style={{ color: '#4BBF73' }}><b>Брендирование</b> <FontAwesomeIcon icon={faCheckCircle} /></li>
                        </ul>

                        <h6>При перерасходе</h6>
                        <p>+390 ₽ в месяц за 10,000 показов</p>

                        <div className="mt-auto">
                          {this.showPlanButton('business')}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="annual">
                <Row className="py-4">
                  <Col sm="4" className="mb-3 mb-md-0">
                    <Card className="text-center h-100">
                      <CardBody className="d-flex flex-column">
                        <div className="mb-4">
                          <h5>Free</h5>
                          <span className="display-4">$0</span>
                        </div>
                        <h6>Includes:</h6>
                        <ul className="list-unstyled">
                          <li className="mb-2">1 users</li>
                          <li className="mb-2">5 projects</li>
                          <li className="mb-2">5 GB storage</li>
                        </ul>
                        <div className="mt-auto">
                          <Button size="lg" color="primary" outline>
                            Sign up
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="4" className="mb-3 mb-md-0">
                    <Card className="text-center h-100">
                      <CardBody className="d-flex flex-column">
                        <div className="mb-4">
                          <h5>Standard</h5>
                          <span className="display-4">$199</span>
                          <span>/mo</span>
                        </div>
                        <h6>Includes:</h6>
                        <ul className="list-unstyled">
                          <li className="mb-2">5 users</li>
                          <li className="mb-2">50 projects</li>
                          <li className="mb-2">50 GB storage</li>
                          <li className="mb-2">Security policy</li>
                          <li className="mb-2">Weekly backups</li>
                        </ul>
                        <div className="mt-auto">
                          <Button size="lg" color="primary">
                            Try it for free
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="4" className="mb-3 mb-md-0">
                    <Card className="text-center h-100">
                      <CardBody className="d-flex flex-column">
                        <div className="mb-4">
                          <h5>Plus</h5>
                          <span className="display-4">$399</span>
                          <span>/mo</span>
                        </div>
                        <h6>Includes:</h6>
                        <ul className="list-unstyled">
                          <li className="mb-2">Unlimited users</li>
                          <li className="mb-2">Unlimited projects</li>
                          <li className="mb-2">250 GB storage</li>
                          <li className="mb-2">Priority support</li>
                          <li className="mb-2">Security policy</li>
                          <li className="mb-2">Daily backups</li>
                          <li className="mb-2">Custom CSS</li>
                        </ul>
                        <div className="mt-auto">
                          <Button size="lg" color="primary" outline>
                            Try it for free
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

            <br />

            <h4 className="text-center">ХОТИТЕ ПОЛУЧИТЬ СКИДКУ 50% ПРИ ОПЛАТЕ НА ГОД?<br />НАПИШИТЕ НАМ В ЧАТ ПРОМО-КОД: <b style={{ color: 'rgb(75, 191, 115)' }}>NY2021</b></h4>

            <br />

            <div className="text-center my-4">
              Безопасность оплаты гарантируется провайдером <a href="https://www.cloudpayments.ru" target="blank">CloudPayments</a>.
              <br />
              Мы используем шифрованное SSL-соединение для надежной передачи платежной информации.
              <br /><br />
            </div>

            <hr />

            <div className="text-center my-4">
              <br />
              <h2>Часто задаваемые вопросы</h2>
              <br />
            </div>

            <Row>
              <Col sm="6" md="5" className="ml-auto">
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Что считается показом виджета?</CardTitle>
                    <p className="mb-0">
                      Показы виджета &ndash; это количество загрузок виджета на вашем сайте. Это число зависит от посещаемости страницы, на которой установлен виджет. По сути, это количество запросов к нашему серверу &ndash; чем больше запросов, тем больше нам требуется ресурсов для показа видео. Если пользователь закрыл виджет, то в течение сессии этому пользователю не будет показываться виджет, и, соответственно, показ не будет засчитан.
                    </p>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Подписка продлевается автоматически?</CardTitle>
                    <p className="mb-0">
                      Да, ваша подписка будет автоматически продлена в соответствии с вашим тарифным планом и периодом оплаты.
                    </p>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Как узнать дату следующего платежа?</CardTitle>
                    <p className="mb-0">
                      Дата следующего платежа отображается в вашем личном кабинете в разделе «Тарифы и оплата». За день до оплаты вам будет отправлено уведомления по email.
                    </p>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Как гарантируется безопаность передаваемых данных при совершении платежей?</CardTitle>
                    <p className="mb-0">
                      Для приема платежей мы используем один из самых надежных и передовых сервисов &ndash; <a href="https://www.cloudpayments.ru" target="blank">CloudPayments</a>. Процессинговый центр <a href="https://www.cloudpayments.ru" target="blank">CloudPayments</a> защищает и обрабатывает данные вашей банковской карты по стандарту безопасности <b>PCI DSS 3.2</b>. Передача информации в платежный шлюз происходит с применением технологии шифрования <b>SSL</b>. Дальнейшая передача информации происходит по закрытым банковским сетям, имеющим наивысший уровень надежности. <a href="https://www.cloudpayments.ru" target="blank">CloudPayments</a> не передает данные вашей карты нам и иным третьим лицам. Для дополнительной аутентификации держателя карты используется протокол <b>3D Secure</b>.
                    </p>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Вы формируете закрывающие документы?</CardTitle>
                    <p className="mb-0">
                      Мы формируем закрывающие документы в рамках тарифного плана <b>«Бизнес»</b> после заключения договора.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6" md="5" className="mr-auto">
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Что такое подписка?</CardTitle>
                    <p className="mb-0">
                      При первой оплате мы привяжем вашу карту, далее платежи будут производиться автоматически раз в месяц согласно выбранному тарифу без необходимости повторного ввода данных. Если по каким-то причинам не произошло списание денежных средств, например, нет денег на карте или закончился срок действия карты, вам будет отправлено письмо с предложением привязать новую карту или провести оплату вручную.
                    </p>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Что такое перерасход по тарифу?</CardTitle>
                    <p className="mb-0">
                      Если вам необходимо в этом месяце большее количество показов виджета, чем предусмотрено в вашем тарифном плане, то вы можете отдельно докупить пакет показов.
                    </p>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Есть ли у вас специальные предложения для проектов с посещаемостью от 350,000 и более?</CardTitle>
                    <p className="mb-0">
                      Мы будем рады такому сотрудничеству, поэтому свяжитесь с нами по почте <a href="mailto:corp@getreview.io">corp@getreview.io</a>, оставив в письме контакты для обратной связи. Мы обязательно обсудим все условия и постараемся сделать для вас максимально выгодное предложение.
                    </p>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Могу ли я поменять тарифный план?</CardTitle>
                    <p className="mb-0">
                      Да, вы можете сделать как <b>апгрейд</b>, так и <b>даунгрейд</b> своего тарифа в любой момент. Новая подписка начнет действовать с наступления платежной даты. Размер списания будет изменен согласно новому тарифу. Для смены тарифного плана рекомендуем написать нам в поддержку на <a href="mailto:corp@getreview.io">corp@getreview.io</a>.
                    </p>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Могу ли я отменить подписку?</CardTitle>
                    <p className="mb-0">
                      Вы можете отменить подписку в любой момент, просто написав нам в чате или на <a href="mailto:corp@getreview.io">corp@getreview.io</a>. После отмены подписки показы виджетов будут приостановлены.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <hr />

            <br />

            <div className="text-center my-4">
              <h2>Остались вопросы?</h2>

              Напиши нам в чат или на почту <a href="mailto:corp@getreview.io">corp@getreview.io</a><br />
              или позвоните по телефону <a href="tel:+7-909-635-82-44">+7 909 635 82 44</a>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  account: state.account,
});

export default connect(mapStateToProps)(Pricing);
