import React from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { Trans } from "react-i18next";
import { GETREVIEW_API_HOST } from "../../constants";
import { IntercomAPI } from "react-intercom";
import WidgetsForm from "./Form";

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

class WidgetsNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    IntercomAPI("trackEvent", "open-new-widget-page");
  }

  createNewWidget = (formData, successCallback, errorCallback) => {
    return axios
      .post(`${GETREVIEW_API_HOST}/api/widgets`, formData, {
        withCredentials: true,
      })
      .then((json) => {
        const widgetsCount = json.data.widgets_count;

        if (process.env.NODE_ENV == "production" && widgetsCount === 1) {
          window.dataLayer.push({
            event: "eventTracking",
            category: "Workflow",
            action: "widgetCreated",
          });

          IntercomAPI("trackEvent", "first-widget-created");
        }

        successCallback();
        toastr.success("Поздравляем!", "Виджет был успешно сохранен");
        this.props.history.push("/widgets/all");
      })
      .catch(errorCallback);
  };

  render() {
    return (
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-4">
          <Col xs="auto" className="d-none d-sm-block">
            <h3>
              <Trans>dashboard.widget_form.new_widget</Trans>
            </h3>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <WidgetsForm onFormSubmitHandler={this.createNewWidget} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default WidgetsNew;
