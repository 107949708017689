import React, { useState, useEffect, forwardRef } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";

import { useVideoStatusChecker } from "../../../components/VideoStatusChecker";

const Story = forwardRef((props, ref) => {
  const [video, setVideo] = useState(props.video);

  useEffect(() => {
    setVideo(props.video);
  }, [props.video]);

  useVideoStatusChecker(video.status, video.token, (updatedVideo) => {
    setVideo(updatedVideo);

    if (props.onVideoStatusUpdate) {
      props.onVideoStatusUpdate(updatedVideo.status);
    }
  });

  const classNames = classnames({
    "videostories-item-block": true,
    active: props.isActive,
    draggable: props.isDraggable,
  });

  return (
    <div className={classNames} ref={ref} style={props.style}>
      <div className="videostories-item">
        {video.thumbnail_url && (
          <div
            className="thumbnail"
            style={{ backgroundImage: `url(${video.thumbnail_url})` }}
            {...props.attributes}
            {...props.listeners}
          />
        )}

        <div className="widget-item-title">{props.title}</div>
        <div className="widget-item-subtitle">{props.subtitle}</div>

        {video.status !== "done" && (
          <div className="widget-item-status-processing">
            <div>
              Обработка
              <br />
              видео
            </div>
            <div className="mt-3">
              <Spinner
                color="secondary"
                size="sm"
                style={{ fontSize: "9px" }}
              />
            </div>
          </div>
        )}

        <div
          className="overlay-icon settings-icon"
          onClick={props.onSettingsClick}
        >
          <FontAwesomeIcon icon={faEdit} />
        </div>

        <div className="overlay-icon delete-icon" onClick={props.onDeleteClick}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
    </div>
  );
});

export default Story;
