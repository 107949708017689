import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../../constants";
import { loadIdentity } from "../../redux/actions/identityActions";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const GoogleAnalytics = () => {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.identity);

  const [googleAnalyticsEnabled, setGoogleAnalyticsEnabled] = useState(
    project.google_analytics_enabled
  );
  const [googleAnalyticsCounterID, setGoogleAnalyticsCounterID] = useState(
    project.google_analytics_counter_id
  );
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    const changed =
      project.google_analytics_enabled !== googleAnalyticsEnabled ||
      project.google_analytics_counter_id !== googleAnalyticsCounterID;
    setFormChanged(changed);
  }, [project, googleAnalyticsEnabled, googleAnalyticsCounterID]);

  const saveSettings = () => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/projects/${project.token}`,
        {
          google_analytics_enabled: googleAnalyticsEnabled,
          google_analytics_counter_id: googleAnalyticsCounterID,
        },
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success("", "Настройки были успешно сохранены");
        setFormChanged(false);
        dispatch(loadIdentity());
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error(
            "",
            "Возникла неизвестная ошибка при сохранении настроек"
          );
        }
      });
  };

  return (
    <Container fluid className="p-0">
      <Row className="mb-2 mb-xl-4">
        <Col xs="auto" className="d-none d-sm-block">
          <h3>Интеграция с Google Analytics 4</h3>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <CustomInput
                    type="switch"
                    id="exampleCustomSwitch"
                    name="customSwitch"
                    label="Отправлять события в Google Analytics 4"
                    checked={googleAnalyticsEnabled}
                    onChange={(event) =>
                      setGoogleAnalyticsEnabled(event.target.checked)
                    }
                  />
                </FormGroup>

                {googleAnalyticsEnabled && (
                  <FormGroup>
                    <Label>Номер счетчика</Label>

                    <Input
                      type="text"
                      name="name"
                      value={googleAnalyticsCounterID || ""}
                      style={{ width: "300px" }}
                      onChange={(event) =>
                        setGoogleAnalyticsCounterID(event.target.value)
                      }
                    />

                    <div className="text-muted">
                      Укажите номер счетчика в формате G-**********.
                      <br />
                      Если вы не знаете номер счетчика, то оставьте данное поле
                      пустым. В этом случае события будут отправляться в первый
                      найденный счетчик GA.
                    </div>
                  </FormGroup>
                )}

                <Button
                  color="primary"
                  disabled={formChanged === false}
                  onClick={saveSettings}
                >
                  Сохранить настройки
                </Button>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              Данный механизм позволяет настроить автоматическую передачу
              событий из видеовиджета в систему аналитики{" "}
              <b className="text-dark">Google Analytics 4</b>.<br />
              Используя события из видеовиджета вы можете строить{" "}
              <mark>отчеты</mark>, создавать <mark>аудитории</mark> и многое
              другое.
              <br />
              <br />
              Список событий, которые передаются в GA4:
              <br />
              <br />
              <ul>
                <li>
                  <code>getreview_widget_open</code>
                  <br />
                  Событие отправляется при <mark>клике</mark> пользователя на
                  видеовиджет, то есть в тот момент,
                  <br />
                  когда начинается непосредственный <mark>просмотр</mark> видео
                  ролика.
                  <br />
                  <br />
                </li>
                <li>
                  <code>getreview_widget_midpoint</code>
                  <br />
                  Событие отправляется, когда пользователь посмотрел{" "}
                  <mark>половину</mark> видео ролика.
                  <br />
                  <br />
                </li>
                <li>
                  <code>getreview_widget_complete</code>
                  <br />
                  Событие отправляется, когда пользователь{" "}
                  <mark>полностью</mark> посмотрел видео ролик.
                  <br />
                  <br />
                </li>
                <li>
                  <code>getreview_widget_cta_click</code>
                  <br />
                  Событие отправляется при <mark>клике</mark> пользователя на{" "}
                  <mark>Call-To-Action</mark> кнопку в видеовиджете.
                  <br />
                  Если видеовиджет настроен без использования CTA, то данное
                  событие не будет отправлено.
                  <br />
                  <br />
                </li>
              </ul>
              В каждое событие в Google Analytics 4 мы передаем дополнительные
              параметры:
              <br />
              <br />
              <ul>
                <li>
                  <code>widget_id</code>
                  <br />
                  Уникальный идентификатор видеовиджета.
                  <br />
                  <br />
                </li>
                <li>
                  <code>video_id</code>
                  <br />
                  Уникальный идентификатор видео, которое смотрел пользователь.
                  <br />
                  <br />
                </li>
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GoogleAnalytics;
