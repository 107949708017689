import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import { useVideoStatusChecker } from "../../components/VideoStatusChecker";

const ThumbnailBlock = styled.div`
  position: absolute;
  top: ${(props) => 10 + props.index * 5}px;
  left: ${(props) => 20 + props.index * 10}px;
  z-index: ${(props) => 10 - props.index};
  width: 80px;
  height: ${(props) => 80 - props.index * 10}px;
  background-size: cover;
  background-position: 50% 30%;
  border-radius: 8px;
  margin-right: 5px;
  opacity: ${(props) => 1 - props.index / 5};
  box-shadow: 1px 2px 0.875rem 0 rgba(53, 64, 82, 0.1);
  background-color: white;

  ${(props) =>
    props.videoStatus === "done" && {
      backgroundImage: `url(${props.thumbnailURL})`,
    }}
`;

const ProcessingLabel = styled.div`
  text-align: center;
  font-size: 9px;
  color: #333333;
  line-height: 12px;
  margin-top: 12px;
`;

const Thumbnail = ({ index, widgetItem }) => {
  const [video, setVideo] = useState(widgetItem.video);

  useVideoStatusChecker(video.status, video.token, (updatedVideo) => {
    setVideo(updatedVideo);
  });

  return (
    <ThumbnailBlock
      index={index}
      videoStatus={video.status}
      thumbnailURL={video.thumbnail_url}
    >
      {video.status !== "done" && (
        <ProcessingLabel>
          <Trans>video.is_processing</Trans>
          <br />
          <br />
          <Spinner color="secondary" size="sm" />
        </ProcessingLabel>
      )}
    </ThumbnailBlock>
  );
};

const Thumbnails = ({ widgetToken, widgetItems }) => {
  const { t } = useTranslation();

  return (
    <Link to={"/widgets/" + widgetToken + "/edit"} title={t("widget.settings")}>
      <div>
        {widgetItems.slice(0, 3).map((widgetItem, index) => (
          <Thumbnail key={index} index={index} widgetItem={widgetItem} />
        ))}
      </div>
    </Link>
  );
};

export default Thumbnails;
