import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-radius: 30px;
  color: #020c38;
  background: #f0f0f0;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  margin: 0 5px;
  transition: background-color 0.1s ease-out, border-color 0.1s ease-out;
  white-space: nowrap;

  ${(props) =>
    props.outline && {
      background: "white",
      border: "1px solid #eee",
    }}

  &:hover {
    color: white;
    border-color: #000a3b;
    background-color: #000a3b;

    ${(props) =>
      props.outline && {
        backgroundColor: "#3b89ff",
        borderColor: "#3b89ff",
      }}

    .link-button-icon {
      color: white;
    }
  }
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Label = styled.div`
  margin: 0 25px 0 10px;
`;

const Icon = styled.div`
  font-size: ${(props) => props.iconSize || 14}px;
  line-height: 0px;
  color: #464d6c;
  transition: color 0.2s ease-out;

  ${(props) =>
    props.label && {
      marginLeft: "20px",
    }}
`;

const LinkTo = ({ id, title, to: linkToURL, openInNewWindow, children }) => {
  if (linkToURL) {
    let targetProps = {};

    if (openInNewWindow) {
      targetProps.target = "_blank";
    }

    return (
      <Link id={id} to={linkToURL} {...targetProps} title={title}>
        {children}
      </Link>
    );
  } else {
    return children;
  }
};

const LinkButton = ({
  id,
  label,
  title,
  icon,
  iconSize,
  outline,
  to: linkToURL,
  onClick,
  openInNewWindow,
}) => {
  const onLinkButtonClick = (event) => {
    if (onClick) {
      event.stopPropagation();
      onClick(event);
    }
  };

  return (
    <LinkTo
      id={id ? id : null}
      to={linkToURL}
      title={title}
      openInNewWindow={openInNewWindow}
    >
      <Container
        className="link-button"
        outline={outline}
        label={label}
        onClick={onLinkButtonClick}
      >
        <Block>
          <Icon
            className="link-button-icon"
            iconSize={iconSize}
            outline={outline}
            label={label}
          >
            <FontAwesomeIcon icon={icon} />
          </Icon>

          {label && <Label>{label}</Label>}
        </Block>
      </Container>
    </LinkTo>
  );
};

export default LinkButton;
