import React, { useState, useEffect } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useSelector, useDispatch } from "react-redux";
import { GETREVIEW_API_HOST } from "../../constants";
import { loadIdentity } from "../../redux/actions/identityActions";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CustomInput,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const YandexMetrika = () => {
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.identity);

  const [yandexMetrikaEnabled, setYandexMetrikaEnabled] = useState(
    project.yandex_metrika_enabled
  );
  const [yandexMetrikaCounterID, setYandexMetrikaCounterID] = useState(
    project.yandex_metrika_counter_id
  );
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    const changed =
      project.yandex_metrika_enabled !== yandexMetrikaEnabled ||
      project.yandex_metrika_counter_id !== yandexMetrikaCounterID;
    setFormChanged(changed);
  }, [project, yandexMetrikaEnabled, yandexMetrikaCounterID]);

  const saveSettings = () => {
    if (yandexMetrikaEnabled && yandexMetrikaCounterID === "") {
      toastr.error("", "Некорректный формат счетчика");
      return;
    }

    axios
      .put(
        `${GETREVIEW_API_HOST}/api/projects/${project.token}`,
        {
          yandex_metrika_enabled: yandexMetrikaEnabled,
          yandex_metrika_counter_id: yandexMetrikaCounterID,
        },
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success("", "Настройки были успешно сохранены");
        setFormChanged(false);
        dispatch(loadIdentity());
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error(
            "",
            "Возникла неизвестная ошибка при сохранении настроек"
          );
        }
      });
  };

  return (
    <Container fluid className="p-0">
      <Row className="mb-2 mb-xl-4">
        <Col xs="auto" className="d-none d-sm-block">
          <h3>Интеграция с Яндекс Метрикой</h3>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Form>
                <FormGroup>
                  <CustomInput
                    type="switch"
                    id="exampleCustomSwitch"
                    name="customSwitch"
                    label="Отправлять события в Яндекс Метрику"
                    checked={yandexMetrikaEnabled}
                    onChange={(event) =>
                      setYandexMetrikaEnabled(event.target.checked)
                    }
                  />
                </FormGroup>

                {yandexMetrikaEnabled && (
                  <FormGroup>
                    <Label>Номер счетчика</Label>

                    <Input
                      type="text"
                      name="name"
                      value={yandexMetrikaCounterID || ""}
                      style={{ width: "300px" }}
                      onChange={(event) =>
                        setYandexMetrikaCounterID(event.target.value)
                      }
                    />
                  </FormGroup>
                )}

                <Button
                  color="primary"
                  disabled={formChanged === false}
                  onClick={saveSettings}
                >
                  Сохранить настройки
                </Button>
              </Form>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              Данный механизм позволяет настроить автоматическую передачу
              событий из видеовиджета в <b>Яндекс Метрику</b>.<br />
              Используя события из видеовиджета вы можете создавать{" "}
              <mark>конверсии</mark>, <mark>сегменты</mark>, строить{" "}
              <mark>отчеты</mark> и т.п.
              <br />К примеру, вы можете настроить <mark>ретаргетинг</mark> на
              пользователей, которые посмотрели определенное видео в
              видеовиджете.
              <br />
              <br />
              Список событий (конверсий), которые можно передавать:
              <br />
              <br />
              <ul>
                <li>
                  <code>GetReview_Widget_Open</code>
                  <br />
                  Событие отправляется при <mark>клике</mark> пользователя на
                  видеовиджет, то есть в тот момент,
                  <br />
                  когда начинается непосредственный <mark>просмотр</mark> видео
                  ролика.
                  <br />
                  <br />
                </li>
                <li>
                  <code>GetReview_Widget_Midpoint</code>
                  <br />
                  Событие отправляется, когда пользователь посмотрел{" "}
                  <mark>половину</mark> видео ролика.
                  <br />
                  <br />
                </li>
                <li>
                  <code>GetReview_Widget_Complete</code>
                  <br />
                  Событие отправляется, когда пользователь{" "}
                  <mark>полностью</mark> посмотрел видео ролик.
                  <br />
                  <br />
                </li>
                <li>
                  <code>GetReview_Widget_CTA_Click</code>
                  <br />
                  Событие отправляется при <mark>клике</mark> пользователя на{" "}
                  <mark>Call-To-Action</mark> кнопку в видеовиджете.
                  <br />
                  Если видеовиджет настроен без использования CTA, то данное
                  событие не будет отправлено.
                  <br />
                  <br />
                </li>
              </ul>
              В каждое событие в Яндекс Метрику мы передаем дополнительные
              параметры:
              <br />
              <br />
              <ul>
                <li>
                  <code>WidgetID</code>
                  <br />
                  Уникальный идентификатор видеовиджета.
                  <br />
                  <br />
                </li>
                <li>
                  <code>VideoID</code>
                  <br />
                  Уникальный идентификатор видео, которое смотрел пользователь.
                  <br />
                  <br />
                </li>
              </ul>
              Подробнее о работе с <b>Яндекс Метрикой</b> и настройке конверсий
              читайте в{" "}
              <a href="https://getreview.io/knowledgebase/ym" target="blank">
                нашей инструкции
              </a>
              .
              <br />
              <br />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default YandexMetrika;
