import React from "react";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { GETREVIEW_API_HOST } from "../../constants";

import {
  AvForm,
  AvField,
  AvGroup
} from "availity-reactstrap-validation";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput
} from "reactstrap";

class NewPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'enter_password',
      password: ''
    };
  }

  handleSubmit = (event) => {
    const query = new URLSearchParams(this.props.location.search);
    const resetPasswordToken = query.get('reset_password_token');

    const formData = new FormData();
    formData.append('account[password]', this.state.password);
    formData.append('account[reset_password_token]', resetPasswordToken);

    axios.put(`${GETREVIEW_API_HOST}/accounts/password`, formData, { withCredentials: true })
      .then(json => {
        if (json.data.status === 'ok') {
          this.setState({ status: 'password_changed' });
          toastr.success('Поздравляем!', 'Новый пароль был успешно сохранен');
        } else {
          alert('Save new password ERROR');
        }
      })
      .catch(error => {
        alert('Save new password ERROR');
      })
  }

  render() {
    return (
      <Card>
        <CardBody>
          <div className="m-sm-4">
            <p className="text-center" style={{ fontSize: '20px', padding: '0 10px' }}>
              Восстановление пароля
            </p>

            {this.state.status == 'enter_password' ? (
              <React.Fragment>
                <p>Придумайте новый пароль для вашего аккаунта</p>

                <AvForm onValidSubmit={this.handleSubmit}>
                  <FormGroup>
                    <AvField
                      required
                      validate={{ minLength: {value: 6, errorMessage: 'Пароль должен содержать не менее шести символов'} }}
                      errorMessage="Поле обязательно для заполнения"
                      type="password"
                      bsSize="lg"
                      name="password"
                      value={this.state.password}
                      onChange={(e) => this.setState({ password: e.target.value })}
                      placeholder="Новый пароль"
                    />
                  </FormGroup>
                  <div className="text-center mt-3">
                    <Button type="submit" color="primary" size="lg">
                      Сохранить новый пароль
                    </Button>
                  </div>
                </AvForm>
              </React.Fragment>
            ) : (
              <div>
                <p className="text-center">Ваш пароль был успешно сохранен! <br />Пожалуйста, войтите в свой аккаунт, используя новый пароль.</p><br />

                <p className="text-center">
                  <a href="/">
                    <Button type="submit" color="primary" size="lg">
                      Перейти на страницу входа
                    </Button>
                  </a>
                </p>
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    );
  }
};

export default NewPassword;
