import React, { useState, useEffect } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { GETREVIEW_API_HOST } from "../../constants";
import Breadcrumbs from "../../components/Breadcrumbs";
import UpgradeRequiredModalWindow from "../../components/UpgradeRequiredModalWindow";
import ReviewsCampaignsList from "./campaigns/List";

import { Card, Container, Table, Row, Col, Button } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const ReviewsIndex = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = () => {
    return axios
      .get(`${GETREVIEW_API_HOST}/api/reviews`, { withCredentials: true })
      .then((json) => {
        setReviews(json.data.reviews);
      })
      .catch((error) => {
        console.error(error);
        toastr.error("", "Ошибка при загрузке видеоотзывов");
      });
  };

  // const ExportCSVURL = `${GETREVIEW_API_HOST}/api/reviews.csv`;

  return (
    <Container fluid className="p-0">
      <UpgradeRequiredModalWindow
        requiredPlans={["standard", "business", "enterprise"]}
        redirectBackPath="/"
        featuresDesc="по сбору видеоотзывов"
        featuresList={[
          "Создание кампаний по автоматическому сбору видеоотзывов",
          "Клиент может записать видеоотзыв с любого устройства",
          "Получение контактов от клиентов, отправивших видеоотзыв",
          "Использование видеоотзывов в видеовиджетах",
          "Скачивание видеоотзывов",
        ]}
      />

      <Row className="mb-3">
        <Col>
          <Breadcrumbs />
          <h3>Видеоотзывы</h3>
        </Col>

        <Col className="d-none d-sm-block">
          <Link to={"/reviews/campaigns/new"} className="float-right">
            <Button
              color="primary"
              className="d-flex justify-content-center align-items-center"
              style={{
                fontSize: "12px",
                lineHeight: "15px",
                padding: "5px 20px",
              }}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="mr-3"
                style={{ fontSize: "12px" }}
              />
              <div className="text-left">
                Создать кампанию
                <br />
                по сбору видеоотзывов
              </div>
            </Button>
          </Link>
        </Col>

        {/* 
        <Col>
          <a href={ExportCSVURL} target="_blank" rel="noopener noreferrer">
            <Button color="success" className="float-right">
              <FontAwesomeIcon icon={faDownload} />
              &nbsp; Экспорт в CSV
            </Button>
          </a>
        </Col> */}
      </Row>

      <Row className="mb-2">
        <Col>
          <h4>
            Кампании
            <Link to={"/reviews/campaigns/new"}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{
                  marginLeft: "12px",
                  position: "relative",
                  top: "1px",
                  color: "#3A89FF",
                  cursor: "pointer",
                }}
              />
            </Link>
          </h4>

          <ReviewsCampaignsList />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <h4>Видеоотзывы</h4>
          </div>

          <Card>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ width: "150px" }}>Видео</th>
                  <th>Описание</th>
                  <th style={{ width: "200px" }}>Имя</th>
                  <th style={{ width: "200px" }}>Email</th>
                </tr>
              </thead>
              <tbody>
                {reviews.map((review) => (
                  <ReviewRow key={review.token} {...review} />
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const ReviewRow = (props) => {
  const video = props.video;

  return (
    <tr>
      <td>
        <div className="review-thumbnail">
          {video ? (
            <div>
              {video.formats.length > 0 && (
                <a
                  href={video.video_mp4_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={
                      video.formats.find((f) => f.layout === "vertical")
                        .thumbnails_url
                    }
                    alt=""
                  />
                </a>
              )}
              <br />
              <a
                href={video.video_mp4_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Смотреть видео</span>
              </a>
            </div>
          ) : (
            <div>Видео удалено</div>
          )}
        </div>
      </td>
      <td>
        <b>Кампания</b>
        <br />
        {props.campaign.name}

        <br />
        <br />

        <b>Длительность</b>
        <br />
        {video ? video.duration : "??"}

        <br />
        <br />

        <b>Дата создания</b>
        <br />
        {video ? video.created_at : "??"}
      </td>
      <td>{props.name}</td>
      <td>{props.email}</td>
    </tr>
  );
};

export default ReviewsIndex;
