import React from "react";
import classnames from "classnames";

const Tab = (props) => {
  const tabClassNames = classnames({
    "tab-block-item": true,
    "tab-block-item-active": props.isActive,
  });

  return (
    <div className={tabClassNames} onClick={props.onClick}>
      <div className="tab-block-button">{props.title}</div>
    </div>
  );
};

export const TabContent = ({ tab, activeTab, children }) => {
  return activeTab === tab ? children : null;
};

const Tabs = (props) => {
  return (
    <div className="tab-block" style={props.style}>
      {props.tabs.map((tab, index) => (
        <Tab
          key={index}
          {...tab}
          isActive={tab.name === props.activeTab}
          onClick={() => props.onChange(tab.name)}
        />
      ))}
    </div>
  );
};

export default Tabs;
