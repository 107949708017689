import { toastr } from "react-redux-toastr";
import { Plans } from "./Pricing";
import { createPayment, savePaymentError } from "./PaymentsGateway";

const CloudPaymentsWidget = (plan, period, account, recurrent, callback) => {
  createPayment(
    plan,
    period,
    "cloudpayments",
    (paymentId, paymentAmount, paymentPlan, paymentPeriod, workspaceId) => {
      callback();
      ShowPaymentWidget(
        paymentId,
        paymentAmount,
        paymentPlan,
        paymentPeriod,
        account,
        recurrent,
        workspaceId
      );
    }
  );
};

const ShowPaymentWidget = (
  paymentId,
  amount,
  plan,
  period,
  account,
  recurrent,
  workspaceId
) => {
  const planOptions = Plans[plan];

  let itemLabel = `Подписка на тариф ${planOptions.name}`;

  if (recurrent === false) {
    itemLabel = `Оплата тарифа ${planOptions.name}`;
  }

  if (period === 12) {
    itemLabel = `Оплата на год по тарифу ${planOptions.name}`;
  } else if (period === 3) {
    itemLabel += " на 3 месяца";
  } else if (period === 6) {
    itemLabel += " на 6 месяцев";
  } else if (recurrent === false && period === 1) {
    itemLabel += " на 1 месяц";
  }

  const cloudPaymentsOptions = {
    googlePaySupport: false,
  };

  const widget = new window.cp.CloudPayments(cloudPaymentsOptions);

  const receipt = {
    Items: [
      {
        label: itemLabel, //наименование товара
        price: amount, //цена
        quantity: 1.0, //количество
        amount: amount, //сумма
        vat: 0, //ставка НДС
        method: 0, // тег-1214 признак способа расчета - признак способа расчета
        object: 0, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
      },
    ],
    email: account.email, //e-mail покупателя, если нужно отправить письмо с чеком
    phone: "", //телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
    isBso: false, //чек является бланком строгой отчётности
    amounts: {
      electronic: amount, // Сумма оплаты электронными деньгами
      advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
      credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после запятой)
      provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
    },
  };

  const data = {
    plan: planOptions.name,
    cloudPayments: {
      customerReceipt: receipt,
    },
  };

  if (recurrent === true) {
    data.cloudPayments.recurrent = {
      customerReceipt: receipt,
      interval: "Month",
      period: period,
    };
  }

  widget.charge(
    {
      // options
      publicId: process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_KEY,
      description: itemLabel + " в GetReview",
      amount: amount,
      currency: "RUB",
      accountId: workspaceId,
      invoiceId: paymentId.toString(),
      email: account.email,
      skin: "mini",
      googlePaySupport: false,
      data: data,
    },
    (options) => {
      toastr.success("Поздравляем!", "Оплата тарифного плана прошла успешно");

      if (process.env.NODE_ENV === "production") {
        window.dataLayer.push({
          event: "eventTracking",
          category: "Purchase",
          action: "planPaid",
        });
      }

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    },
    (reason) => {
      savePaymentError(paymentId, reason || "CLOUDPAYMENTS WIDGET ERROR");

      toastr.warning(
        "Внимание!",
        "Не удалось совершить оплату тарифного плана"
      );
    }
  );
};

export default CloudPaymentsWidget;
