import React, { useState, useEffect } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { GETREVIEW_API_HOST } from "../../constants";
import { Trans } from "react-i18next";
import { Table } from "reactstrap";

const AnalyticsForWidgets = (props) => {
  const [analyticsData, setAnalyticsData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [props.widgetToken, props.dateFrom, props.dateTo, props.deviceType]);

  const fetchData = () => {
    axios
      .get(`${GETREVIEW_API_HOST}/api/analytics/data`, {
        params: {
          widget_id: props.widgetToken,
          device_type: props.deviceType,
          date_from: format(props.dateFrom, "yyyy-MM-dd"),
          date_to: format(props.dateTo, "yyyy-MM-dd"),
          group_by: "widget_id",
        },
        withCredentials: true,
      })
      .then((json) => {
        const analyticsData = [];

        for (const widgetIdStr in json.data) {
          const widgetId = parseInt(widgetIdStr);
          const widget = props.widgets.find((w) => w.id === widgetId);

          if (widget) {
            analyticsData.push({ widget, data: json.data[widgetIdStr] });
          }
        }

        setAnalyticsData(analyticsData);
      })
      .catch((error) => {
        toastr.error("Ошибка!", "Не удалось загрузить аналитику по виджетам");
      });
  };

  return (
    <div style={{ minHeight: "600px" }}>
      <AnalyticsTable data={analyticsData} />
    </div>
  );
};

const AnalyticsTable = (props) => {
  const percentage = (percentFor, percentOf) => {
    if (percentOf === 0) {
      return 0;
    }
    return ((percentFor / percentOf) * 100).toFixed(2);
  };

  const metricPercentage = (value1, value2) => {
    let value = percentage(value1, value2);

    return value === 0 ? (
      <span style={{ opacity: 0.3 }}>0%</span>
    ) : (
      <span>{value}%</span>
    );
  };

  return (
    <Table responsive bordered hover>
      <thead>
        <tr>
          <th style={{ width: "200px", minWidth: "200px" }}>
            <Trans>dashboard.analytics.widget</Trans>
          </th>
          <th className="text-nowrap">
            <Trans>dashboard.analytics.widget_uniq_loads_short</Trans>
          </th>
          <th className="text-nowrap">
            <Trans>dashboard.analytics.widget_opens_short</Trans>
          </th>
          <th className="text-nowrap">
            <Trans>dashboard.analytics.widget_opens_percentage</Trans>
          </th>
          <th className="text-nowrap">
            <Trans>dashboard.analytics.video_progress_start_count_short</Trans>
          </th>
          <th className="text-nowrap">
            <Trans>dashboard.analytics.widget_midpoints_short</Trans>
          </th>
          <th className="text-nowrap">
            <Trans>dashboard.analytics.widget_midpoints_percentage</Trans>
          </th>
          <th className="text-nowrap">
            <Trans>dashboard.analytics.widget_completes_short</Trans>
          </th>
          <th className="text-nowrap">
            <Trans>dashboard.analytics.widget_completes_percentage</Trans>
          </th>
          <th className="text-nowrap">
            <Trans>dashboard.analytics.widget_clicks_short</Trans>
          </th>
          <th className="text-nowrap">
            <Trans>dashboard.analytics.widget_clicks_percentage</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((record) => (
          <tr key={record.widget.token}>
            <td
              style={{ width: "200px", minWidth: "200px", lineHeight: "18px" }}
            >
              <Link to={"/widgets/" + record.widget.token + "/edit"}>
                {record.widget.name}
              </Link>

              <div
                style={{
                  fontSize: "11px",
                  fontFamily: "Courier New",
                  opacity: 0.5,
                  marginTop: "5px",
                }}
              >
                {record.widget.token}
              </div>
            </td>
            <td>{record.data.widget_load_uniq_count}</td>
            <td>{record.data.widget_open_count}</td>
            <td>
              {metricPercentage(
                record.data.widget_open_count,
                record.data.widget_load_uniq_count
              )}
            </td>
            <td>{record.data.video_progress_start_count}</td>
            <td>{record.data.video_progress_midpoint_count}</td>
            <td>
              {metricPercentage(
                record.data.video_progress_midpoint_count,
                record.data.video_progress_start_count
              )}
            </td>
            <td>{record.data.video_progress_complete_count}</td>
            <td>
              {metricPercentage(
                record.data.video_progress_complete_count,
                record.data.video_progress_start_count
              )}
            </td>
            <td>{record.data.call_to_action_button_click_count}</td>
            <td>
              {metricPercentage(
                record.data.call_to_action_button_click_count,
                record.data.widget_open_count
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default AnalyticsForWidgets;
