import axios from "axios";
import React from "react";
import { toastr } from "react-redux-toastr";
import { Button, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumbs";
import { GETREVIEW_API_HOST } from "../../constants";
import Status from "./Status";
import WidgetForm from "./widget_form/WidgetForm";

class WidgetsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      widgetID: this.props.match.params.widget_id,
      customization: {},
    };
  }

  componentDidMount() {
    this.fetchWidget();
  }

  updateWidget = (formData, successCallback, errorCallback) => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/widgets/${this.state.widgetID}`,
        formData,
        { withCredentials: true }
      )
      .then((json) => {
        successCallback();
        toastr.success("Поздравляем!", "Виджет был успешно сохранен");
        this.props.history.push("/widgets/all");
      })
      .catch(errorCallback);
  };

  // v2
  updateVideoWidget = (formData, callbacks) => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/widget/${this.state.widgetID}`,
        formData,
        { withCredentials: true }
      )
      .then(callbacks.onSuccess)
      .catch(callbacks.onError);
  };

  deleteWidget = () => {
    if (window.confirm("Вы уверены, что хотите удалить виджет?")) {
      axios
        .delete(`${GETREVIEW_API_HOST}/api/widgets/${this.state.widgetID}`, {
          withCredentials: true,
        })
        .then((json) => {
          toastr.success("Поздравляем!", "Виджет был успешно удален");
          this.props.history.push("/widgets/all");
        })
        .catch((error) => {
          alert("Delete widget ERROR");
        });
    }
  };

  duplicateWidget = () => {
    if (window.confirm(`Вы уверены, что хотите создать копию виджета?`)) {
      axios
        .put(
          `${GETREVIEW_API_HOST}/api/widgets/${this.state.widgetID}/duplicate`,
          {},
          { withCredentials: true }
        )
        .then((json) => {
          toastr.success("Поздравляем!", "Виджет был успешно продублирован");
          this.props.history.push(`/widgets/${json.data.token}/edit`);
          window.location.reload();
        })
        .catch((error) => {
          alert("Ошибка при дублировании виджета");
        });
    }
  };

  toggleEnabled = (isEnabled) => {
    if (this.state.enabled === isEnabled) return;

    axios
      .put(
        `${GETREVIEW_API_HOST}/api/widgets/${this.state.widgetID}/toggle_enabled`,
        {},
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success("Поздравляем!", "Статус виджета был успешно изменен");
        this.fetchWidget();
      })
      .catch((error) => {
        alert("Ошибка обновления статуса виджета");
      });
  };

  fetchWidget() {
    axios
      .get(`${GETREVIEW_API_HOST}/api/widgets/${this.state.widgetID}`, {
        withCredentials: true,
      })
      .then((json) => {
        const widget = json.data.widget;

        let widgetData = {
          items: widget.items,
          name: widget.name,
          type: widget.type,
          width: widget.width,
          height: widget.height,
          mobile_width: widget.mobile_width,
          mobile_height: widget.mobile_height,
          margin_side: widget.margin_side,
          margin_bottom: widget.margin_bottom,
          mobile_margin_side: widget.mobile_margin_side,
          mobile_margin_bottom: widget.mobile_margin_bottom,
          enabled: widget.enabled,
          hide_after_destroy: widget.hide_after_destroy,
          desktop_enabled: widget.desktop_enabled,
          mobile_enabled: widget.mobile_enabled,
          recorded_videos: widget.recorded_videos,
          cta_scope: (widget.cta && widget.cta.scope) || "",
          cta_button_text: widget.cta && widget.cta.button_text,
          cta_show_at: widget.cta && widget.cta.show_at,
          cta_form_submit_text:
            (widget.cta &&
              widget.cta.options &&
              widget.cta.options.submit &&
              widget.cta.options.submit.text) ||
            "Отправить",
          cta_link:
            (widget.cta && widget.cta.options && widget.cta.options.url) || "",
          cta_link_new_window:
            (widget.cta &&
              widget.cta.options &&
              widget.cta.options.new_window) ||
            false,
          cta_scroll:
            (widget.cta && widget.cta.options && widget.cta.options.scroll) ||
            "",
          cta_click:
            (widget.cta && widget.cta.options && widget.cta.options.click) ||
            "",
          customization_form:
            Object.entries(widget.customization).length > 0 ? "visible" : "",
          customization_border_color:
            widget.customization && widget.customization.border_color,
          customization_border_hover:
            widget.customization && widget.customization.border_hover,
          customization_cta_color:
            widget.customization && widget.customization.cta_color,
          customization_cta_text_color:
            widget.customization && widget.customization.cta_text_color,
          customization: widget.customization || {},
          position: widget.position,
          display_pages: widget.display_pages,
          display_pages_list: widget.display_pages_list,
          site_domain: widget.site_domain,
          site_token: widget.site_token,
        };

        if (widget.cta && widget.cta.scope == "form") {
          let cta_form_name = null;
          let cta_form_email = null;

          widget.cta.options.fields.forEach((field, index) => {
            if (field.name == "name") {
              cta_form_name = field.placeholder;
            }

            if (field.name == "email") {
              cta_form_email = field.placeholder;
            }
          });

          widgetData.cta_form_email = cta_form_email;
          widgetData.cta_form_email_checked = !!cta_form_email;
          widgetData.cta_form_name = cta_form_name;
          widgetData.cta_form_name_checked = !!cta_form_name;
        }

        widgetData.frequencyUserInteractedOption =
          widget.customization.frequencyUserInteractedOption;
        widgetData.frequencyUserInteractedDelayDimension =
          widget.customization.frequencyUserInteractedDelayDimension;
        widgetData.frequencyUserInteractedDelayValue =
          widget.customization.frequencyUserInteractedDelayValue;
        widgetData.frequencyUserDestroyedOption =
          widget.customization.frequencyUserDestroyedOption;
        widgetData.frequencyUserDestroyedDelayDimension =
          widget.customization.frequencyUserDestroyedDelayDimension;
        widgetData.frequencyUserDestroyedDelayValue =
          widget.customization.frequencyUserDestroyedDelayValue;

        widgetData.displayByCustomPropertiesRules =
          widget.customization.displayByCustomPropertiesRules;

        this.setState(widgetData);
      })
      .catch((error) => {
        toastr.error("", "Возникла ошибка при загрузке виджета");
      });
  }

  render() {
    const widgetTypeName = {
      VideoBubble: "Bubble",
      VideoInline: "Inline",
      VideoCarousel: "Carousel",
      VideoStories: "Stories",
      VideoGrid: "Grid",
    }[this.state.type];

    const widgetSettings = this.state.customization;

    return (
      <div>
        <Container fluid className="p-0 edit-widget-settings">
          <Row className="mb-2 mb-xl-4 d-flex">
            <Col>
              <Breadcrumbs />
              <h3>{this.state.name}</h3>
            </Col>

            {this.state.type === null && (
              <Col>
                <div className="d-flex justify-content-end edit-widget-settings-buttons">
                  <div className="edit-widget-settings-button">
                    <div style={{ marginTop: "6px", marginRight: "15px" }}>
                      <Status
                        isEnabled={this.state.enabled}
                        onSelect={this.toggleEnabled}
                      />
                    </div>
                  </div>

                  <Button
                    color="light"
                    className="edit-widget-settings-button"
                    onClick={this.duplicateWidget}
                  >
                    Дублировать
                  </Button>

                  <Button
                    color="danger"
                    className="edit-widget-settings-button"
                    onClick={this.deleteWidget}
                  >
                    Удалить
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </Container>

        {this.state.type != null && this.state.name != null && (
          <WidgetForm
            widgetType={this.state.type}
            widgetTypeName={widgetTypeName}
            widgetToken={this.state.widgetID}
            widgetSettings={widgetSettings}
            widgetItems={this.state.items}
            isEnabled={this.state.enabled}
            name={this.state.name}
            siteDomain={this.state.site_domain}
            siteToken={this.state.site_token}
            displayPages={this.state.display_pages}
            displayPagesList={this.state.display_pages_list}
            onDuplicateWidgetClick={this.duplicateWidget}
            onEnableToggleWidgetClick={this.toggleEnabled}
            onDeleteWidgetClick={this.deleteWidget}
            onFormSubmit={this.updateVideoWidget}
            onNameUpdate={(value) => this.setState({ name: value })}
          />
        )}
      </div>
    );
  }
}

export default WidgetsEdit;
