import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Tabs, { TabContent } from "../../components/Tabs";
import WorkspaceBillingSettingsForm from "../../components/WorkspaceBillingSettingsForm";
import WorkspaceSettingsInvoices from "./WorkspaceSettingsInvoices";
import WorkspaceSettingsMembers from "./WorkspaceSettingsMembers";

import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";

const WorkspaceSettings = () => {
  const history = useHistory();
  const location = useLocation();

  const tabs = [
    { name: "#projects", title: "Проекты" },
    { name: "#members", title: "Пользователи" },
    { name: "#details", title: "Реквизиты" },
    { name: "#invoices", title: "Счета и акты" },
  ];

  const defaultTab = (
    tabs.find((t) => {
      return t.name === location.hash;
    }) || tabs[0]
  ).name;

  const { workspace, workspaces, projects } = useSelector(
    (state) => state.identity
  );

  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    history.push({ hash: activeTab });
  }, [history, activeTab]);

  const changeWorkspace = (workspaceToken) => {
    localStorage.setItem("_gr_selected_workspace_token", workspaceToken);
    window.location.reload();
  };

  return (
    <Container fluid className="p-0">
      <Row className="mb-4">
        <Col>
          <h3>Настройки организации</h3>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            style={{
              marginBottom: 0,
            }}
            onChange={(tabName) => setActiveTab(tabName)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <TabContent tab="#details" activeTab={activeTab}>
            <Card style={{ maxWidth: "600px" }}>
              <CardBody>
                <WorkspaceBillingSettingsForm forModalWindow={false} />
              </CardBody>
            </Card>
          </TabContent>

          <TabContent tab="#invoices" activeTab={activeTab}>
            <WorkspaceSettingsInvoices />
          </TabContent>

          <TabContent tab="#projects" activeTab={activeTab}>
            <Card>
              <CardBody>
                <h4>Проекты в организации</h4>

                <ul>
                  {projects.map((workspaceProject) => (
                    <li key={workspaceProject.token}>
                      {workspaceProject.name}
                    </li>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </TabContent>

          <TabContent tab="#members" activeTab={activeTab}>
            <WorkspaceSettingsMembers />
          </TabContent>
        </Col>

        {activeTab === "#projects" && workspaces.length > 1 && (
          <Col md={6}>
            <Card>
              <CardBody>
                <h4>Переключение оргазинации</h4>
                <p>Выберите организацию для переключения рабочей области</p>
                {workspaces.map((workspaceData, index) => (
                  <Button
                    disabled={workspaceData.workspace.token === workspace.token}
                    color="secondary"
                    size="sm"
                    className="mr-2"
                    onClick={() =>
                      changeWorkspace(workspaceData.workspace.token)
                    }
                  >
                    {workspaceData.workspace.name}
                  </Button>
                ))}
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default WorkspaceSettings;
