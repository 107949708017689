import axios from "axios";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../../constants";

export const createPayment = (plan, period, merchant, successCallback) => {
  axios
    .post(
      `${GETREVIEW_API_HOST}/api/billing/create_payment`,
      { plan, period, merchant },
      { withCredentials: true }
    )
    .then((json) => {
      const response = json.data;

      if (response.status === "ok") {
        const paymentId = response.id;
        const amount = response.amount;
        const workspaceId = response.workspace_id;
        const invoicePdfUrl = response.invoice_pdf_url;

        if (paymentId != null || amount != null) {
          successCallback(
            paymentId,
            amount,
            plan,
            period,
            workspaceId,
            invoicePdfUrl
          );
        }
      } else {
        toastr.error("", "Возникла неизвестная ошибка при создании платежа");
      }
    })
    .catch((error) => {
      console.log("error", error);
      if (error.unknownError) {
        toastr.error("", "Возникла неизвестная ошибка при создании платежа");
      }
    });
};

export const savePaymentError = (paymentId, errorMessage) => {
  axios.put(
    `${GETREVIEW_API_HOST}/api/billing/save_payment_error`,
    { payment_id: paymentId, error_message: errorMessage },
    { withCredentials: true }
  );
};
