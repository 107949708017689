import React, { useState } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useTranslation, Trans } from "react-i18next";
import { GETREVIEW_API_HOST } from "../../constants";
import GoogleLogin from "react-google-login";

import googleIcon from "../../assets/img/icons/google_icon.svg";

const GoogleButton = (props) => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);

  const handleSuccess = (response) => {
    setProcessing(false);

    const firstName = response.profileObj.givenName;
    const lastName = response.profileObj.familyName;
    const email = response.profileObj.email;
    const googleAuthToken = response.tokenId;

    const googleAccountData = {
      firstName,
      lastName,
      email,
      googleAuthToken,
    };

    if (props.onSuccess != null) {
      props.onSuccess(googleAccountData);
    }
  };

  const checkAuthToken = (response) => {
    axios
      .post(`${GETREVIEW_API_HOST}/accounts/auth/google`, response, {
        withCredentials: true,
      })
      .then((json) => {
        const status = json.data.status;

        console.log("json.data", json.data);

        if (status === "account_exists") {
          if (process.env.NODE_ENV === "production") {
            window.dataLayer.push({
              event: "eventTracking",
              category: "Account",
              action: "signInDone",
            });
          }

          window.location.reload();
        } else if (status === "account_not_exists" && props.failIfNotExists) {
          toastr.error(
            "Ошибка!",
            "Ошибка авторизации через Google: Аккаунт не найден"
          );
          setProcessing(false);
        } else {
          handleSuccess(response);
        }
      })
      .catch((error) => {
        toastr.error(
          "Ошибка!",
          "Ошибка авторизации через Google: Неизвестная ошибка"
        );
        setProcessing(false);
      });
  };

  const LoginButton = (renderProps) => {
    const defaultText = <span>{t("auth.google_button.sign_up_text")}</span>;

    const onLoginButtonClick = () => {
      setProcessing(true);
      renderProps.onClick();
    };

    return (
      <div
        className="auth-login-button"
        style={{ opacity: processing ? 0.5 : 1 }}
        onClick={onLoginButtonClick}
      >
        <div className="auth-login-text">{props.text || defaultText}</div>
        <img src={googleIcon} alt="Sign in with Google" />
      </div>
    );
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      autoLoad={false}
      onSuccess={checkAuthToken}
      onFailure={() => setProcessing(false)}
      cookiePolicy={"single_host_origin"}
      render={LoginButton}
    />
  );
};

export default GoogleButton;
