import React from "react";
import axios from "axios";
import { GETREVIEW_API_HOST } from "../constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { Trans } from "react-i18next";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import instructionsIcon from "../assets/img/icons/instructions_icon.svg";
import PendingPayment from "./PendingPayment";

const NavbarComponent = (props) => {
  const dispatch = useDispatch();
  const { workspace, account } = useSelector((state) => state.identity);

  const signOut = () => {
    axios
      .delete(`${GETREVIEW_API_HOST}/accounts/sign_out`, {
        withCredentials: true,
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        alert("Sign out error");
      });
  };

  let AccountBadge;

  if (workspace.status === "trial_active") {
    AccountBadge = (
      <Badge color={workspace.billing_days_left === 0 ? "danger" : "warning"}>
        <Trans values={{ count: workspace.billing_days_left }}>
          plans.days_left
        </Trans>
      </Badge>
    );
  } else if (workspace.status === "regular_active") {
    AccountBadge = (
      <Badge color="success">
        <b>
          <Trans>plans.active</Trans>
        </b>
      </Badge>
    );
  } else {
    AccountBadge = (
      <Badge color="danger">
        <b>
          <Trans>plans.suspended</Trans>
        </b>
      </Badge>
    );
  }

  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          {(workspace.status === "regular_active" ||
            workspace.status === "regular_suspended") &&
            workspace.billing_auto_generated_pending_payment &&
            workspace.billing_days_left <= 10 && (
              <PendingPayment
                payment={workspace.billing_auto_generated_pending_payment}
                billing_days_left={workspace.billing_days_left}
              />
            )}

          <Link
            to="/account/billing/plans"
            className="d-none d-md-block mr-6"
            style={{ color: "#05003b", textDecoration: "none" }}
          >
            <span style={{ fontSize: "100%" }}>
              <Trans>navbar.plan_label</Trans>
              &nbsp;
              <b id="workspace_plan_name">
                <Trans>plans.names.{workspace.plan.name}</Trans>
              </b>
            </span>
            <br />
            {AccountBadge}
          </Link>
          <div className="d-sm-inline-block mr-5" style={{ marginTop: "8px" }}>
            <span>
              <a
                href="https://getreview.io/help"
                className="text-dark"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  style={{ width: "22px", opacity: 0.5 }}
                  className="mr-2"
                  src={instructionsIcon}
                  alt="Help Center"
                />
                <Trans>navbar.help_center</Trans>
              </a>
            </span>
          </div>
          <UncontrolledDropdown nav inNavbar id="navbar-profile">
            <div className="d-sm-inline-block">
              <DropdownToggle nav style={{ padding: 0 }}>
                <div className="d-flex">
                  <div
                    className="text-dark d-none d-sm-none d-md-none d-lg-inline"
                    style={{ textAlign: "right", marginRight: "20px" }}
                  >
                    <span id="navbar_account_first_name">
                      {account.first_name}
                    </span>
                    <br />
                    <span style={{ opacity: 0.5 }} id="navbar_account_email">
                      {account.email}
                    </span>
                  </div>

                  <div>
                    <FontAwesomeIcon
                      className="avatar mr-2"
                      style={{
                        color: "#354052",
                        opacity: 0.5,
                        fontSize: "20px",
                        position: "relative",
                        top: "8px",
                      }}
                      icon={faUser}
                    />
                  </div>
                </div>
              </DropdownToggle>
            </div>
            <DropdownMenu right>
              <DropdownItem>
                <Link to="/workspace/settings" className="menu-item">
                  <Trans>navbar.workspace_settings</Trans>
                </Link>
              </DropdownItem>

              <DropdownItem divider />

              <DropdownItem>
                <Link to="/account/settings" className="menu-item">
                  <Trans>navbar.account</Trans>
                </Link>
              </DropdownItem>

              <DropdownItem>
                <Link to="/account/billing/plans" className="menu-item">
                  <Trans>navbar.plans</Trans>
                </Link>
              </DropdownItem>

              <DropdownItem divider />

              <DropdownItem onClick={signOut} id="navbar_menu_sign_out">
                <div className="menu-item">
                  <Trans>navbar.sign_out</Trans>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
