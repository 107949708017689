import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";
import { useSelector } from "react-redux";
import { dashboard as dashboardRoutes, auth as authRoutes } from "./index";

import NewWidgetType from "../pages/widgets/NewWidgetType";
import Pricing from "../pages/pages/Pricing";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import SignIn from "../pages/auth/SignIn";
import AccountConfirmed from "../pages/auth/AccountConfirmed";
import ResetPassword from "../pages/auth/ResetPassword";
import NewPassword from "../pages/auth/NewPassword";
import Preview from "../pages/widgets/Preview";
import Customize from "../pages/widgets/Customize";
import WidgetEditor from "../components/WidgetEditor";

import ScrollToTop from "../components/ScrollToTop";
// import initLangSupport from "../i18n";

const childRoutes = (Layout, routes) => {
  return routes.map(({ children, path, component: Component }, index) =>
    children ? (
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );
};

const SecureRoutes = ({ account, workspace }) => (
  <div className="global-wrapper">
    <Switch>
      {account &&
        (account.sign_up_done === false || account.confirmed === false) && (
          <Route
            path="*"
            exact
            render={(props) => (
              <DashboardLayout>
                <NewWidgetType {...props} />
              </DashboardLayout>
            )}
          />
        )}

      {account != null && <Redirect from="/account/sign_up" to="/" />}
      {childRoutes(DashboardLayout, dashboardRoutes)}
      <Route
        path="/widgets/:widget_id/preview"
        exact
        render={(componentProps) => <Preview {...componentProps} />}
      />
      <Route
        path="/widgets/:widget_id/editor"
        exact
        render={(componentProps) => <WidgetEditor {...componentProps} />}
      />
      <Route
        path="/widgets/:widget_id/customize"
        exact
        render={(componentProps) => <Customize {...componentProps} />}
      />
      <Route
        path="/account/confirmed"
        render={(componentProps) => (
          <AuthLayout>
            <AccountConfirmed {...componentProps} />
          </AuthLayout>
        )}
      />
      <Route
        path="/account/reset_password"
        render={(componentProps) => (
          <AuthLayout>
            <ResetPassword {...componentProps} />
          </AuthLayout>
        )}
      />
      <Route
        path="/account/new_password"
        render={(componentProps) => (
          <AuthLayout>
            <NewPassword {...componentProps} />
          </AuthLayout>
        )}
      />
      <Route
        render={(componentProps) => (
          <AuthLayout>
            <Page404 {...componentProps} />
          </AuthLayout>
        )}
      />
    </Switch>
  </div>
);

const Routes = (props) => {
  const identity = useSelector((state) => state.identity);
  const { account, workspace } = identity;

  return (
    <ScrollToTop>
      {identity.authenticated === false ? (
        <Switch>
          {childRoutes(AuthLayout, authRoutes)}
          <Route
            render={() => (
              <AuthLayout>
                <SignIn />
              </AuthLayout>
            )}
          />
        </Switch>
      ) : (
        <SecureRoutes account={account} workspace={workspace} />
      )}
    </ScrollToTop>
  );
};

export default Routes;
