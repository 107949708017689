import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { Trans } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { selectProject } from "../redux/actions/identityActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProjectSettingsFormModalWindow from "./ProjectSettingsFormModalWindow";

import {
  faAngleDown,
  faArrowRight,
  faTimesCircle,
  faPlus,
  faCog,
} from "@fortawesome/free-solid-svg-icons";

const growDown = keyframes`
  0% {
    opacity: 0;
    top: -20px;
  }

  100% {
    opacity: 1;
    top: 0px;
  }
`;

const Container = styled.div`
  padding: 0.6rem 1.5rem;
`;

const Block = styled.div`
  cursor: pointer;
  position: relative;
  background: #131a47;
  color: white;
  border-radius: 6px;
  border: 1px solid #212754;
  transition: border-color 0.1s ease-out;

  &:hover {
    border-color: #455399;
  }
`;

const CurrentProject = styled.div`
  padding: 10px 15px;
`;

const Label = styled.div`
  font-size: 0.75rem;
  opacity: 0.7;
  color: #adb5bd;
  margin-bottom: 1px;
`;

const ProjectName = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  color: white;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 10px;
  right: 0px;
  opacity: 0.7;
  color: #adb5bd;
`;

const ProjectList = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background: white;
  z-index: 10;
  border-radius: 6px;
  animation: 0.5s ${growDown} ease-out;
`;

const ProjectListLabel = styled.div`
  font-size: 0.8rem;
  color: #000a3b;
  opacity: 0.7;
  padding: 15px 20px 15px 20px;
`;

const ProjectListCloseButton = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
  cursor: pointer;
  font-size: 16px;
  color: #d9dcde;
  z-index: 20;
  transition: transform 0.3s ease-out;

  &:hover {
    transform: scale(1.1);
    color: #969fa8;
  }
`;

const Project = styled.div`
  position: relative;
  padding: 10px 20px;
  font-size: 0.8rem;
  color: #000a3b;
  border-bottom: 1px solid #edeff0;
  transition: background-color 0.2s ease-out;

  ${(props) =>
    props.selected === true && {
      background: "#edeff0",
      fontWeight: "600",
    }}

  &:hover {
    background: #edeff0;
  }
`;

const ProjectSettingsIcon = styled.div`
  position: absolute;
  top: 7px;
  right: 10px;
  z-index: 10;
  opacity: 0.5;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out,
    color 0.2s ease-out;
  color: #000a3b;
  font-size: 17px;

  &:hover {
    opacity: 1;
    transform: scale(1.2);
    color: #3a89ff;
  }
`;

const AddNewProjectButton = styled.div`
  color: #3a89ff;
  margin: 15px 15px;
  text-align: center;
  font-size: 0.8rem;
  background: white;
  padding: 10px 15px;
  border-radius: 6px;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;

  &:hover {
    color: #000a3b;
    background: #edeff0;
  }
`;

const ProjectSelectorSidebar = (props) => {
  const dispatch = useDispatch();
  const { project: currentProject, projects } = useSelector(
    (state) => state.identity
  );
  const prevProject = useRef(currentProject);
  const [projectListVisible, setProjectListVisible] = useState(false);
  const [newProjectModalVisible, setNewProjectModalVisible] = useState(false);

  useEffect(() => {
    if (currentProject.token !== prevProject.current.token) {
      saveProjectTokenToLocalStorage(currentProject.token);
      window.location.reload();
    }

    prevProject.current = currentProject;
  }, [currentProject]);

  const saveProjectTokenToLocalStorage = (projectToken) => {
    localStorage.setItem("_gr_selected_project_token", projectToken);
  };

  return (
    <Container>
      {newProjectModalVisible && (
        <ProjectSettingsFormModalWindow
          onClose={() => setNewProjectModalVisible(false)}
        />
      )}

      <Block>
        <CurrentProject
          onClick={() => setProjectListVisible((value) => !value)}
        >
          <Label>
            <Trans>sidebar.current_project</Trans>
          </Label>
          <ProjectName>{currentProject && currentProject.name}</ProjectName>
          <Dropdown>
            <FontAwesomeIcon className="mr-3" icon={faAngleDown} />
          </Dropdown>
        </CurrentProject>

        {projectListVisible && (
          <ProjectList>
            <ProjectListCloseButton
              onClick={() => setProjectListVisible(false)}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </ProjectListCloseButton>

            <ProjectListLabel>
              <Trans>sidebar.your_projects</Trans>
            </ProjectListLabel>

            {projects.map((project, index) => (
              <Project
                key={index}
                selected={currentProject.token === project.token}
                onClick={() => {
                  dispatch(selectProject(project.token));
                }}
              >
                {currentProject.token === project.token && (
                  <FontAwesomeIcon className="mr-2" icon={faArrowRight} />
                )}
                {project.name}

                {currentProject.token === project.token && (
                  <Link
                    to={`/settings/project`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setProjectListVisible(false);
                    }}
                  >
                    <ProjectSettingsIcon>
                      <FontAwesomeIcon icon={faCog} />
                    </ProjectSettingsIcon>
                  </Link>
                )}
              </Project>
            ))}

            <AddNewProjectButton
              onClick={() => setNewProjectModalVisible(true)}
            >
              <FontAwesomeIcon className="mr-2" icon={faPlus} />
              <Trans>sidebar.new_project</Trans>
            </AddNewProjectButton>
          </ProjectList>
        )}
      </Block>
    </Container>
  );
};

export default ProjectSelectorSidebar;
