import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import classnames from "classnames";
import { InView } from "react-intersection-observer";
import axios from "axios";
import LinesEllipsis from "react-lines-ellipsis";
import { Trans } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { useSelector } from "react-redux";
import { GETREVIEW_API_HOST } from "../../constants";
import { useDrag, useDrop } from "react-dnd";
import Folders from "../../components/Folders";
import Uploader from "../../components/Uploader";
import VideoEditor from "../../components/VideoEditor";
import Breadcrumbs from "../../components/Breadcrumbs";
import FolderSelector from "../../components/FolderSelector";
import { useVideoStatusChecker } from "../../components/VideoStatusChecker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faAngleRight,
  faFolder,
  faFolderOpen,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
  CustomInput,
} from "reactstrap";

const VideosNavLink = (props) => {
  const [{ highlighted }, drop] = useDrop({
    accept: "video",
    drop: (item, monitor) => {
      return { folderToken: "root" };
    },
    collect: (monitor) => ({
      highlighted: monitor.canDrop(),
    }),
  });

  const classNames = classnames({
    "header-link": true,
    highlighted: highlighted,
  });

  return (
    <Link to="/videos" ref={drop} className={classNames}>
      <Trans>sidebar.all_videos</Trans>
    </Link>
  );
};

const Videos = () => {
  const { folderToken } = useParams();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [folder, setFolder] = useState(null);
  const [videos, setVideos] = useState([]);
  const [videoEditorToken, setVideoEditorToken] = useState(null);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectFolderModalVisible, setSelectFolderModalVisible] =
    useState(false);

  const reloadVideos = () => {
    fetchVideos({ reload: true });

    document.querySelector(".main").scrollIntoView({ behavior: "smooth" });
  };

  const fetchVideos = (options) => {
    let pageToLoad = page;

    if (options && options.reload === true) {
      setLastPage(false);
      setPage(1);
      pageToLoad = 1;
    } else if (lastPage) {
      return;
    }

    axios
      .get(`${GETREVIEW_API_HOST}/api/videos`, {
        params: {
          folder: folderToken,
          page: pageToLoad,
          page_size: 20,
          sort_by: "created_at",
          order_by: "desc",
        },
        withCredentials: true,
      })
      .then((json) => {
        const currentVideos = [...videos];
        let newVideos = json.data.videos;

        if (options && options.append === true) {
          newVideos = currentVideos.concat(...newVideos);
        }

        setPage((prevPage) => prevPage + 1);
        setLastPage(json.data.last_page);
        setVideos(newVideos);
        setFolder(json.data.folder);
        setLoading(false);
      })
      .catch((error) => {
        alert("Ошибка при получении списка видео");
      });
  };

  const onUploadSuccess = (uploadedVideos) => {
    toastr.success("Поздравляем!", "Видеофайлы были успешно загружены");
    reloadVideos();
  };

  const onVideoDeleted = () => {
    toastr.success("Поздравляем!", "Видео было успешно удалено");

    setVideoEditorToken(null);
    reloadVideos();
  };

  const onToggleSelection = (videoToken, checked) => {
    const selectedVideoTokens = [...selectedVideos];
    const selectedVideoTokenIndex = selectedVideos.findIndex(
      (token) => token === videoToken
    );

    if (checked) {
      if (selectedVideoTokenIndex === -1) {
        setSelectedVideos([...selectedVideoTokens, videoToken]);
      }
    } else {
      if (selectedVideoTokenIndex !== -1) {
        selectedVideoTokens.splice(selectedVideoTokenIndex, 1);
        setSelectedVideos(selectedVideoTokens);
      }
    }
  };

  const syncVideo = (updatedVideo) => {
    const newVideos = [...videos];
    const videoIndex = newVideos.findIndex(
      (v) => v.token === updatedVideo.token
    );
    newVideos[videoIndex] = updatedVideo;

    setVideos(newVideos);
    setVideoEditorToken(null);
  };

  const deleteSelectedVideos = () => {
    const confirmBlock = (
      <div>
        Вы уверены, что хотите удалить выбранные видео?
        <br />
        <br />
        Будет удалено видео: <b>{selectedVideos.length}</b>
      </div>
    );

    toastr.confirm(confirmBlock, {
      onOk: () => {
        axios
          .delete(`${GETREVIEW_API_HOST}/api/videos/delete_selected`, {
            data: { videos: selectedVideos },
            withCredentials: true,
          })
          .then((json) => {
            toastr.success("Поздравляем!", "Видео были успешно удалены");

            setSelectedVideos([]);
            reloadVideos();
          })
          .catch(() => {
            alert("Ошибка при удалении видео");
          });
      },
    });
  };

  const moveToFolderSelectedVideos = (moveToFolderToken) => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/videos/move_to_folder_selected`,
        { folder_token: moveToFolderToken, videos: selectedVideos },
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success("Поздравляем!", "Видео были успешно перенесены в папку");
        setSelectedVideos([]);
        reloadVideos();
      })
      .catch(() => {
        alert("Ошибка при переносе видео в папку");
      });
  };

  return (
    <Container fluid className="p-0">
      {videoEditorToken && (
        <div>
          <VideoEditor
            videoToken={videoEditorToken}
            onClose={syncVideo}
            onDelete={onVideoDeleted}
          />
        </div>
      )}

      {selectFolderModalVisible && (
        <FolderSelector
          section="videos"
          onClose={() => setSelectFolderModalVisible(false)}
          onComplete={(moveToFolderToken) => {
            setSelectFolderModalVisible(false);
            moveToFolderSelectedVideos(moveToFolderToken);
          }}
        />
      )}

      {selectedVideos.length > 0 && (
        <div className="checked-items-block">
          <div className="selected-items-count d-none d-md-block">
            Выбрано видео: <b>{selectedVideos.length}</b>
          </div>

          <div className="selected-items-actions">
            <div
              className="selected-items-action"
              onClick={() => setSelectFolderModalVisible(true)}
            >
              <FontAwesomeIcon
                icon={faFolderOpen}
                style={{ fontSize: "14px" }}
              />
              <div
                style={{ display: "inline-block", marginLeft: "10px" }}
                className="d-none d-md-inline-block"
              >
                Перенести
              </div>
            </div>

            <div
              className="selected-items-action"
              onClick={deleteSelectedVideos}
            >
              <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: "14px" }} />
              <div
                style={{ display: "inline-block", marginLeft: "10px" }}
                className="d-none d-md-inline-block"
              >
                Удалить
              </div>
            </div>
          </div>

          <div className="selected-items-cancel">
            <div
              className="selected-items-action"
              onClick={() => setSelectedVideos([])}
            >
              Отмена
            </div>
          </div>
        </div>
      )}

      <Row className="mb-2 mb-xl-4">
        <Col xs="12">
          <Breadcrumbs />

          <h3>
            <VideosNavLink />

            {folder && <FontAwesomeIcon className="ml-2" icon={faAngleRight} />}
          </h3>

          {folder && (
            <div>
              <FontAwesomeIcon
                className="mr-2"
                icon={faFolder}
                style={{ color: "#3a89ff" }}
              />
              {folder.name}
            </div>
          )}
        </Col>

        <Col xs="12">
          <div
            className="video-selector-uploader justify-content-start justify-content-sm-end"
            style={{ marginTop: "10px" }}
          >
            <Uploader folderToken={folderToken} onSuccess={onUploadSuccess} />
          </div>
        </Col>
      </Row>

      {folderToken == null && (
        <Row>
          <Col lg="12">
            <Folders section="videos" />
          </Col>
        </Row>
      )}

      <Row>
        {loading ? (
          <Col lg="12">Загрузка видео...</Col>
        ) : (
          <>
            {videos.map((video) => (
              <Video
                key={video.token}
                video={video}
                isSelected={selectedVideos.indexOf(video.token) !== -1}
                onClick={() => setVideoEditorToken(video.token)}
                onMoveToFolder={() => reloadVideos()}
                onToggleSelection={onToggleSelection}
              />
            ))}
          </>
        )}
      </Row>

      <Row>
        <InView
          as="div"
          rootMargin="100px"
          onChange={(inView) => {
            if (inView === true) {
              fetchVideos({ append: true });
            }
          }}
        ></InView>
      </Row>
    </Container>
  );
};

const Video = (props) => {
  const [video, setVideo] = useState(props.video);

  useEffect(() => {
    setVideo(props.video);
  }, [props.video]);

  const [{ isDragging }, drag] = useDrag({
    item: { videoID: props.video.token, type: "video" },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult && dropResult.folderToken != null) {
        moveVideoToFolder(dropResult.folderToken);
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useVideoStatusChecker(video.status, video.token, (updatedVideo) => {
    setVideo(updatedVideo);
  });

  const moveVideoToFolder = (folderToken) => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/videos/${props.video.token}/move_to_folder`,
        { folder_token: folderToken },
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success("Поздравляем!", "Видео было успешно перенесено в папку");

        if (props.onMoveToFolder != null) {
          props.onMoveToFolder();
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Ошибка при переносе видео в папку");
      });
  };

  const onClick = (event) => {
    if (video.status === "done") {
      props.onClick();
    }
  };

  const classNames = classnames({
    "video-gallery-block": true,
    selected: props.isSelected,
    draggable: isDragging,
  });

  return (
    <div ref={drag} className={classNames}>
      <CustomInput
        type="checkbox"
        id={`select-video-${video.token}`}
        checked={props.isSelected}
        className="video-gallery-checkbox"
        onChange={(event) => {
          props.onToggleSelection(video.token, event.target.checked);
        }}
      />

      <div
        className="video-gallery-thumbnail"
        style={{
          backgroundImage:
            video.status === "done" ? `url(${video.thumbnail_url})` : "none",
        }}
        onClick={onClick}
      >
        <div className="video-gallery-gradient-top"></div>
        <div className="video-gallery-gradient-bottom"></div>

        {video.status === "done" && (
          <div className="video-gallery-duration">{video.duration_human}</div>
        )}

        {video.status !== "done" && (
          <div className="video-gallery-status-processing">
            <div>
              Обработка
              <br />
              видео
            </div>
            <div className="mt-3">
              <Spinner color="secondary" size="sm" />
            </div>
          </div>
        )}
      </div>
      <div className="video-gallery-meta">
        <LinesEllipsis
          text={video.name}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>
    </div>
  );
};

export default Videos;
