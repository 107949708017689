import {
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import styled from "styled-components";
import { GETREVIEW_API_HOST } from "../constants";
import Folder from "./Folder";
import SortableFolder from "./SortableFolder";

const Container = styled.div`
  margin-bottom: 10px;
  font-size: 12px;
`;

const FoldersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 568px) {
    flex-direction: column;
  }
`;

const Folders = ({ section, onUpdate }) => {
  const { t } = useTranslation();
  const [folders, setFolders] = useState([]);
  const prevFolders = useRef();
  const [draggableFolderToken, setDraggableFolderToken] = useState(undefined);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 3,
      },
    }),
    useSensor(TouchSensor)
  );

  useEffect(() => {
    const fetchFolders = () => {
      axios
        .get(`${GETREVIEW_API_HOST}/api/folders`, {
          params: {
            section,
          },
          withCredentials: true,
        })
        .then((json) => {
          let normalizedFolders = json.data.folders.map((folder) => {
            folder.id = folder.token;
            return folder;
          });

          setFolders(normalizedFolders);
        })
        .catch((error) => {
          alert(error);
        });
    };

    fetchFolders();
  }, []);

  useEffect(() => {
    if (draggableFolderToken === null) {
      saveFoldersPosition();
    }
  }, [draggableFolderToken]);

  useEffect(() => {
    if (onUpdate) {
      onUpdate(folders);
    }
  }, [folders]);

  const handleDragStart = ({ active }) => {
    setDraggableFolderToken(active.id);
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    prevFolders.current = [...folders];

    if (active.id !== over.id) {
      setFolders((prevFolder) => {
        const oldIndex = prevFolder.findIndex(
          (folder) => folder.token === active.id
        );
        const newIndex = prevFolder.findIndex(
          (folder) => folder.token === over.id
        );

        return arrayMove(prevFolder, oldIndex, newIndex);
      });
    }

    setDraggableFolderToken(null);
  }

  const saveFoldersPosition = () => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/folders/update_position`,
        {
          section,
          folders,
        },
        { withCredentials: true }
      )
      .then((json) => {})
      .catch((error) => {
        if (prevFolders.current) {
          setFolders(prevFolders.current);
        }

        if (error.unknownError) {
          toastr.error("", "Error occurred!");
        }
      });
  };

  const createNewFolder = () => {
    axios
      .post(
        `${GETREVIEW_API_HOST}/api/folders`,
        {
          section,
          folder_name: generateNewFolderName(),
        },
        { withCredentials: true }
      )
      .then((json) => {
        let newFolder = json.data.folder;
        newFolder.id = newFolder.token;
        newFolder.isEditing = true;

        setFolders((prevFolders) => [...prevFolders, newFolder]);
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error("", "Error occurred!");
        }
      });
  };

  const generateNewFolderName = () => {
    const baseFolderName = t("dashboard.widgets.default_folder_name");

    let newFolderName = baseFolderName.repeat(1);
    let counter = 1;

    while (folderExists(newFolderName) === true) {
      newFolderName = `${baseFolderName} ${counter}`;
      counter += 1;
    }

    return newFolderName;
  };

  const folderExists = (folderName) => {
    return !!folders.find((f) => f.name === folderName);
  };

  const onFolderUpdate = (folderToken, folderName) => {
    const newFolders = [...folders];
    const folder = newFolders.find((f) => f.token === folderToken);
    folder.name = folderName;
    setFolders(newFolders);
  };

  const onFolderDelete = (folderToken) => {
    const newFolders = folders.filter((s) => s.token !== folderToken);
    setFolders(newFolders);
  };

  return (
    <Container>
      <div className="mb-3">
        <h4>
          <Trans>dashboard.widgets.folders</Trans>
          <FontAwesomeIcon
            icon={faPlusCircle}
            onClick={createNewFolder}
            style={{
              marginLeft: "12px",
              position: "relative",
              top: "1px",
              color: "#3A89FF",
              cursor: "pointer",
            }}
            className="create-new-folder-icon"
          />
        </h4>
      </div>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={folders}>
          <FoldersContainer id={`folders_list_container_${section}`}>
            {folders.map((folder) => (
              <SortableFolder
                key={folder.token}
                {...folder}
                isActive={folder.token === draggableFolderToken}
                onUpdate={onFolderUpdate}
                onDelete={onFolderDelete}
              />
            ))}
          </FoldersContainer>
        </SortableContext>

        <DragOverlay>
          {draggableFolderToken && (
            <Folder
              {...folders.find((f) => f.token === draggableFolderToken)}
              isDraggable={true}
            />
          )}
        </DragOverlay>
      </DndContext>
    </Container>
  );
};

export default Folders;
