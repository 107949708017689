import React from "react";
import axios from 'axios';
import { GETREVIEW_API_HOST } from "../../constants";
import * as Icon from "react-feather";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Container,
  Table,
  Row,
  Col
} from "reactstrap";

class Inbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leads: [],
      leadsCount: 0
    };
  }

  componentDidMount() {
    this.fetchLeads();
  }

  fetchLeads() {
    return axios.get(`${GETREVIEW_API_HOST}/api/leads/inbox`, { withCredentials: true })
      .then(json => {
        const leadsCount = json.data.count;
        const leads = json.data.leads;

        this.setState({ leads: leads, leadsCount: leadsCount });
      })
      .catch(error => {
        console.log("leads inbox ERROR", error);
        alert('leads inbox ERROR');
      })
  }

  render() {
    const { leads, pending } = this.state;

    if (pending) {
      return (
        <div>Загрузка...</div>
      )
    }

    return (
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-4">
          <Col xs="auto" className="d-none d-sm-block">
            <h3>Заявки</h3>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5" className="mb-0">
                  Найдено заявок: {this.state.leadsCount}
                </CardTitle>
              </CardHeader>
              <Table bordered responsive hover>
                <thead>
                  <tr>
                    <th>Дата</th>
                    <th>Email</th>
                    <th>Телефон</th>
                    <th>Имя</th>
                    <th>Дополнительные данные</th>
                  </tr>
                </thead>
                <tbody>
                  {leads.map(lead => (
                    <LeadRow key={lead.id} {...lead} />
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
};

class LeadRow extends React.Component {
  render() {
    return (
      <tr>
        <td>
          {this.props.created_at}
        </td>
        <td>
          <a href={"mailto:" + this.props.email}>
            {this.props.email}
          </a>
        </td>
        <td>
          {this.props.phone}
        </td>
        <td>
          {this.props.name}
        </td>
        <td>
          
        </td>
      </tr>
    )
  }
}

export default Inbox;
