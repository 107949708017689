import {
  faBan,
  faCheck,
  faClone,
  faCode,
  faExternalLinkAlt,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Tabs, { TabContent } from "../../../components/Tabs";
import AppearanceSettings from "../AppearanceSettings";
import BehaviourSettings from "../BehaviourSettings";
import CallToActionSettings from "../CallToActionSettings";
import CustomInternalsSettings from "../CustomInternalsSettings";
import DisplayByCustomProperties from "../DisplayByCustomProperties";
import DisplayByDevice from "../DisplayByDevice";
import DisplayByFrequency from "../DisplayByFrequency";
import DisplayByTrigger from "../DisplayByTrigger";
import DisplayPagesSettings from "../DisplayPagesSettings";
import LinkButton from "../LinkButton";
import MenuDropdown from "../MenuDropdown";
import SettingsBlock from "../SettingsBlock";
import { CopyCodeContent } from "./CopyCodeModal";
import Items from "./Items";

const WidgetForm = ({
  widgetTypeName,
  widgetSettings,
  widgetItems,
  ...props
}) => {
  const { t } = useTranslation();

  const defaultWidgetName = `${t(
    "dashboard.widget_form.default_widget_name"
  )} ${widgetTypeName}`;

  const tabs = [
    { name: "content", title: t("dashboard.widget_form.tabs.content") },
    { name: "appearance", title: t("dashboard.widget_form.tabs.appearance") },
    { name: "behaviour", title: t("dashboard.widget_form.tabs.behaviour") },
    { name: "cta", title: t("dashboard.widget_form.tabs.cta") },
    { name: "code", title: t("dashboard.widget_form.tabs.code") },
  ];

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get("tab") || "content";
  const folder = queryParams.get("folder") || "root";

  const { workspace, project, account } = useSelector(
    (state) => state.identity
  );

  const [settings, setSettings] = useState(widgetSettings);
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [formSending, setFormSending] = useState(false);
  const [name, setName] = useState(props.name || defaultWidgetName);
  const [displayPages, setDisplayPages] = useState(props.displayPages || "all");
  const [displayPagesList, setDisplayPagesList] = useState(
    props.displayPagesList || []
  );
  const [stories, setStories] = useState(widgetItems);

  const planName = workspace.plan.name;

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    if (props.onNameUpdate) {
      props.onNameUpdate(name);
    }
  }, [name]);

  useEffect(() => {
    const params = new URLSearchParams();

    params.append("tab", activeTab);
    params.append("type", props.widgetType);
    params.append("folder", folder);

    history.push({ search: params.toString() });
  }, [activeTab, folder, props.widgetType, history]);

  useEffect(() => {
    if (displayPages !== "all" && displayPagesList.length === 0) {
      setDisplayPagesList([{ type: "equals_without_params", url: "" }]);
    }
  }, [displayPages, displayPagesList]);

  const submitForm = () => {
    const normalizedItems = stories.map((story) => {
      return {
        token: story.token,
        caption: story.caption,
        title: story.title,
        subtitle: story.subtitle,
        new_record: story.newRecord === true ? "1" : null,
        video: story.video,
        metadata: story.metadata,
        call_to_actions: story.call_to_actions,
      };
    });

    const formData = {
      items: normalizedItems,
      widget: {
        type: props.widgetType,
        name: name,
        width: 300,
        height: 300,
        mobile_width: 300,
        mobile_height: 300,
        display_pages: displayPages,
        display_pages_list: displayPagesList,
        customization: settings,
        site_token: project.token,
        folder_token: folder,
      },
    };

    if (!validateFormData(formData)) {
      return;
    }

    setFormSending(true);

    props.onFormSubmit(formData, {
      onSuccess: (response) => {
        const createdWidgetToken = response.data.widget_token;

        setFormSending(false);
        toastr.success("Поздравляем!", "Виджет был успешно сохранен");

        if (props.siteDomain) {
          setActiveTab("code");
        } else {
          history.push(`/widgets/${createdWidgetToken}/edit?tab=code`);
        }
      },

      onError: () => {
        setFormSending(false);
        alert("Ошибка при сохранении видеовиджета");
      },
    });
  };

  const validateFormData = (formData) => {
    if (formData.items.length === 0) {
      toastr.error(
        "Внимание!",
        "Выберите хотя бы один видеофайл для сохранения видеовиджета"
      );
      return false;
    }

    if (
      formData.widget.customization.itemCallToActionBorderRadius < 0 ||
      formData.widget.customization.itemCallToActionBorderRadius > 100
    ) {
      toastr.error(
        "Внимание!",
        "Радиус закругления CTA кнопки должен быть от 0 до 100 пикселей"
      );
      return false;
    }

    if (
      formData.widget.customization.widgetItemsLimited === "limited" &&
      (formData.widget.customization.widgetItemsLimitCount > 100 ||
        formData.widget.customization.widgetItemsLimitCount < 1)
    ) {
      toastr.error(
        "Внимание!",
        "Лимит на количество видео должен быть от 1 до 100"
      );
      return false;
    }

    if (
      formData.widget.customization.itemBorderRadius < 0 ||
      formData.widget.customization.itemBorderRadius > 500
    ) {
      toastr.error(
        "Внимание!",
        "Радиус закругления видео должен быть от 0 до 500 пикселей"
      );
      return false;
    }

    if (
      formData.widget.customization.itemPlayButtonHeight < 10 ||
      formData.widget.customization.itemPlayButtonHeight > 500
    ) {
      toastr.error(
        "Внимание!",
        "Высота кнопки плей должен быть от 10 до 500 пикселей"
      );
      return false;
    }

    if (
      formData.widget.customization.itemPlayButtonBorderRadius < 0 ||
      formData.widget.customization.itemPlayButtonBorderRadius > 500
    ) {
      toastr.error(
        "Внимание!",
        "Радиус закругления кнопки плей должен быть от 0 до 500 пикселей"
      );
      return false;
    }

    if (
      formData.widget.customization.itemPlayButtonFontSize < 6 ||
      formData.widget.customization.itemPlayButtonFontSize > 128
    ) {
      toastr.error(
        "Внимание!",
        "Размер шрифта кнопки плей должен быть от 6 до 128 пикселей"
      );
      return false;
    }

    if (
      formData.widget.customization.itemMarginX < 0 ||
      formData.widget.customization.itemMarginX > 500 ||
      formData.widget.customization.itemMarginY < 0 ||
      formData.widget.customization.itemMarginY > 500
    ) {
      toastr.error(
        "Внимание!",
        "Отступ от контента должен быть от 0 до 500 пикселей"
      );
      return false;
    }

    // todo: add parseInt
    if (
      formData.widget.customization.triggerShowWidgetType === "scroll" &&
      (formData.widget.customization.triggerShowWidgetScrollPixels < 0 ||
        formData.widget.customization.triggerShowWidgetScrollPixels > 10000)
    ) {
      toastr.error(
        "Внимание!",
        "Значение триггера при скролле должно быть от 0 до 10000 пикселей"
      );
      return false;
    }

    if (formData.widget.customization.displayPositionSelector !== null) {
      const selectorRegEx = /^[\wа-я\s\>\(\)\:\.\,\-\_\=\#\[\]\"\']+$/;

      if (
        !selectorRegEx.test(
          formData.widget.customization.displayPositionSelector
        )
      ) {
        toastr.error(
          "",
          "Некорректный формат CSS-селектора для вставки виджета"
        );
        return false;
      }
    }

    return true;
  };

  const findNextTab = (tabName) => {
    const currentTabIndex = tabs.findIndex((t) => t.name === tabName);

    if (currentTabIndex !== -1) {
      const nextTab = tabs[currentTabIndex + 1];

      if (nextTab != null) {
        return nextTab.name;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const onStoriesUpdate = (stories) => {
    setStories(stories);
  };

  const addPage = () => {
    const newList = [...displayPagesList];
    newList.push({ url: "", type: "equals_without_params" });
    setDisplayPagesList(newList);
  };

  const changePageType = (type, index) => {
    const newList = [...displayPagesList];
    newList[index].type = type;
    setDisplayPagesList(newList);
  };

  const changePageURL = (url, index) => {
    const newList = [...displayPagesList];
    newList[index].url = url;
    setDisplayPagesList(newList);
  };

  const deletePage = (index) => {
    const newList = [...displayPagesList];
    newList.splice(index, 1);
    setDisplayPagesList(newList);
  };

  const onSettingsChange = useCallback(
    (settingName, settingValue, settingName2, settingValue2) => {
      const newSettings = {};
      newSettings[settingName] = settingValue;

      if (settingName2 != null) {
        newSettings[settingName2] = settingValue2;
      }

      setSettings({ ...settings, ...newSettings });
    },
    [settings]
  );

  const menuDropdownItems = [
    {
      type: "item",
      label: "Дублировать",
      icon: faClone,
      onClick: props.onDuplicateWidgetClick,
    },
  ];

  if (workspace.status !== "regular_exceeded") {
    menuDropdownItems.push({
      type: "item",
      label: props.isEnabled ? "Отключить" : "Включить",
      icon: props.isEnabled ? faBan : faCheck,
      onClick: props.onEnableToggleWidgetClick,
    });
  }

  menuDropdownItems.push({ type: "separator" });

  menuDropdownItems.push({
    type: "item",
    label: "Удалить",
    icon: faTrashAlt,
    colorType: "danger",
    onClick: props.onDeleteWidgetClick,
  });

  return (
    <Container fluid className="p-0">
      <Row>
        <Col xs="12">
          <div className="d-flex justify-content-between">
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={(tabName) => setActiveTab(tabName)}
            />

            <div className="d-flex">
              <div className="mr-2">
                <Button
                  id="save_widget_settings_button"
                  color="primary"
                  disabled={formSending}
                  onClick={submitForm}
                  style={{
                    position: "relative",
                    width: "150px",
                  }}
                >
                  <Trans>dashboard.widget_form.save_button</Trans>
                  {formSending && (
                    <Spinner
                      color="light"
                      size="sm"
                      style={{
                        position: "absolute",
                        top: "11px",
                        right: "10px",
                      }}
                    />
                  )}
                </Button>
              </div>

              <div className="mr-1">
                <LinkButton
                  id="preview_widget_button"
                  label={t("dashboard.widget_form.preview_button")}
                  title=""
                  icon={faExternalLinkAlt}
                  to={`/widgets/${props.widgetToken}/preview`}
                  openInNewWindow
                />
              </div>

              <div style={{ position: "relative" }}>
                <MenuDropdown items={menuDropdownItems} />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Form className="videostories-form">
                <TabContent tab="content" activeTab={activeTab}>
                  <div>
                    <FormGroup style={{ maxWidth: "400px" }}>
                      <Label>
                        <Trans>dashboard.widget_form.widget_name</Trans>
                      </Label>

                      <Input
                        type="text"
                        name="name"
                        value={name}
                        placeholder="Виджет на главной"
                        onChange={(event) => setName(event.target.value)}
                      />
                    </FormGroup>

                    <br />
                  </div>

                  <SettingsBlock
                    title={t("dashboard.widget_form.video.title")}
                    description={t("dashboard.widget_form.video.desc")}
                    openByDefault={true}
                  >
                    <Items
                      widgetItems={stories}
                      widgetToken={props.widgetToken}
                      widgetType={props.widgetType}
                      onUpdate={onStoriesUpdate}
                    />

                    <BehaviourSettings
                      widgetType={props.widgetType}
                      widgetSettings={settings}
                      onChange={onSettingsChange}
                    />
                  </SettingsBlock>

                  {["VideoStories", "VideoInline"].includes(
                    props.widgetType
                  ) && (
                    <CustomInternalsSettings
                      widgetType={props.widgetType}
                      widgetSettings={settings}
                      onChange={onSettingsChange}
                    />
                  )}
                </TabContent>

                <TabContent tab="appearance" activeTab={activeTab}>
                  <AppearanceSettings
                    widgetType={props.widgetType}
                    widgetSettings={settings}
                    onChange={onSettingsChange}
                  />
                </TabContent>

                <TabContent tab="behaviour" activeTab={activeTab}>
                  <DisplayPagesSettings
                    scope={displayPages}
                    values={displayPagesList}
                    onAdd={addPage}
                    onChangeType={changePageType}
                    onChangeURL={changePageURL}
                    onDelete={deletePage}
                    onScopeChange={(scope) => setDisplayPages(scope)}
                  />

                  <DisplayByDevice
                    widgetType={props.widgetType}
                    widgetSettings={settings}
                    onChange={onSettingsChange}
                  />

                  <DisplayByTrigger
                    requiredPlans={["standard", "business", "enterprise"]}
                    widgetType={props.widgetType}
                    widgetSettings={settings}
                    onChange={onSettingsChange}
                  />

                  <DisplayByFrequency
                    requiredPlans={["standard", "business", "enterprise"]}
                    widgetType={props.widgetType}
                    widgetSettings={settings}
                    onChange={onSettingsChange}
                  />

                  <DisplayByCustomProperties
                    requiredPlans={["standard", "business", "enterprise"]}
                    operator={settings.displayByCustomPropertiesRulesOperator}
                    rules={settings.displayByCustomPropertiesRules}
                    onChange={(updatedRules) => {
                      onSettingsChange(
                        "displayByCustomPropertiesRules",
                        updatedRules
                      );
                    }}
                    onOperatorChange={(newOperator) => {
                      onSettingsChange(
                        "displayByCustomPropertiesRulesOperator",
                        newOperator
                      );
                    }}
                  />
                </TabContent>

                <TabContent tab="cta" activeTab={activeTab}>
                  <CallToActionSettings
                    widgetType={props.widgetType}
                    widgetSettings={settings}
                    onChange={onSettingsChange}
                  />
                </TabContent>

                <TabContent tab="code" activeTab={activeTab}>
                  <h4>
                    <FontAwesomeIcon
                      icon={faCode}
                      style={{ marginRight: "10px" }}
                    />
                    Установка виджета на сайт
                  </h4>
                  <br />

                  <CopyCodeContent
                    widgetType={props.widgetType}
                    widgetToken={props.widgetToken}
                    siteToken={props.siteToken}
                    displayPages={displayPages}
                    displayPagesList={displayPagesList}
                    displayPositionSelector={settings.displayPositionSelector}
                    onDisplayPositionSelectorChange={(value) => {
                      onSettingsChange("displayPositionSelector", value);
                    }}
                  />
                </TabContent>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WidgetForm;
