import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tabs, { TabContent } from "../../../components/Tabs";
import UpgradeRequiredModalWindow from "../../../components/UpgradeRequiredModalWindow";
import SettingsBlock from "../../widgets/SettingsBlock";
import RecordedVideo from "../../widgets/RecordedVideo";
import Uploader from "../../../components/Uploader";
import VideoSelector from "../../../components/VideoSelector";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faShare } from "@fortawesome/free-solid-svg-icons";

import {
  Form,
  FormGroup,
  Button,
  Input,
  Label,
  Spinner,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

const ReviewsCampaignsForm = ({ campaign, onFormSubmit }) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get("tab") || "content";

  const [activeTab, setActiveTab] = useState(defaultTab);
  const [formSending, setFormSending] = useState(false);
  const [name, setName] = useState(campaign.name);
  const [video, setVideo] = useState(campaign.video);
  const [linkCopied, setLinkCopied] = useState(false);

  const tabs = [
    { name: "content", title: "Контент" },
    { name: "link", title: "Ссылка на кампанию" },
  ];

  useEffect(() => {
    const params = new URLSearchParams();

    params.append("tab", activeTab);

    history.push({ search: params.toString() });
  }, [activeTab, history]);

  const onVideoSelectorComplete = (selectedVideos) => {
    if (selectedVideos.length > 0) {
      setVideo(selectedVideos[0]);
    }
  };

  const onVideoUploaderComplete = (selectedVideos) => {
    if (selectedVideos.length > 0) {
      setVideo(selectedVideos[0]);
    }
  };

  const submitForm = () => {
    onFormSubmit({ token: campaign.token, name, video });
  };

  return (
    <Container fluid className="p-0">
      <UpgradeRequiredModalWindow
        requiredPlans={["standard", "business", "enterprise"]}
        redirectBackPath="/"
        featuresDesc="по сбору видеоотзывов"
        featuresList={[
          "Создание кампаний по автоматическому сбору видеоотзывов",
          "Клиент может записать видеоотзыв с любого устройства",
          "Получение контактов от клиентов, отправивших видеоотзыв",
          "Использование видеоотзывов в видеовиджетах",
          "Скачивание видеоотзывов",
        ]}
      />

      <Row className="mb-2 mb-xl-4">
        <Col xs="auto" className="d-none d-sm-block">
          <h3>Новая кампания по сбору видеоотзывов</h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex justify-content-between">
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={(tabName) => setActiveTab(tabName)}
            />

            <div className="d-flex">
              <div className="mr-2">
                <Button
                  color="primary"
                  disabled={formSending}
                  onClick={submitForm}
                  style={{
                    position: "relative",
                    width: "150px",
                  }}
                >
                  Сохранить
                  {formSending && (
                    <Spinner
                      color="light"
                      size="sm"
                      style={{
                        position: "absolute",
                        top: "11px",
                        right: "10px",
                      }}
                    />
                  )}
                </Button>
              </div>

              {/* <div style={{ position: "relative" }}>
                <MenuDropdown
                  items={[
                    {
                      type: "item",
                      label: "Удалить",
                      icon: faTrashAlt,
                      colorType: "danger",
                      onClick: () => {},
                    },
                  ]}
                />
              </div> */}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <Form>
                <TabContent tab="content" activeTab={activeTab}>
                  <div>
                    <FormGroup style={{ maxWidth: "400px" }}>
                      <Label>Название кампании</Label>

                      <Input
                        type="text"
                        name="name"
                        value={name}
                        placeholder="Название кампании"
                        onChange={(event) => setName(event.target.value)}
                      />
                    </FormGroup>
                  </div>

                  <br />

                  <SettingsBlock
                    title={"Видеообращение"}
                    description={"Ваше видеообращение для сбора видеоотзывов"}
                    openByDefault={true}
                  >
                    {video && (
                      <FormGroup className="mb-3">
                        <RecordedVideo
                          {...video}
                          onRemoveClick={() => setVideo(null)}
                        />
                      </FormGroup>
                    )}

                    <div className="video-selector-uploader">
                      <Uploader
                        disableMultipleUpload={true}
                        onSuccess={onVideoUploaderComplete}
                      />
                      <VideoSelector
                        disableMultipleSelect={true}
                        onComplete={onVideoSelectorComplete}
                        disabledVideoIDs={video && [video.id]}
                      />
                    </div>
                  </SettingsBlock>
                </TabContent>

                <TabContent tab="link" activeTab={activeTab}>
                  <h4>
                    <FontAwesomeIcon
                      icon={faShare}
                      style={{ marginRight: "10px" }}
                    />
                    Ссылка
                  </h4>

                  {campaign.token ? (
                    <div>
                      <div>Отправьте ссылку клиенту для сбора видеоотзывов</div>

                      <div className="input-group mb-3 mt-2">
                        <Input
                          type="text"
                          name="input"
                          disabled
                          value={campaign.url}
                          onChange={() => {}}
                          style={{
                            height: "41px",
                            fontFamily: "Courier New",
                            fontSize: "13px",
                            color: "#111111",
                            resize: "none",
                            whiteSpace: "nowrap",
                          }}
                        />
                        <div className="input-group-append">
                          <CopyToClipboard
                            text={campaign.url}
                            onCopy={() => setLinkCopied(true)}
                          >
                            <Button
                              color={linkCopied ? "success" : "primary"}
                              onClick={() => {}}
                            >
                              <FontAwesomeIcon
                                icon={faCopy}
                                style={{
                                  fontSize: "13px",
                                  marginRight: "10px",
                                }}
                              />
                              {linkCopied ? `Скопировано` : `Копировать`}
                            </Button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span>
                      Ссылка будет доступна после сохранения настроек кампании
                    </span>
                  )}
                </TabContent>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ReviewsCampaignsForm;
