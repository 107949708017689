import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpgradeRequiredModalWindow from "./UpgradeRequiredModalWindow";
import {
  faAngleDown,
  faAngleUp,
  faUpload,
  faTags,
  faArrowCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FormGroup, CustomInput, Label } from "reactstrap";

const Container = styled.div`
  margin: 30px 0px 30px 0px;
  padding: 5px;
  border-radius: 10px;
  background: white;
  border: 1px solid #eee;
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  user-select: none;

  &:hover {
    border-color: #ced4da;

    .project-white-label-settings-icon {
      opacity: 0.2;
    }
  }

  ${(props) =>
    props.isOpen && {
      borderColor: "#ced4da",
    }}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-left: 15px;
`;

const Icon = styled.div`
  font-size: 24px;
  margin-left: 8px;
  opacity: 0.1;
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 0.2;
  }

  ${(props) =>
    props.isOpen && {
      opacity: 0.2,
    }}
`;

const Dropdown = styled.div`
  margin-right: 10px;
`;

const Content = styled.div`
  background: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0px 0px 0px;
`;

const UpgradeButtonContainer = styled.div`
  font-size: 11px;
  display: inline-block;
  padding: 0 11px 0 7px;
  height: 24px;
  line-height: 22px;
  border-radius: 15px;
  background-color: #773ae8;
  color: white;
  transition: background-color 0.15s ease-out, transform 0.15s ease-out;
  margin: 0px 5px 0px 20px;

  @media (max-width: 568px) {
    margin: 3px 0 10px 0;
  }

  &:hover {
    transform: translateY(-2px);
    background-color: #592aaf;
  }
`;

const UpgradeButtonIcon = styled.div`
  display: inline-block;
  font-size: 14px;
  margin-right: 5px;
  position: relative;
  top: 1px;
`;

const UpgradeButton = () => {
  return (
    <UpgradeButtonContainer>
      <UpgradeButtonIcon>
        <FontAwesomeIcon icon={faArrowCircleUp} />
      </UpgradeButtonIcon>
      <Trans>project.white_label.upgrade_required.label</Trans>
    </UpgradeButtonContainer>
  );
};

const ProjectWhiteLabelSettings = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { workspace } = useSelector((state) => state.identity);

  let upgradeRequired = false;

  if (["standard", "business", "enterprise"].includes(workspace.plan.name)) {
    upgradeRequired = false;

    if (
      workspace.plan.name === "standard" &&
      workspace.plan_period_months < 12
    ) {
      upgradeRequired = true;
    }
  } else {
    upgradeRequired = true;
  }

  const project = props.project;

  const [watermarkType, setWatermarkType] = useState(project.watermark_type);
  const [watermarkFile, setWatermarkFile] = useState(null);
  const [brandingBadge, setBrandingBadge] = useState(project.branding_badge);
  const [showUpgradeRequiredModal, setShowUpgradeRequiredModal] =
    useState(false);

  useEffect(() => {
    if (props.onChange) {
      props.onChange({ watermarkType, watermarkFile, brandingBadge });
    }
  }, [watermarkType, watermarkFile, brandingBadge]);

  useEffect(() => {
    if (watermarkFile != null) {
      if (watermarkFile.type !== "image/png") {
        toastr.error("", t("project.watermark.invalid_image_format"));
        setWatermarkFile(null);
      }

      if (watermarkFile.size === 0 || watermarkFile.size > 1000000) {
        toastr.error("", t("project.watermark.invalid_image_size"));
        setWatermarkFile(null);
      }
    }
  }, [watermarkFile]);

  const onWatermarkInputFileChange = (event) => {
    if (event && event.target && event.target.files.length > 0) {
      setWatermarkFile(event.target.files[0]);
    }
  };

  return (
    <Container isOpen={isOpen}>
      {showUpgradeRequiredModal && (
        <UpgradeRequiredModalWindow
          requiredPlans={["standard", "business", "enterprise"]}
          requireAnnualStandardPlan
          onClose={() => setShowUpgradeRequiredModal(false)}
          featuresDesc={t("project.white_label.upgrade_required.features_desc")}
          featuresList={[
            t("project.white_label.upgrade_required.features_list.1"),
            t("project.white_label.upgrade_required.features_list.2"),
            t("project.white_label.upgrade_required.features_list.3"),
            t("project.white_label.upgrade_required.features_list.4"),
          ]}
        />
      )}

      <Header
        onClick={() => {
          if (upgradeRequired === false) {
            setIsOpen(!isOpen);
          } else {
            setShowUpgradeRequiredModal(true);
          }
        }}
      >
        <div className="d-flex align-items-center">
          <Icon className="project-white-label-settings-icon" isOpen={isOpen}>
            <FontAwesomeIcon icon={faTags} />
          </Icon>
          <Title>
            <Trans>project.white_label_settings</Trans>
          </Title>
          {upgradeRequired && <UpgradeButton />}
        </div>
        <Dropdown>
          <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
        </Dropdown>
      </Header>

      {isOpen && (
        <Content>
          <FormGroup className="mr-3">
            <b>
              <Trans>project.branding_badge.form_title</Trans>
            </b>

            <br />

            <Label className="mt-2" check>
              <CustomInput
                id="brandingBadge"
                checked={brandingBadge}
                type="checkbox"
                name="brandingBadge"
                label={t("project.branding_badge.label")}
                onChange={(event) => {
                  setBrandingBadge(event.target.checked);
                }}
              />
            </Label>
          </FormGroup>

          <div>
            <b>
              <Trans>project.watermark.form_title</Trans>
            </b>
            <div
              className="d-flex flex-column flex-sm-row"
              style={{ lineHeight: "18px " }}
            >
              <CustomInput
                id={`projectWatermarkType_${project.token}_getreview`}
                checked={watermarkType === "getreview"}
                type="radio"
                name="projectWatermarkType_getreview"
                value="small"
                label={t("project.watermark.types.getreview")}
                onChange={() => {
                  setWatermarkType("getreview");
                }}
                className="mr-3"
              />

              <CustomInput
                id={`projectWatermarkType_${project.token}_custom`}
                checked={watermarkType === "custom"}
                type="radio"
                name="projectWatermarkType_custom"
                value="medium"
                label={t("project.watermark.types.custom")}
                onChange={() => {
                  setWatermarkType("custom");
                }}
                className="mr-3"
              />

              <CustomInput
                id={`projectWatermarkType_${project.token}_none`}
                checked={watermarkType === "none"}
                type="radio"
                name="projectWatermarkType_none"
                value="large"
                label={t("project.watermark.types.disabled")}
                onChange={() => {
                  setWatermarkType("none");
                }}
              />
            </div>

            {watermarkType === "custom" &&
              project.watermark_type === "custom" && (
                <div
                  style={{
                    background: "#eeeeee",
                    borderRadius: "10px",
                    display: "inline-block",
                    minWidth: "100px",
                    padding: "10px",
                    overflow: "hidden",
                  }}
                >
                  <center>
                    <img src={project.watermark_url} alt="Watermark" />
                  </center>
                </div>
              )}

            {watermarkType === "custom" && (
              <div className="mt-2">
                <div className="d-flex">
                  <div>
                    <label
                      htmlFor={`projectWatermarkFileInput_${project.token}`}
                      className="watermark-upload-input-label"
                    >
                      <FontAwesomeIcon icon={faUpload} className="mr-3" />
                      <Trans>project.watermark.upload_image</Trans>
                      <input
                        type="file"
                        accept="image/png"
                        id={`projectWatermarkFileInput_${project.token}`}
                        className="watermark-upload-input"
                        onChange={onWatermarkInputFileChange}
                      />
                    </label>
                  </div>

                  <div
                    className="text-sm text-muted"
                    style={{ lineHeight: "14px", marginTop: "8px" }}
                  >
                    {watermarkFile ? (
                      <div>
                        <Trans>project.watermark.selected_file</Trans>:
                        <br />
                        <b style={{ color: "green" }}>{watermarkFile.name}</b>
                      </div>
                    ) : (
                      <Trans>project.watermark.supported_upload_type</Trans>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Content>
      )}
    </Container>
  );
};

export default ProjectWhiteLabelSettings;
