import React, { useState, useEffect } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useTranslation, Trans } from "react-i18next";
import { GETREVIEW_API_HOST } from "../../constants";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import GoogleButton from "./GoogleButton";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  CustomInput,
  Spinner,
} from "reactstrap";

const SignUp = (props) => {
  const { t } = useTranslation();

  const [cookies, setCookie] = useCookies();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [email, setEmail] = useState(queryParams.get("invite_email") || "");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    setUtmAndReferralCookies();

    const inviteToken = queryParams.get("invite_token");

    if (inviteToken != null && inviteToken !== "") {
      setCookies("invite_token", queryParams.get("invite_token"));
    }
  }, []);

  const setUtmAndReferralCookies = () => {
    setCookies("source", queryParams.get("utm_source"));
    setCookies("medium", queryParams.get("utm_medium"));
    setCookies("campaign", queryParams.get("utm_campaign"));
    setCookies("content", queryParams.get("utm_content"));
    setCookies("term", queryParams.get("utm_term"));
    setCookies("referral_code", queryParams.get("referral_code"), true);
  };

  const setCookies = (utmParamName, utmValue, overwrite) => {
    if (utmValue == null) return;

    const cookieName = "_gr_utm_" + utmParamName;
    const cookieCurrentValue = cookies[cookieName];

    if (cookieCurrentValue == null || overwrite === true) {
      let expiresAtTime = new Date();

      expiresAtTime.setTime(
        expiresAtTime.getTime() + 365 * 24 * 60 * 60 * 1000
      );

      setCookie(`_gr_utm_${utmParamName}`, utmValue, {
        domain: process.env.REACT_APP_GETREVIEW_WEBSITE,
        path: "/",
        secure: true,
        expires: expiresAtTime,
      });
    }
  };

  const submitForm = (googleAuthResponse) => {
    setIsSending(true);

    const formData = {
      account: {
        email,
        password,
      },
    };

    if (googleAuthResponse != null) {
      formData.account.first_name = googleAuthResponse.firstName;
      formData.account.last_name = googleAuthResponse.lastName;
      formData.account.email = googleAuthResponse.email;
      formData.google_auth_token = googleAuthResponse.googleAuthToken;
    }

    axios
      .post(`${GETREVIEW_API_HOST}/accounts`, formData, {
        withCredentials: true,
      })
      .then((json) => {
        if (process.env.NODE_ENV === "production") {
          window.dataLayer.push({
            event: "eventTracking",
            category: "Account",
            action: "signUpDone",
          });
        }

        window.location.href = `/widgets/new_widget_type`;
      })
      .catch((error) => {
        setIsSending(false);

        let errorMessage = t("auth.errors.sign_up_unknown_error");

        if (error.response) {
          const errors = error.response.data.errors;

          if (errors && errors.email && errors.email[0].error === "taken") {
            errorMessage = t("auth.errors.account_taken");
          }
        }

        toastr.error("", errorMessage);
      });
  };

  return (
    <Card>
      <CardBody>
        <div className="m-sm-4">
          <div
            className="lead text-center"
            style={{ fontSize: "20px", padding: "0 10px" }}
          >
            <div>
              <Trans>auth.sign_up.title</Trans>
            </div>

            <div
              style={{
                opacity: 0.5,
                fontSize: "12px",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <Trans>auth.sign_up.subtitle</Trans>
            </div>
          </div>

          <br />

          <AvForm onValidSubmit={() => submitForm()}>
            <FormGroup className="from-group-email">
              <div
                style={{ marginBottom: "3px", fontWeight: "600", opacity: 0.7 }}
              >
                <Trans>auth.forms.email_label</Trans>
              </div>

              <AvField
                required
                disabled={isSending}
                errorMessage={t("auth.errors.email_invalid")}
                type="email"
                bsSize="md"
                name="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormGroup>

            <FormGroup className="from-group-password">
              <div
                style={{ marginBottom: "3px", fontWeight: "600", opacity: 0.7 }}
              >
                <Trans>auth.forms.password_label</Trans>
              </div>

              <AvField
                required
                disabled={isSending}
                validate={{
                  minLength: {
                    value: 6,
                    errorMessage: t("auth.errors.password_length_error"),
                  },
                }}
                errorMessage={t("auth.errors.required_field")}
                type="password"
                bsSize="md"
                name="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <CustomInput
                type="checkbox"
                id="accept_terms_checkbox"
                label={
                  <span style={{ color: "#555555", fontSize: "12px" }}>
                    {t("auth.sign_up.accept_terms_label")}{" "}
                    <a
                      href={`https://${process.env.REACT_APP_GETREVIEW_WEBSITE}/terms`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("auth.sign_up.accept_terms_terms")}
                    </a>
                    ,{" "}
                    <a
                      href={`https://${process.env.REACT_APP_GETREVIEW_WEBSITE}/agreement`}
                      rel="noopener noreferrer"
                    >
                      {t("auth.sign_up.accept_terms_agreement")}
                    </a>{" "}
                    {t("auth.sign_up.accept_terms_and")}{" "}
                    <a
                      href={`https://${process.env.REACT_APP_GETREVIEW_WEBSITE}/privacy`}
                      rel="noopener noreferrer"
                    >
                      {t("auth.sign_up.accept_terms_privacy")}
                    </a>
                  </span>
                }
                checked={termsAccepted}
                onChange={(event) => setTermsAccepted(event.target.checked)}
                className="accept-terms-checkbox"
              />
            </FormGroup>

            <FormGroup>
              <div className="text-center mt-3">
                <Button
                  id="submit"
                  disabled={isSending || termsAccepted === false}
                  color="primary"
                  size="lg"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div style={{ marginLeft: "20px" }}>
                    {t("auth.forms.continue")}
                  </div>
                  <div
                    style={{
                      marginLeft: "15px",
                      width: "25px",
                      height: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {isSending ? (
                      <Spinner
                        color="white"
                        size="sm"
                        className="mr-2"
                        style={{}}
                      />
                    ) : (
                      <FontAwesomeIcon icon={faArrowRight} />
                    )}
                  </div>
                </Button>
              </div>
            </FormGroup>

            {process.env.REACT_APP_GETREVIEW_REGION === "RU" && (
              <div className="d-flex">
                <div className="flex-fill pr-1">
                  <GoogleButton onSuccess={submitForm} />
                </div>
              </div>
            )}
          </AvForm>

          <div className="text-center mt-3">
            <p style={{ fontSize: "12px" }}>
              <span style={{ opacity: 0.5 }}>
                {t("auth.forms.already_have_account")}
              </span>
              <br />
              <Link to="/" style={{ color: "#000a3b", fontWeight: "600" }}>
                {t("auth.forms.sign_in")}
              </Link>
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default SignUp;
