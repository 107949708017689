import * as types from "../constants";
import axios from "axios";
import { GETREVIEW_API_HOST } from "../../constants";
import { loadAccountSuccess } from "./accountActions";

export const selectProject = (projectToken) => {
  return {
    type: types.SELECT_PROJECT,
    projectToken,
  };
};

export const loadIdentitySuccess = (identity, workspaceToken, projectToken) => {
  return {
    type: types.LOAD_IDENTITY_SUCCESS,
    identity,
    workspaceToken,
    projectToken,
  };
};

export const loadIdentityError = () => {
  return {
    type: types.LOAD_IDENTITY_ERROR,
  };
};

export const loadIdentity = () => (dispatch, getState) => {
  axios
    .get(`${GETREVIEW_API_HOST}/api/account/identity`, {
      withCredentials: true,
    })
    .then((json) => {
      const selectedWorkspaceToken = localStorage.getItem(
        "_gr_selected_workspace_token"
      );

      const selectedProjectToken = localStorage.getItem(
        "_gr_selected_project_token"
      );

      setTimeout(() => {
        dispatch(
          loadIdentitySuccess(
            json.data,
            selectedWorkspaceToken,
            selectedProjectToken
          )
        );

        const { workspace, project } = getState().identity;
        axios.defaults.headers.common["Workspace"] = workspace.token;
        axios.defaults.headers.common["Project"] = project.token;

        // temporary to support legacy
        dispatch(loadAccountSuccess(json.data.account));
      }, 500);
    })
    .catch((error) => {
      dispatch(loadIdentityError());
      console.error(error);
    });
};
