import React from "react";
import SettingsBlock from "./SettingsBlock";
import { CustomInput, FormGroup, Input } from "reactstrap";
import { useTranslation } from "react-i18next";

const BehaviourSettings = ({ onChange, widgetType, widgetSettings }) => {
  const { t } = useTranslation();

  return (
    <div>
      <SettingsBlock
        title={t("widget_settings.behaviour_settings.items_order.title")}
        description={t(
          "widget_settings.behaviour_settings.items_order.description"
        )}
      >
        <CustomInput
          id="widgetItemsOrder_ordered"
          checked={widgetSettings.widgetItemsOrder === "ordered"}
          type="radio"
          name="widgetItemsOrder"
          value="ordered"
          label={t("widget_settings.behaviour_settings.items_order.ordered")}
          onChange={() => onChange("widgetItemsOrder", "ordered")}
          className="mr-3"
        />

        <CustomInput
          id="widgetItemsOrder_random"
          checked={widgetSettings.widgetItemsOrder === "random"}
          type="radio"
          name="widgetItemsOrder"
          value="random"
          label={t("widget_settings.behaviour_settings.items_order.random")}
          onChange={() => onChange("widgetItemsOrder", "random")}
          className="mr-3"
        />
      </SettingsBlock>

      {(widgetType === "VideoCarousel" ||
        widgetType === "VideoGrid" ||
        widgetType === "VideoStories") && (
        <SettingsBlock
          title="Лимит отображаемых видео"
          description="Ограничение количества отображаемых видео в виджете"
          isVisible={widgetSettings.widgetType !== "VideoInline"}
        >
          <CustomInput
            id="widgetItemsLimited_unlimited"
            checked={widgetSettings.widgetItemsLimited === "unlimited"}
            type="radio"
            name="widgetItemsLimited"
            value="unlimited"
            label="Показывать все видео"
            onChange={() => onChange("widgetItemsLimited", "unlimited")}
            className="mr-3"
          />

          <CustomInput
            id="widgetItemsLimited_limited"
            checked={widgetSettings.widgetItemsLimited === "limited"}
            type="radio"
            name="widgetItemsLimited"
            value="limited"
            label="Ограничить количество отображаемых видео"
            onChange={() => onChange("widgetItemsLimited", "limited")}
            className="mr-3"
          />

          {widgetSettings.widgetItemsLimited === "limited" && (
            <FormGroup style={{ maxWidth: "200px", marginTop: "10px" }}>
              <div className="d-flex">
                <div className="mr-3">
                  <span className="text-muted text-sm">Количество видео</span>
                  <Input
                    type="text"
                    name="widgetItemsLimitCount"
                    value={widgetSettings.widgetItemsLimitCount}
                    onChange={(e) =>
                      onChange("widgetItemsLimitCount", e.target.value)
                    }
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
            </FormGroup>
          )}
        </SettingsBlock>
      )}
    </div>
  );
};

export default BehaviourSettings;
