import * as types from "../constants";

const initialState = {
  pending: false,
  videos: [],
  error: null
}

export default function videosReducer(state = initialState, action) {
  switch(action.type) {
    case types.FETCH_VIDEOS_PENDING: 
      return {
          ...state,
          pending: true,
          error: null
      }
    case types.FETCH_VIDEOS_SUCCESS:
      return {
          ...state,
          pending: false,
          videos: action.videos
      }
    case types.FETCH_VIDEOS_ERROR:
      return {
          ...state,
          pending: false,
          error: action.error
      }
    default: 
      return state;
  }
}
