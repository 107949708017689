import React, { useState } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useTranslation, Trans } from "react-i18next";
import { GETREVIEW_API_HOST } from "../../constants";
import { Link } from "react-router-dom";

import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";

const ResetPassword = (props) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("enter_email");
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("account[email]", email);

    axios
      .post(`${GETREVIEW_API_HOST}/accounts/password`, formData, {
        withCredentials: true,
      })
      .then((json) => {
        if (json.data.status === "ok") {
          setStatus("email_sent");
          toastr.success("", t("auth.reset_password.link_has_been_sent"));
        } else {
          alert("Reset password ERROR");
        }
      })
      .catch((error) => {
        alert("Reset password ERROR");
      });
  };

  return (
    <Card>
      <CardBody>
        <div className="m-sm-4">
          <p
            className="text-center"
            style={{ fontSize: "20px", padding: "0 10px" }}
          >
            <Trans>auth.reset_password.title</Trans>
          </p>

          {status === "enter_email" ? (
            <React.Fragment>
              <p>
                <Trans>auth.reset_password.desc</Trans>
              </p>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Input
                    bsSize="md"
                    type="email"
                    name="email"
                    placeholder={t("auth.forms.email_label")}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormGroup>

                <div className="text-center mt-3">
                  <Button
                    type="submit"
                    color="primary"
                    size="lg"
                    disabled={email === ""}
                  >
                    <Trans>auth.forms.reset_password</Trans>
                  </Button>
                </div>

                <div className="text-center mt-3">
                  <p style={{ fontSize: "12px" }}>
                    <span style={{ opacity: 0.5 }}>
                      {t("auth.forms.remember_password")}
                    </span>
                    <br />
                    <Link
                      to="/"
                      style={{ color: "#000a3b", fontWeight: "600" }}
                    >
                      {t("auth.forms.sign_in")}
                    </Link>
                  </p>
                </div>
              </Form>
            </React.Fragment>
          ) : (
            <div>
              <p className="text-center">
                <Trans>auth.reset_password.link_sent_info</Trans>
              </p>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default ResetPassword;
