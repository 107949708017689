import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import CallToActionPreview from "./CallToActionPreview";

export default (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <CallToActionPreview
      nodeRef={setNodeRef}
      style={style}
      {...props}
      attributes={attributes}
      listeners={listeners}
    />
  );
};
