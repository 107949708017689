import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const growDown = keyframes`
  0% {
    opacity: 0;
    top: -10px;
  }

  100% {
    opacity: 1;
    top: 0px;
  }
`;

const Container = styled.div`
  position: relative;
  color: #000a3b;
  border: 1px solid #ececec;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 5px 22px;
  margin-right: 10px;
  border-radius: 30px;
  cursor: pointer;
  background: white;
  transition: border-color 0.2s ease-out;

  &:hover {
    border-color: #8c8c8c;
  }

  ${(props) =>
    props.highlighted && {
      backgroundColor: "##eff6ff",
      borderColor: "#7faaeb",
      color: "#226ee2",
    }}
`;

const Label = styled.span`
  font-weight: 600;
  margin-left: 5px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0px;
  left: -3px;
  right: -3px;
  background: white;
  border-radius: 6px;
  box-shadow: 1px 2px 0.875rem 0 rgb(53, 64, 82, 0.1);
  animation: 0.2s ${growDown} ease-out;
  z-index: 100;
  overflow: hidden;
  transform: translateY(-2px);
`;

const Option = styled.div`
  display: flex;
  justify-content: start;
  text-align: left;
  color: #000a3b;
  padding: 8px 16px 8px 12px;
  border-bottom: 1px solid #edeff0;
  transition: color 0.1s ease-out, background-color 0.1s ease-out;

  &:hover {
    ${(props) =>
      props.chosen === false && {
        color: "black",
        backgroundColor: "#f7f7f7",
      }}
  }

  &:last-child {
    border-bottom: none;
  }

  ${(props) =>
    props.chosen && {
      color: "#1d6fea",
    }}
`;

const CheckLabel = styled.div`
  width: 20px;
`;

const OverlayOption = ({ chosen, label, onClick }) => {
  return (
    <Option chosen={chosen} onClick={onClick}>
      <CheckLabel>
        {chosen && <FontAwesomeIcon icon={faCheck} className="mr-2" />}
      </CheckLabel>
      {label}
    </Option>
  );
};

const FilterOption = ({ title, options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef();
  const chosenOptionTitle = options.find(
    (option) => option.value === value
  ).title;

  const onClick = () => {
    if (isOpen === false) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    const onDocumentClick = (event) => {
      if (
        isOpen &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", onDocumentClick);

    return () => {
      document.addEventListener("mousedown", onDocumentClick);
    };
  }, [isOpen]);

  return (
    <Container
      ref={containerRef}
      highlighted={value !== null}
      onClick={onClick}
    >
      <div>
        {title} <Label>{chosenOptionTitle}</Label>
      </div>

      {isOpen && (
        <Overlay>
          {options.map((option, index) => (
            <OverlayOption
              key={index}
              onClick={() => {
                setIsOpen(false);
                onChange(option.value);
              }}
              chosen={option.value === value}
              label={option.title}
            />
          ))}
        </Overlay>
      )}
    </Container>
  );
};

export default FilterOption;
