import React from "react";
import { Link } from "react-router-dom";

import { Button, Card, CardBody } from "reactstrap";

const AccountConfirmed = () => (
  <React.Fragment>
    <Card>
      <CardBody>
        <div className="m-sm-4">
          <br />
          <h3 class="text-center">Поздравляем!</h3>
          <h2 class="text-center">Ваш аккаунт подтвержден</h2>
          <br />
          <p class="text-center">
            Теперь вы можете войти в свой личный кабинет на странице входа.
          </p>
          <br />
          <div className="text-center mt-3">
            <Link to="/">
              <Button type="submit" color="primary" size="lg">
                Войти в личный кабинет
              </Button>
            </Link>
          </div>
        </div>
      </CardBody>
    </Card>
  </React.Fragment>
);

export default AccountConfirmed;
