import React, { useState, useEffect } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../../constants";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import * as fnsLocales from "date-fns/locale";
import { Spinner, Table, Button, Card, CardBody } from "reactstrap";
import WorkspaceSettingsInviteMemberModalWindow from "./WorkspaceSettingsInviteMemberModalWindow";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const WorkspaceSettingsMembers = (props) => {
  const { t } = useTranslation();
  const { account } = useSelector((state) => state.identity);

  const fnsLocale = {
    ru: fnsLocales.ru,
    en: fnsLocales.enUS,
  }[account.lang];

  const [showInviteWindow, setShowInviteWindow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = () => {
    setLoading(true);

    axios
      .get(`${GETREVIEW_API_HOST}/api/workspace/members`, {
        withCredentials: true,
      })
      .then((json) => {
        setLoading(false);
        setMembers(json.data.members);
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error(
            "",
            "Возникла неизвестная ошибка при загрузке списка счетов"
          );
        }
      });
  };

  const deactivateMember = (memberId) => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/workspace/members/${memberId}/deactivate`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((json) => {
        fetchMembers();
        toastr.success("", "Пользователь был успешно деактивирован");
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error(
            "",
            "Возникла неизвестная ошибка при деактивации пользователя"
          );
        }
      });
  };

  const deleteMember = (memberId) => {
    axios
      .delete(`${GETREVIEW_API_HOST}/api/workspace/members/${memberId}`, {
        withCredentials: true,
      })
      .then((json) => {
        fetchMembers();
        toastr.success("", "Пользователь был успешно удален");
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error(
            "",
            "Возникла неизвестная ошибка при удалении пользователя"
          );
        }
      });
  };

  return (
    <div>
      {showInviteWindow && (
        <WorkspaceSettingsInviteMemberModalWindow
          onClose={() => {
            setShowInviteWindow(false);
            fetchMembers();
          }}
        />
      )}

      <Button
        color="primary"
        size="sm"
        className="mb-4"
        onClick={() => setShowInviteWindow(true)}
      >
        <FontAwesomeIcon className="mr-2" icon={faPlus} />
        Пригласить пользователя
      </Button>

      <Card>
        {loading ? (
          <CardBody>
            <Spinner color="primary" size="sm" className="mr-3" />
            Загрузка...
          </CardBody>
        ) : (
          <Table responsive bordered hover style={{ borderTop: "none" }}>
            <thead>
              <tr>
                <th>Имя</th>
                <th>Роль</th>
                <th>Дата добавления</th>
                <th>Email</th>
                <th>Статус</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {members.map((member, index) => (
                <tr key={`member_${index}`}>
                  <td nowrap="nowrap">
                    {member.account_id ? (
                      <span>
                        {member.first_name} {member.last_name}
                      </span>
                    ) : (
                      <span className="text-muted">Приглашение отправлено</span>
                    )}
                  </td>
                  <td nowrap="nowrap">
                    {t(`workspace.member.role.${member.role}`)}
                  </td>
                  <td nowrap="nowrap">
                    <span className="text-muted" style={{ fontSize: "12px" }}>
                      {format(new Date(member.created_at), "dd MMMM yyyy", {
                        locale: fnsLocale,
                      })}
                    </span>
                  </td>
                  <td nowrap="nowrap">{member.invite_email || member.email}</td>
                  <td nowrap="nowrap">
                    <div
                      className={`badge badge-${
                        {
                          invited: "warning",
                          active: "success",
                          deactivated: "danger",
                        }[member.status]
                      }`}
                    >
                      {t(`workspace.member.status.${member.status}`)}
                    </div>
                  </td>
                  <td nowrap="nowrap">
                    {member.status === "active" &&
                      member.account_id !== account.id && (
                        <Button
                          color="danger"
                          size="sm"
                          style={{ width: "120px" }}
                          onClick={() => deactivateMember(member.id)}
                        >
                          Деактивировать
                        </Button>
                      )}

                    {member.status === "deactivated" && (
                      <Button
                        color="danger"
                        disabled={member.account_id === account.id}
                        size="sm"
                        style={{ width: "120px" }}
                        onClick={() => deleteMember(member.id)}
                      >
                        Удалить
                      </Button>
                    )}

                    {member.status === "invited" && (
                      <Button
                        color="warning"
                        size="sm"
                        style={{ width: "120px" }}
                        onClick={() => deleteMember(member.id)}
                      >
                        Отменить
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card>
    </div>
  );
};

export default WorkspaceSettingsMembers;
