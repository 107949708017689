import React from "react";
import SettingsBlock from "./SettingsBlock";
import { useTranslation } from "react-i18next";
import { FormGroup, Label, CustomInput } from "reactstrap";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

const DisplayByDevice = ({ widgetType, widgetSettings, onChange }) => {
  const { t } = useTranslation();

  return (
    <SettingsBlock
      title={t("dashboard.widget_form.device.title")}
      appliedSettingsText={() => {
        if (
          widgetSettings.deviceShowOnDesktop === true &&
          widgetSettings.deviceShowOnMobile === true
        ) {
          return "Включено на всех устройствах";
        }

        if (
          widgetSettings.deviceShowOnDesktop === true &&
          widgetSettings.deviceShowOnMobile === false
        ) {
          return "Только десктопы";
        }

        if (
          widgetSettings.deviceShowOnDesktop === false &&
          widgetSettings.deviceShowOnMobile === true
        ) {
          return "Только мобильные";
        }

        if (
          widgetSettings.deviceShowOnDesktop === false &&
          widgetSettings.deviceShowOnMobile === false
        ) {
          return "Выключено на всех устройствах";
        }
      }}
      description={t("dashboard.widget_form.device.desc")}
      icon={faMobileAlt}
    >
      <FormGroup check className="mb-2 form-checkbox-label">
        <Label check>
          <CustomInput
            id="device_show_on_desktop_true"
            checked={widgetSettings.deviceShowOnDesktop === true}
            type="checkbox"
            name="deviceShowOnDesktop"
            label="Компьютеры и ноутбуки"
            className="mr-3"
            onChange={(event) => {
              onChange("deviceShowOnDesktop", event.target.checked);
            }}
          />
        </Label>
      </FormGroup>

      <FormGroup check className="mb-2 form-checkbox-label">
        <Label check>
          <CustomInput
            id="device_show_on_mobile_true"
            checked={widgetSettings.deviceShowOnMobile === true}
            type="checkbox"
            name="deviceShowOnMobile"
            label="Мобильные устройства и планшеты"
            className="mr-3"
            onChange={(event) => {
              onChange("deviceShowOnMobile", event.target.checked);
            }}
          />
        </Label>
      </FormGroup>
    </SettingsBlock>
  );
};

export default DisplayByDevice;
