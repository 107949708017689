import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: start;
  font-size: 12px;
`;

const TabBlock = styled.div`
  display: flex;
  padding: 8px 16px;
  border: 1px solid #cfd4d9;
  border-right-width: 0;
  background: white;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  color: #686868;

  &:hover {
    ${(props) =>
      props.active === false && {
        background: "#F6F6F6",
      }}
  }

  &:first-child {
    border-radius: 10px 0px 0px 10px;
  }

  &:last-child {
    border-right-width: 1px;
    border-radius: 0px 10px 10px 0px;
  }

  ${(props) =>
    props.active && {
      background: "#020A38",
      borderColor: "#020A38",
      color: "white",
      fontWeight: "600",
    }}
`;

const TabExtraTitle = styled.div`
  margin-left: 15px;
  font-weight: 600;
  color: #2d9f28;

  ${(props) =>
    props.active && {
      color: "#87ff93",
    }}
`;

const MultiTab = ({ tabs, activeTab, onChange }) => {
  return (
    <Container>
      {tabs.map((tab) => (
        <TabBlock
          key={`tab_${tab.name}`}
          active={tab.name === activeTab}
          onClick={() => onChange(tab.name)}
        >
          <div>{tab.title}</div>
          {tab.extraTitle && (
            <TabExtraTitle active={tab.name === activeTab}>
              {tab.extraTitle}
            </TabExtraTitle>
          )}
        </TabBlock>
      ))}
    </Container>
  );
};

export default MultiTab;
