import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import UpgradeRequiredModalWindow from "../../components/UpgradeRequiredModalWindow";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowCircleUp,
} from "@fortawesome/free-solid-svg-icons";

const SettingsBlockContainer = styled.div`
  border-radius: 12px;
  margin-bottom: 20px;
  background: white;
  border: 1px solid #eee;
  transition: border-color 0.3s ease-out;
  user-select: none;

  &:hover {
    border-color: #c6c9d8;
  }
`;

const SettingsHeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 15px 15px;
  cursor: pointer;

  &:hover {
    .settings-header-icon {
      opacity: 0.2;
    }

    .settings-header-button {
      opacity: 0.5;
    }
  }

  .settings-header-icon {
    ${(props) =>
      props.isOpen && {
        opacity: 0.2,
      }}
  }

  .settings-header-button {
    ${(props) =>
      props.isOpen && {
        opacity: 0.5,
      }}
  }
`;

const SettingsHeaderIcon = styled.div`
  width: 50px;
  text-align: center;
  font-size: 35px;
  opacity: 0.1;
  transition: opacity 0.3s ease-out;

  @media (max-width: 568px) {
    display: none;
  }
`;

const SettingsContentBlock = styled.div`
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 0.7s ease-out;

  ${(props) =>
    props.isOpen && {
      overflow: "visible",
      opacity: 1,
      height: "auto",
      margin: "10px 30px 30px 30px",
    }}
`;

const SettingsBlockTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 12px;

  @media (max-width: 568px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SettingsBlockTitleText = styled.div`
  display: inline-block;
  margin-right: 15px;
`;

const SettingsBlockAppliedSettingsText = styled.div`
  display: inline-block;
  background: #e6f3f6;
  font-weight: normal;
  font-size: 10px;
  padding: 1px 15px;
  border-radius: 20px;
`;

const SettingsBlockDescription = styled.div`
  width: 90%;
  opacity: 0.4;
  margin-left: 12px;
`;

const SettingsBlockToggleButton = styled.div`
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  opacity: 0.2;
  transition: opacity 0.3s ease-out;
`;

const UpgradeButtonContainer = styled.div`
  font-size: 11px;
  display: inline-block;
  padding: 0 11px 0 7px;
  height: 24px;
  line-height: 22px;
  border-radius: 15px;
  background-color: #773ae8;
  color: white;
  transition: background-color 0.15s ease-out, transform 0.15s ease-out;

  @media (max-width: 568px) {
    margin: 3px 0 10px 0;
  }

  &:hover {
    transform: translateY(-2px);
    background-color: #592aaf;
  }
`;

const UpgradeButtonIcon = styled.div`
  display: inline-block;
  font-size: 14px;
  margin-right: 5px;
  position: relative;
  top: 1px;
`;

const UpgradeButton = () => {
  return (
    <UpgradeButtonContainer>
      <UpgradeButtonIcon>
        <FontAwesomeIcon icon={faArrowCircleUp} />
      </UpgradeButtonIcon>
      Обновите тариф
    </UpgradeButtonContainer>
  );
};

const SettingsBlock = ({
  requiredPlans,
  title,
  description,
  icon,
  openByDefault,
  isVisible,
  appliedSettingsText,
  children,
}) => {
  const { workspace } = useSelector((state) => state.identity);
  const [isOpen, setIsOpen] = useState(openByDefault === true);
  const [upgradeWindowVisible, setUpgradeWindowVisible] = useState(false);

  let upgradeRequired = false;

  if (requiredPlans != null && !requiredPlans.includes(workspace.plan.name)) {
    upgradeRequired = true;
  }

  const toggleOpenClose = () => {
    if (upgradeRequired) {
      setUpgradeWindowVisible(true);
    } else {
      setIsOpen((value) => !value);
    }
  };

  if (isVisible === false) {
    return null;
  }

  return (
    <SettingsBlockContainer>
      {upgradeWindowVisible && (
        <UpgradeRequiredModalWindow
          requiredPlans={["standard", "business", "enterprise"]}
          featuresDesc={`по настройке триггера появления видеовиджета`}
          featuresList={[
            "Появление видеовиджета сразу же при загрузке страницы",
            "Появление видеовиджета через определенное время на странице",
            "Появление видеовиджета при скролле до блока на странице",
          ]}
          onClose={() => setUpgradeWindowVisible(false)}
        />
      )}

      <SettingsHeaderBlock isOpen={isOpen} onClick={toggleOpenClose}>
        {icon && (
          <SettingsHeaderIcon className="settings-header-icon">
            <FontAwesomeIcon icon={icon} />
          </SettingsHeaderIcon>
        )}

        <div style={{ flexGrow: 1 }}>
          <SettingsBlockTitle>
            <SettingsBlockTitleText>{title}</SettingsBlockTitleText>
            {typeof appliedSettingsText === "function" &&
              appliedSettingsText() != null && (
                <SettingsBlockAppliedSettingsText>
                  {appliedSettingsText()}
                </SettingsBlockAppliedSettingsText>
              )}
            {upgradeRequired && <UpgradeButton />}
          </SettingsBlockTitle>
          <SettingsBlockDescription>{description}</SettingsBlockDescription>
        </div>

        <SettingsBlockToggleButton className="settings-header-button">
          <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
        </SettingsBlockToggleButton>
      </SettingsHeaderBlock>

      <SettingsContentBlock isOpen={isOpen}>{children}</SettingsContentBlock>
    </SettingsBlockContainer>
  );
};

export default SettingsBlock;
