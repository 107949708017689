import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import UpgradeRequiredModalWindow from "../../../components/UpgradeRequiredModalWindow";

export default ({ instance, instanceIndex, onSuccess, onClose }) => {
  const { workspace, project } = useSelector((state) => state.identity);
  const presets = project.presets || [];

  const defaultInstance = {
    id: Math.random().toString(),
    new_record: "1",
    preset_id: presets[0] && presets[0].id,
  };

  const [modalInstance, setModalInstance] = useState(
    instance || defaultInstance
  );

  const onChange = (propertyName, propertyValue) => {
    const newModalInstance = Object.assign({}, modalInstance);
    newModalInstance[propertyName] = propertyValue;
    setModalInstance(newModalInstance);
  };

  const submitForm = () => {
    onSuccess(modalInstance, instanceIndex);
  };

  return (
    <>
      {workspace.token !== "syfDePhO5WizzrqJ" && (
        <UpgradeRequiredModalWindow
          requiredPlans={["business", "enterprise"]}
          onClose={onClose}
          featuresDesc="добавлению карточек товаров в видеовиджеты"
          featuresList={[
            "Карточки товаров в видеовиджетах",
            "Отображение описание товара, его стоимости и изображения",
            "Переход на карточку товара по клику на название",
            "Возможность добавлять несколько товаров на видео",
            "Кастомизация цветов и текстов на кнопках",
            "Аналитика по взаимодействию пользователей с карточками товаров",
          ]}
        />
      )}

      <Modal
        isOpen={true}
        scrollable
        toggle={onClose}
        size="md"
        centered
        className="modal-popup"
      >
        <ModalHeader>Редактирование товара</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Название товара</Label>
              <Input
                type="text"
                name="name"
                value={modalInstance.name || ""}
                onChange={(event) => onChange("name", event.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label>Ссылка на карточку товара</Label>
              <Input
                type="text"
                name="url"
                value={modalInstance.url || ""}
                onChange={(event) => onChange("url", event.target.value)}
              />
            </FormGroup>

            <div className="d-flex">
              <div className="w-100 mr-3">
                <FormGroup>
                  <Label>Ссылка на изображение</Label>
                  <Input
                    type="text"
                    name="image"
                    value={modalInstance.image || ""}
                    onChange={(event) => onChange("image", event.target.value)}
                  />
                </FormGroup>
              </div>

              <div className="w-100">
                <FormGroup>
                  <Label>ID товара в CMS</Label>
                  <Input
                    type="text"
                    name="uuid"
                    value={modalInstance.uuid || ""}
                    onChange={(event) => onChange("uuid", event.target.value)}
                  />
                </FormGroup>
              </div>
            </div>

            <div className="d-flex">
              <div className="w-100 mr-3">
                <FormGroup>
                  <Label>Стоимость</Label>
                  <Input
                    type="text"
                    name="price"
                    value={modalInstance.price || ""}
                    onChange={(event) => onChange("price", event.target.value)}
                  />
                </FormGroup>
              </div>

              <div className="w-100">
                <FormGroup>
                  <Label>Предыдущая стоимость</Label>
                  <Input
                    type="text"
                    name="oldPrice"
                    value={modalInstance.old_price || ""}
                    onChange={(event) =>
                      onChange("old_price", event.target.value)
                    }
                  />
                </FormGroup>
              </div>
            </div>

            <div className="d-flex">
              <div className="w-100 mr-3">
                <FormGroup>
                  <Label>Внешний вид кнопки</Label>
                  <br />
                  {presets.length > 0 ? (
                    <CustomInput
                      type="select"
                      id="call_to_action_modal_preset_selector"
                      name="call-to-action-modal-preset-selector"
                      value={modalInstance.preset_id || presets[0].id}
                      onChange={(event) =>
                        onChange("preset_id", event.target.value)
                      }
                    >
                      {presets.map((preset, index) => (
                        <option
                          key={`preset_option_${preset.id}`}
                          value={preset.id}
                        >
                          {preset.name}
                        </option>
                      ))}
                    </CustomInput>
                  ) : (
                    <div>
                      <b style={{ color: "red" }}>
                        Необходимо создать пресет для кнопки. Пожалуйста,
                        обратитесь в чат поддержки
                      </b>
                    </div>
                  )}
                </FormGroup>
              </div>

              <div className="w-100"></div>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="light" className="mr-2" onClick={onClose}>
            Отмена
          </Button>

          <Button
            color="primary"
            disabled={presets.length === 0}
            onClick={submitForm}
          >
            Сохранить
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
