import React from "react";
import ColorPickerRGBA from "../../components/ColorPickerRGBA";
import SettingsBlock from "./SettingsBlock";

import { FormGroup, CustomInput, Input, Label } from "reactstrap";

import {
  faExpand,
  faPlayCircle,
  faCropAlt,
  faAlignLeft,
  faPortrait,
  faCompass,
  faCircleNotch,
  faPaintRoller,
  faArrowsAlt,
  faSearchPlus,
  faBorderAll,
  faGamepad,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";

const AppearanceSettings = ({ onChange, widgetType, widgetSettings }) => {
  return (
    <div>
      <SettingsBlock
        title="Размеры"
        description={
          widgetType === "VideoInline"
            ? "Задайте фиксированный или адаптивный размер для виджета в зависимости от устройства"
            : "Задайте размеры для видео в виджете в зависимости от устройства"
        }
        icon={faExpand}
        isVisible={true}
      >
        <FormGroup>
          {widgetType === "VideoInline" && (
            <div className="d-flex">
              <CustomInput
                id="item_responsive_layout_false"
                checked={widgetSettings.itemResponsiveLayout === false}
                type="radio"
                name="item_responsive_layout"
                value={false}
                label="Фиксированный"
                onChange={() => onChange("itemResponsiveLayout", false)}
                className="mr-3"
              />

              <CustomInput
                id="item_responsive_layout_true"
                checked={widgetSettings.itemResponsiveLayout === true}
                type="radio"
                name="item_responsive_layout"
                value={true}
                label="Адаптивный"
                onChange={() => onChange("itemResponsiveLayout", true)}
                className="mr-3"
              />
            </div>
          )}

          {widgetSettings.itemResponsiveLayout === true && (
            <div>
              <span className="text-muted text-sm">
                Виджет будет адаптироваться под размеры блока, в который встроен
              </span>
            </div>
          )}

          {widgetSettings.itemResponsiveLayout === false && (
            <div className="d-flex mt-3">
              <div className="mr-6">
                <Label>
                  <b>На компьютерах</b>
                </Label>

                <div className="d-flex">
                  <div>
                    <span className="text-muted text-sm">Ширина</span>
                    <Input
                      type="text"
                      value={widgetSettings.itemWidth}
                      onChange={(e) => onChange("itemWidth", e.target.value)}
                      style={{ width: "60px" }}
                    />
                  </div>

                  <div className="ml-2 mr-2">
                    <span className="text-muted text-sm">&nbsp;</span>
                    <br />x
                  </div>

                  <div>
                    <span className="text-muted text-sm">Высота</span>
                    <Input
                      type="text"
                      value={widgetSettings.itemHeight}
                      onChange={(e) => onChange("itemHeight", e.target.value)}
                      style={{ width: "60px" }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <Label>
                  <b>На мобильных</b>
                </Label>

                <div className="d-flex">
                  <div>
                    <span className="text-muted text-sm">Ширина</span>
                    <Input
                      type="text"
                      value={widgetSettings.itemMobileWidth}
                      onChange={(e) =>
                        onChange("itemMobileWidth", e.target.value)
                      }
                      style={{ width: "60px" }}
                    />
                  </div>

                  <div className="ml-2 mr-2">
                    <span className="text-muted text-sm">&nbsp;</span>
                    <br />x
                  </div>

                  <div>
                    <span className="text-muted text-sm">Высота</span>
                    <Input
                      type="text"
                      value={widgetSettings.itemMobileHeight}
                      onChange={(e) =>
                        onChange("itemMobileHeight", e.target.value)
                      }
                      style={{ width: "60px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </FormGroup>

        <br />

        <SettingsBlock
          title="Увеличение виджета в режиме просмотра"
          description="Для компьютеров, ноутбуков и планшетов"
          icon={faSearchPlus}
          isVisible={
            widgetType === "VideoBubble" ||
            widgetType === "VideoInline" ||
            widgetType === "VideoCarousel"
          }
        >
          {widgetType === "VideoBubble" ? (
            <div className="d-flex">
              <CustomInput
                id="itemOpenWithZoomLevel_small"
                checked={widgetSettings.itemOpenWithZoomLevel === "small"}
                type="radio"
                name="itemOpenWithZoomLevel"
                value="small"
                label="Небольшое"
                onChange={() => {
                  onChange("itemOpenWithZoomLevel", "small");
                }}
                className="mr-3"
              />

              <CustomInput
                id="itemOpenWithZoomLevel_medium"
                checked={widgetSettings.itemOpenWithZoomLevel === "medium"}
                type="radio"
                name="itemOpenWithZoomLevel"
                value="medium"
                label="Среднее"
                onChange={() => {
                  onChange("itemOpenWithZoomLevel", "medium");
                }}
                className="mr-3"
              />

              <CustomInput
                id="itemOpenWithZoomLevel_large"
                checked={widgetSettings.itemOpenWithZoomLevel === "large"}
                type="radio"
                name="itemOpenWithZoomLevel"
                value="large"
                label="Большое"
                onChange={() => {
                  onChange("itemOpenWithZoomLevel", "large");
                }}
                className="mr-3"
              />
            </div>
          ) : (
            <div className="d-flex">
              <CustomInput
                id="itemOpenWithZoom_false"
                checked={widgetSettings.itemOpenWithZoom === false}
                type="radio"
                name="itemOpenWithZoom"
                value={false}
                label="Не увеличивать видео"
                onChange={() => {
                  onChange("itemOpenWithZoom", false);
                }}
                className="mr-3"
              />

              <CustomInput
                id="itemOpenWithZoom_true"
                checked={widgetSettings.itemOpenWithZoom === true}
                type="radio"
                name="itemOpenWithZoom"
                value={true}
                label="Увеличивать видео"
                onChange={() => {
                  onChange("itemOpenWithZoom", true);
                }}
                className="mr-3"
              />
            </div>
          )}
        </SettingsBlock>
      </SettingsBlock>

      <SettingsBlock
        title="Расположение на странице"
        description="Расположение виджета на странице, отступы от экрана и перетаскивание"
        icon={faArrowsAlt}
        isVisible={widgetType === "VideoBubble"}
      >
        <div className="d-flex flex-column">
          <div>
            <Label className="mb-0">
              <b>На десктопах</b>
            </Label>
            <div className="d-flex mb-3">
              <CustomInput
                id="position_left_bottom"
                checked={
                  widgetSettings.widgetPositionX === "left" &&
                  widgetSettings.widgetPositionY === "bottom"
                }
                type="radio"
                name="position"
                value="left_bottom"
                label="Слева внизу"
                onChange={() => {
                  onChange(
                    "widgetPositionX",
                    "left",
                    "widgetPositionY",
                    "bottom"
                  );
                }}
                className="mr-3"
              />

              <CustomInput
                id="position_left_top"
                checked={
                  widgetSettings.widgetPositionX === "left" &&
                  widgetSettings.widgetPositionY === "top"
                }
                type="radio"
                name="position"
                value="left_top"
                label="Слева вверху"
                onChange={() => {
                  onChange("widgetPositionX", "left", "widgetPositionY", "top");
                }}
                className="mr-3"
              />

              <CustomInput
                id="position_right_bottom"
                checked={
                  widgetSettings.widgetPositionX === "right" &&
                  widgetSettings.widgetPositionY === "bottom"
                }
                type="radio"
                name="position"
                value="right_bottom"
                label="Справа внизу"
                onChange={() => {
                  onChange(
                    "widgetPositionX",
                    "right",
                    "widgetPositionY",
                    "bottom"
                  );
                }}
                className="mr-3"
              />

              <CustomInput
                id="position_right_top"
                checked={
                  widgetSettings.widgetPositionX === "right" &&
                  widgetSettings.widgetPositionY === "top"
                }
                type="radio"
                name="position"
                value="right_top"
                label="Справа вверху"
                onChange={() => {
                  onChange(
                    "widgetPositionX",
                    "right",
                    "widgetPositionY",
                    "top"
                  );
                }}
                className="mr-3"
              />

              <CustomInput
                id="position_center_bottom"
                checked={
                  widgetSettings.widgetPositionX === "center" &&
                  widgetSettings.widgetPositionY === "bottom"
                }
                type="radio"
                name="position"
                value="center_bottom"
                label="По центру внизу"
                onChange={() => {
                  onChange(
                    "widgetPositionX",
                    "center",
                    "widgetPositionY",
                    "bottom"
                  );
                }}
                className="mr-3"
              />
            </div>
          </div>

          <div>
            <Label className="mb-0">
              <b>На мобильных</b>
            </Label>
            <div className="d-flex">
              <CustomInput
                id="mobile_position_left_bottom"
                checked={
                  widgetSettings.mobile_widgetPositionX === "left" &&
                  widgetSettings.mobile_widgetPositionY === "bottom"
                }
                type="radio"
                name="mobile_position"
                value="left_bottom"
                label="Слева внизу"
                onChange={() => {
                  onChange(
                    "mobile_widgetPositionX",
                    "left",
                    "mobile_widgetPositionY",
                    "bottom"
                  );
                }}
                className="mr-3"
              />

              <CustomInput
                id="mobile_position_left_top"
                checked={
                  widgetSettings.mobile_widgetPositionX === "left" &&
                  widgetSettings.mobile_widgetPositionY === "top"
                }
                type="radio"
                name="mobile_position"
                value="left_top"
                label="Слева вверху"
                onChange={() => {
                  onChange(
                    "mobile_widgetPositionX",
                    "left",
                    "mobile_widgetPositionY",
                    "top"
                  );
                }}
                className="mr-3"
              />

              <CustomInput
                id="mobile_position_right_bottom"
                checked={
                  widgetSettings.mobile_widgetPositionX === "right" &&
                  widgetSettings.mobile_widgetPositionY === "bottom"
                }
                type="radio"
                name="mobile_position"
                value="mobile_right_bottom"
                label="Справа внизу"
                onChange={() => {
                  onChange(
                    "mobile_widgetPositionX",
                    "right",
                    "mobile_widgetPositionY",
                    "bottom"
                  );
                }}
                className="mr-3"
              />

              <CustomInput
                id="mobile_position_right_top"
                checked={
                  widgetSettings.mobile_widgetPositionX === "right" &&
                  widgetSettings.mobile_widgetPositionY === "top"
                }
                type="radio"
                name="mobile_position"
                value="right_top"
                label="Справа вверху"
                onChange={() => {
                  onChange(
                    "mobile_widgetPositionX",
                    "right",
                    "mobile_widgetPositionY",
                    "top"
                  );
                }}
                className="mr-3"
              />

              <CustomInput
                id="position_center_bottom"
                checked={
                  widgetSettings.mobile_widgetPositionX === "center" &&
                  widgetSettings.mobile_widgetPositionY === "bottom"
                }
                type="radio"
                name="mobile_position"
                value="center_bottom"
                label="По центру внизу"
                onChange={() => {
                  onChange(
                    "mobile_widgetPositionX",
                    "center",
                    "mobile_widgetPositionY",
                    "bottom"
                  );
                }}
                className="mr-3"
              />
            </div>
          </div>
        </div>

        <br />

        <SettingsBlock
          title="Отступы от экрана"
          description="Размеры отступа виджета от границ экрана"
          icon={faCropAlt}
          isVisible={widgetType === "VideoBubble"}
        >
          <div className="d-flex mt-3">
            <div className="mr-6">
              <Label>
                <b>На компьютерах</b>
              </Label>

              <FormGroup style={{ maxWidth: "200px" }}>
                <div className="d-flex">
                  <div className="mr-3">
                    <span className="text-muted text-sm">Справа/Слева</span>
                    <Input
                      type="text"
                      value={widgetSettings.itemMarginX}
                      onChange={(e) => onChange("itemMarginX", e.target.value)}
                      style={{ width: "100px" }}
                    />
                  </div>
                  <div>
                    <span className="text-muted text-sm">Сверху/Снизу</span>
                    <Input
                      type="text"
                      value={widgetSettings.itemMarginY}
                      onChange={(e) => onChange("itemMarginY", e.target.value)}
                      style={{ width: "100px" }}
                    />
                  </div>
                </div>
              </FormGroup>
            </div>
            <div>
              <Label>
                <b>На мобильных</b>
              </Label>

              <FormGroup style={{ maxWidth: "200px" }}>
                <div className="d-flex">
                  <div className="mr-3">
                    <span className="text-muted text-sm">Справа/Слева</span>
                    <Input
                      type="text"
                      value={widgetSettings.itemMobileMarginX}
                      onChange={(e) =>
                        onChange("itemMobileMarginX", e.target.value)
                      }
                      style={{ width: "100px" }}
                    />
                  </div>
                  <div>
                    <span className="text-muted text-sm">Сверху/Снизу</span>
                    <Input
                      type="text"
                      value={widgetSettings.itemMobileMarginY}
                      onChange={(e) =>
                        onChange("itemMobileMarginY", e.target.value)
                      }
                      style={{ width: "100px" }}
                    />
                  </div>
                </div>
              </FormGroup>
            </div>
          </div>
        </SettingsBlock>

        <SettingsBlock
          title="Перетаскивание виджета"
          description="Возможность перетаскивать виджет по экрану с помощью курсора мыши (для десктопов) или свайпа (для мобильных)"
          icon={faSearchPlus}
          isVisible={widgetType === "VideoBubble"}
        >
          <div className="d-flex">
            <CustomInput
              id="widgetDraggable_false"
              checked={widgetSettings.widgetDraggable === false}
              type="radio"
              name="widgetDraggable"
              value="false"
              label="Без перетаскивания"
              onChange={() => {
                onChange("widgetDraggable", false);
              }}
              className="mr-3"
            />

            <CustomInput
              id="widgetDraggable_true"
              checked={widgetSettings.widgetDraggable === true}
              type="radio"
              name="widgetDraggable"
              value="true"
              label="С перетаскиванием"
              onChange={() => {
                onChange("widgetDraggable", true);
              }}
              className="mr-3"
            />
          </div>
        </SettingsBlock>
      </SettingsBlock>

      <SettingsBlock
        title="Кнопка плей"
        description="Дизайн, форма и расположение кнопки плей"
        icon={faPlayCircle}
      >
        <div className="d-flex">
          <CustomInput
            id="play_button_visible_true"
            checked={widgetSettings.itemPlayButtonVisible === true}
            type="radio"
            name="play_button_visible"
            value={true}
            label="Включена"
            onChange={() => onChange("itemPlayButtonVisible", true)}
            className="mr-3"
          />

          <CustomInput
            id="play_button_visible_false"
            checked={widgetSettings.itemPlayButtonVisible === false}
            type="radio"
            name="play_button_visible"
            value={false}
            label="Выключена"
            onChange={() => onChange("itemPlayButtonVisible", false)}
            className="mr-3"
          />
        </div>

        {widgetSettings.itemPlayButtonVisible && (
          <div style={{ marginTop: "15px" }}>
            <Label>
              <b>Форма кнопки</b>
            </Label>

            <div className="d-flex">
              <CustomInput
                id="play_button_type_circle"
                checked={widgetSettings.itemPlayButtonType === "circle"}
                type="radio"
                name="play_button_type"
                value="circle"
                label="Круглая без текста"
                onChange={() => onChange("itemPlayButtonType", "circle")}
                className="mr-3"
              />

              <CustomInput
                id="play_button_type_rectangle"
                checked={widgetSettings.itemPlayButtonType === "rectangle"}
                type="radio"
                name="play_button_type"
                value="rectangle"
                label="Прямоугольная с текстом"
                onChange={() => onChange("itemPlayButtonType", "rectangle")}
                className="mr-3"
              />
            </div>

            <br />

            <FormGroup style={{ maxWidth: "200px" }}>
              <Label>Высота кнопки</Label>
              <Input
                type="text"
                name="play_button_height"
                value={widgetSettings.itemPlayButtonHeight}
                onChange={(e) =>
                  onChange("itemPlayButtonHeight", e.target.value)
                }
              />
            </FormGroup>

            {widgetSettings.itemPlayButtonType === "rectangle" && (
              <FormGroup style={{ maxWidth: "200px" }}>
                <Label>Закругление кнопки</Label>
                <Input
                  type="text"
                  name="play_button_border_radius"
                  value={widgetSettings.itemPlayButtonBorderRadius}
                  onChange={(e) =>
                    onChange("itemPlayButtonBorderRadius", e.target.value)
                  }
                />
              </FormGroup>
            )}

            <FormGroup>
              Цвет фона кнопки
              <br />
              <ColorPickerRGBA
                color={widgetSettings.itemPlayButtonBackgroundColor}
                onChange={(color) =>
                  onChange("itemPlayButtonBackgroundColor", color)
                }
              />
            </FormGroup>

            <FormGroup>
              {widgetSettings.itemPlayButtonType === "rectangle"
                ? `Цвет текста`
                : `Цвет треугольника в кнопке`}

              <br />
              <ColorPickerRGBA
                color={widgetSettings.itemPlayButtonFontColor}
                onChange={(color) => onChange("itemPlayButtonFontColor", color)}
              />
            </FormGroup>

            {widgetSettings.itemPlayButtonType === "rectangle" && (
              <>
                <FormGroup style={{ maxWidth: "200px" }}>
                  <Label>Текст на кнопке</Label>
                  <Input
                    type="text"
                    name="play_button_text"
                    value={widgetSettings.itemPlayButtonText}
                    onChange={(e) =>
                      onChange("itemPlayButtonText", e.target.value)
                    }
                  />
                </FormGroup>

                <FormGroup style={{ maxWidth: "200px" }}>
                  <Label>Размер шрифта</Label>
                  <Input
                    type="text"
                    name="play_button_font_size"
                    value={widgetSettings.itemPlayButtonFontSize}
                    onChange={(e) =>
                      onChange("itemPlayButtonFontSize", e.target.value)
                    }
                  />
                </FormGroup>
              </>
            )}

            <FormGroup style={{ marginTop: "10px" }}>
              <Label>Расположение кнопки</Label>

              <div className="d-flex">
                <CustomInput
                  id="play_button_position_center"
                  checked={widgetSettings.itemPlayButtonPosition === "center"}
                  type="radio"
                  name="play_button_position"
                  value="center"
                  label="По центру"
                  onChange={() => onChange("itemPlayButtonPosition", "center")}
                  className="mr-3"
                />

                <CustomInput
                  id="play_button_position_bottom"
                  checked={widgetSettings.itemPlayButtonPosition === "bottom"}
                  type="radio"
                  name="play_button_position"
                  value="bottom"
                  label="В нижней части"
                  onChange={() => onChange("itemPlayButtonPosition", "bottom")}
                  className="mr-3"
                />
              </div>
            </FormGroup>
          </div>
        )}
      </SettingsBlock>

      <SettingsBlock
        title="Фон карусели"
        description="Используйте прозрачный фон либо задайте свой цвет"
        icon={faPaintRoller}
        isVisible={widgetType === "VideoCarousel"}
      >
        <div className="d-flex">
          <CustomInput
            id="widget_background_color_transparent"
            checked={widgetSettings.widgetBackgroundColor === "transparent"}
            type="radio"
            name="widgetBackgroundColor"
            value={true}
            label="Прозрачный фон"
            onChange={(e) => onChange("widgetBackgroundColor", "transparent")}
            className="mr-3"
          />

          <CustomInput
            id="widget_background_color_color"
            checked={widgetSettings.widgetBackgroundColor !== "transparent"}
            type="radio"
            name="widgetBackgroundColor"
            value={false}
            label="Использовать свой цвет"
            onChange={(color) =>
              onChange("widgetBackgroundColor", {
                r: 255,
                g: 255,
                b: 255,
                a: 1,
              })
            }
            className="mr-3"
          />
        </div>

        {widgetSettings.widgetBackgroundColor !== "transparent" && (
          <div style={{ marginTop: "15px" }}>
            <FormGroup>
              Цвет фона
              <br />
              <ColorPickerRGBA
                color={widgetSettings.widgetBackgroundColor}
                onChange={(color) => onChange("widgetBackgroundColor", color)}
              />
            </FormGroup>
          </div>
        )}
      </SettingsBlock>

      <SettingsBlock
        title="Стрелки вправо-влево"
        description="Отображение стрелок вправо-влево в карусели из видео"
        icon={faCompass}
        isVisible={widgetType === "VideoCarousel"}
      >
        <div className="d-flex">
          <CustomInput
            id="widget_arrows_visible_true"
            checked={widgetSettings.widgetArrowsVisible === true}
            type="radio"
            name="widget_arrows_visible"
            value={true}
            label="Включены"
            onChange={(e) => onChange("widgetArrowsVisible", true)}
            className="mr-3"
          />

          <CustomInput
            id="widget_arrows_visible_false"
            checked={widgetSettings.widgetArrowsVisible === false}
            type="radio"
            name="widget_arrows_visible"
            value={false}
            label="Выключены"
            onChange={(e) => onChange("widgetArrowsVisible", false)}
            className="mr-3"
          />
        </div>

        {widgetSettings.widgetArrowsVisible && (
          <div style={{ marginTop: "15px" }}>
            <FormGroup style={{ maxWidth: "200px" }}>
              <span className="text-muted text-sm">Размер стрелки</span>
              <br />
              <Input
                type="text"
                value={widgetSettings.widgetArrowsSize}
                onChange={(e) => onChange("widgetArrowsSize", e.target.value)}
                style={{ width: "100px" }}
              />
            </FormGroup>
            <FormGroup>
              <span className="text-muted text-sm">Фон стрелки</span>
              <br />
              <ColorPickerRGBA
                color={widgetSettings.widgetArrowsBackgroundColor}
                onChange={(color) =>
                  onChange("widgetArrowsBackgroundColor", color)
                }
              />
            </FormGroup>
            <FormGroup>
              <span className="text-muted text-sm">Цвет рамки у стрелки</span>
              <br />
              <ColorPickerRGBA
                color={widgetSettings.widgetArrowsBorderColor}
                onChange={(color) => onChange("widgetArrowsBorderColor", color)}
              />
            </FormGroup>
            <FormGroup style={{ maxWidth: "200px" }}>
              <span className="text-muted text-sm">
                Размер иконки со стрелкой
              </span>
              <br />
              <Input
                type="text"
                value={widgetSettings.widgetArrowsIconSize}
                onChange={(e) =>
                  onChange("widgetArrowsIconSize", e.target.value)
                }
                style={{ width: "100px" }}
              />
            </FormGroup>
            <FormGroup style={{ maxWidth: "200px" }}>
              <span className="text-muted text-sm">Тень у стрелки (px)</span>
              <br />
              <Input
                type="text"
                value={widgetSettings.widgetArrowsShadowBlurRadius}
                onChange={(e) =>
                  onChange("widgetArrowsShadowBlurRadius", e.target.value)
                }
                style={{ width: "100px" }}
              />
            </FormGroup>
            <FormGroup>
              <span className="text-muted text-sm">
                Цвет иконки со стрелкой
              </span>
              <br />
              <ColorPickerRGBA
                color={widgetSettings.widgetArrowsColor}
                onChange={(color) => onChange("widgetArrowsColor", color)}
              />
            </FormGroup>
          </div>
        )}
      </SettingsBlock>

      <SettingsBlock
        title="Рамка у видео"
        description="Цвет рамки при загрузке виджета и при наведении на него"
        icon={faBorderAll}
      >
        <div className="d-flex">
          <CustomInput
            id="item_border_true"
            checked={widgetSettings.itemBorder === true}
            type="radio"
            name="item_border"
            value={true}
            label="Отображается"
            onChange={() => onChange("itemBorder", true)}
            className="mr-3"
          />

          <CustomInput
            id="item_border_false"
            checked={widgetSettings.itemBorder === false}
            type="radio"
            name="item_border"
            value={false}
            label="Не отображается"
            onChange={() => onChange("itemBorder", false)}
            className="mr-3"
          />
        </div>

        {widgetSettings.itemBorder && (
          <>
            <br />
            <FormGroup>
              Цвет при загрузке
              <br />
              <ColorPickerRGBA
                color={widgetSettings.itemBorderColor}
                onChange={(color) => onChange("itemBorderColor", color)}
              />
            </FormGroup>
            <FormGroup>
              Цвет при наведении
              <br />
              <ColorPickerRGBA
                color={widgetSettings.itemBorderColorMouseOver}
                onChange={(color) =>
                  onChange("itemBorderColorMouseOver", color)
                }
              />
            </FormGroup>
          </>
        )}
      </SettingsBlock>

      <SettingsBlock
        title="Закругление видео"
        description="Радиус закругления у видео"
        icon={faCircleNotch}
      >
        <FormGroup style={{ maxWidth: "200px" }}>
          <div className="d-flex">
            <div className="mr-3">
              <span className="text-muted text-sm">Радиус закругления, px</span>
              <Input
                type="text"
                value={widgetSettings.itemBorderRadius}
                onChange={(e) => onChange("itemBorderRadius", e.target.value)}
                style={{ width: "100px" }}
              />
            </div>
          </div>
        </FormGroup>
      </SettingsBlock>

      <SettingsBlock
        title="Отступы у видео"
        description="Размеры отступов от видео до других элементов"
        icon={faCropAlt}
        isVisible={widgetType !== "VideoBubble"}
      >
        <div className="d-flex mt-3">
          <div className="mr-6">
            <Label>
              <b>На компьютерах</b>
            </Label>

            <FormGroup style={{ maxWidth: "200px" }}>
              <div className="d-flex">
                <div className="mr-3">
                  <span className="text-muted text-sm">Справа/Слева</span>
                  <Input
                    type="text"
                    value={widgetSettings.itemMarginX}
                    onChange={(e) => onChange("itemMarginX", e.target.value)}
                    style={{ width: "100px" }}
                  />
                </div>
                <div>
                  <span className="text-muted text-sm">Сверху/Снизу</span>
                  <Input
                    type="text"
                    value={widgetSettings.itemMarginY}
                    onChange={(e) => onChange("itemMarginY", e.target.value)}
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
            </FormGroup>
          </div>
          <div>
            <Label>
              <b>На мобильных</b>
            </Label>

            <FormGroup style={{ maxWidth: "200px" }}>
              <div className="d-flex">
                <div className="mr-3">
                  <span className="text-muted text-sm">Справа/Слева</span>
                  <Input
                    type="text"
                    value={widgetSettings.itemMobileMarginX}
                    onChange={(e) =>
                      onChange("itemMobileMarginX", e.target.value)
                    }
                    style={{ width: "100px" }}
                  />
                </div>
                <div>
                  <span className="text-muted text-sm">Сверху/Снизу</span>
                  <Input
                    type="text"
                    value={widgetSettings.itemMobileMarginY}
                    onChange={(e) =>
                      onChange("itemMobileMarginY", e.target.value)
                    }
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
            </FormGroup>
          </div>
        </div>
      </SettingsBlock>

      <SettingsBlock
        title="Выравнивание заголовков"
        description="Расположение заголовков и подзаголовков в видео по левому краю или по центру"
        icon={faAlignLeft}
      >
        <CustomInput
          id="item_title_alignment_left"
          checked={widgetSettings.itemTitleAlignment === "left"}
          type="radio"
          name="itemTitleAlignment"
          value="left"
          label="По левому краю"
          onChange={(e) => onChange("itemTitleAlignment", "left")}
          className="mr-3"
        />

        <CustomInput
          id="item_title_alignment_center"
          checked={widgetSettings.itemTitleAlignment === "center"}
          type="radio"
          name="itemTitleAlignment"
          value="center"
          label="По центру"
          onChange={(e) => onChange("itemTitleAlignment", "center")}
          className="mr-3"
        />
      </SettingsBlock>

      <SettingsBlock
        title="Обложка видео и предпросмотр"
        description="Автоматически проигрывать видео без звука или показывать сгенерированную картинку из видео"
        icon={faPortrait}
        isVisible={widgetType !== "VideoBubble"}
      >
        <div className="d-flex" style={{ flexDirection: "column" }}>
          <CustomInput
            id="widget_items_preview_mode_first"
            checked={widgetSettings.widgetItemsPreviewMode === "first"}
            type="radio"
            name="widget_items_preview_mode"
            value="first"
            label="Автоплей превью первого видео"
            onChange={(e) => onChange("widgetItemsPreviewMode", "first")}
            className="mr-3"
          />

          <CustomInput
            id="widget_items_preview_mode_all"
            checked={widgetSettings.widgetItemsPreviewMode === "all"}
            type="radio"
            name="widget_items_preview_mode"
            value="all"
            label="Автоплей превью всех видео"
            onChange={(e) => onChange("widgetItemsPreviewMode", "all")}
            className="mr-3"
          />

          <CustomInput
            id="widget_items_preview_mode_none"
            checked={widgetSettings.widgetItemsPreviewMode === "none"}
            type="radio"
            name="widget_items_preview_mode"
            value="none"
            label="Отображать сгенерированную картинку из видео"
            onChange={(e) => onChange("widgetItemsPreviewMode", "none")}
            className="mr-3"
          />
        </div>
      </SettingsBlock>

      <SettingsBlock
        title="Контролы и элементы управления"
        description="Показывать или скрыть регулятор громкости, прогресс бар, крестик закрытия и др."
        icon={faGamepad}
      >
        <div className="d-flex">
          <CustomInput
            id="widgetControlsVisible_true"
            checked={widgetSettings.widgetControlsVisible === true}
            type="radio"
            name="widgetControlsVisible"
            value={true}
            label="Показывать контролы"
            onChange={() => {
              onChange("widgetControlsVisible", true);
            }}
            className="mr-3"
          />

          <CustomInput
            id="widgetControlsVisible_false"
            checked={widgetSettings.widgetControlsVisible === false}
            type="radio"
            name="widgetControlsVisible"
            value={false}
            label="Скрыть контролы"
            onChange={() => {
              onChange("widgetControlsVisible", false);
            }}
            className="mr-3"
          />
        </div>
      </SettingsBlock>

      <SettingsBlock
        title="Кнопка закрытия виджета"
        description="Кастомизация цветов для кнопки закрытия виджета (крестика)"
        icon={faWindowClose}
        isVisible={widgetType === "VideoBubble"}
      >
        <FormGroup>
          Цвет фона кнопки
          <br />
          <ColorPickerRGBA
            color={widgetSettings.itemCloseButtonBackgroundColor}
            onChange={(color) =>
              onChange("itemCloseButtonBackgroundColor", color)
            }
          />
        </FormGroup>

        <FormGroup>
          Цвет иконки (крестика)
          <br />
          <ColorPickerRGBA
            color={widgetSettings.itemCloseButtonIconColor}
            onChange={(color) => onChange("itemCloseButtonIconColor", color)}
          />
        </FormGroup>
      </SettingsBlock>
    </div>
  );
};

export default AppearanceSettings;
