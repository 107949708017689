import {
  faCaretDown,
  faPlusCircle,
  faTimesCircle,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { CustomInput, Input } from "reactstrap";
import SettingsBlock from "./SettingsBlock";

const AssertionSelector = ({ id, name, value, onChange }) => {
  const { t } = useTranslation();

  return (
    <CustomInput
      type="select"
      id={id}
      name={name}
      value={value}
      style={{ width: "140px", marginRight: "10px" }}
      onChange={(event) => onChange(event.target.value)}
    >
      <optgroup label={t("assertion_selector.comparison")}>
        <option value="equal">{t("assertion_selector.equal")}</option>
        <option value="not_equal">{t("assertion_selector.not_equal")}</option>
      </optgroup>
    </CustomInput>
  );
};

const Rule = ({
  index,
  name,
  value,
  assertion,
  onPropertyChange,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: "flex", marginBottom: "10px" }}>
      <Input
        type="text"
        value={name}
        name="ruleName"
        placeholder={t(
          "dashboard.widget_form.display_by_custom_properties.rule_name"
        )}
        onChange={(event) => onPropertyChange("name", event.target.value)}
        style={{ width: "220px", marginRight: "10px" }}
      />

      <AssertionSelector
        id={`ruleAssetion_${index}`}
        name="ruleAssetion"
        value={assertion}
        onChange={(value) => onPropertyChange("assertion", value)}
      />

      <Input
        type="text"
        value={value}
        name="ruleValue"
        placeholder={t(
          "dashboard.widget_form.display_by_custom_properties.rule_value"
        )}
        onChange={(event) => onPropertyChange("value", event.target.value)}
        style={{ width: "200px" }}
      />

      <div>
        <FontAwesomeIcon
          onClick={onDelete}
          icon={faTimesCircle}
          style={{
            fontSize: "14px",
            color: "#d9534f",
            margin: "7px 0 0 10px",
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};

const DisplayByCustomProperties = ({
  requiredPlans,
  rules,
  operator,
  onChange,
  onOperatorChange,
}) => {
  const { t } = useTranslation();

  const addRule = () => {
    const newRules = [...rules];
    newRules.push({ name: "", assertion: "equal", value: "" });
    onChange(newRules);
  };

  const onPropertyChange = (index, propertyName, propertyValue) => {
    const newRules = [...rules];
    newRules[index][propertyName] = propertyValue;
    onChange(newRules);
  };

  const deleteRule = (index) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    onChange(newRules);
  };

  return (
    <SettingsBlock
      requiredPlans={requiredPlans}
      title={t("dashboard.widget_form.display_by_custom_properties.title")}
      description={t("dashboard.widget_form.display_by_custom_properties.desc")}
      icon={faUsersCog}
    >
      <div>
        {rules.map((rule, index) => (
          <div key={index}>
            {index > 0 && (
              <div
                onClick={() =>
                  onOperatorChange(operator === "AND" ? "OR" : "AND")
                }
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  color: "#aaaaaa",
                  border: "1px solid #eeeeee",
                  padding: "3px 5px",
                  borderRadius: "5px",
                  display: "inline-block",
                  cursor: "pointer",
                }}
              >
                {t(`assertion_operator.${operator.toLowerCase()}`)}
                <FontAwesomeIcon
                  icon={faCaretDown}
                  style={{ marginLeft: "5px" }}
                />
              </div>
            )}

            <Rule
              index={index}
              {...rule}
              onPropertyChange={(propertyName, propertyValue) =>
                onPropertyChange(index, propertyName, propertyValue)
              }
              onDelete={() => deleteRule(index)}
            />
          </div>
        ))}
      </div>

      <div
        style={{
          fontSize: "11px",
          margin: "5px 3px",
          cursor: "pointer",
          display: "inline-block",
        }}
        onClick={addRule}
      >
        <Trans>
          dashboard.widget_form.display_by_custom_properties.add_rule
        </Trans>
        <FontAwesomeIcon
          icon={faPlusCircle}
          style={{
            fontSize: "14px",
            marginLeft: "7px",
            position: "relative",
            top: "2px",
            color: "#3A89FF",
            cursor: "pointer",
          }}
        />
      </div>

      <br />
      <br />

      <a
        href="https://getreview.io/help/display-custom-properties"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Trans>dashboard.widget_form.help_link_title</Trans>
      </a>
    </SettingsBlock>
  );
};

export default DisplayByCustomProperties;
