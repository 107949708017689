import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import ReduxToastr from "react-redux-toastr";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { ConnectedRouter } from "connected-react-router";
import "intersection-observer";
import { history } from "./redux/store";

import App from "./App";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <ConnectedRouter history={history}>
        <ReduxToastr
          timeOut={5000}
          newestOnTop={false}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          closeOnToastrClick
          preventDuplicates={true}
        />
        <App />
      </ConnectedRouter>
    </DndProvider>
  </Provider>,
  document.getElementById("root")
);
