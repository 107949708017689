import React, { useState, useEffect } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../../constants";
import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { format } from "date-fns";
import * as fnsLocales from "date-fns/locale";
import {
  Spinner,
  Table,
  Button,
  Card,
  CardBody,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faCreditCard,
  faFileDownload,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const LegacyInvoiceLastId = 5760;
const LegacyInvoiceTooltipText =
  "Счета, созданные до 10 октября 2022, отображаются без номера. Если вам необходимо скачать данный счет или акт, то обратитесь, пожалуйста, в чат поддержки.";

const WorkspaceSettingsInvoices = (props) => {
  const { t } = useTranslation();
  const { account } = useSelector((state) => state.identity);

  const fnsLocale = {
    ru: fnsLocales.ru,
    en: fnsLocales.enUS,
  }[account.lang];

  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = () => {
    setLoading(true);

    axios
      .get(`${GETREVIEW_API_HOST}/api/billing/invoices`, {
        withCredentials: true,
      })
      .then((json) => {
        let invoices = json.data.invoices;

        setLoading(false);
        setInvoices(invoices);
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error(
            "",
            "Возникла неизвестная ошибка при загрузке списка счетов"
          );
        }
      });
  };

  return (
    <Card>
      {loading ? (
        <CardBody>
          <Spinner color="primary" size="sm" className="mr-3" />
          Загрузка...
        </CardBody>
      ) : (
        <Table responsive bordered hover style={{ borderTop: "none" }}>
          <thead>
            <tr>
              <th>Номер счета</th>
              <th>Сумма</th>
              <th>Тариф</th>
              <th>Период</th>
              <th>Статус</th>
              <th nowrap="nowrap">Способ оплаты</th>
              <th>Действия</th>
            </tr>
          </thead>
          <tbody>
            {invoices.length === 0 && (
              <tr>
                <td colSpan="7">
                  У вас нет выставленных счетов или проведенных оплат
                </td>
              </tr>
            )}

            {invoices.map((invoice) => (
              <tr key={`invoice_${invoice.id}`}>
                <td nowrap="nowrap">
                  {invoice.merchant === "ip_direct" &&
                  invoice.id <= LegacyInvoiceLastId ? (
                    <div>
                      <UncontrolledPopover
                        placement="top"
                        trigger="hover"
                        target={`invoice_without_id_popover_${invoice.id}`}
                      >
                        <PopoverBody>{LegacyInvoiceTooltipText}</PopoverBody>
                      </UncontrolledPopover>

                      <span className="mr-1 text-muted">Без номера</span>
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        id={`invoice_without_id_popover_${invoice.id}`}
                        style={{ opacity: 0.3, cursor: "pointer" }}
                      />
                    </div>
                  ) : (
                    <div>№{invoice.id}</div>
                  )}
                  <span className="text-muted" style={{ fontSize: "12px" }}>
                    от&nbsp;
                    {format(new Date(invoice.created_at), "dd MMMM yyyy", {
                      locale: fnsLocale,
                    })}
                  </span>
                </td>
                <td nowrap="nowrap">
                  {invoice.amount}
                  &nbsp;
                  <span className="text-muted">руб.</span>
                </td>
                <td nowrap="nowrap">
                  <b>{t(`plans.names.${invoice.plan}`)}</b>
                </td>
                <td nowrap="nowrap">
                  {invoice.months == null ? (
                    <span>{t(`workspace.invoice.period.not_specified`)}</span>
                  ) : (
                    <Trans values={{ count: invoice.months }}>
                      months.counter
                    </Trans>
                  )}
                  {invoice.plan && invoice.period_from_date && (
                    <div
                      className="text-muted"
                      style={{ lineHeight: "15px", fontSize: "12px" }}
                    >
                      c&nbsp;
                      {format(
                        new Date(invoice.period_from_date),
                        "dd MMMM yyyy",
                        {
                          locale: fnsLocale,
                        }
                      )}
                      <br />
                      по&nbsp;
                      {format(
                        new Date(invoice.period_to_date),
                        "dd MMMM yyyy",
                        {
                          locale: fnsLocale,
                        }
                      )}
                    </div>
                  )}
                </td>
                <td nowrap="nowrap">
                  <div
                    className={`badge badge-${
                      {
                        created: "secondary",
                        pending: "warning",
                        paid: "success",
                        failed: "danger",
                      }[invoice.status]
                    }`}
                  >
                    {t(`workspace.invoice.status.${invoice.status}`)}
                  </div>
                </td>
                <td nowrap="nowrap">
                  <FontAwesomeIcon
                    className="mr-2 text-muted"
                    icon={
                      invoice.merchant === "ip_direct"
                        ? faFileInvoice
                        : faCreditCard
                    }
                  />
                  {t(`workspace.invoice.merchant.${invoice.merchant}`)}
                </td>
                <td nowrap="nowrap">
                  {invoice.cdn_invoice_pdf_url && (
                    <a
                      href={invoice.cdn_invoice_pdf_url}
                      target="blank"
                      onClick={(event) => {
                        if (
                          invoice.merchant === "ip_direct" &&
                          invoice.id <= LegacyInvoiceLastId
                        ) {
                          event.preventDefault();
                          toastr.success("", LegacyInvoiceTooltipText);
                        }
                      }}
                    >
                      <Button
                        size="sm"
                        color={
                          invoice.status === "paid" ? "success" : "primary"
                        }
                        className="mr-2"
                      >
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faFileDownload}
                        />
                        Счет в <b>PDF</b>
                      </Button>
                    </a>
                  )}

                  {invoice.cdn_act_pdf_url && (
                    <a
                      href={invoice.cdn_act_pdf_url}
                      target="blank"
                      onClick={(event) => {
                        if (
                          invoice.merchant === "ip_direct" &&
                          invoice.id <= LegacyInvoiceLastId
                        ) {
                          event.preventDefault();
                          toastr.success("", LegacyInvoiceTooltipText);
                        }
                      }}
                    >
                      <Button size="sm" color="success">
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faFileDownload}
                        />
                        Акт в <b>PDF</b>
                      </Button>
                    </a>
                  )}

                  {invoice.status !== "paid" && invoice.pay_invoice_url && (
                    <a href={invoice.pay_invoice_url} target="blank">
                      <Button size="sm" color="warning">
                        <FontAwesomeIcon
                          className="mr-2"
                          icon={faFileDownload}
                        />
                        Оплатить
                      </Button>
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Card>
  );
};

export default WorkspaceSettingsInvoices;
