import React, { useState, useEffect, useRef, useCallback } from "react";
import { ChromePicker } from "react-color";

const ColorPicker = (props) => {
  const containerRef = useRef();

  const [colorPickerVisibile, setColorPickerVisibile] = useState(false);
  const [selectedColor, setSelectedColor] = useState(props.color);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(selectedColor);
    }
  }, [selectedColor]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (!containerRef.current || containerRef.current.contains(event.target)) {
      return;
    }

    setColorPickerVisibile(false);
  };

  return (
    <div
      className="color-picker"
      ref={containerRef}
      onClick={() => setColorPickerVisibile(true)}
    >
      <div
        className="color-preview"
        style={{ backgroundColor: selectedColor }}
      ></div>

      <input
        type="text"
        value={selectedColor}
        onChange={(e) => setSelectedColor(e.target.value)}
        className="hex-value"
      />

      {colorPickerVisibile === true && (
        <ChromePicker
          color={selectedColor}
          onChange={(color) => setSelectedColor(color.hex)}
          disableAlpha={true}
        />
      )}
    </div>
  );
};

export default ColorPicker;
