import axios from "axios";
import classnames from "classnames";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { InView } from "react-intersection-observer";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import FilterOption from "../../components/FilterOption";
import FolderSelector from "../../components/FolderSelector";
import Folders from "../../components/Folders";
import { GETREVIEW_API_HOST } from "../../constants";
import WidgetItem from "./WidgetItem";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CustomInput,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faAngleRight,
  faCopy,
  faEye,
  faEyeSlash,
  faFolder,
  faFolderOpen,
  faPlus,
  faPlusCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import { useDrop } from "react-dnd";

const MyWidgetsNavLink = (props) => {
  const [{ highlighted }, drop] = useDrop({
    accept: "widget_item",
    drop: (item, monitor) => {
      return { folderToken: "root" };
    },
    collect: (monitor) => ({
      highlighted: monitor.canDrop(),
    }),
  });

  const classNames = classnames({
    "header-link": true,
    highlighted: highlighted,
  });

  return (
    <Link to="/widgets/all" ref={drop} className={classNames}>
      <Trans>sidebar.my_widgets</Trans>
    </Link>
  );
};

const AddItemIcon = () => {
  return (
    <FontAwesomeIcon
      icon={faPlusCircle}
      style={{
        marginLeft: "12px",
        position: "relative",
        top: "1px",
        color: "#3A89FF",
        cursor: "pointer",
      }}
    />
  );
};

class WidgetsTable extends React.Component {
  constructor(props) {
    super(props);

    const selectedFolderToken = this.props.match.params.folder_token || null;

    this.state = {
      folders: [],
      widgets: [],
      totalCount: 0,
      fetching: false,
      page: 0,
      lastPage: false,
      filterByStatus: null,
      filterByType: null,
      selectedFolderToken: selectedFolderToken,
      selectedFolderName: null,
      copyWidgetCodeModalIsVisible: false,
      moveToFolderModalIsVisible: false,
      moveToFolderSelectedOption: null,
    };
  }

  componentDidUpdate() {
    const selectedFolderToken = this.props.match.params.folder_token || null;

    if (selectedFolderToken !== this.state.selectedFolderToken) {
      this.setState(
        { selectedFolderToken: selectedFolderToken, selectedFolderName: null },
        () => {
          this.fetchWidgets();
        }
      );
    }
  }

  loadNextPage() {
    if (this.state.lastPage) return;

    this.setState(
      (prevState) => ({ page: prevState.page + 1 }),
      () => {
        this.fetchWidgets({ append: true });
      }
    );
  }

  fetchWidgets(options) {
    return axios
      .get(`${GETREVIEW_API_HOST}/api/widgets`, {
        params: {
          folder: this.state.selectedFolderToken,
          status: this.state.filterByStatus,
          type: this.state.filterByType,
          page: this.state.page,
          page_size: 20,
          sort_by: "created_at",
          order_by: "desc",
        },
        withCredentials: true,
      })
      .then((json) => {
        const currentWidgets = [...this.state.widgets];
        let widgets = json.data.widgets;
        const folderName = json.data.folder_name || null;

        if (options && options.append === true) {
          widgets = currentWidgets.concat(...widgets);
        }

        this.setState({
          widgets: widgets,
          totalCount: json.data.total_count,
          lastPage: json.data.last_page,
          selectedFolderName: folderName,
        });
      })
      .catch(() => {
        alert("Error fetching widgets!");
      })
      .then(() => this.setState({ fetching: false }));
  }

  handleMoveWidgetToFolder = (widgetToken, folderToken) => {
    const folders = [...this.state.folders];
    const widgets = [...this.state.widgets];

    const widgetIndex = widgets.findIndex((w) => w.token === widgetToken);

    if (widgetIndex != null) {
      widgets.splice(widgetIndex, 1);
    }

    this.setState({ folders: folders, widgets: widgets });
  };

  handleToggleSelected = (widgetToken, isSelected) => {
    const widgets = [...this.state.widgets];

    this.state.widgets.forEach((widget) => {
      if (widget.token === widgetToken) {
        widget.isSelected = isSelected;
      }
    });

    this.setState({ widgets: widgets });
  };

  cancelWidgetsSelection = () => {
    const widgets = [...this.state.widgets];

    this.state.widgets.forEach((widget) => {
      widget.isSelected = false;
    });

    this.setState({ widgets: widgets });
  };

  handleStatusSelected = (widgetToken, isEnabled) => {
    const widget = this.state.widgets.find((w) => w.token === widgetToken);

    if (widget.enabled !== isEnabled) {
      if (isEnabled) {
        this.enableWidgetsByTokens([widgetToken]);
      } else {
        this.disableWidgetsByTokens([widgetToken]);
      }
    }
  };

  toggleSelectAllWidgets = (event) => {
    const isChecked = event.target.checked === true;
    const widgets = [...this.state.widgets];

    widgets.forEach((widget) => (widget.isSelected = isChecked));

    this.setState({ widgets: widgets });
  };

  disableSelectedWidgets = () => {
    const selectedWidgets = this.state.widgets.filter(
      (widget) => widget.isSelected === true
    );
    const selectedWidgetTokens = selectedWidgets.map((widget) => widget.token);

    const confirmBlock = (
      <div>
        <Trans values={{ count: selectedWidgetTokens.length }}>
          dashboard.widgets_actions.disable_confirm_message
        </Trans>
      </div>
    );

    toastr.confirm(confirmBlock, {
      onOk: () => {
        this.disableWidgetsByTokens(selectedWidgetTokens);
      },
    });
  };

  disableWidgetsByTokens = (widgetTokens) => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/widgets/disable_selected`,
        { tokens: widgetTokens },
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success(
          "",
          this.props.t("dashboard.widgets_actions.disable_success")
        );
        this.cancelWidgetsSelection();

        const widgets = [...this.state.widgets];

        widgets.forEach((widget) => {
          if (widgetTokens.includes(widget.token)) {
            widget.enabled = false;
          }
        });

        this.setState({ widgets: widgets });
      })
      .catch(() => {
        alert("Error occurred!");
      });
  };

  enableSelectedWidgets = () => {
    const selectedWidgets = this.state.widgets.filter(
      (widget) => widget.isSelected === true
    );
    const selectedWidgetTokens = selectedWidgets.map((widget) => widget.token);

    const confirmBlock = (
      <div>
        <Trans values={{ count: selectedWidgetTokens.length }}>
          dashboard.widgets_actions.enable_confirm_message
        </Trans>
      </div>
    );

    toastr.confirm(confirmBlock, {
      onOk: () => {
        this.enableWidgetsByTokens(selectedWidgetTokens);
      },
    });
  };

  enableWidgetsByTokens = (widgetTokens) => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/widgets/enable_selected`,
        { tokens: widgetTokens },
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success(
          "",
          this.props.t("dashboard.widgets_actions.enable_success")
        );
        this.cancelWidgetsSelection();

        const widgets = [...this.state.widgets];

        widgets.forEach((widget) => {
          if (widgetTokens.includes(widget.token)) {
            widget.enabled = true;
          }
        });

        this.setState({ widgets: widgets });
      })
      .catch(() => {
        alert("Error occurred!");
      });
  };

  deleteSelectedWidgets = () => {
    const selectedWidgets = this.state.widgets.filter(
      (widget) => widget.isSelected === true
    );
    const selectedWidgetTokens = selectedWidgets.map((widget) => widget.token);

    const confirmBlock = (
      <div>
        <Trans values={{ count: selectedWidgetTokens.length }}>
          dashboard.widgets_actions.delete_confirm_message
        </Trans>
      </div>
    );

    toastr.confirm(confirmBlock, {
      onOk: () => {
        axios
          .delete(
            `${GETREVIEW_API_HOST}/api/widgets/delete_selected`,
            { data: { tokens: selectedWidgetTokens }, withCredentials: true },
            { withCredentials: true }
          )
          .then((json) => {
            toastr.success(
              "",
              this.props.t("dashboard.widgets_actions.delete_success")
            );
            this.cancelWidgetsSelection();

            const widgets = [...this.state.widgets];

            selectedWidgetTokens.forEach((token) => {
              let widgetIndex = widgets.findIndex((w) => w.token === token);

              if (widgetIndex != null) {
                widgets.splice(widgetIndex, 1);
              }
            });

            this.setState((prevState) => ({
              widgets: widgets,
              totalCount: prevState.totalCount - selectedWidgetTokens.length,
            }));
          })
          .catch(() => {
            alert("Error occurred!");
          });
      },
    });
  };

  duplicateSelectedWidgets = () => {
    const selectedWidgets = this.state.widgets.filter(
      (widget) => widget.isSelected === true
    );
    const selectedWidgetTokens = selectedWidgets.map((widget) => widget.token);

    const confirmBlock = (
      <div>
        <Trans values={{ count: selectedWidgetTokens.length }}>
          dashboard.widgets_actions.duplicate_confirm_message
        </Trans>
      </div>
    );

    toastr.confirm(confirmBlock, {
      onOk: () => {
        axios
          .put(
            `${GETREVIEW_API_HOST}/api/widgets/duplicate_selected`,
            { tokens: selectedWidgetTokens },
            { withCredentials: true }
          )
          .then((json) => {
            toastr.success(
              "",
              this.props.t("dashboard.widgets_actions.duplicate_success")
            );

            this.cancelWidgetsSelection();

            document
              .querySelector(".main")
              .scrollIntoView({ behavior: "smooth" });

            this.setState({ page: 1 }, () => {
              this.fetchWidgets();
            });
          })
          .catch(() => {
            alert("Error occurred!");
          });
      },
    });
  };

  openMoveToFolderModal = () => {
    this.setState({ moveToFolderModalIsVisible: true });
  };

  closeMoveToFolderModal = () => {
    this.setState({ moveToFolderModalIsVisible: false });
  };

  handleMoveToFolderSelectorChanged = (optionValue) => {
    this.setState({ moveToFolderSelectedOption: optionValue });
  };

  moveToFolderSelectedWidgets = (moveToFolderToken) => {
    const selectedWidgetsTokens = this.state.widgets
      .filter((widget) => widget.isSelected === true)
      .map((widget) => widget.token);

    axios
      .put(
        `${GETREVIEW_API_HOST}/api/widgets/move_to_folder_selected`,
        { folder_token: moveToFolderToken, widgets: selectedWidgetsTokens },
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success(
          "",
          this.props.t("dashboard.widgets_actions.move_success")
        );
        this.closeMoveToFolderModal();
        selectedWidgetsTokens.forEach((widgetToken) =>
          this.handleMoveWidgetToFolder(widgetToken, moveToFolderToken)
        );
      })
      .catch(() => {
        alert("Error occurred!");
      });
  };

  render() {
    const { widgets, pending, selectedFolderToken, selectedFolderName } =
      this.state;

    let containsEnabledWidgets = false;
    let selectedWidgets = [];

    widgets.forEach((w) => {
      if (w.isSelected === true) {
        selectedWidgets.push(w.token);

        if (w.enabled === true) {
          containsEnabledWidgets = true;
        }
      }
    });

    const selectedWidgetsCount = selectedWidgets.length;

    if (pending) {
      return <div>Загрузка...</div>;
    }

    let newWidgetPath = "/widgets/new_widget_type";

    if (this.state.selectedFolderToken) {
      newWidgetPath = `${newWidgetPath}?folder=${this.state.selectedFolderToken}`;
    }

    return (
      <Container fluid className="p-0">
        {this.state.moveToFolderModalIsVisible && (
          <FolderSelector
            section="widgets"
            onClose={this.closeMoveToFolderModal}
            onComplete={(moveToFolderToken) => {
              this.closeMoveToFolderModal();
              this.moveToFolderSelectedWidgets(moveToFolderToken);
            }}
          />
        )}

        <Row className="mb-2 mb-xl-4">
          <Col>
            <Breadcrumbs />
            <h3>
              <MyWidgetsNavLink />

              {selectedFolderName && (
                <FontAwesomeIcon className="ml-2" icon={faAngleRight} />
              )}
            </h3>

            {selectedFolderName && (
              <div>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faFolder}
                  style={{ color: "#3a89ff" }}
                />
                {selectedFolderName}
              </div>
            )}
          </Col>

          <Col className="d-none d-sm-block">
            <Link to={newWidgetPath} className="float-right">
              <Button color="primary">
                <FontAwesomeIcon
                  icon={faPlus}
                  className="mr-3"
                  style={{ fontSize: "12px" }}
                />
                <Trans>dashboard.widgets.add_widget</Trans>
              </Button>
            </Link>
          </Col>
        </Row>

        {selectedFolderToken == null && (
          <Row>
            <Col lg="12">
              <Folders
                section="widgets"
                onUpdate={(folders) => this.setState({ folders: folders })}
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col lg="12">
            <div className="d-flex justify-content-between align-items-sm-start align-items-md-center flex-sm-row flex-column mb-3">
              <h4 className="flex-grow-1 mb-md-0 mb-sm-3">
                <Trans>dashboard.widgets.video_widgets</Trans>
                <Link to={newWidgetPath}>
                  <AddItemIcon />
                </Link>
              </h4>
              <div className="mb-3 mb-md-0">
                <FilterOption
                  title={this.props.t("dashboard.widget_status.status")}
                  options={[
                    {
                      title: this.props.t("dashboard.widget_status.any"),
                      value: null,
                    },
                    {
                      title: this.props.t("dashboard.widget_status.enabled"),
                      value: "enabled",
                    },
                    {
                      title: this.props.t("dashboard.widget_status.disabled"),
                      value: "disabled",
                    },
                  ]}
                  value={this.state.filterByStatus}
                  onChange={(value) =>
                    this.setState({ page: 1, filterByStatus: value }, () => {
                      this.fetchWidgets();
                    })
                  }
                />
              </div>
              <div
                className="text-md-right text-sm-left"
                style={{
                  width: "100px",
                  opacity: 0.5,
                  fontSize: "12px",
                }}
              >
                <Trans values={{ count: this.state.totalCount }}>
                  widgets.counter
                </Trans>
              </div>
            </div>

            <Card>
              <Table responsive>
                <thead>
                  <tr>
                    <th style={{ width: "40px", position: "relative" }}>
                      <div className="table-checkbox">
                        <CustomInput
                          id="toggle-select-all-widgets"
                          type="checkbox"
                          onClick={this.toggleSelectAllWidgets}
                        />
                      </div>
                    </th>
                    <th style={{ width: "140px" }}>
                      <Trans>widget.video</Trans>
                    </th>
                    <th>
                      <Trans>widget.name</Trans>
                    </th>
                    <th style={{ width: "150px", textAlign: "center" }}>
                      <Trans>widget.status</Trans>
                    </th>
                    <th style={{ width: "150px", textAlign: "center" }}>
                      <Trans>widget.preview</Trans>
                    </th>
                    <th
                      style={{
                        width: "190px",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Trans>widget.code</Trans>
                    </th>
                    <th style={{ width: "140px", textAlign: "right" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {widgets.map((widget) => (
                    <WidgetItem
                      key={widget.token}
                      {...widget}
                      onMoveToFolder={this.handleMoveWidgetToFolder}
                      onToggleSelected={this.handleToggleSelected}
                      onStatusSelect={(isEnabled) =>
                        this.handleStatusSelected(widget.token, isEnabled)
                      }
                    />
                  ))}
                </tbody>
              </Table>

              <InView
                as="div"
                rootMargin="100px"
                onChange={(inView) => {
                  if (inView === true) {
                    this.loadNextPage();
                  }
                }}
              ></InView>

              {this.state.fetching === true && (
                <CardBody>
                  <br />
                  <center>
                    <Spinner
                      color="info"
                      type="grow"
                      className="mr-2"
                      style={{ marginLeft: "10px" }}
                    />
                  </center>
                  <br />
                </CardBody>
              )}

              {this.state.fetching === false &&
                widgets &&
                widgets.length === 0 && (
                  <CardBody>
                    <Trans>dashboard.widgets.not_added_yet</Trans>
                  </CardBody>
                )}
            </Card>
          </Col>
        </Row>

        {selectedWidgetsCount > 0 && (
          <div className="checked-items-block">
            <div className="selected-items-count d-none d-md-block">
              <Trans>dashboard.widgets_actions.selected_widgets</Trans>: &nbsp;
              <b>{selectedWidgets.length}</b>
            </div>

            <div className="selected-items-actions">
              <div
                className="selected-items-action"
                onClick={this.openMoveToFolderModal}
              >
                <FontAwesomeIcon
                  icon={faFolderOpen}
                  style={{ fontSize: "14px" }}
                />
                <div
                  style={{ display: "inline-block", marginLeft: "10px" }}
                  className="d-none d-md-inline-block"
                >
                  <Trans>dashboard.widgets_actions.move</Trans>
                </div>
              </div>

              <div
                className="selected-items-action"
                onClick={this.duplicateSelectedWidgets}
              >
                <FontAwesomeIcon icon={faCopy} style={{ fontSize: "14px" }} />
                <div
                  style={{ display: "inline-block", marginLeft: "10px" }}
                  className="d-none d-md-inline-block"
                >
                  <Trans>dashboard.widgets_actions.duplicate</Trans>
                </div>
              </div>

              {containsEnabledWidgets === true ? (
                <div
                  className="selected-items-action"
                  onClick={this.disableSelectedWidgets}
                >
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    style={{ fontSize: "14px" }}
                  />
                  <div
                    style={{ display: "inline-block", marginLeft: "10px" }}
                    className="d-none d-md-inline-block"
                  >
                    <Trans>dashboard.widgets_actions.disable</Trans>
                  </div>
                </div>
              ) : (
                <div
                  className="selected-items-action"
                  onClick={this.enableSelectedWidgets}
                >
                  <FontAwesomeIcon icon={faEye} style={{ fontSize: "14px" }} />
                  <div
                    style={{ display: "inline-block", marginLeft: "10px" }}
                    className="d-none d-md-inline-block"
                  >
                    <Trans>dashboard.widgets_actions.enable</Trans>
                  </div>
                </div>
              )}

              <div
                className="selected-items-action"
                onClick={this.deleteSelectedWidgets}
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={{ fontSize: "14px" }}
                />
                <div
                  style={{ display: "inline-block", marginLeft: "10px" }}
                  className="d-none d-md-inline-block"
                >
                  <Trans>dashboard.widgets_actions.delete</Trans>
                </div>
              </div>
            </div>

            <div className="selected-items-cancel">
              <div
                className="selected-items-action"
                onClick={this.cancelWidgetsSelection}
              >
                <Trans>dashboard.widgets_actions.cancel</Trans>
              </div>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  identity: state.identity,
});

export default connect(mapStateToProps)(withTranslation()(WidgetsTable));
