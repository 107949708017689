import React from "react";
import { Link } from "react-router-dom";

import { Button } from "reactstrap";

const Page404 = () => (
  <div className="text-center">
    <h1 className="display-1 font-weight-bold" style={{ color: "white " }}>
      404
    </h1>
    <p className="h1" style={{ color: "white " }}>
      Страница не найдена
    </p>
    <p className="h2 font-weight-normal mt-3 mb-4" style={{ color: "white " }}>
      Страница не существует или была удалена
    </p>
    <Link to="/">
      <Button color="secondary" size="lg">
        Вернуться в личный кабинет
      </Button>
    </Link>
  </div>
);

export default Page404;
