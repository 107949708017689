import React from "react";
import axios from "axios";
import { GETREVIEW_API_HOST } from "../constants";
import { toastr } from "react-redux-toastr";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

const ProjectSettingsRetranscodeModalWindow = (props) => {
  const project = props.project;

  const retranscodeVideos = () => {
    axios
      .post(
        `${GETREVIEW_API_HOST}/api/projects/${project.token}/retranscode_videos`,
        {},
        {
          withCredentials: true,
        }
      )
      .then(() => {
        toastr.success(
          "Поздравляем!",
          "Видео были успешно отправлены на переподготовку"
        );

        props.onClose();
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error("", "Возникла ошибка при переподготовке видео");
        }
      });
  };

  return (
    <Modal
      isOpen={true}
      size="md"
      toggle={props.onClose}
      centered
      className="modal-popup"
    >
      <ModalHeader toggle={props.onClose}>
        <FontAwesomeIcon icon={faCopyright} />
        &nbsp;&nbsp;
        <Trans>project.retranscode_modal.header_text</Trans>
      </ModalHeader>
      <ModalBody>
        <p>
          <Trans>project.retranscode_modal.body_text</Trans>
        </p>

        <p>
          <Trans>project.retranscode_modal.videos_amount_text</Trans>
          {` `}
          <b>{props.videosCount}</b>
        </p>

        {props.videosCount < 10 ? (
          <div className="d-flex justify-content-end mt-5">
            <Button
              style={{ minWidth: "200px" }}
              color="primary"
              onClick={retranscodeVideos}
              className="mr-3"
            >
              <Trans>project.retranscode_modal.retranscode_button</Trans>
            </Button>

            <Button
              style={{ minWidth: "200px" }}
              color="success"
              onClick={props.onClose}
            >
              <Trans>project.retranscode_modal.nothing_to_do_button</Trans>
            </Button>
          </div>
        ) : (
          <div className="mt-3">
            <Trans>project.retranscode_modal.contact_support</Trans>

            <br />
            <br />

            <div className="d-flex justify-content-end">
              <Button
                style={{ minWidth: "200px" }}
                color="light"
                onClick={props.onClose}
              >
                <Trans>project.retranscode_modal.cancel_button</Trans>
              </Button>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ProjectSettingsRetranscodeModalWindow;
