import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCheck } from "@fortawesome/free-solid-svg-icons";

const growDown = keyframes`
  0% {
    opacity: 0;
    top: -10px;
  }

  100% {
    opacity: 1;
    top: 0px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 110px;
  height: 28px;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 3px 0;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.1s ease-out;
  background: ${(props) => (props.isEnabled ? "#e2f5e3" : "#dadada")};

  ${(props) =>
    props.isEnabled &&
    props.isSuspended && {
      width: "140px",
      background: "#f8dcd3",
    }}

  &:hover {
    background: ${(props) => (props.isEnabled ? "#a0eaa4" : "#9e9e9e")};

    ${(props) =>
      props.isSuspended && {
        background: "#f8dcd3",
        cursor: "not-allowed",
      }}
  }
`;

const Label = styled.div`
  position: relative;
  left: -5px;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 50%;
  right: 14px;
  margin-top: -6px;
  line-height: 0px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background: white;
  border-radius: 6px;
  box-shadow: 1px 2px 0.875rem 0 rgb(53, 64, 82, 0.1);
  animation: 0.2s ${growDown} ease-out;
  z-index: 100;
  overflow: hidden;
`;

const Option = styled.div`
  display: flex;
  justify-content: start;
  text-align: left;
  padding: 8px 16px 8px 12px;
  border-bottom: 1px solid #edeff0;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;

  &:hover {
    background: #000a3b;
    color: white;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const CheckLabel = styled.div`
  width: 20px;
`;

const OverlayOption = ({ chosen, label, onClick }) => {
  return (
    <Option onClick={onClick}>
      <CheckLabel>
        {chosen && <FontAwesomeIcon icon={faCheck} className="mr-2" />}
      </CheckLabel>
      {label}
    </Option>
  );
};

const Status = ({ isEnabled, onSelect }) => {
  const { t } = useTranslation();
  const { workspace } = useSelector((state) => state.identity);
  const isSuspended = workspace.status === "regular_exceeded";

  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef();

  const onClick = () => {
    if (isSuspended) {
      return false;
    }

    if (isOpen === false) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    const onDocumentClick = (event) => {
      if (
        isOpen &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", onDocumentClick);

    return () => {
      document.addEventListener("mousedown", onDocumentClick);
    };
  }, [isOpen]);

  let labelNode;

  if (workspace.status === "regular_exceeded" && isEnabled) {
    labelNode = t("dashboard.widget_status.suspended");
  } else {
    labelNode = isEnabled
      ? t("dashboard.widget_status.enabled")
      : t("dashboard.widget_status.disabled");
  }

  return (
    <Container
      ref={containerRef}
      isSuspended={isSuspended}
      isEnabled={isEnabled}
      onClick={onClick}
    >
      <Label>{labelNode}</Label>
      <Dropdown>
        <FontAwesomeIcon
          icon={faAngleDown}
          style={{ color: "rgba(0, 0, 0, 0.8)" }}
        />
      </Dropdown>

      {isOpen && (
        <Overlay>
          <OverlayOption
            onClick={() => {
              setIsOpen(false);
              onSelect(true);
            }}
            chosen={isEnabled}
            label={t("dashboard.widget_status.enabled")}
          />
          <OverlayOption
            onClick={() => {
              setIsOpen(false);
              onSelect(false);
            }}
            chosen={!isEnabled}
            label={t("dashboard.widget_status.disabled")}
          />
        </Overlay>
      )}
    </Container>
  );
};

export default Status;
