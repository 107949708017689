import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  CustomInput,
  Label,
} from "reactstrap";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";

const WorkspaceSettingsInviteMemberModalWindow = (props) => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("admin");

  const sendInvite = () => {
    axios
      .post(
        `${GETREVIEW_API_HOST}/api/workspace/members`,
        { workspace_member: { invite_email: email, role } },
        { withCredentials: true }
      )
      .then((json) => {
        props.onClose();
        toastr.success("", "Приглашение было успешно отправлено");
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error("", "Возникла ошибка при отправке приглашения");
        }
      });
  };

  return (
    <Modal
      isOpen={true}
      size="md"
      toggle={props.onClose}
      centered
      className="modal-popup"
    >
      <ModalHeader toggle={props.onClose}>
        <FontAwesomeIcon icon={faUserAlt} />
        &nbsp;&nbsp; Приглашение пользователя
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between mb-4 mt-3">
          <div className="mr-2" style={{ width: "70%" }}>
            <Label>Email пользователя</Label>
            <br />
            <Input
              type="text"
              id="workspace_member_email"
              name="workspace_member_email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div style={{ width: "30%" }}>
            <Label>Роль</Label>
            <br />
            <CustomInput
              type="select"
              id="workspace_member_role"
              name="workspace_member_role"
              value={role}
              onChange={(event) => setRole(event.target.value)}
            >
              <option value="admin">Администратор</option>
              {/* <option value="member">Пользователь</option> */}
            </CustomInput>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="light" className="mr-2 mb-2" onClick={props.onClose}>
          Вернуться назад
        </Button>

        <Button
          color="primary"
          disabled={/^\S+@\S+\.\S+$/.test(email) === false}
          onClick={sendInvite}
        >
          Отправить приглашение
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WorkspaceSettingsInviteMemberModalWindow;
