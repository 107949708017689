import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { GETREVIEW_API_HOST } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { IntercomAPI } from "react-intercom";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { Trans } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { loadIdentity } from "../../redux/actions/identityActions";
import Breadcrumbs from "../../components/Breadcrumbs";

import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  Container,
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Spinner,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faCheckCircle,
  faArrowRight,
  faEdit,
  faEnvelope,
  faRedo,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";

import widgetTypeImgBubble from "../../assets/img/pics/widget_type_bubble.png";
import widgetTypeImgCarousel from "../../assets/img/pics/widget_type_carousel.png";
import widgetTypeImgInline from "../../assets/img/pics/widget_type_inline.png";
import widgetTypeImgStories from "../../assets/img/pics/widget_type_stories.png";
import widgetTypeImgGrid from "../../assets/img/pics/widget_type_grid.png";

const WidgetTypeItemContainer = styled.div`
  display: block;
  position: relative;
  width: 380px;
  margin-left: 15px;
  margin-bottom: 30px;
  background: white;
  padding: 20px;
  cursor: pointer;
  border: 2px solid white;
  transition: border 0.3s ease-in-out;
  height: 160px;
  border-radius: 20px;
  box-shadow: 1px 2px 0.875rem 0 rgb(53, 64, 82, 0.1);

  &:hover {
    border-color: #3a89ff;
  }

  @media (max-width: 568px) {
    img {
      width: 80px;
    }
  }
`;

const UpgradeButtonContainer = styled.div`
  position: absolute;
  top: -12px;
  right: 18px;
  font-size: 11px;
  display: inline-block;
  padding: 0 11px 0 7px;
  height: 24px;
  line-height: 22px;
  border-radius: 15px;
  background-color: #31af5c;
  color: white;
  transition: background-color 0.15s ease-out, transform 0.15s ease-out;

  ${(props) =>
    props.upgradeRequired && {
      backgroundColor: "#773ae8",
    }}

  @media (max-width: 568px) {
    margin: 3px 0 10px 0;
  }

  &:hover {
    ${(props) =>
      props.upgradeRequired && {
        transform: "translateY(-2px)",
        backgroundColor: "#592aaf",
      }}
  }
`;

const UpgradeButtonIcon = styled.div`
  display: inline-block;
  font-size: 14px;
  margin-right: 5px;
  position: relative;
  top: 1px;
`;

const ConfirmationCodeContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 30px 0;
  min-height: 80px;
`;

const ConfirmationCodeNumber = styled.input`
  width: 80%;
  height: 80px;
  line-height: 80px;
  font-size: 18px;
  padding: 0 20px;
  border-radius: 15px;
  border: 1px solid #d1d1d1;
  color: #000a3b;

  &::placeholder {
    color: #000a3b;
    opacity: 0.5;
  }
`;

const ConfirmationActionButtonBlock = styled.div`
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 30px;
  color: #5b7dff;
  cursor: pointer;
  transition: color 0.2s ease-out;

  &:hover {
    color: black;
  }

  ${(props) =>
    props.muted && {
      color: "#848484",
    }}
`;

const ConfirmationActionButton = (props) => {
  return (
    <ConfirmationActionButtonBlock muted={props.muted} onClick={props.onClick}>
      <FontAwesomeIcon icon={props.icon} />
      &nbsp; {props.text}
    </ConfirmationActionButtonBlock>
  );
};

const ConfirmAccountModal = (props) => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.identity);
  const [isChecking, setIsChecking] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState("");
  const confirmationCodeRef = useRef();

  useEffect(() => {
    if (confirmationCode.length === 6) {
      checkConfirmationCode();
    }

    setErrorMessage("");
  }, [confirmationCode]);

  const checkConfirmationCode = () => {
    setIsChecking(true);

    axios
      .post(
        `${GETREVIEW_API_HOST}/api/account/check_confirmation_code`,
        {
          code: confirmationCode,
        },
        {
          withCredentials: true,
        }
      )
      .then(() => {
        setIsConfirmed(true);

        toastr.success(
          "Поздравляем!",
          "Вы успешно завершили процесс регистрации"
        );

        setTimeout(() => {
          dispatch(loadIdentity());
        }, 1000);
      })
      .catch(() => {
        setIsChecking(false);
        setErrorMessage("Неверный код подтверждения");
      });
  };

  const resendConfirmationCode = () => {
    setConfirmationCode("");
    setErrorMessage(null);

    axios
      .post(
        `${GETREVIEW_API_HOST}/api/account/resend_confirmation_code`,
        {
          code: confirmationCode,
        },
        {
          withCredentials: true,
        }
      )
      .then((json) => {
        toastr.success(
          "Поздравляем!",
          "Код подтверждения был повторно отправлен"
        );
      })
      .catch(() => {
        toastr.error("Ошибка!", "Возникла ошибка отправке кода");
      });
  };

  const signOut = () => {
    axios
      .delete(`${GETREVIEW_API_HOST}/accounts/sign_out`, {
        withCredentials: true,
      })
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <Modal
      id="email_confirmation_modal"
      onOpened={() => {
        if (confirmationCodeRef.current) {
          confirmationCodeRef.current.focus();
        }
      }}
      isOpen={true}
      size="md"
      centered
      className="modal-popup"
    >
      <ModalHeader>
        <FontAwesomeIcon icon={faEnvelope} />
        &nbsp;&nbsp;&nbsp; Подтверждение почты
      </ModalHeader>

      <ModalBody>
        Мы отправили код подтверждения на <b>{account.email}</b>.
        <br />
        Пожалуйста, введите код из письма, чтобы подтвердить вашу почту.
        <br />
        <br />
        {isConfirmed ? (
          <ConfirmationCodeContainer>
            <h4 className="mb-0 mr-2">Спасибо! Ваша почта подтверждена</h4>
            <Spinner color="info" size="sm" />
          </ConfirmationCodeContainer>
        ) : (
          <ConfirmationCodeContainer>
            <ConfirmationCodeNumber
              id="email_confirmation_code_number"
              ref={confirmationCodeRef}
              type="text"
              placeholder="Введите код из письма"
              maxLength="6"
              required
              autoFocus
              disabled={isChecking}
              value={confirmationCode}
              onChange={(event) => setConfirmationCode(event.target.value)}
            ></ConfirmationCodeNumber>

            {isChecking && (
              <div className="ml-3">
                <Spinner color="primary" size="sm" className="mr-2" />
              </div>
            )}
          </ConfirmationCodeContainer>
        )}
        {errorMessage && (
          <div
            id="email_confirmation_error_message"
            style={{
              height: "20px",
              marginBottom: "15px",
              fontSize: "11px",
              color: "red",
            }}
          >
            {errorMessage}
          </div>
        )}
        <ConfirmationActionButton
          icon={faRedo}
          text="Отправить код заново"
          onClick={resendConfirmationCode}
        />
        <ConfirmationActionButton
          muted
          icon={faSignOutAlt}
          text="Выйти из аккаунта"
          onClick={signOut}
        />
        <br />
        <br />
      </ModalBody>
    </Modal>
  );
};

const CompleteSignUpModal = (props) => {
  const { role, workspace } = useSelector((state) => state.identity);
  const [isSending, setIsSending] = useState(false);
  const [firstName, setFirstName] = useState(props.firstName);
  const [lastName, setLastName] = useState(props.lastName);
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");

  const submitForm = () => {
    setIsSending(true);

    const formData = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      company_name: companyName,
    };

    axios
      .put(`${GETREVIEW_API_HOST}/api/account/update_settings`, formData, {
        withCredentials: true,
      })
      .then(props.onSuccess)
      .catch((error) => {
        setIsSending(false);
        toastr.error("Ошибка!", "Возникла ошибка при завершении регистрации");
      });
  };

  return (
    <Modal isOpen={true} size="sm" centered className="modal-popup">
      <AvForm onValidSubmit={submitForm}>
        <ModalHeader>
          <FontAwesomeIcon icon={faEdit} />
          &nbsp;&nbsp;&nbsp;
          <Trans>dashboard.complete_sign_up.header</Trans>
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <div
              style={{ marginBottom: "3px", fontWeight: "600", opacity: 0.7 }}
            >
              <Trans>account.first_name</Trans>
            </div>

            <AvField
              required
              errorMessage="Поле обязательно для заполнения"
              type="text"
              name="first_name"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <div
              style={{ marginBottom: "3px", fontWeight: "600", opacity: 0.7 }}
            >
              <Trans>account.last_name</Trans>
            </div>

            <AvField
              type="text"
              name="last_name"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <div
              style={{ marginBottom: "3px", fontWeight: "600", opacity: 0.7 }}
            >
              <Trans>account.phone</Trans>
            </div>

            <AvField
              type="text"
              name="phone"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </FormGroup>

          {workspace.setup_done === false && role === "admin" && (
            <FormGroup>
              <div
                style={{ marginBottom: "3px", fontWeight: "600", opacity: 0.7 }}
              >
                <Trans>account.company_name</Trans>
              </div>

              <AvField
                required
                errorMessage="Поле обязательно для заполнения"
                type="text"
                name="company_name"
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
              />
            </FormGroup>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            id="complete_sign_up_button"
            disabled={isSending}
            color="primary"
            size="lg"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div style={{ marginLeft: "20px" }}>
              <Trans>dashboard.complete_sign_up.continue</Trans>
            </div>
            <div
              style={{
                marginLeft: "15px",
                width: "25px",
                height: "25px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isSending ? (
                <Spinner color="white" size="sm" className="mr-2" style={{}} />
              ) : (
                <FontAwesomeIcon icon={faArrowRight} />
              )}
            </div>
          </Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

const UpgradeButton = ({ requiredPlans }) => {
  const { workspace } = useSelector((state) => state.identity);
  const upgradeRequired = !requiredPlans.includes(workspace.plan.name);

  return (
    <UpgradeButtonContainer upgradeRequired={upgradeRequired}>
      <UpgradeButtonIcon>
        <FontAwesomeIcon
          icon={upgradeRequired ? faArrowCircleUp : faCheckCircle}
        />
      </UpgradeButtonIcon>
      {upgradeRequired ? "Обновите тариф" : "Доступен"}
    </UpgradeButtonContainer>
  );
};

const WidgetTypeItem = (props) => {
  const history = useHistory();

  const openNewWidgetPage = () => {
    let pageURL = `/widgets/new_widget?type=${props.widgetType}&folder=${props.folderToken}`;

    history.push(pageURL);
  };

  return (
    <WidgetTypeItemContainer
      id={`new_widget_type_${props.widgetType}`}
      onClick={openNewWidgetPage}
    >
      {props.children}
    </WidgetTypeItemContainer>
  );
};

const NewWidgetType = (props) => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.identity);

  const onCompleteSignUp = () => {
    dispatch(loadIdentity());
    IntercomAPI("trackEvent", "sign-up-completed");
  };

  const project = useSelector((state) => state.projects.current);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const folderToken = queryParams.get("folder") || "root";

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailConfirmedParam = queryParams.get("emailConfirmed");

    if (emailConfirmedParam === "1") {
      setTimeout(() => {
        toastr.success("Поздравляем!", "Ваша почта была успешно подтверждена");
      }, 1000);
    }
  }, []);

  return (
    <Container fluid className="p-0">
      {account.sign_up_done === false && (
        <CompleteSignUpModal
          onSuccess={onCompleteSignUp}
          firstName={account.first_name}
          lastName={account.last_name}
        />
      )}

      {account.sign_up_done === true && account.confirmed === false && (
        <ConfirmAccountModal />
      )}

      <Row className="mb-3">
        <Col>
          <Breadcrumbs />
          <h3>
            <Trans>dashboard.new_widget.new_widget</Trans>
          </h3>
        </Col>
      </Row>

      <Row>
        <Col>
          <h4>
            <Trans>dashboard.new_widget.choose_widget_type</Trans>
          </h4>
        </Col>
      </Row>

      <Row>
        <WidgetTypeItem folderToken={folderToken} widgetType="VideoBubble">
          <UpgradeButton
            requiredPlans={[
              "trial",
              "light",
              "standard",
              "business",
              "enterprise",
            ]}
          />

          <div className="d-flex mt-2 mb-3 ml-2">
            <div className="mr-4">
              <img src={widgetTypeImgBubble} width="120" alt="Bubble" />
            </div>
            <div>
              <h3 className="mt-1">Bubble</h3>
              <Trans>dashboard.new_widget.bubble</Trans>
            </div>
          </div>
        </WidgetTypeItem>

        <WidgetTypeItem folderToken={folderToken} widgetType="VideoInline">
          <UpgradeButton
            requiredPlans={["standard", "business", "enterprise"]}
          />

          <div className="d-flex mt-2 mb-3 ml-2">
            <div className="mr-4">
              <img src={widgetTypeImgInline} width="120" alt="Inline" />
            </div>
            <div>
              <h3 className="mt-1">Inline</h3>
              <Trans>dashboard.new_widget.inline</Trans>
            </div>
          </div>
        </WidgetTypeItem>

        <WidgetTypeItem folderToken={folderToken} widgetType="VideoCarousel">
          <UpgradeButton
            requiredPlans={["standard", "business", "enterprise"]}
          />

          <div className="d-flex mt-2 mb-3 ml-2">
            <div className="mr-4">
              <img src={widgetTypeImgCarousel} width="120" alt="Carousel" />
            </div>
            <div>
              <h3 className="mt-1">Carousel</h3>
              <Trans>dashboard.new_widget.carousel</Trans>
            </div>
          </div>
        </WidgetTypeItem>

        <WidgetTypeItem folderToken={folderToken} widgetType="VideoStories">
          <UpgradeButton requiredPlans={["business", "enterprise"]} />

          <div className="d-flex mt-2 mb-3 ml-2">
            <div className="mr-4">
              <img src={widgetTypeImgStories} width="120" alt="Stories" />
            </div>
            <div>
              <h3 className="mt-1">Stories</h3>
              <Trans>dashboard.new_widget.stories</Trans>
            </div>
          </div>
        </WidgetTypeItem>

        <WidgetTypeItem folderToken={folderToken} widgetType="VideoGrid">
          <UpgradeButton requiredPlans={["business", "enterprise"]} />

          <div className="d-flex mt-2 mb-3 ml-2">
            <div className="mr-4">
              <img src={widgetTypeImgGrid} width="120" alt="Grid" />
            </div>
            <div>
              <h3 className="mt-1">Grid</h3>
              <Trans>dashboard.new_widget.grid</Trans>
            </div>
          </div>
        </WidgetTypeItem>
      </Row>
    </Container>
  );
};

export default NewWidgetType;
