import React from "react";
import SettingsBlock from "./SettingsBlock";
import { useTranslation, Trans } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faPlusCircle,
  faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";

import { Input, CustomInput } from "reactstrap";

const DisplayPagesRadio = ({ scope, value, label, onChange }) => (
  <CustomInput
    checked={scope === value}
    id={`display_pages_${value}`}
    type="radio"
    name="display_pages"
    value={value}
    label={label}
    onChange={onChange}
    className="mr-3"
  />
);

const DisplayPagesList = ({
  values,
  onAdd,
  onChangeType,
  onChangeURL,
  onDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      {values.map((value, index) => (
        <div key={index} style={{ display: "flex", marginTop: "15px" }}>
          <CustomInput
            type="select"
            id={`display_pages_list_type_${index}`}
            name="display_pages_list_type"
            style={{ width: "330px", marginRight: "10px" }}
            onChange={(event) => onChangeType(event.target.value, index)}
            value={value.type}
          >
            <option value="equals_without_params">
              {t("dashboard.widget_form.display_pages.equals_without_params")}
            </option>
            <option value="equals_with_params">
              {t("dashboard.widget_form.display_pages.equals_with_params")}
            </option>
            <option value="contains_text">
              {t("dashboard.widget_form.display_pages.contains_text")}
            </option>
          </CustomInput>

          <Input
            type="text"
            value={value.url}
            name="value"
            placeholder="https://example.com/page1.html"
            onChange={(event) => onChangeURL(event.target.value, index)}
            style={{ width: "500px" }}
          />

          <div>
            <FontAwesomeIcon
              onClick={() => onDelete(index)}
              icon={faTimesCircle}
              style={{
                fontSize: "14px",
                color: "#d9534f",
                margin: "7px 0 0 10px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      ))}

      <div
        style={{
          fontSize: "11px",
          margin: "15px 3px",
          cursor: "pointer",
          display: "inline-block",
        }}
        onClick={onAdd}
      >
        <Trans>dashboard.widget_form.display_pages.add_page</Trans>
        <FontAwesomeIcon
          icon={faPlusCircle}
          style={{
            fontSize: "14px",
            marginLeft: "7px",
            position: "relative",
            top: "2px",
            color: "#3A89FF",
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
};

const DisplayPages = ({
  scope,
  values,
  onAdd,
  onChangeType,
  onChangeURL,
  onDelete,
  onScopeChange,
}) => {
  const { t } = useTranslation();

  const onChange = (event) => {
    onScopeChange(event.target.value);
  };

  return (
    <SettingsBlock
      title={t("dashboard.widget_form.display_pages.title")}
      appliedSettingsText={() => {
        if (scope === "all") {
          return "На всех страницах";
        } else if (scope === "include") {
          return "На выбранных страницах";
        } else {
          return "На всех страница, кроме указанных";
        }
      }}
      description={t("dashboard.widget_form.display_pages.desc")}
      icon={faWindowRestore}
    >
      <div className="d-flex">
        <DisplayPagesRadio
          value="all"
          label={t("dashboard.widget_form.display_pages.all")}
          scope={scope}
          onChange={onChange}
        />
        <DisplayPagesRadio
          value="include"
          label={t("dashboard.widget_form.display_pages.include")}
          scope={scope}
          onChange={onChange}
        />
        <DisplayPagesRadio
          value="exclude"
          label={t("dashboard.widget_form.display_pages.exclude")}
          scope={scope}
          onChange={onChange}
        />
      </div>

      {scope === "all" && (
        <p style={{ opacity: 0.5, marginTop: "10px" }}>
          <Trans>dashboard.widget_form.display_pages.all_desc</Trans>
        </p>
      )}

      {scope !== "all" && (
        <DisplayPagesList
          values={values}
          onAdd={onAdd}
          onChangeType={onChangeType}
          onChangeURL={onChangeURL}
          onDelete={onDelete}
        />
      )}

      <a
        href="https://getreview.io/knowledgebase/display-settings"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Trans>dashboard.widget_form.help_link_title</Trans>
      </a>
    </SettingsBlock>
  );
};

export default DisplayPages;
