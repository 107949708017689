const initialState = {
  list: [],
  current: {},
};

export default function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case "PROJECTS_SELECT_PROJECT":
      return {
        ...state,
        current: action.project || {},
      };
    case "PROJECTS_UPDATE_PROJECT":
      const projects = state.list;
      const projectIndex = projects.findIndex(
        (p) => p.token === action.project.token
      );
      projects[projectIndex] = action.project;

      let currentProject = state.current;

      if (currentProject.token === action.project.token) {
        currentProject = action.project;
      }

      return {
        ...state,
        list: projects,
        current: currentProject,
      };
    case "PROJECTS_LOAD_LIST":
      return {
        ...state,
        list: action.projects,
      };
    default:
      return state;
  }
}
