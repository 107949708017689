import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as toastr } from "react-redux-toastr";

import sidebar from "./sidebarReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";
import videos from "./videosReducer";
import account from "./accountReducer";
import identity from "./identityReducer";
import projects from "./projectsReducer";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    sidebar,
    layout,
    theme,
    toastr,
    videos,
    account,
    identity,
    projects,
  });

export default createRootReducer;
