import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React, { forwardRef } from "react";
import styled from "styled-components";
import { useDrop } from "react-dnd";

const Container = styled.div`
  border: 1px solid #cccccc;
  border-radius: 10px;
  overflow: hidden;
  padding: 8px 10px;
  font-size: 12px;
  background: #fafafa;
  display: inline-block;
  margin: 0 5px 5px 0;
  width: 160px;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s ease-out, box-shadow 0.2s ease-out;
  user-select: none;

  &.active {
    opacity: 0.3;
  }

  &.draggable {
    transform: scale(1.03);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }

  &:hover {
    border-color: #333333;
  }
`;

const Name = styled.div`
  height: 30px;
  line-height: 14px;
  letter-spacing: -0.3px;
  text-decoration: none;
  overflow: hidden;
  user-select: none;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Price = styled.div`
  opacity: 0.5;
  height: 15px;
`;

const DestroyButton = styled.div`
  position: absolute;
  top: 1px;
  right: 4px;
  z-index: 10;
  color: #d1d1d1;
  transition: color 0.2s ease-out;
  font-size: 14px;

  &:hover {
    color: red;
  }
`;

export default ({
  nodeRef = null,
  instance,
  instanceIndex,
  style,
  attributes,
  listeners,
  isActive = false,
  isDraggable = false,
  onClick,
  onDelete,
}) => {
  const classNames = classnames({
    active: isActive,
    draggable: isDraggable,
  });

  return (
    <Container
      className={classNames}
      ref={nodeRef}
      style={style}
      onClick={onClick}
      {...attributes}
      {...listeners}
    >
      <Name>{instance.name}</Name>

      <Price>
        {instance.price && instance.price !== ""
          ? `${instance.price} ₽`
          : "Без стоимости"}
      </Price>

      <DestroyButton
        onClick={(event) => {
          event.stopPropagation();
          onDelete(instanceIndex);
        }}
      >
        <FontAwesomeIcon icon={faTimesCircle} />
      </DestroyButton>
    </Container>
  );
};
