import React from "react";
import { Col, Container, Row } from "reactstrap";
import Main from "../components/Main";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import logotype from "../assets/img/logo/logotype_white.svg";

const Auth = ({ children }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LeNMPwoAAAAAFQN8qzFDtjYuXOEsuetmhC-gNcj">
    <Main className="d-flex w-100 justify-content-center main-auth-container">
      <Container className="d-flex flex-column">
        <Row className="h-100">
          <Col sm="10" lg="5" className="mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <a
                href={`https://${process.env.REACT_APP_GETREVIEW_WEBSITE}`}
                target="_blank"
              >
                <img src={logotype} alt="GetReview" className="auth-logo" />
              </a>
              {children}
            </div>
          </Col>
        </Row>
      </Container>
      <br />
    </Main>
  </GoogleReCaptchaProvider>
);

export default Auth;
