import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faArrowRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

const CheckMark = () => (
  <FontAwesomeIcon
    icon={faCheck}
    style={{ color: "#3BA336", marginLeft: "20px", marginRight: "10px" }}
  />
);

const UpgradeRequiredModalWindow = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { workspace } = useSelector((state) => state.identity);

  let isOpen = false;

  if (props.requiredPlans.includes(workspace.plan.name)) {
    if (
      props.requireAnnualStandardPlan != null &&
      workspace.plan.name === "standard" &&
      workspace.plan_period_months < 12
    ) {
      isOpen = true;
    }
  } else {
    isOpen = true;
  }

  const plansOptions = {
    standard: [
      t("plans.names.standard") +
        (props.requireAnnualStandardPlan
          ? t("upgrade_modal.annual_plan_required")
          : ""),
      "#ff5975",
    ],
    business: [t("plans.names.business"), "#59adff"],
    enterprise: [t("plans.names.enterprise"), "#595fff"],
  };

  const onCloseButtonClick = () => {
    if (props.redirectBackPath != null) {
      history.push(props.redirectBackPath);
    } else if (typeof props.onClose === "function") {
      props.onClose();
    }
  };

  return (
    <Modal
      zIndex="20000"
      isOpen={isOpen}
      size="md"
      centered
      className="modal-popup"
    >
      <ModalHeader>
        <FontAwesomeIcon icon={faArrowCircleUp} style={{ color: "#773ae8" }} />
        &nbsp;&nbsp; <Trans>upgrade_modal.header</Trans>
      </ModalHeader>
      <ModalBody>
        <h4 className="mb-4">
          <Trans>upgrade_modal.feature</Trans> {props.featuresDesc}{" "}
          <Trans>upgrade_modal.available_only_in_plans</Trans>{" "}
          {props.requiredPlans
            .map((plan) => (
              <b key={plan} style={{ color: plansOptions[plan][1] }}>
                {plansOptions[plan][0]}
              </b>
            ))
            .reduce((prev, curr) => [prev, ", ", curr])}
        </h4>

        {props.featuresList && (
          <ul className="list-unstyled mb-3">
            {props.featuresList.map((feature, index) => (
              <li key={index} className="mb-2">
                <CheckMark />
                {feature}
              </li>
            ))}
          </ul>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex w-100 justify-content-end flex-sm-row flex-column mt-2">
          <Button
            color="light"
            className="mr-2 mb-2"
            onClick={onCloseButtonClick}
          >
            <Trans>upgrade_modal.cancel_button</Trans>
          </Button>

          <Link to="/account/billing/plans">
            <Button
              color="primary"
              className="mb-2 custom-upgrade-button"
              style={{ position: "relative", width: "240px" }}
            >
              <Trans>upgrade_modal.choose_plan</Trans>
              &nbsp;&nbsp;&nbsp;
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </Link>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default UpgradeRequiredModalWindow;
