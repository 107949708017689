import { toastr } from "react-redux-toastr";
import axios from "axios";
import { GETREVIEW_API_HOST } from "../../constants";
import { createPayment, savePaymentError } from "./PaymentsGateway";

const InvoiceWidget = (plan, period, callback) => {
  createPayment(
    plan,
    period,
    "ip_direct",
    (
      paymentId,
      paymentAmount,
      paymentPlan,
      paymentPeriod,
      workspaceId,
      invoicePdfUrl
    ) => {
      if (invoicePdfUrl == null || invoicePdfUrl === "") {
        savePaymentError(paymentId, "NULL OR EMPTY INVOICE PDF URL");
        toastr.warning("", "Произошла ошибка при получении счета");
      } else {
        callback(invoicePdfUrl);
      }
    }
  );
};

export default InvoiceWidget;
