import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";

const DownloadInvoiceModalWindow = (props) => {
  return (
    <Modal
      isOpen={true}
      size="md"
      toggle={props.onClose}
      centered
      className="modal-popup"
    >
      <ModalHeader toggle={props.onClose}>
        <FontAwesomeIcon icon={faFileInvoice} style={{ color: "#3b89ff" }} />
        &nbsp;&nbsp; Создан счет на оплату
      </ModalHeader>
      <ModalBody>
        <Button
          color="primary"
          style={{
            minWidth: "300px",
            position: "relative",
          }}
          onClick={props.onClick}
        >
          Скачать счет в PDF
          <FontAwesomeIcon icon={faCloudDownloadAlt} className="ml-3" />
        </Button>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default DownloadInvoiceModalWindow;
