import * as types from "../constants";

const initialState = {
  isAuthenticated: null,
  current: null,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.LOAD_ACCOUNT_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        current: actions.payload,
        workspace: actions.workspace,
      };
    case types.LOAD_ACCOUNT_ERROR:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
