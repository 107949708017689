import async from "../components/Async";

import {
  Bell as Bellicon,
  BookOpen as BookOpenIcon,
  Calendar as CalendarIcon,
  CheckSquare as CheckSquareIcon,
  Grid as GridIcon,
  Heart as HeartIcon,
  Layout as LayoutIcon,
  List as ListIcon,
  MapPin as MapPinIcon,
  Monitor as MonitorIcon,
  PieChart as PieChartIcon,
  Sliders as SlidersIcon,
  Users as UsersIcon,
  Play as PlayIcon,
  Video as VideoIcon,
  Briefcase as BriefcaseIcon,
  BarChart as BarChartIcon,
  AlignLeft as AlignLeftIcon,
  User as UserIcon,
  Mail as MailIcon,
  GitPullRequest as GitPullRequestIcon,
  Crosshair as CrosshairIcon,
} from "react-feather";

import {
  faHeart,
  faChartLine,
  faFilm,
  faIdBadge,
  faAddressCard,
  faLayerGroup,
  faDollarSign,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import NewPassword from "../pages/auth/NewPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Misc
import Blank from "../pages/misc/Blank";

// Workspace
import WorkspaceSettings from "../pages/workspace/Settings";

// Account
import AccountSettings from "../pages/account/Settings";
import Pricing from "../pages/pages/Pricing";
import PricingBlackFriday from "../pages/pages/PricingBlackFriday";
import BillingInvoices from "../pages/billing/Invoices";
import ChangePlan from "../pages/billing/ChangePlan";

// Projects
import Project from "../pages/projects/Project";

// Analytics
import Analytics from "../pages/dashboard/Analytics";

// Reviews
import ReviewsIndex from "../pages/reviews/Index";
import ReviewsCampaignsNew from "../pages/reviews/campaigns/New";
import ReviewsCampaignsEdit from "../pages/reviews/campaigns/Edit";

// Videos
import VideosList from "../pages/videos/All";

// Widgets
import WidgetsAll from "../pages/widgets/All";
import WidgetsNew from "../pages/widgets/New";
import NewWidgetType from "../pages/widgets/NewWidgetType";
import WidgetsEdit from "../pages/widgets/Edit";
import WidgetEditor from "../components/WidgetEditor";

// Video Stories
import NewVideoStories from "../pages/widgets/widget_form/NewVideoStories";

// Leads
import LeadsInbox from "../pages/leads/Inbox";

// Tracking
import Facebook from "../pages/tracking/Facebook";
import Yandex from "../pages/tracking/Yandex";
import Google from "../pages/tracking/Google";

const workspaceRoutes = {
  path: "/",
  name: "Организация",
  icon: LayoutIcon,
  component: Analytics,
  hidden: true,
  children: [
    {
      path: "/workspace/settings",
      name: "Настройки компании",
      component: WorkspaceSettings,
    },
  ],
};

const accountRoutes = {
  path: "/",
  name: "Аккаунт",
  icon: LayoutIcon,
  component: Analytics,
  hidden: true,
  children: [
    // {
    //   path: "/account/billing/plans",
    //   name: "Тарифные планы",
    //   component: Pricing
    // },
    {
      path: "/account/billing/invoices",
      name: "История платежей",
      component: BillingInvoices,
    },
    {
      path: "/account/billing/change_plan",
      name: "Смена тарифного плана",
      component: ChangePlan,
    },
    {
      path: "/account/settings",
      name: "Настройки аккаунта",
      component: AccountSettings,
    },
  ],
};

const projectsRoutes = {
  path: "/settings/project",
  name: "Настройки проекта",
  component: Project,
  hidden: true,
  children: null,
};

const welcomeRoutes = {
  path: "/",
  name: "sidebar.dashboard",
  icon: faChartLine,
  component: Analytics,
  children: null,
};

const widgetRoutes = {
  path: "/widgets/all",
  name: "sidebar.video_widgets",
  icon: faIdBadge,
  component: WidgetsAll,
  header: "sidebar.content",
  childrenHidden: true,
  children: [
    {
      path: "/widgets/all",
      name: "sidebar.my_widgets",
      component: WidgetsAll,
      hidden: true,
    },
    {
      path: "/widgets/folder/:folder_token",
      name: "sidebar.my_widgets",
      component: WidgetsAll,
      hidden: true,
    },
    {
      path: "/widgets/new",
      name: "sidebar.add_widget",
      component: WidgetsNew,
      hidden: true,
    },
    {
      path: "/widgets/new_widget",
      name: "sidebar.add_widget",
      component: NewVideoStories,
      hidden: true,
    },
    {
      path: "/widgets/new_widget_type",
      name: "sidebar.add_widget",
      component: NewWidgetType,
      hidden: true,
    },
    {
      path: "/widgets/:widget_id/edit",
      name: "Редактировать виджет",
      component: WidgetsEdit,
      hidden: true,
    },
    {
      path: "/widgets/:widget_id/editor",
      name: "Редактор виджета",
      component: WidgetEditor,
      hidden: true,
    },
  ],
};

const reviewsRoutes = {
  path: "/reviews",
  name: "sidebar.reviews.title",
  icon: faStar,
  component: ReviewsIndex,
  childrenHidden: true,
  children: [
    {
      path: "/reviews",
      name: "sidebar.reviews.title",
      component: ReviewsIndex,
      hidden: true,
    },
    {
      path: "/reviews/campaigns/new",
      name: "sidebar.reviews.new_campaign",
      component: ReviewsCampaignsNew,
      hidden: true,
    },
    {
      path: "/reviews/campaigns/:campaignToken/edit",
      name: "sidebar.reviews.edit_campaign",
      component: ReviewsCampaignsEdit,
      hidden: true,
    },
  ],
};

const videosRoutes = {
  path: "/videos",
  name: "sidebar.all_videos",
  icon: faFilm,
  component: VideosList,
  childrenHidden: true,
  children: [
    {
      path: "/videos",
      name: "sidebar.all_videos",
      component: VideosList,
      hidden: true,
    },
    {
      path: "/videos/folder/:folderToken",
      name: "sidebar.all_videos",
      component: VideosList,
      hidden: true,
    },
  ],
};

const leadsRoutes = {
  path: "/leads",
  name: "sidebar.leads",
  icon: faAddressCard,
  component: LeadsInbox,
  hidden: true,
  children: null,
};

const trackingRoutes = {
  path: "/tracking",
  name: "sidebar.integrations",
  icon: faLayerGroup,
  component: LeadsInbox,
  header: "sidebar.marketing",
  children: [
    {
      path: "/tracking/facebook",
      name: "sidebar.integrations_list.fb",
      component: Facebook,
    },
    {
      path: "/tracking/google",
      name: "sidebar.integrations_list.ga",
      component: Google,
    },
    {
      path: "/tracking/yandex",
      name: "sidebar.integrations_list.ym",
      component: Yandex,
    },
  ],
};

const billingRoutes = {
  path: "/account/billing/plans",
  name: "sidebar.plans",
  icon: faDollarSign,
  component: Pricing,
  header: "sidebar.billing",
  children: null,
};

const billingBlackFridayRoutes = {
  path: "/account/billing/plans_new_year_2021",
  name: "sidebar.plans",
  icon: faDollarSign,
  component: PricingBlackFriday,
  header: "sidebar.billing",
  children: null,
  hidden: true,
};

const abtestsRoutes = {
  path: "/abtests",
  name: "A/B тесты",
  //icon: GitPullRequestIcon,
  component: LeadsInbox,
  children: null,
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  //icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    // {
    //   path: "/auth/sign-in",
    //   name: "Sign In",
    //   component: SignIn
    // },
    {
      path: "/account/sign_up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/account/reset_password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/account/new_password",
      name: "New Password",
      component: NewPassword,
    },
    // {
    //   path: "/auth/404",
    //   name: "404 Page",
    //   component: Page404
    // },
    // {
    //   path: "/auth/500",
    //   name: "500 Page",
    //   component: Page500
    // }
  ],
};

// Dashboard specific routes
export const dashboard = [
  workspaceRoutes,
  accountRoutes,
  projectsRoutes,
  welcomeRoutes,
  widgetRoutes,
  reviewsRoutes,
  videosRoutes,
  leadsRoutes,
  trackingRoutes,
  billingRoutes,
  billingBlackFridayRoutes,
  //abtestsRoutes
];

// Auth specific routes
export const auth = [authRoutes];

// All routes
export default [
  workspaceRoutes,
  accountRoutes,
  projectsRoutes,
  welcomeRoutes,
  widgetRoutes,
  reviewsRoutes,
  videosRoutes,
  leadsRoutes,
  trackingRoutes,
  billingRoutes,
  billingBlackFridayRoutes,
  //abtestsRoutes
];
