import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import localeRU from "./locales/ru.json";
import localeEN from "./locales/en.json";

function initLangSupport(lang) {
  i18n.use(initReactI18next).init({
    resources: {
      ru: localeRU,
      en: localeEN,
    },
    lng: lang,
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

  // if (lang === "ru") {
  //   document.title = "Личный кабинет GetReview";
  // } else {
  //   document.title = "GetReview Dashboard";
  // }

  return i18n;
}

export default initLangSupport;
