import React, { useState } from "react";
import { GETREVIEW_API_HOST } from "../../../constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDotCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

const CopyCodeForm = ({ codeText }) => {
  const [codeCopied, setCodeCopied] = useState(false);

  return (
    <div className="input-group mb-3 mt-2">
      <Input
        type="text"
        name="input"
        disabled
        value={codeText}
        onChange={() => {}}
        style={{
          height: "41px",
          fontFamily: "Courier New",
          fontSize: "13px",
          color: "#111111",
          resize: "none",
          whiteSpace: "nowrap",
        }}
      />
      <div className="input-group-append">
        <CopyToClipboard text={codeText} onCopy={() => setCodeCopied(true)}>
          <Button color={codeCopied ? "success" : "primary"} onClick={() => {}}>
            <FontAwesomeIcon
              icon={faCopy}
              style={{ fontSize: "13px", marginRight: "10px" }}
            />
            {codeCopied ? `Скопировано` : `Копировать`}
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export const CopyCodeContent = ({
  widgetType,
  widgetToken,
  siteToken,
  displayPages,
  displayPagesList,
  displayPositionSelector,
  onDisplayPositionSelectorChange,
}) => {
  const { project } = useSelector((state) => state.identity);

  const siteCode = `<script src="${GETREVIEW_API_HOST}/tags/${siteToken}/sdk.js" async></script>`;
  const widgetCode = `<div class="getreview-widget" data-widget-id="${widgetToken}"></div>`;

  const displayPagesName = {
    all: "Показывать на всех страницах сайта",
    include: "Показывать только на выбранных страницах",
    exclude: "Показывать везде, кроме указанных страниц",
  }[displayPages];

  const displayPagesType = (pagesType) => {
    return {
      equals_without_params: "Адрес страницы (параметры игнорируются)",
      equals_with_params: "Адрес страницы (параметры учитываются)",
      contains_text: "Адрес страницы содержит строку",
    }[pagesType];
  };

  const onDisplayPositionSelectorChangeHandler = (value) => {
    if (onDisplayPositionSelectorChange != null) {
      onDisplayPositionSelectorChange(value);
    }
  };

  if (widgetToken == null) {
    return <div>Код виджета будет доступен после сохранения настроек</div>;
  }

  return (
    <div style={{ maxWidth: "900px" }}>
      <h4>Шаг 1. Код инициализации</h4>
      <p style={{ opacity: 0.7 }}>
        Вставьте код инициализации в блок HEAD на все страницы вашего сайта.
        Если код инициализации уже добавлен, то его не нужно устанавливать
        второй раз. Данный код является единым для всех виджетов в рамках
        проекта <b>{project.name}</b>.
      </p>
      <CopyCodeForm codeText={siteCode} />
      {widgetType !== "VideoBubble" && widgetType !== null ? (
        <div>
          <br />
          <h4>Шаг 2. Место для отображения виджета</h4>

          <div className="d-flex mb-2">
            <FormGroup check className="mb-2 mr-3">
              <Label check>
                <Input
                  type="radio"
                  name="displayPositionSelector"
                  checked={displayPositionSelector === null}
                  onChange={() => onDisplayPositionSelectorChangeHandler(null)}
                  disabled={onDisplayPositionSelectorChange == null}
                />{" "}
                Через вставку кода{" "}
                <span className="text-muted">(рекомендовано)</span>
              </Label>
            </FormGroup>
            <FormGroup check className="mb-2">
              <Label check>
                <Input
                  type="radio"
                  name="displayPositionSelector"
                  checked={displayPositionSelector != null}
                  onChange={() => onDisplayPositionSelectorChangeHandler("")}
                  disabled={onDisplayPositionSelectorChange == null}
                />
                Использовать CSS-селектор
              </Label>
            </FormGroup>
          </div>

          {displayPositionSelector === null ? (
            <div>
              <p style={{ opacity: 0.7 }}>
                Вставьте следующий код в то место на странице, где должен
                отображаться виджет:
              </p>
              <CopyCodeForm codeText={widgetCode} />
            </div>
          ) : (
            <div>
              <p style={{ opacity: 0.7 }}>
                Укажите CSS-селектор блока, в котором будет отображаться виджет:
              </p>

              <Input
                type="text"
                value={displayPositionSelector}
                name="value"
                placeholder="#selector_example"
                onChange={(event) =>
                  onDisplayPositionSelectorChangeHandler(event.target.value)
                }
                style={{ width: "500px" }}
                disabled={onDisplayPositionSelectorChange == null}
              />

              <br />
            </div>
          )}
        </div>
      ) : (
        <div>
          <br />
          <h4>Шаг 2. Проверка настроек отображения</h4>

          <p style={{ opacity: 0.7 }}>
            После вставки кода инициализации данный Bubble-виджет сразу же
            начнет отображаться в соответствии с условиями показа:
          </p>

          <p>
            <FontAwesomeIcon icon={faDotCircle} className="mr-2" />
            {displayPagesName}
          </p>

          <div>
            {displayPagesList.map((value, index) => (
              <div className="d-flex mb-2" key={index}>
                <Input
                  disabled
                  type="text"
                  value={displayPagesType(value.type)}
                  name="display_pages_value_type"
                  className="mr-2"
                  style={{
                    width: "400px",
                    fontSize: "11px",
                    fontWeight: "600",
                    height: "28px",
                  }}
                />

                <Input
                  disabled
                  type="text"
                  value={value.url}
                  name="display_pages_value_url"
                  style={{ fontSize: "11px", height: "28px" }}
                />
              </div>
            ))}
          </div>

          <div className="mt-3">
            <Link to={`/widgets/${widgetToken}/edit?tab=behaviour`}>
              <FontAwesomeIcon
                icon={faPen}
                style={{ fontSize: "12px" }}
                className="mr-2"
              />
              Изменить настройки
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

const CopyCodeModal = ({
  siteToken,
  widgetToken,
  widgetType,
  displayPages,
  displayPagesList,
  displayPositionSelector,
  onClose,
}) => {
  return (
    <Modal isOpen={true} toggle={onClose} size="lg" className="modal-popup">
      <ModalHeader toggle={onClose}>Установка виджета</ModalHeader>
      <ModalBody>
        <CopyCodeContent
          widgetType={widgetType}
          widgetToken={widgetToken}
          siteToken={siteToken}
          displayPages={displayPages}
          displayPagesList={displayPagesList}
          displayPositionSelector={displayPositionSelector}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={onClose}>
          Готово
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CopyCodeModal;
