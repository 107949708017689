import axios from "axios";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../constants";
import { loadIdentity } from "../redux/actions/identityActions";
import {
  Button,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  faArrowRight,
  faPlusCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EmailFieldBlock = ({ index, value, onChange, onDelete }) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="w-100">
        <AvField
          bsSize="md"
          type="text"
          style={{ marginBottom: 0 }}
          name={`billing_accountant_email_${index}`}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      </div>

      <FontAwesomeIcon
        icon={faTrashAlt}
        style={{
          fontSize: "15px",
          marginLeft: "10px",
          position: "relative",
          top: "10px",
          color: "red",
          cursor: "pointer",
        }}
        onClick={onDelete}
      />
    </div>
  );
};

const WorkspaceBillingSettingsForm = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { workspace } = useSelector((state) => state.identity);

  const [billingCompanyName, setBillingCompanyName] = useState(
    workspace.billing_company_name
  );
  const [billingCompanyInn, setBillingCompanyInn] = useState(
    workspace.billing_company_inn
  );
  const [billingCompanyKpp, setBillingCompanyKpp] = useState(
    workspace.billing_company_kpp
  );
  const [billingCompanyAddress, setBillingCompanyAddress] = useState(
    workspace.billing_company_address
  );
  const [billingSendActsByEmail, setBillingSendActsByEmail] = useState(
    workspace.billing_send_acts_by_email
  );
  const [billingSendActsByEdo, setBillingSendActsByEdo] = useState(
    workspace.billing_send_acts_by_edo
  );
  const [billingAccountantEmails, setBillingAccountantEmails] = useState(
    workspace.billing_accountant_emails
  );

  const [sending, setSending] = useState(false);

  const submitBillingSettings = () => {
    setSending(true);

    axios
      .put(
        `${GETREVIEW_API_HOST}/api/workspaces/${workspace.token}`,
        {
          billing_company_name: billingCompanyName,
          billing_company_inn: billingCompanyInn,
          billing_company_kpp: billingCompanyKpp,
          billing_company_address: billingCompanyAddress,
          billing_send_acts_by_email: billingSendActsByEmail,
          billing_send_acts_by_edo: billingSendActsByEdo,
          billing_accountant_email: billingAccountantEmails.join(", "),
        },
        {
          withCredentials: true,
        }
      )
      .then(() => {
        toastr.success(
          "Поздравляем!",
          "Реквизиты организации были успешно сохранены"
        );

        dispatch(loadIdentity());

        if (props.onSuccess) {
          props.onSuccess();
        }
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error("", "Ошибка при сохранении реквизитов организации");
        }
      })
      .then(() => {
        setSending(false);
      });
  };

  return (
    <AvForm onValidSubmit={submitBillingSettings}>
      <h4>Реквизиты</h4>

      <FormGroup>
        <Label for="billingCompanyName">
          <Trans>workspace.billing_company_name</Trans>
        </Label>
        <AvField
          required
          errorMessage={t("workspace.errors.invalid_field")}
          bsSize="md"
          type="text"
          name="billingCompanyName"
          value={billingCompanyName}
          onChange={(event) => setBillingCompanyName(event.target.value)}
        />
      </FormGroup>

      <div className="d-flex">
        <div className="w-100 mr-3">
          <Label for="billingCompanyInn">
            <Trans>workspace.billing_company_inn</Trans>
          </Label>
          <AvField
            required
            validate={{
              pattern: {
                value: "^[0-9]{10,12}$",
              },
            }}
            errorMessage={t("workspace.errors.invalid_field")}
            bsSize="md"
            type="text"
            name="billingCompanyInn"
            value={billingCompanyInn}
            onChange={(event) => setBillingCompanyInn(event.target.value)}
          />
        </div>

        <div className="w-100">
          <Label for="billingCompanyKpp">
            <Trans>workspace.billing_company_kpp</Trans>
          </Label>
          <AvField
            validate={{
              pattern: {
                value: "^[0-9]{9}$",
              },
            }}
            errorMessage={t("workspace.errors.invalid_field")}
            bsSize="md"
            type="text"
            name="billingCompanyKpp"
            value={billingCompanyKpp}
            onChange={(event) => setBillingCompanyKpp(event.target.value)}
          />
        </div>
      </div>

      <FormGroup>
        <Label for="billingCompanyAddress">
          <Trans>workspace.billing_company_address</Trans>
        </Label>
        <AvField
          required
          errorMessage={t("workspace.errors.invalid_field")}
          bsSize="md"
          type="text"
          name="billingCompanyAddress"
          value={billingCompanyAddress}
          onChange={(event) => setBillingCompanyAddress(event.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <div className="d-flex">
          <Label for="billingAccountantEmail">
            <Trans>workspace.billing_accountant_email</Trans>
          </Label>
          <div>
            <FontAwesomeIcon
              icon={faPlusCircle}
              style={{
                fontSize: "15px",
                marginLeft: "8px",
                position: "relative",
                top: "2px",
                color: "#3A89FF",
                cursor: "pointer",
              }}
              onClick={() => {
                setBillingAccountantEmails([...billingAccountantEmails, ""]);
              }}
            />
          </div>
        </div>
        <div>
          {billingAccountantEmails.map((email, index) => (
            <EmailFieldBlock
              key={`billing_accountant_email_${index}`}
              index={index}
              value={email}
              onChange={(value) => {
                const newBillingAccountantEmails = [...billingAccountantEmails];
                newBillingAccountantEmails[index] = value;
                setBillingAccountantEmails(newBillingAccountantEmails);
              }}
              onDelete={() => {
                const newBillingAccountantEmails = [...billingAccountantEmails];
                newBillingAccountantEmails.splice(index, 1);
                setBillingAccountantEmails(newBillingAccountantEmails);
              }}
            />
          ))}
        </div>
      </FormGroup>

      <div className="d-flex">
        <FormGroup className="mr-3">
          <Label check>
            <CustomInput
              id="billingSendActsByEmail"
              checked={billingSendActsByEmail === true}
              type="checkbox"
              name="billingSendActsByEmail"
              label="Отправлять акты на email"
              onChange={(event) => {
                setBillingSendActsByEmail(event.target.checked);
              }}
            />
          </Label>
        </FormGroup>

        <FormGroup>
          <Label check>
            <CustomInput
              id="billingSendActsByEdo"
              checked={billingSendActsByEdo === true}
              type="checkbox"
              name="billingSendActsByEdo"
              label="Отправлять акты в ЭДО"
              onChange={(event) => {
                setBillingSendActsByEdo(event.target.checked);
              }}
            />
          </Label>
        </FormGroup>
      </div>

      <div
        className="d-flex w-100 flex-sm-row flex-column mt-4"
        style={{ justifyContent: props.forModalWindow ? "end" : "start" }}
      >
        {props.forModalWindow && (
          <Button color="light" className="mr-2 mb-2" onClick={props.onClose}>
            Вернуться назад
          </Button>
        )}

        <Button
          color="primary"
          className="mb-2"
          disabled={sending}
          style={{
            minWidth: "250px",
            position: "relative",
          }}
        >
          {props.forModalWindow ? "Сохранить и продолжить" : "Сохранить"}

          {sending && (
            <Spinner
              color="white"
              size="sm"
              className="mr-2"
              style={{
                position: "absolute",
                right: "10px",
                top: "13px",
              }}
            />
          )}
          {props.forModalWindow && (
            <FontAwesomeIcon
              icon={faArrowRight}
              className="ml-3"
              style={{ opacity: sending ? 0 : 1 }}
            />
          )}
        </Button>
      </div>
    </AvForm>
  );
};

export default WorkspaceBillingSettingsForm;
