import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer" style={{ fontSize: "13px" }}>
      <Container fluid>
        <Row className="text-muted">
          <Col xs="8" className="text-left">
            <ul className="list-inline d-none d-md-block">
              <li className="list-inline-item">
                <span style={{ fontSize: "12px" }}>
                  <a
                    href={`https://${process.env.REACT_APP_GETREVIEW_WEBSITE}/terms`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#555555" }}
                  >
                    {t("footer.terms")}
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    href={`https://${process.env.REACT_APP_GETREVIEW_WEBSITE}/agreement`}
                    rel="noopener noreferrer"
                    style={{ color: "#555555" }}
                  >
                    {t("footer.agreement")}
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    href={`https://${process.env.REACT_APP_GETREVIEW_WEBSITE}/privacy`}
                    rel="noopener noreferrer"
                    style={{ color: "#555555" }}
                  >
                    {t("footer.privacy")}
                  </a>
                </span>
              </li>
            </ul>
          </Col>
          <Col xs="4" className="text-right">
            <p
              className="mb-0"
              style={{
                marginRight: "70px",
                fontSize: "12px",
                color: "#555555",
              }}
            >
              &copy;&nbsp;2020-2024&nbsp;&nbsp;GetReview
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
