import { faArrowCircleRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import {
  paymentReminderHide,
  paymentReminderShaked,
} from "../redux/actions/layoutActions";

const shakeKeyframe = keyframes`
  10%, 90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(3px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-6px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(6px, 0, 0);
  }
`;

const Container = styled.div`
  font-size: 12px;
  line-height: 18px;
  background: #fc3737;
  color: white;
  padding: 7px 10px;
  position: absolute;
  top: 8px;
  left: 60px;
  border-radius: 10px;
  animation-delay: 1s;
  transition: background-color 0.2s ease-out;

  animation: ${(props) =>
    props.isShaking
      ? css`
          ${shakeKeyframe} 1s
        `
      : ""};
`;

const Attention = styled.div`
  font-weight: bold;
`;

const ActionButtonIcon = styled.div`
  font-size: 18px;
  margin-left: 5px;
  position: relative;
  right: -3px;
  color: #000a3b;
`;

export default ({ payment, billing_days_left }) => {
  const layout = useSelector((state) => state.layout);
  const identity = useSelector((state) => state.identity);
  const { workspace } = identity;
  const dispatch = useDispatch();

  const payInvoiceURL =
    payment.merchant === "ip_direct"
      ? payment.cdn_invoice_pdf_url
      : payment.pay_invoice_url;

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(paymentReminderShaked());
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (layout.paymentReminderVisible === false) {
    return null;
  }

  return (
    <Container isShaking={layout.paymentReminderShaked === false}>
      <div className="d-flex justify-content-between">
        <Attention>
          <Trans>navbar.pending_payment.attention</Trans>&nbsp;
          {workspace.status === "regular_suspended" ? (
            <Trans>navbar.pending_payment.workspace_suspended</Trans>
          ) : (
            <>
              <Trans values={{ count: billing_days_left }}>
                plans.days_left
              </Trans>
              &nbsp;
              <Trans>navbar.pending_payment.service_access</Trans>
            </>
          )}
        </Attention>

        <div
          style={{ cursor: "pointer" }}
          onClick={() => dispatch(paymentReminderHide())}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>

      <div>
        {workspace.status === "regular_suspended" ? (
          <Trans>navbar.pending_payment.pay_to_continue</Trans>
        ) : (
          <Trans values={{ amount: payment.amount }}>
            navbar.pending_payment.content
          </Trans>
        )}
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <a href={payInvoiceURL} target="blank">
          <div
            style={{
              padding: "5px 10px",
              margin: "8px 0 5px 0",
              background: "#f4fff3",
              borderRadius: "30px",
              color: "black",
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            <div className="d-flex">
              <div>
                Продлить тариф <Trans>plans.names.{payment.plan}</Trans> на{" "}
                {payment.months} мес. за {payment.amount} руб.
              </div>
              <ActionButtonIcon>
                <FontAwesomeIcon icon={faArrowCircleRight} />
              </ActionButtonIcon>
            </div>
          </div>
        </a>

        <div style={{ position: "relative", top: "2px", marginLeft: "10px" }}>
          <Link
            to="/account/billing/plans"
            style={{ color: "white", textDecoration: "underline" }}
          >
            Тарифные планы
          </Link>
        </div>
      </div>
    </Container>
  );
};
