import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { GETREVIEW_API_HOST } from "../../../constants";
import WidgetForm from "./WidgetForm";
import UpgradeRequiredModalWindow from "../../../components/UpgradeRequiredModalWindow";
import Breadcrumbs from "../../../components/Breadcrumbs";

import { Container, Row, Col } from "reactstrap";

const NewVideoStories = (props) => {
  const [defaultSettings, setDefaultSettings] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const widgetType = queryParams.get("type");

  useEffect(() => {
    const fetchDefaultSettings = () => {
      axios
        .get(`${GETREVIEW_API_HOST}/api/widgets/default_settings`, {
          params: {
            widget_type: widgetType,
          },
          withCredentials: true,
        })
        .then((json) => {
          setDefaultSettings(json.data);
        })
        .catch((error) => {
          alert("Ошибка при получении шаблона виджета");
        });
    };

    if (widgetType != null) {
      fetchDefaultSettings();
    }
  }, [widgetType]);

  const createVideoStories = (formData, { onSuccess, onError }) => {
    axios
      .post(`${GETREVIEW_API_HOST}/api/widget`, formData, {
        withCredentials: true,
      })
      .then(onSuccess)
      .catch(onError);
  };

  const widgetTypeName = {
    VideoBubble: "Bubble",
    VideoInline: "Inline",
    VideoCarousel: "Carousel",
    VideoStories: "Stories",
    VideoGrid: "Grid",
  }[widgetType];

  if (widgetTypeName == null) {
    return "Undefined widget type";
  }

  if (defaultSettings == null) {
    return <b>Загрузка...</b>;
  }

  return (
    <Container fluid className="p-0">
      {widgetType === "VideoInline" && (
        <UpgradeRequiredModalWindow
          requiredPlans={["standard", "business", "enterprise"]}
          redirectBackPath="/widgets/new_widget_type"
          featuresDesc={`по созданию видеовиджетов в формате Inline`}
          featuresList={[
            "Интеграция видеовиджета внутри контента сайта",
            "Поддержка как вертикальных, так и горизонтальных видео",
            "Ускорение загрузки страницы (отложенная загрузка видео, lazy load)",
            "Кастомизация внешнего вида видеовиджета",
            "Гибкая настройка показа в зависимости от UTM-меток",
            "Call-to-Action кнопки",
            "Аналитика по видео",
          ]}
        />
      )}

      {widgetType === "VideoCarousel" && (
        <UpgradeRequiredModalWindow
          requiredPlans={["standard", "business", "enterprise"]}
          redirectBackPath="/widgets/new_widget_type"
          featuresDesc={`по созданию видеовиджетов в формате Carousel`}
          featuresList={[
            "Интеграция видеовиджета внутри контента сайта",
            "Отображение сразу нескольких видео в формате карусели",
            "Поддержка как вертикальных, так и горизонтальных видео",
            "Ускорение загрузки страницы (отложенная загрузка видео, lazy load)",
            "Кастомизация внешнего вида видеовиджета",
            "Гибкая настройка показа в зависимости от UTM-меток",
            "Call-to-Action кнопки",
            "Аналитика по видео",
          ]}
        />
      )}

      {widgetType === "VideoStories" && (
        <UpgradeRequiredModalWindow
          requiredPlans={["business", "enterprise"]}
          redirectBackPath="/widgets/new_widget_type"
          featuresDesc={`по созданию видеовиджетов в формате Stories`}
          featuresList={[
            "Отображение сразу нескольких видео в формате сториз",
            "Полноэкранный просмотр видео",
            "Поддержка как вертикальных, так и горизонтальных видео",
            "Ускорение загрузки страницы (отложенная загрузка видео, lazy load)",
            "Кастомизация внешнего вида видеовиджета",
            "Гибкая настройка показа в зависимости от UTM-меток",
            "Call-to-Action кнопки",
            "Аналитика по видео",
          ]}
        />
      )}

      {widgetType === "VideoGrid" && (
        <UpgradeRequiredModalWindow
          requiredPlans={["business", "enterprise"]}
          redirectBackPath="/widgets/new_widget_type"
          featuresDesc={`по созданию видеовиджетов в формате Grid`}
          featuresList={[
            "Отображение сразу нескольких видео в формате плитки",
            "Полноэкранный просмотр видео",
            "Поддержка как вертикальных, так и горизонтальных видео",
            "Ускорение загрузки страницы (отложенная загрузка видео, lazy load)",
            "Кастомизация внешнего вида видеовиджета",
            "Гибкая настройка показа в зависимости от UTM-меток",
            "Call-to-Action кнопки",
            "Аналитика по видео",
          ]}
        />
      )}

      <Row className="mb-2 mb-xl-4">
        <Col xs="auto" className="d-none d-sm-block">
          <Breadcrumbs />
          <h3>Новый виджет {widgetTypeName}</h3>
        </Col>
      </Row>

      <WidgetForm
        widgetType={widgetType}
        widgetTypeName={widgetTypeName}
        widgetSettings={defaultSettings}
        widgetItems={[]}
        onFormSubmit={createVideoStories}
      />
    </Container>
  );
};

export default NewVideoStories;
