// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";
export const LAYOUT_PAYMENT_REMINDER_SHAKED = "LAYOUT_PAYMENT_REMINDER_SHAKED";
export const LAYOUT_PAYMENT_REMINDER_HIDE = "LAYOUT_PAYMENT_REMINDER_HIDE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

export const FETCH_VIDEOS_PENDING = "FETCH_VIDEOS_PENDING";
export const FETCH_VIDEOS_SUCCESS = "FETCH_VIDEOS_SUCCESS";
export const FETCH_VIDEOS_ERROR = "FETCH_VIDEOS_ERROR";

export const LOAD_ACCOUNT_SUCCESS = "LOAD_ACCOUNT_SUCCESS";
export const LOAD_ACCOUNT_ERROR = "LOAD_ACCOUNT_ERROR";

// Identity

export const SELECT_PROJECT = "SELECT_PROJECT";
export const LOAD_IDENTITY_SUCCESS = "LOAD_IDENTITY_SUCCESS";
export const LOAD_IDENTITY_ERROR = "LOAD_IDENTITY_ERROR";
