import React, { useState } from "react";
import ColorPickerRGBA from "../../components/ColorPickerRGBA";

import {
  FormGroup,
  FormText,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CustomInput,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faPlusSquare,
} from "@fortawesome/free-regular-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const CallToActionSettings = ({ onChange, widgetType, widgetSettings }) => {
  const [codeExampleVisible, setCodeExampleVisible] = useState(false);
  const [videoTutorialVisible, setVideoTutorialVisible] = useState(false);
  const [scrollVideoTutorialVisible, setScrollVideoTutorialVisible] =
    useState(false);

  return (
    <div>
      <h4>Тип CTA кнопки</h4>

      <FormGroup check className="mb-2 form-checkbox-label">
        <Label check>
          <Input
            type="radio"
            name="itemCallToActionType"
            value="none"
            checked={widgetSettings.itemCallToActionType === "none"}
            onChange={() => onChange("itemCallToActionType", "none")}
          />
          Не использовать CTA
          <FormText color="muted">
            Кнопка не будет появляться в виджете
          </FormText>
        </Label>
      </FormGroup>

      <FormGroup check className="mb-2 form-checkbox-label">
        <Label check>
          <Input
            type="radio"
            name="itemCallToActionType"
            value="link"
            checked={widgetSettings.itemCallToActionType === "link"}
            onChange={() => onChange("itemCallToActionType", "link")}
          />
          Переход по ссылке
          <FormText color="muted">
            Открытие страницы сайта или переход по якорной ссылке
          </FormText>
        </Label>
      </FormGroup>

      <FormGroup check className="mb-2 form-checkbox-label">
        <Label check>
          <Input
            type="radio"
            name="itemCallToActionType"
            value="click"
            checked={widgetSettings.itemCallToActionType === "click"}
            onChange={() => onChange("itemCallToActionType", "click")}
          />
          Открытие вашей формы на сайте, вызов popup-окна, запуск квиза и др.
          <br />
          <FormText color="muted">
            Используются уже существующие формы и popup-окна на сайте
          </FormText>
        </Label>
      </FormGroup>

      <FormGroup check className="mb-2 form-checkbox-label">
        <Label check>
          <Input
            type="radio"
            name="itemCallToActionType"
            value="scroll"
            checked={widgetSettings.itemCallToActionType === "scroll"}
            onChange={() => onChange("itemCallToActionType", "scroll")}
          />
          Скролл до блока на странице сайта
          <FormText color="muted">
            Плавный скролл до определенного блока на странице вашего сайта
          </FormText>
        </Label>
      </FormGroup>

      <FormGroup check className="mb-2 form-checkbox-label">
        <Label check>
          <Input
            type="radio"
            name="itemCallToActionType"
            value="trigger"
            checked={widgetSettings.itemCallToActionType === "trigger"}
            onChange={() => onChange("itemCallToActionType", "trigger")}
          />
          Запуск вашего кода через JavaScript API
          <br />
          <FormText color="muted">
            Для более сложных интеграций. Необходимо начальное знание JavaScript
          </FormText>
        </Label>
      </FormGroup>

      {widgetSettings.itemCallToActionType !== "none" && (
        <div
          style={{
            padding: "30px 30px",
            background: "#f5f5f5",
            borderRadius: "10px",
          }}
          className="form-checkbox-block"
        >
          <FormGroup style={{ maxWidth: "400px" }}>
            <Label>Текст на CTA-кнопке в виджете</Label>
            <Input
              type="text"
              name="itemCallToActionText"
              value={widgetSettings.itemCallToActionText}
              onChange={(e) => onChange("itemCallToActionText", e.target.value)}
            />
          </FormGroup>

          <FormGroup style={{ maxWidth: "600px" }}>
            <Label>Время появления кнопки</Label>
            <div className="d-flex align-items-center">
              <Input
                style={{ width: "50px" }}
                type="text"
                name="itemCallToActionShowTime"
                value={widgetSettings.itemCallToActionShowTime}
                onChange={(event) =>
                  onChange(
                    "itemCallToActionShowTime",
                    parseInt(event.target.value) || 0
                  )
                }
              />
              <div className="ml-2">сек.</div>
            </div>
          </FormGroup>

          <FormGroup>
            Цвет кнопки
            <br />
            <ColorPickerRGBA
              color={widgetSettings.itemCallToActionBackgroundColor}
              onChange={(color) =>
                onChange("itemCallToActionBackgroundColor", color)
              }
            />
          </FormGroup>

          <FormGroup>
            Цвет текста на кнопке
            <br />
            <ColorPickerRGBA
              color={widgetSettings.itemCallToActionFontColor}
              onChange={(color) => onChange("itemCallToActionFontColor", color)}
            />
          </FormGroup>

          <FormGroup>
            Стиль текста на кнопке
            <br />
            <div className="d-flex mt-1">
              <CustomInput
                id="itemCallToActionFontWeight_normal"
                checked={widgetSettings.itemCallToActionFontWeight === "normal"}
                type="radio"
                name="itemCallToActionFontWeight"
                value={true}
                label="Обычный"
                onChange={() =>
                  onChange("itemCallToActionFontWeight", "normal")
                }
                className="mr-3"
              />

              <CustomInput
                id="itemCallToActionFontWeight_bold"
                checked={widgetSettings.itemCallToActionFontWeight === "bold"}
                type="radio"
                name="itemCallToActionFontWeight"
                value={false}
                label="Жирный"
                onChange={() => onChange("itemCallToActionFontWeight", "bold")}
                className="mr-3"
              />
            </div>
          </FormGroup>

          <FormGroup style={{ maxWidth: "400px" }}>
            <Label>Радиус закругления кнопки</Label>
            <div className="d-flex">
              <Input
                style={{ width: "50px", marginRight: "5px" }}
                type="text"
                name="itemCallToActionBorderRadius"
                value={widgetSettings.itemCallToActionBorderRadius}
                onChange={(e) =>
                  onChange("itemCallToActionBorderRadius", e.target.value)
                }
              />
              <div className="text-muted mt-1">px</div>
            </div>
          </FormGroup>

          {widgetSettings.itemCallToActionType === "link" && (
            <React.Fragment>
              <br />
              <h5>Настройка перехода по ссылке</h5>
              <Label>
                Укажите ссылку для перехода после клика на CTA-кнопку.
                <br />
                Это может быть абсолютная ссылка{" "}
                <b>https://example.com/my-page</b> или якорная ссылка для
                перехода к конкретному блоку, например,{" "}
                <b>https://example.com/my-page#lead_form</b>
              </Label>
              <InputGroup style={{ maxWidth: "400px", marginBottom: "10px" }}>
                <InputGroupAddon addonType="prepend">URL</InputGroupAddon>
                <Input
                  type="text"
                  name="cta_link"
                  placeholder="https://example.com/my-page"
                  value={widgetSettings.itemCallToActionLinkURL}
                  onChange={(e) =>
                    onChange("itemCallToActionLinkURL", e.target.value)
                  }
                />
              </InputGroup>
              <FormGroup check className="mb-2 form-checkbox-label">
                <Label check>
                  <Input
                    type="checkbox"
                    name="cta_link_new_window"
                    checked={
                      widgetSettings.itemCallToActionLinkOpenInNewWindow ===
                      true
                    }
                    onChange={(e) =>
                      onChange(
                        "itemCallToActionLinkOpenInNewWindow",
                        e.target.checked
                      )
                    }
                  />
                  Открывать ссылку в новом окне
                </Label>
              </FormGroup>
              <br />
              <h5>Не получается настроить?</h5>
              Если у вас не получается настроить переход по обычной или якорной
              ссылке, то вы всегда можете написать нам в поддержку через чат в
              правом нижнем углу экрана или на почту{" "}
              <a href="mailto:corp@getreview.io">corp@getreview.io</a>.
            </React.Fragment>
          )}

          {widgetSettings.itemCallToActionType === "trigger" && (
            <React.Fragment>
              <br />
              <h5>Как настроить обработку клика на кнопку?</h5>
              Для реализации собственной логики при клике на кнопку вам нужно
              использовать обработку события <code>onCallToActionClick</code> с
              помощью <b>JavaScript</b>-кода.
              <br />
              После кода видеовиджета добавьте следующий фрагмент кода:
              <br />
              <br />
              <code>
                {` GetReview('onCallToActionClick', function() { `}
                <br />
                &nbsp;{` // Ваш код здесь `}
                <br />
                {`  }); `}
              </code>
              <br />
              <br />
              <Modal
                isOpen={codeExampleVisible}
                toggle={(e) => setCodeExampleVisible(false)}
                centered
              >
                <ModalHeader>
                  Пример кода виджета для собственной обработки клика по CTA
                </ModalHeader>
                <ModalBody>
                  <code>
                    {`<!-- GetReview.io Widget BEGIN -->`}
                    <br />
                    {`<script type="text/javascript">`}
                    <br />
                    &nbsp;{`  (function(g, e, t) {`}
                    <br />
                    &nbsp;&nbsp;&nbsp;
                    {`    e.GetReviewWidgetID = '<ИдентификаторВиджета>';`}
                    <br />
                    &nbsp;&nbsp;&nbsp;{`    var s = g.createElement('script');`}
                    <br />
                    &nbsp;&nbsp;&nbsp;{`    e[t] = e[t] || function() {`}
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {`      (e[t].queue = e[t].queue || []).push(arguments);`}
                    <br />
                    &nbsp;&nbsp;&nbsp;{`    };`}
                    <br />
                    &nbsp;&nbsp;&nbsp;{`    s.async = true;`}
                    <br />
                    &nbsp;&nbsp;&nbsp;{`    s.charset = 'UTF-8';`}
                    <br />
                    &nbsp;&nbsp;&nbsp;
                    {`    s.src = 'https://app.getreview.io/static/bundle.js';`}
                    <br />
                    &nbsp;&nbsp;&nbsp;
                    {`    if (g.head) { g.head.appendChild(s); }`}
                    <br />
                    &nbsp;{`  })(document, window, 'GetReview');`}
                    <br />
                    <br />
                    &nbsp;{` GetReview('onCallToActionClick', function() { `}
                    <br />
                    &nbsp;&nbsp;&nbsp;{` // Ваш код здесь `}
                    <br />
                    &nbsp;{`  }); `}
                    <br />
                    {`</script>`}
                    <br />
                    {`<!-- GetReview.io Widget END -->`}
                    <br />
                  </code>
                  <br />
                  Ваш <b>JavaScript</b>-код, который будет выполняться при клике
                  на CTA-кнопку, необходимо вставить вместо текста{" "}
                  <code>{`// Ваш код здесь`}</code>. Обратите внимание, что вам
                  нужно заменить текст <code>&lt;ИдентификаторВиджета&gt;</code>{" "}
                  на ID вашего видеовиджета.
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="light"
                    onClick={(e) => setCodeExampleVisible(false)}
                  >
                    Закрыть
                  </Button>
                </ModalFooter>
              </Modal>
              <Button
                type="button"
                color="info"
                onClick={(e) => setCodeExampleVisible(true)}
              >
                <FontAwesomeIcon icon={faPlusSquare} fixedWidth /> Показать
                полный пример кода
              </Button>
              <br />
              <br />
              <h5>Как настроить открытие формы или popup-окна на сайте?</h5>
              Как правило, настройка открытия собственной формы занимает не
              более 10 минут, но для этого необходимо начальное знание{" "}
              <b>JavaScript</b>. Мы записали видео инструкцию о том, как
              настроить открытие формы при клике на CTA:
              <br />
              <br />
              <Modal
                isOpen={videoTutorialVisible}
                toggle={(e) => setVideoTutorialVisible(false)}
                size="lg"
                centered
              >
                <ModalBody>
                  <div
                    style={{ height: "60vh" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        '<iframe src="https://www.loom.com/embed/c95f419b09234d8392459297695c32ef" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="width: 100%; height: 100%;"></iframe>',
                    }}
                  ></div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="light"
                    onClick={(e) => setVideoTutorialVisible(false)}
                  >
                    Закрыть
                  </Button>
                </ModalFooter>
              </Modal>
              <Button
                type="button"
                color="info"
                onClick={(e) => setVideoTutorialVisible(true)}
              >
                <FontAwesomeIcon icon={faPlayCircle} fixedWidth /> Смотреть
                видео инструкцию
              </Button>
              <br />
              <br />
              <h5>Не получается настроить?</h5>
              Если у вас не получается настроить выполнение собственного
              сценария, то вы всегда можете написать нам в поддержку через чат в
              правом нижнем углу экрана или на почту{" "}
              <a href="mailto:corp@getreview.io">corp@getreview.io</a>. Наши
              разработчики помогут вам с кастомизацией и подготовят обновленный
              код виджета в течение 1-го рабочего дня.
            </React.Fragment>
          )}

          {widgetSettings.itemCallToActionType === "scroll" && (
            <React.Fragment>
              <br />
              <h5>ID блока</h5>
              <Label>
                Укажите значение аттрибута <b>id</b> блока (HTML-тега), до
                которого нужно совершить плавный скролл при клике на CTA-кнопку.
                Символ решетки <b>#</b> указывать не нужно.
              </Label>
              <InputGroup style={{ maxWidth: "400px" }}>
                <InputGroupAddon addonType="prepend">ID</InputGroupAddon>
                <Input
                  type="text"
                  name="itemCallToActionScrollSelector"
                  placeholder="pricing-block"
                  value={widgetSettings.itemCallToActionScrollSelector}
                  onChange={(e) =>
                    onChange("itemCallToActionScrollSelector", e.target.value)
                  }
                />
              </InputGroup>
              <br />
              <br />
              <h5>Что такое ID блока?</h5>
              ID блока (HTML-тега) - это его уникальный идентификатор,
              переданный через аттрибут <b>id</b>. Например, это может быть тег{" "}
              <b>div</b> с аттрибутом <b>id</b>, имеющим значение{" "}
              <b>pricing-block</b>:
              <br />
              <br />
              <code>{`<div id="pricing-block">...</div>`}</code>
              <br />
              <br />
              <h5>Как найти нужный ID?</h5>
              Самый удобный способ поиска ID нужного блока - это использование
              панели Developer Tools (инструменты разработчика) в браузерах
              Chrome, Firefox или Safari. Мы записали видео инструкцию о том,
              как найти ID нужного блока и настроить скролл до него:
              <br />
              <br />
              <Modal
                isOpen={scrollVideoTutorialVisible}
                toggle={(e) => setScrollVideoTutorialVisible(false)}
                size="lg"
                centered
              >
                <ModalBody>
                  <div
                    style={{ height: "60vh" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        '<iframe src="https://www.loom.com/embed/5a6227b24bca499fa248d9096e25c09e" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="width: 100%; height: 100%;"></iframe>',
                    }}
                  ></div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="light"
                    onClick={(e) => setScrollVideoTutorialVisible(false)}
                  >
                    Закрыть
                  </Button>
                </ModalFooter>
              </Modal>
              <Button
                type="button"
                color="info"
                onClick={(e) => setScrollVideoTutorialVisible(true)}
              >
                <FontAwesomeIcon icon={faPlayCircle} fixedWidth /> Смотреть
                видео инструкцию
              </Button>
              <br />
              <br />
              <h5>Не получается настроить?</h5>
              Если у вас не получается настроить скролл до определенного блока
              на сайте, то вы всегда можете написать нам в поддержку через чат в
              правом нижнем углу экрана или на почту{" "}
              <a href="mailto:corp@getreview.io">corp@getreview.io</a>. Наши
              разработчики помогут вам с поиском нужного блока на странице и
              настройками виджета в течение 1-го рабочего дня.
            </React.Fragment>
          )}

          {widgetSettings.itemCallToActionType === "click" && (
            <React.Fragment>
              <Label>
                Укажите селектор кнопки на вашем сайте, при клике на которую
                происходит открытие формы или popup-окна
              </Label>
              <InputGroup>
                <Input
                  style={{ maxWidth: "400px" }}
                  type="text"
                  name="itemCallToActionClickSelector"
                  placeholder="#rec236523522 > div > div > a > div"
                  value={widgetSettings.itemCallToActionClickSelector}
                  onChange={(e) =>
                    onChange("itemCallToActionClickSelector", e.target.value)
                  }
                />

                <a
                  href="https://www.loom.com/share/14bd56dae73e43f5827a3879c599d93d"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    position: "relative",
                    top: "5px",
                    left: "15px",
                    fontSize: "12px",
                  }}
                >
                  Как найти селектор за 30 секунд без помощи программиста?
                </a>
              </InputGroup>
              <br />
              <a
                href="https://www.loom.com/share/7ff00d6075f54f9e8f3d7a84b6e4510b"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button type="button" color="info">
                  <FontAwesomeIcon icon={faInfoCircle} fixedWidth /> Смотреть
                  инструкцию
                </Button>
              </a>
              <br />
              <br />
              <h5>Не получается настроить самостоятельно?</h5>
              Напишите нам в чат или на почту{" "}
              <a href="mailto:corp@getreview.io">corp@getreview.io</a>, настроим
              в течение 1 рабочего дня.
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default CallToActionSettings;
