import React, { useState, useEffect } from "react";
import axios from "axios";
import { GETREVIEW_API_HOST } from "../../constants";
import { format } from "date-fns";
import { Table, Spinner } from "reactstrap";

const eventsPercentage = (percentFor, percentOf) => {
  if (percentOf === 0) {
    return 0;
  }

  return ((percentFor / percentOf) * 100).toFixed(2);
};

const Item = ({
  token,
  is_deleted,
  title,
  thumbnail_url,
  video_progress_start_count,
  video_progress_midpoint_count,
  video_progress_complete_count,
  call_to_action_button_click_count,
  call_to_action_link_click_count,
}) => {
  return (
    <tr>
      <td className="text-nowrap" style={{ width: "90px" }}>
        <div style={{ display: "flex" }}>
          {thumbnail_url && (
            <div
              style={{
                width: "80px",
                height: "80px",
                backgroundImage: `url(${thumbnail_url})`,
                backgroundSize: "cover",
                backgroundPosition: "50% 30%",
                borderRadius: "8px",
                marginRight: "5px",
                boxShadow: "1px 2px 0.875rem 0 rgba(53, 64, 82, 0.1)",
                opacity: is_deleted ? 0.5 : 1,
              }}
            />
          )}
          <div
            style={{
              margin: "5px 10px",
              fontSize: "11px",
              opacity: is_deleted ? 0.5 : 1,
            }}
          >
            {title === null ? "Не определено" : title}
            <br />
            {is_deleted && (
              <div style={{ color: "#be0c0c" }}>Видео удалено</div>
            )}
          </div>
        </div>
      </td>

      <td className="text-nowrap">{video_progress_start_count}</td>
      <td className="text-nowrap">{video_progress_midpoint_count}</td>
      <td className="text-nowrap">
        {eventsPercentage(
          video_progress_midpoint_count,
          video_progress_start_count
        )}
        %
      </td>
      <td className="text-nowrap">{video_progress_complete_count}</td>
      <td className="text-nowrap">
        {eventsPercentage(
          video_progress_complete_count,
          video_progress_start_count
        )}
        %
      </td>
      <td className="text-nowrap">{call_to_action_button_click_count}</td>
      <td className="text-nowrap">
        {eventsPercentage(
          call_to_action_button_click_count,
          video_progress_start_count
        )}
        %
      </td>
      <td className="text-nowrap">{call_to_action_link_click_count}</td>
      <td className="text-nowrap">
        {eventsPercentage(
          call_to_action_link_click_count,
          video_progress_start_count
        )}
        %
      </td>
    </tr>
  );
};

const ItemsAnalytics = ({ widgetToken, dateFrom, dateTo, deviceType }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    fetchData();
  }, [widgetToken, dateFrom, dateTo, deviceType]);

  const fetchData = () => {
    setIsLoading(true);

    const dateFromValue = format(dateFrom, "yyyy-MM-dd");
    const dateToValue = format(dateTo, "yyyy-MM-dd");

    axios
      .get(`${GETREVIEW_API_HOST}/api/analytics/items`, {
        params: {
          widget_id: widgetToken,
          device_type: deviceType,
          date_from: dateFromValue,
          date_to: dateToValue,
        },
        withCredentials: true,
      })
      .then((json) => {
        // console.log("analytics_result", json.data);
        setItems(json.data.analytics_result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching items", error);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return (
      <div style={{ height: "130px" }}>
        <center>
          <Spinner
            color="secondary"
            style={{ opacity: 0.3, marginTop: "50px" }}
          />
        </center>
      </div>
    );
  }

  return (
    <Table responsive bordered hover>
      <thead>
        <tr>
          <th className="text-nowrap">Видео</th>
          <th className="text-nowrap">Просмотров</th>
          <th className="text-nowrap">Просмотров 50%</th>
          <th className="text-nowrap">% просмотров 50%</th>
          <th className="text-nowrap">Просмотров 100%</th>
          <th className="text-nowrap">% просмотров 100%</th>
          <th className="text-nowrap">Кликов по CTA</th>
          <th className="text-nowrap">% кликов CTA</th>
          <th className="text-nowrap">Переходов</th>
          <th className="text-nowrap">% переходов</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </tbody>
    </Table>
  );
};

export default ItemsAnalytics;
