import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { loadIdentity } from "../redux/actions/identityActions";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../constants";
import ProjectWhiteLabelSettings from "./ProjectWhiteLabelSettings";
import ProjectSettingsRetranscodeModalWindow from "./ProjectSettingsRetranscodeModalWindow";

import { Button, FormGroup, Label, Spinner } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

const ProjectSettingsForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const project = props.project;

  const [name, setName] = useState(project.name);
  const [domain, setDomain] = useState(project.domain);
  const [sending, setSending] = useState(false);
  const [whiteLabelSettings, setWhiteLabelSettings] = useState(null);
  const [showRetranscodeModal, setShowRetranscodeModal] = useState(false);
  const [projectVideosCount, setProjectVideosCount] = useState(null);

  const onWhiteLabelSettingsChange = (settings) => {
    setWhiteLabelSettings(settings);
  };

  const saveProjectTokenToLocalStorage = (projectToken) => {
    localStorage.setItem("_gr_selected_project_token", projectToken);
  };

  const createNewProject = (formData) => {
    axios
      .post(`${GETREVIEW_API_HOST}/api/projects`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((json) => {
        toastr.success("", t("project.form.project_created_success"));

        saveProjectTokenToLocalStorage(json.data.project.token);
        window.location.href = "/widgets/all";
      })
      .catch((error) => {
        if (error.unknownError) {
          toastr.error("", t("project.form.project_created_error"));
        }
      })
      .then(() => {
        setSending(false);
      });
  };

  const submitProjectSettings = () => {
    const { watermarkType, watermarkFile, brandingBadge } = whiteLabelSettings;

    if (
      watermarkType === "custom" &&
      project.watermark_type !== "custom" &&
      watermarkFile == null
    ) {
      toastr.error("", t("project.watermark.image_not_selected_error"));
      return;
    }

    setSending(true);

    const data = new FormData();
    data.append("project[name]", name);
    data.append("project[domain]", domain);
    data.append("project[watermark_type]", watermarkType);
    data.append("project[branding_badge]", brandingBadge);

    if (watermarkFile) {
      data.append("watermark", watermarkFile);
    }

    if (project.persisted === false) {
      createNewProject(data);
    } else {
      axios
        .put(`${GETREVIEW_API_HOST}/api/projects/${project.token}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((result) => {
          toastr.success("", t("project.form.settings_saved_success"));

          dispatch(loadIdentity());

          const videosCount = result.data.videos_count;
          setProjectVideosCount(videosCount);

          if (
            videosCount > 0 &&
            result.data.project.watermark_url !== project.watermark_url
          ) {
            setShowRetranscodeModal(true);
          }
        })
        .catch((error) => {
          if (error.unknownError) {
            toastr.error("", t("project.form.settings_saved_error"));
          }
        })
        .then(() => {
          setSending(false);
        });
    }
  };

  const validateDomain = (value, ctx) => {
    let givenURL;

    try {
      givenURL = new URL(value);
    } catch (error) {
      return false;
    }

    if (givenURL.protocol !== "http:" && givenURL.protocol !== "https:") {
      return false;
    }

    if (givenURL.pathname !== "/") {
      return false;
    }

    return true;
  };

  return (
    <div>
      {showRetranscodeModal && (
        <ProjectSettingsRetranscodeModalWindow
          project={project}
          videosCount={projectVideosCount}
          onClose={() => {
            setShowRetranscodeModal(false);
          }}
        />
      )}

      <AvForm
        onValidSubmit={submitProjectSettings}
        className="project-settings-form"
      >
        {project.persisted !== false && (
          <h4>
            <Trans>project.form_title</Trans>
          </h4>
        )}

        <FormGroup>
          <Label for="name">
            <Trans>project.name</Trans>
          </Label>
          <AvField
            required
            errorMessage={t("project.errors.invalid_field")}
            bsSize="md"
            type="text"
            name="name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Label for="domain">
            <Trans>project.domain</Trans>
          </Label>
          <AvField
            required
            validate={{
              validateDomain,
            }}
            errorMessage={t("project.errors.invalid_field")}
            bsSize="md"
            type="text"
            name="domain"
            value={domain}
            onChange={(event) => setDomain(event.target.value)}
          />
        </FormGroup>

        <ProjectWhiteLabelSettings
          project={project}
          onChange={onWhiteLabelSettingsChange}
        />

        <div
          className="d-flex w-100 flex-sm-row flex-column mt-3"
          style={{ justifyContent: props.forModalWindow ? "end" : "start" }}
        >
          {props.forModalWindow && (
            <Button
              color="light"
              className="mr-2 mb-2"
              style={{
                minWidth: "200px",
              }}
              onClick={props.onClose}
            >
              <Trans>project.form.cancel_button</Trans>
            </Button>
          )}

          <Button
            color="primary"
            className="mb-2"
            disabled={sending}
            style={{
              minWidth: "200px",
              position: "relative",
            }}
          >
            <Trans>project.form.save_button</Trans>
            {` `}
            {sending && (
              <Spinner
                color="white"
                size="sm"
                className="mr-2"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "13px",
                }}
              />
            )}
          </Button>
        </div>
      </AvForm>
    </div>
  );
};

const defaultProps = {
  project: {
    token: "new_project",
    persisted: false,
    name: "My New Project",
    domain: "https://example.com",
    branding_badge: true,
    watermark_type: "getreview",
  },
};

ProjectSettingsForm.defaultProps = defaultProps;

export default ProjectSettingsForm;
