import React from "react";
import { useTranslation } from "react-i18next";
import { Line } from "react-chartjs-2";

const AnalyticsChart = ({ dates, series }) => {
  const { t } = useTranslation();

  const eventParams = {
    widget_load_uniq_count: [
      t("dashboard.analytics.widget_uniq_loads_short"),
      "#3A89FF",
    ],
    widget_open_count: [t("dashboard.analytics.widget_opens_short"), "#1F9BCF"],
    video_progress_start_count: [
      t("dashboard.analytics.video_progress_start_count_short"),
      "#f0ad4e",
    ],
    video_progress_midpoint_count: [
      t("dashboard.analytics.widget_midpoints_short"),
      "#f0ad4e",
    ],
    video_progress_complete_count: [
      t("dashboard.analytics.widget_completes_short"),
      "#4BBF73",
    ],
    call_to_action_button_click_count: [
      t("dashboard.analytics.widget_clicks_short"),
      "#05003b",
    ],
  };

  let datasets = [];

  Object.keys(eventParams).forEach((key) => {
    let seriesData = series[key];
    let params = eventParams[key];

    let dataset = {
      label: params[0],
      fill: true,
      backgroundColor: "transparent",
      borderColor: params[1],
      data: seriesData,
    };

    datasets.push(dataset);
  });

  const data = {
    labels: dates,
    datasets: datasets,
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 10,
      },
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 100,
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
          },
        },
      ],
    },
  };

  return <Line data={data} options={options} />;
};

export default AnalyticsChart;
