import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import styled from "styled-components";
import { GETREVIEW_API_HOST } from "../constants";
import Tabs, { TabContent } from "./Tabs";

const VideoContainer = styled.div`
  height: 60vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 20px;

  video {
    border-radius: 30px;
    overflow: hidden;
    transition: opacity 0.5s ease-out;
    opacity: 0;

    ${(props) =>
      props.videoPlayerVisible && {
        opacity: 1,
      }}
  }
`;

const Thumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Thumbnail = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 15px;
  background-size: cover;
  background-position: center center;
  transition: border-color 0.2s ease;
  border: 3px solid white;
  cursor: pointer;

  &:hover {
    ${(props) =>
      props.active === false && {
        borderColor: "#b5d3ff",
      }}
  }

  ${(props) =>
    props.active && {
      borderColor: "#3a89ff",
    }}

  ${(props) =>
    props.big && {
      width: `calc(260px * ${props.aspectRatio})`,
      height: "260px",
    }}

  ${(props) =>
    props.big == null && {
      width: `calc(130px * ${props.aspectRatio})`,
      height: "130px",
    }}
`;

const VideoEditor = ({ videoToken, onClose, onDelete }) => {
  const tabs = [
    { name: "video", title: "Видео" },
    { name: "thumbnail", title: "Обложка" },
    { name: "actions", title: "Действия" },
  ];

  const [videoPlayerVisible, setVideoPlayerVisible] = useState(false);
  const [newThumbnailFile, setNewThumbnailFile] = useState(null);
  const [activeTab, setActiveTab] = useState("video");
  const [video, setVideo] = useState(null);
  const [thumbnails, setThumbnails] = useState(null);
  const [activeThumbnail, setActiveThumbnail] = useState(null);
  const [name, setName] = useState("");

  useEffect(() => {
    if (videoToken) {
      fetchVideo();
      fetchThumbnails(videoToken);
    } else {
      setVideo(null);
      setThumbnails(null);
    }
  }, [videoToken]);

  useEffect(() => {
    if (video) {
      setName(video.name);
    }
  }, [video]);

  const fetchVideo = () => {
    axios
      .get(`${GETREVIEW_API_HOST}/api/videos/${videoToken}`, {
        withCredentials: true,
      })
      .then((json) => {
        setVideo(json.data.video);
        setActiveThumbnail(json.data.video.thumbnail_filename);
      })
      .catch((error) => {
        setVideo(null);
        alert("Ошибка при получении видео");
      });
  };

  const fetchThumbnails = (token) => {
    axios
      .get(`${GETREVIEW_API_HOST}/api/videos/${token}/thumbnails`, {
        withCredentials: true,
      })
      .then((json) => {
        setThumbnails(json.data.thumbnails);
      })
      .catch((error) => {
        setThumbnails(null);
        alert("Ошибка при получении обложек");
      });
  };

  const saveActiveThumbnail = () => {
    console.log("newThumbnailFile", newThumbnailFile);

    const formData = new FormData();
    formData.append("thumbnail_filename", activeThumbnail);

    if (newThumbnailFile != null) {
      formData.append("thumbnail_file", newThumbnailFile);
    }

    axios
      .post(
        `${GETREVIEW_API_HOST}/api/videos/${videoToken}/save_thumbnail`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      )
      .then((json) => {
        toastr.success("Поздравляем!", "Обложка была успешно обновлена");
        onClose(json.data.video);
      })
      .catch((error) => {
        alert("Ошибка при сохранении обложки");
      });
  };

  const saveVideoName = () => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/videos/${videoToken}`,
        { name: name },
        { withCredentials: true }
      )
      .then((json) => {
        toastr.success("Поздравляем!", "Название было успешно сохранено");
        onClose(json.data.video);
      })
      .catch((error) => {
        alert("Ошибка при сохранении видео");
      });
  };

  const deleteVideo = () => {
    const confirmBlock = (
      <div>
        Вы уверены, что хотите удалить видео?
        <br />
        После удаления данное видео будет отключено от всех связанных
        видеовиджетов
      </div>
    );

    toastr.confirm(confirmBlock, {
      onOk: () => {
        axios
          .delete(`${GETREVIEW_API_HOST}/api/videos/${videoToken}`, {
            withCredentials: true,
          })
          .then((json) => {
            if (onDelete) {
              onDelete(videoToken);
            }
          })
          .catch((error) => {
            alert("Ошибка при удалении видео");
          });
      },
    });
  };

  const onThumbnailInputFileChange = (event) => {
    if (event && event.target && event.target.files.length > 0) {
      setNewThumbnailFile(event.target.files[0]);
    }
  };

  return (
    <Modal isOpen={true} toggle={onClose} size="lg" className="modal-popup">
      <ModalHeader>
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          style={{
            marginBottom: 0,
          }}
          onChange={(tabName) => setActiveTab(tabName)}
        />
      </ModalHeader>
      <ModalBody>
        {video && thumbnails ? (
          <>
            <TabContent tab="video" activeTab={activeTab}>
              <Label>Название</Label>
              <div className="d-flex w-100 justify-content-between">
                <div className="flex-grow-1 mr-3">
                  <Input
                    type="text"
                    name="play_button_text"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <div>
                  <Button color="primary" onClick={saveVideoName}>
                    Сохранить
                  </Button>
                </div>
              </div>

              <VideoContainer videoPlayerVisible={videoPlayerVisible}>
                <video
                  onCanPlayThrough={() => setVideoPlayerVisible(true)}
                  muted
                  controls
                  autoPlay
                  playsInline
                  preload="auto"
                  disablePictureInPicture
                >
                  <source src={video.video_mp4_url} type="video/mp4" />
                </video>
              </VideoContainer>
            </TabContent>

            <TabContent tab="thumbnail" activeTab={activeTab}>
              <div style={{ marginBottom: "10px" }}>Текущая обложка:</div>

              {activeThumbnail && (
                <Thumbnail
                  aspectRatio={video.aspect_ratio}
                  active
                  big
                  style={{
                    backgroundImage: `url(${
                      video.thumbnail_type === "generated"
                        ? thumbnails.find(
                            (t) => t.thumbnail_filename === activeThumbnail
                          ).thumbnail_url
                        : video.thumbnail_url
                    })`,
                  }}
                ></Thumbnail>
              )}

              <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
                <h4 className="mb-0">Выберите новую обложку:</h4>

                <div className="d-flex">
                  <div className="mr-2">
                    <label
                      htmlFor="videoEditorNewThumbnail"
                      className="video-editor-upload-input-label"
                    >
                      <FontAwesomeIcon icon={faUpload} className="mr-3" />
                      {newThumbnailFile
                        ? newThumbnailFile.name
                        : "Загрузить изображение"}

                      <input
                        type="file"
                        accept="image/jpg, image/jpeg"
                        id="videoEditorNewThumbnail"
                        className="video-editor-upload-input"
                        onChange={onThumbnailInputFileChange}
                      />
                    </label>
                  </div>

                  <div>
                    {activeThumbnail !== 1 && (
                      <Button
                        color="primary"
                        style={{
                          fontSize: "12px",
                          padding: "8px 20px",
                        }}
                        onClick={saveActiveThumbnail}
                      >
                        Сохранить обложку
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <Thumbnails>
                {thumbnails.map((thumbnail, index) => (
                  <Thumbnail
                    key={index}
                    aspectRatio={video.aspect_ratio}
                    active={thumbnail.thumbnail_filename === activeThumbnail}
                    onClick={() => {
                      const newVideo = Object.assign({}, video);
                      newVideo.thumbnail_type = "generated";
                      setVideo(newVideo);
                      setActiveThumbnail(thumbnail.thumbnail_filename);
                    }}
                    style={{
                      backgroundImage: `url(${thumbnail.thumbnail_url})`,
                    }}
                  ></Thumbnail>
                ))}
              </Thumbnails>
            </TabContent>

            <TabContent tab="actions" activeTab={activeTab}>
              <Button color="danger" outline onClick={deleteVideo}>
                Удалить видео
              </Button>
            </TabContent>
          </>
        ) : (
          <b>Загрузка...</b>
        )}
      </ModalBody>

      <ModalFooter>
        <div className="d-flex w-100 justify-content-between">
          <div className="align-self-center">
            {video && video.name}
            <br />
            {video && (
              <span>
                ID: <b>{video.token}</b>
              </span>
            )}
          </div>
          <div>
            <Button color="light" className="mr-2" onClick={onClose}>
              Закрыть
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default VideoEditor;
