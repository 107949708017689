import React from "react";
import SettingsBlock from "./SettingsBlock";
import { useTranslation, Trans } from "react-i18next";
import { FormGroup, Input, Label } from "reactstrap";
import { faWaveSquare } from "@fortawesome/free-solid-svg-icons";

const TimeDelayForm = ({
  valuePropertyName,
  dimensionPropertyName,
  value,
  dimension,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: "10px", marginLeft: "20px", display: "flex" }}>
      <Input
        type="text"
        name={valuePropertyName}
        value={value}
        onChange={(event) => {
          onChange(valuePropertyName, event.target.value);
        }}
        style={{ width: "50px", marginRight: "10px" }}
      />
      <Input
        type="select"
        name={dimensionPropertyName}
        value={dimension}
        onChange={(event) => {
          onChange(dimensionPropertyName, event.target.value);
        }}
        style={{ width: "100px" }}
      >
        <option value="hour">
          {t("dashboard.widget_form.frequency.time_delay_hour")}
        </option>
        <option value="day">
          {t("dashboard.widget_form.frequency.time_delay_day")}
        </option>
      </Input>
    </div>
  );
};

const DisplayByFrequency = ({
  requiredPlans,
  widgetType,
  widgetSettings,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <SettingsBlock
      requiredPlans={requiredPlans}
      title={t("dashboard.widget_form.frequency.title")}
      description={t("dashboard.widget_form.frequency.desc")}
      icon={faWaveSquare}
      isVisible={widgetType === "VideoBubble"}
    >
      <div>
        <Label>
          <Trans>dashboard.widget_form.frequency.user_interacted</Trans>
        </Label>

        <div className="ml-3">
          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="frequencyUserInteractedOption"
                value="every_pageload"
                checked={
                  widgetSettings.frequencyUserInteractedOption ===
                  "every_pageload"
                }
                onChange={() => {
                  onChange("frequencyUserInteractedOption", "every_pageload");
                }}
              />
              <Trans>dashboard.widget_form.frequency.every_pageload</Trans>
            </Label>
          </FormGroup>

          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="frequencyUserInteractedOption"
                value="next_session"
                checked={
                  widgetSettings.frequencyUserInteractedOption ===
                  "next_session"
                }
                onChange={() => {
                  onChange("frequencyUserInteractedOption", "next_session");
                }}
              />
              <Trans>dashboard.widget_form.frequency.next_session</Trans>
            </Label>
          </FormGroup>

          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="frequencyUserInteractedOption"
                value="time_delay"
                checked={
                  widgetSettings.frequencyUserInteractedOption === "time_delay"
                }
                onChange={() => {
                  onChange("frequencyUserInteractedOption", "time_delay");
                }}
              />
              <Trans>dashboard.widget_form.frequency.time_delay</Trans>
            </Label>
          </FormGroup>

          {widgetSettings.frequencyUserInteractedOption === "time_delay" && (
            <TimeDelayForm
              valuePropertyName="frequencyUserInteractedDelayValue"
              value={widgetSettings.frequencyUserInteractedDelayValue}
              dimensionPropertyName="frequencyUserInteractedDelayDimension"
              dimension={widgetSettings.frequencyUserInteractedDelayDimension}
              onChange={(propertyName, value) => {
                onChange(propertyName, value);
              }}
            />
          )}

          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="frequencyUserInteractedOption"
                value="never"
                checked={
                  widgetSettings.frequencyUserInteractedOption === "never"
                }
                onChange={() => {
                  onChange("frequencyUserInteractedOption", "never");
                }}
              />
              <Trans>dashboard.widget_form.frequency.never</Trans>
            </Label>
          </FormGroup>
        </div>
      </div>

      <div>
        <Label>
          <Trans>dashboard.widget_form.frequency.user_destroyed</Trans>
        </Label>

        <div className="ml-3">
          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="frequencyUserDestroyedOption"
                value="every_pageload"
                checked={
                  widgetSettings.frequencyUserDestroyedOption ===
                  "every_pageload"
                }
                onChange={() => {
                  onChange("frequencyUserDestroyedOption", "every_pageload");
                }}
              />
              <Trans>dashboard.widget_form.frequency.every_pageload</Trans>
            </Label>
          </FormGroup>

          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="frequencyUserDestroyedOption"
                value="next_session"
                checked={
                  widgetSettings.frequencyUserDestroyedOption === "next_session"
                }
                onChange={() => {
                  onChange("frequencyUserDestroyedOption", "next_session");
                }}
              />
              <Trans>dashboard.widget_form.frequency.next_session</Trans>
            </Label>
          </FormGroup>

          <FormGroup check className="mb-2 form-checkbox-label">
            <Label check>
              <Input
                type="radio"
                name="frequencyUserDestroyedOption"
                value="time_delay"
                checked={
                  widgetSettings.frequencyUserDestroyedOption === "time_delay"
                }
                onChange={() => {
                  onChange("frequencyUserDestroyedOption", "time_delay");
                }}
              />
              <Trans>dashboard.widget_form.frequency.time_delay</Trans>
            </Label>
          </FormGroup>

          {widgetSettings.frequencyUserDestroyedOption === "time_delay" && (
            <TimeDelayForm
              valuePropertyName="frequencyUserDestroyedDelayValue"
              value={widgetSettings.frequencyUserDestroyedDelayValue}
              dimensionPropertyName="frequencyUserDestroyedDelayDimension"
              dimension={widgetSettings.frequencyUserDestroyedDelayDimension}
              onChange={(propertyName, value) => {
                onChange(propertyName, value);
              }}
            />
          )}
        </div>
      </div>
    </SettingsBlock>
  );
};

export default DisplayByFrequency;
