import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import LinkButton from "./LinkButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faEllipsisH } from "@fortawesome/free-solid-svg-icons";

const growDown = keyframes`
  0% {
    opacity: 0;
    top: 35px;
  }

  100% {
    opacity: 1;
    top: 50px;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 50px;
  right: -5px;
  background: white;
  border-radius: 8px;
  box-shadow: 1px 2px 0.875rem 0 rgb(53, 64, 82, 0.1);
  z-index: 9999999;
  overflow: hidden;
  font-size: 12px;
  animation: 0.2s ${growDown} ease-out;
  padding: 8px;
`;

const Option = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;
  padding: 7px 15px;
  border-radius: 3px;
  background: white;
  transition: color 0.2s ease-out;

  svg {
    transition: opacity 0.2s ease-out;
    margin-right: 15px;
    opacity: 0.2;
  }

  &:hover {
    background: #f3f4f5;

    svg {
      opacity: 0.7;
    }

    ${(props) =>
      props.colorType === "danger" && {
        color: "#e01818",
      }}
  }
`;

const Separator = styled.div`
  height: 1px;
  background: #edeff0;
  width: 100%;
  margin: 5px 0;
`;

const MenuDropdown = ({ items, vertical }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    const onDocumentClick = (event) => {
      if (
        isOpen &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", onDocumentClick);

    return () => {
      document.addEventListener("mousedown", onDocumentClick);
    };
  }, [isOpen]);

  return (
    <div ref={containerRef}>
      <LinkButton
        title=""
        icon={vertical ? faEllipsisV : faEllipsisH}
        iconSize={14}
        onClick={() => setIsOpen((value) => !value)}
      />

      {isOpen && (
        <Container>
          {items.map((item, index) => {
            if (item.type === "item") {
              return (
                <Option
                  className={item.className}
                  key={index}
                  colorType={item.colorType}
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsOpen(false);
                    return item.onClick ? item.onClick() : null;
                  }}
                >
                  <FontAwesomeIcon icon={item.icon} />
                  {item.label}
                </Option>
              );
            } else {
              return <Separator key={index} />;
            }
          })}
        </Container>
      )}
    </div>
  );
};

export default MenuDropdown;
