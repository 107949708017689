import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { GETREVIEW_API_HOST } from "../../../constants";
import Form from "./Form";

const ReviewsCampaignsEdit = (props) => {
  const history = useHistory();

  const [campaign, setCampaign] = useState(null);
  const { campaignToken } = useParams();

  useEffect(() => {
    fetchCampaign();
  }, []);

  const fetchCampaign = () => {
    axios
      .get(`${GETREVIEW_API_HOST}/api/campaigns/${campaignToken}`, {
        withCredentials: true,
      })
      .then((json) => {
        setCampaign(json.data.campaign);
      })
      .catch((error) => {
        console.error(error);
        toastr.error("", "Возникла ошибка при загрузке кампании");
      });
  };

  const saveCampaign = (updatedCampaign) => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/campaigns/${updatedCampaign.token}`,
        {
          name: updatedCampaign.name,
          recorded_video_id: updatedCampaign.video.id,
        },
        { withCredentials: true }
      )
      .then(() => {
        toastr.success("", "Кампания была успешно сохранена");
      })
      .catch(() => {
        toastr.error("", "Ошибка при сохранении кампании");
      });
  };

  if (campaign == null) {
    return null;
  }

  return <Form campaign={campaign} onFormSubmit={saveCampaign} />;
};

export default ReviewsCampaignsEdit;
