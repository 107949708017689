import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Uploader from "../../../components/Uploader";
import VideoSelector from "../../../components/VideoSelector";
import RecordedVideo from "../RecordedVideo";
import CallToActions from "./CallToActions";

const StoryModalForm = (props) => {
  const { workspace } = useSelector((state) => state.identity);

  const [caption, setCaption] = useState(props.caption || "");
  const [title, setTitle] = useState(props.title || "");
  const [subtitle, setSubtitle] = useState(props.subtitle || "");
  const [metadata, setMetadata] = useState(props.metadata || {});
  const [videos, setVideos] = useState(props.video ? [props.video] : []);
  const [callToActions, setCallToActions] = useState(
    props.call_to_actions || []
  );

  const onVideoSelectorOrUploaderComplete = (selectedVideos) => {
    if (selectedVideos.length > 1) {
      complete(selectedVideos);
    } else if (selectedVideos.length === 1) {
      setVideos([selectedVideos[0]]);
    }
  };

  const onCallToActionsUpdate = (newCallToActions) => {
    setCallToActions(newCallToActions);
  };

  const close = () => {
    setVideos([]);
    setTitle(null);
    setSubtitle(null);
    setCaption(null);
    setCallToActions([]);

    if (props.onClose) {
      props.onClose();
    }
  };

  const complete = (videosList) => {
    close();

    if (props.onComplete) {
      const stories = [];

      videosList.forEach((video) => {
        let storyToken = props.token;

        if (props.newRecord === true && props.token == null) {
          storyToken = Math.random().toString();
        }

        const story = {
          id: storyToken,
          token: storyToken,
          caption: caption,
          title: title,
          subtitle: subtitle,
          metadata: metadata,
          call_to_actions: callToActions,
          video: video,
          newRecord: props.newRecord,
        };

        stories.push(story);
      });

      props.onComplete(stories);
    }
  };

  return (
    <Modal
      isOpen={true}
      scrollable
      toggle={close}
      size="md"
      centered
      className="modal-popup"
    >
      <ModalHeader>Блок с видео</ModalHeader>

      <ModalBody style={{ paddingRight: 0 }}>
        <Form>
          {props.widgetType !== "VideoStories" && (
            <>
              <FormGroup>
                <Label>Заголовок</Label>
                <Input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                  style={{ width: "95%" }}
                />
              </FormGroup>

              <FormGroup>
                <Label>Подзаголовок</Label>
                <Input
                  type="text"
                  name="subtitle"
                  value={subtitle}
                  onChange={(event) => setSubtitle(event.target.value)}
                  style={{ width: "95%" }}
                />
              </FormGroup>
            </>
          )}

          {props.widgetType === "VideoStories" && (
            <FormGroup>
              <Label>Подпись под видео</Label>
              <Input
                type="text"
                name="caption"
                value={caption}
                onChange={(event) => setCaption(event.target.value)}
                style={{ width: "95%" }}
              />
            </FormGroup>
          )}

          <FormGroup>
            {videos.length === 1 && (
              <div>
                <RecordedVideo
                  {...videos[0]}
                  onRemoveClick={() => setVideos([])}
                />
              </div>
            )}

            <div>
              <Label>
                Выберите или загрузите <b>одно</b> или <b>несколько</b> видео
              </Label>
            </div>

            <div className="video-selector-uploader">
              <Uploader onSuccess={onVideoSelectorOrUploaderComplete} />
              <VideoSelector
                disableMultipleSelect={!(props.newRecord === true)}
                onComplete={onVideoSelectorOrUploaderComplete}
                disabledVideoIDs={videos.map((v) => v.id)}
              />
            </div>
          </FormGroup>

          <CallToActions
            callToActions={callToActions}
            onUpdate={onCallToActionsUpdate}
          ></CallToActions>
        </Form>
      </ModalBody>

      <ModalFooter>
        <div className="d-flex w-100 justify-content-between">
          <div className="align-self-center"></div>

          <div>
            <Button color="light" className="mr-2" onClick={close}>
              Отмена
            </Button>

            <Button
              color="primary"
              disabled={videos.length === 0}
              onClick={() => complete(videos)}
            >
              {props.token ? `Применить` : `Добавить`}
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default StoryModalForm;
