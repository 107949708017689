import * as types from "../constants";

const initialState = {
  authenticated: null,
  account: null,
  workspaces: null,
  workspace: null,
  project: null,
  role: null,
  policy: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SELECT_PROJECT: {
      const project = state.workspace.projects.find(
        (p) => p.token === action.projectToken
      );

      return {
        ...state,
        project,
      };
    }
    case types.LOAD_IDENTITY_SUCCESS: {
      const { account, workspaces } = action.identity;

      const workspaceData =
        workspaces.find((w) => w.workspace.token === action.workspaceToken) ||
        workspaces[0];
      const { workspace, role, policy } = workspaceData;

      let projects = [];

      if (role === "admin") {
        projects = workspace.projects;
      } else {
        const availableProjectsTokens = [];

        for (const projectToken in policy.projects) {
          const permissions = policy.projects[projectToken];

          if (permissions.includes("read")) {
            availableProjectsTokens.push(projectToken);
          }
        }
        projects = workspace.projects.filter((p) =>
          availableProjectsTokens.includes(p.token)
        );
      }

      const project =
        projects.find((p) => p.token === action.projectToken) || projects[0];

      return {
        ...state,
        authenticated: true,
        account,
        workspaces,
        workspace,
        projects,
        project,
        role,
        policy,
      };
    }
    case types.LOAD_IDENTITY_ERROR: {
      return {
        ...state,
        authenticated: false,
      };
    }
    default: {
      return state;
    }
  }
}
