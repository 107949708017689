import {
  faAngleDown,
  faAngleUp,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import classnames from "classnames";
import { format } from "date-fns";
import * as fnsLocales from "date-fns/locale";
import React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  PopoverBody,
  Row,
  Spinner,
  UncontrolledPopover,
} from "reactstrap";
import { GETREVIEW_API_HOST } from "../../constants";
import CancelSubscriptionModalWindow from "./CancelSubscriptionModalWindow";
import PricingModalForm from "./PricingModalForm";

export const Plans = {
  light: {
    monthly: 990,
    annually: 9504,
    annually_without_discount: 11880,
    annually_per_month: 792,
    name: "Лайт",
  },
  standard: {
    monthly: 1990,
    annually: 19104,
    annually_without_discount: 23880,
    annually_per_month: 1592,
    name: "Стандарт",
  },
  business: {
    monthly: 5990,
    annually: 57504,
    annually_without_discount: 71880,
    annually_per_month: 4792,
    name: "Бизнес",
  },
  enterprise: {
    monthly: 8990,
    annually: 86304,
    annually_without_discount: 107880,
    annually_per_month: 7192,
    name: "Энтерпрайз",
  },
};

const FAQ = [
  [
    "Как считаются показы виджета?",
    <div>
      <p>
        Засчитываются только уникальные показы виджета на одного пользователя в
        течение дня. Если пользователь в течение одного дня несколько раз зашел
        на страницы сайта, где установлен виджет, то будет засчитан только один
        показ для этого виджета. Показ виджета — это загрузка в режиме превью.
      </p>
    </div>,
  ],
  [
    "Что такое подписка?",
    <div>
      <p>
        При первой оплате платежная система CloudPayments запомнит вашу карту.
        Далее платежи будут производиться автоматически раз в месяц без
        необходимости повторного ввода платежных данных. Если по каким-то
        причинам не произошло списание, то система направит еще 2 запроса с
        интервалом в 24 часа, после чего статус подписки будет изменен.
      </p>
      <p>
        За 2 дня до платежной даты на ваш email будет направлено уведомление о
        предстоящем автосписании. В уведомлении также будет отображаться ссылка
        для отмены подписки.
      </p>
    </div>,
  ],
  [
    "Подписка продлевается автоматически?",
    <div>
      <p>
        Да, ваша подписка будет автоматически продлена в соответствии с вашим
        тарифным планом.
      </p>
      <p>
        За 2 дня до платежной даты на ваш email будет направлено уведомление о
        предстоящем автосписании. В уведомлении также будет отображаться ссылка
        для отмены подписки.
      </p>
    </div>,
  ],
  [
    "Как узнать дату следующего платежа?",
    <div>
      <p>
        Дата следующего платежа отображается в вашем Личном кабинете в разделе
        «Тарифы и оплата». За 2 дня до оплаты вам будет направлено уведомление
        по email.
      </p>
    </div>,
  ],
  [
    "Как гарантируется безопасность передаваемых данных при совершении платежей?",
    <div>
      <p>
        Для приема платежей мы используем один из самых надежных и передовых
        сервисов — CloudPayments. Процессинговый центр CloudPayments защищает и
        обрабатывает данные вашей банковской карты по стандарту безопасности PCI
        DSS 3.2. Передача информации в платежный шлюз происходит с применением
        технологии шифрования SSL. Дальнейшая передача информации происходит по
        закрытым банковским сетям, имеющим наивысший уровень надежности.
        CloudPayments не передает данные вашей карты нам и иным третьим лицам.
        Для дополнительной аутентификации держателя карты используется протокол
        3D Secure.
      </p>
    </div>,
  ],
  [
    "Могу ли я поменять тарифный план?",
    <div>
      <p>Да, можете, в Личном кабинете.</p>
    </div>,
  ],
  [
    "Могу ли я отменить подписку?",
    <div>
      <p>
        Вы можете отменить подписку в любой момент, просто написав нам в чат или
        на corp@getreview.io. Также за 2 дня до платежной даты со стороны
        CloudPayments на ваш емейл направляется уведомление о предстоящем
        автосписании вместе с cсылкой для отключения регулярных платежей. После
        отмены подписки показы виджетов будут приостановлены.
      </p>
    </div>,
  ],
  [
    "Можете ли вы выставить счет на юрлицо?",
    <div>
      <p>Да, в рамках тарифов Стандарт, Бизнес или Энтерпрайз.</p>
    </div>,
  ],
  [
    "Как оплатить сразу на год со скидкой?",
    <div>
      <p>В Личном кабинете или по выставленному счету на юрлицо.</p>
    </div>,
  ],
  [
    "Вы формируете закрывающие документы?",
    <div>
      <p>
        Да, если у вас была оплата по выставленному счету, то мы направим акты в
        ЭДО по вашему запросу.
      </p>
    </div>,
  ],
];

class Pricing extends React.Component {
  constructor(props) {
    super(props);

    this.account = this.props.account.current;

    // this.fnsLocale = {
    //   ru: fnsLocales.ru,
    //   en: fnsLocales.enUS,
    // }[this.account.lang];

    // get locale from account later when moved from hoc to hooks
    this.fnsLocale = fnsLocales.ru;

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "monthly",
      faqItemIndex: null,
      autoRenewal: null,
      modalPaymentPlan: null,
      modalPaymentPeriod: null,
      modalPaymentWindowVisible: false,
      modalCancelSubscriptionWindowVisible: false,
    };
  }

  componentDidMount() {
    var script = document.createElement("script");
    script.src = "https://widget.cloudpayments.ru/bundles/cloudpayments";

    if (document.head) {
      document.head.appendChild(script);
    }

    axios
      .get(`${GETREVIEW_API_HOST}/api/billing/subscriptions`, {
        withCredentials: true,
      })
      .then((json) => {
        this.setState({ autoRenewal: !!json.data.auto_renewal });
      })
      .catch((error) => {
        this.setState({ autoRenewal: null });
      });
  }

  componentDidUpdate() {}

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleFaqItem = (index) => {
    let selectedIndex = index;

    if (this.state.faqItemIndex === selectedIndex) {
      selectedIndex = null;
    }

    this.setState({ faqItemIndex: selectedIndex });
  };

  showPlanButton(plan) {
    const buttonBackgroundColor = {
      light: "#fcca97",
      standard: "#ff5975",
      business: "#59adff",
    }[plan];

    const buttonTextColor = {
      light: "#131344",
      standard: "#ffffff",
      business: "#ffffff",
    }[plan];

    const availablePlanButton = (
      <div
        className="pricing-plan-buy"
        style={{
          backgroundColor: buttonBackgroundColor,
          color: buttonTextColor,
        }}
        onClick={() => this.makePayment(plan)}
      >
        Выбрать
      </div>
    );

    return availablePlanButton;
  }

  currentPlanName() {
    const plans = {
      light: {
        name: "Лайт",
      },
      standard: {
        name: "Стандарт",
      },
      business: {
        name: "Бизнес",
      },
      enterprise: {
        name: "Энтерпрайз",
      },
    };

    return plans[this.props.workspace.plan.name].name;
  }

  makePayment = (planName) => {
    if (this.props.identity.role !== "admin") {
      toastr.error(
        "",
        "У вас недостаточно прав доступа для проведения оплаты. Пожалуйста, обратитесь к администратору вашей компании"
      );
      return;
    }

    const period = this.state.activeTab === "monthly" ? 1 : 12;

    this.setState({
      modalPaymentWindowVisible: true,
      modalPaymentPlan: planName,
      modalPaymentPeriod: period,
    });
  };

  sendEnterpriseRequest = () => {
    this.openMessenger(
      "Здравствуйте! Мы бы хотели получить индивидуальные условия по тарифу Энтерпрайз для нашей компании."
    );
  };

  openMessenger = (message) => {
    if (window.Intercom != null) {
      window.Intercom("showNewMessage", message);
    }
  };

  render() {
    const pricingPeriodToggle = (
      <>
        <div className="pricing-period-toggle">
          <div
            className={classnames({
              "pricing-period-monthly": true,
              active: this.state.activeTab === "monthly",
            })}
            onClick={() => this.toggle("monthly")}
          >
            В месяц
          </div>

          <div
            className={classnames({
              "pricing-period-annually": true,
              active: this.state.activeTab === "annually",
            })}
            onClick={() => this.toggle("annually")}
          >
            В год, скидка 20%
          </div>
        </div>
      </>
    );

    const pricingMarkSvg = (color, height) => (
      <div
        style={{
          width: "20px",
          float: "left",
          height: height || "27px",
          marginRight: "5px",
        }}
      >
        <svg
          className="pricing-plan-mark-icon"
          width="16"
          height="11"
          viewBox="0 0 16 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.3335 4.57143L6.18198 9.33333L14.6668 1"
            stroke={color}
            strokeWidth="2"
          />
        </svg>
      </div>
    );

    const pricingDisabledMarkSvg = (color, height) => (
      <div
        style={{
          width: "20px",
          float: "left",
          height: height || "27px",
          marginRight: "5px",
        }}
      >
        <svg
          className="pricing-plan-mark-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6 6L18.5 18.5M18.5 6L6 18.5"
            stroke={color}
            strokeWidth="2"
          />
        </svg>
      </div>
    );

    const pricingPlansBlock = (
      <>
        <div className="pricing-plans-block">
          <UncontrolledPopover
            placement="top"
            trigger="hover"
            target="question-what-is-view"
          >
            <PopoverBody>
              Засчитываются только уникальные показы виджета на одного
              пользователя в течение дня. Если пользователь в течение одного дня
              несколько раз зашел на страницы сайта, где установлен виджет, то
              будет засчитан только один показ для этого виджета. Показ виджета
              - это загрузка в режиме превью.
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            placement="top"
            trigger="hover"
            target="question-basic-analytics"
          >
            <PopoverBody>
              Просмотр статистики по всем виджетам будет доступен только за
              последние 30 дней.
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            placement="top"
            trigger="hover"
            target="question-extended-analytics"
          >
            <PopoverBody>
              Просмотр статистики по всем виджетам будет доступен за весь период
              времени без ограничений.
            </PopoverBody>
          </UncontrolledPopover>

          <div className="pricing-plan-item pricing-plan-light">
            <div className="pricing-plan-name">Лайт</div>
            <div className="pricing-plan-price">
              <span className="pricing-plan-price-value">
                {this.state.activeTab === "monthly"
                  ? Plans.light.monthly
                  : Plans.light.annually_per_month}
              </span>
              <span className="pricing-plan-price-monthly">₽/месяц</span>
            </div>
            {this.state.activeTab === "annually" && (
              <div className="pricing-plan-price-annually">
                <strike>{Plans.light.annually_without_discount}</strike>
                {` `}
                <span>{Plans.light.annually}</span>
                {` `}
                {`₽/год`}
              </div>
            )}
            <div className="pricing-plan-description">
              Самое необходимое для небольших проектов
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#FCCA97")} 3 видеовиджета
            </div>
            <div className="pricing-plan-sub-feature">
              {pricingMarkSvg("#FCCA97")} Bubble
            </div>
            <div className="pricing-plan-sub-feature pricing-plan-sub-feature-disabled">
              {pricingDisabledMarkSvg("#e7e7ec")} Carousel, Inline
            </div>
            <div className="pricing-plan-sub-feature pricing-plan-sub-feature-disabled">
              {pricingDisabledMarkSvg("#e7e7ec")} Video Stories, Grid
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#FCCA97")} 1 сайт
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#FCCA97")} 7 000 уникальных
              <br />
              показов в месяц
              <FontAwesomeIcon
                icon={faQuestionCircle}
                id="question-what-is-view"
                className="pricing-plan-tooltip-icon"
              />
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#FCCA97")} Базовая аналитика
              <br />
              за 30 дней
              <FontAwesomeIcon
                icon={faQuestionCircle}
                id="question-basic-analytics"
                className="pricing-plan-tooltip-icon"
              />
            </div>
            {this.showPlanButton("light")}
          </div>

          <div className="pricing-plan-item pricing-plan-standard">
            <div className="pricing-plan-name" style={{ color: "#ff5975" }}>
              Стандарт
            </div>
            <div className="pricing-plan-price">
              <span className="pricing-plan-price-value">
                {this.state.activeTab === "monthly"
                  ? Plans.standard.monthly
                  : Plans.standard.annually_per_month}
              </span>
              <span className="pricing-plan-price-monthly">₽/месяц</span>
            </div>
            {this.state.activeTab === "annually" && (
              <div className="pricing-plan-price-annually">
                <strike>{Plans.standard.annually_without_discount}</strike>
                {` `}
                <span>{Plans.standard.annually}</span>
                {` `}
                {`₽/год`}
              </div>
            )}
            <div className="pricing-plan-description">
              Для малых бизнесов, ищущих большой рост
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#ff5975")} 20 видеовиджетов
            </div>
            <div className="pricing-plan-sub-feature">
              {pricingMarkSvg("#ff5975")} Bubble
            </div>
            <div className="pricing-plan-sub-feature">
              {pricingMarkSvg("#ff5975")} Carousel, Inline
            </div>
            <div className="pricing-plan-sub-feature pricing-plan-sub-feature-disabled">
              {pricingDisabledMarkSvg("#e7e7ec")} Video Stories, Grid
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#ff5975")} 3 сайта
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#ff5975")} 30 000 уникальных
              <br />
              показов в месяц
              <FontAwesomeIcon
                icon={faQuestionCircle}
                id="question-what-is-view"
                className="pricing-plan-tooltip-icon"
              />
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#ff5975")} Расширенная
              <br />
              аналитика
              <FontAwesomeIcon
                icon={faQuestionCircle}
                id="question-extended-analytics"
                className="pricing-plan-tooltip-icon"
              />
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#ff5975")} Помощь с настройкой
            </div>
            {this.showPlanButton("standard")}
          </div>

          <div className="pricing-plan-item pricing-plan-business">
            <div className="pricing-plan-name" style={{ color: "#59adff" }}>
              Бизнес
            </div>
            <div className="pricing-plan-price">
              <span className="pricing-plan-price-value">
                {this.state.activeTab === "monthly"
                  ? Plans.business.monthly
                  : Plans.business.annually_per_month}
              </span>
              <span className="pricing-plan-price-monthly">₽/месяц</span>
            </div>
            {this.state.activeTab === "annually" && (
              <div className="pricing-plan-price-annually">
                <strike>{Plans.business.annually_without_discount}</strike>
                {` `}
                <span>{Plans.business.annually}</span>
                {` `}
                {`₽/год`}
              </div>
            )}
            <div className="pricing-plan-description">
              Для средних бизнесов, ищущих кратный рост
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#59adff")} 100 видеовиджетов
            </div>
            <div className="pricing-plan-sub-feature">
              {pricingMarkSvg("#59adff")} Bubble
            </div>
            <div className="pricing-plan-sub-feature">
              {pricingMarkSvg("#59adff")} Carousel, Inline
            </div>
            <div className="pricing-plan-sub-feature">
              {pricingMarkSvg("#59adff")} Video Stories, Grid
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#59adff")} 5 сайтов
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#59adff")} 100 000 уникальных
              <br />
              показов в месяц
              <FontAwesomeIcon
                icon={faQuestionCircle}
                id="question-what-is-view"
                className="pricing-plan-tooltip-icon"
              />
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#59adff")} Расширенная
              <br />
              аналитика
              <FontAwesomeIcon
                icon={faQuestionCircle}
                id="question-extended-analytics"
                className="pricing-plan-tooltip-icon"
              />
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#59adff")} Премиум-поддержка
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#59adff")} White Label
            </div>
            <div className="pricing-plan-feature">
              {pricingMarkSvg("#59adff")} Брендирование
            </div>
            {this.showPlanButton("business")}
          </div>

          <div
            className="pricing-plan-item pricing-plan-enterprise"
            style={{ backgroundColor: "#131344", color: "#ffffff" }}
          >
            <div className="pricing-plan-name" style={{ color: "#ffffff" }}>
              Энтерпрайз
            </div>
            <div className="pricing-plan-price">
              <span
                className="pricing-plan-price-monthly"
                style={{ color: "#ffffff", position: "relative", top: "17px" }}
              >
                Цена по запросу
              </span>
            </div>
            <div
              className="pricing-plan-description"
              style={{ borderColor: "#999999" }}
            >
              Индивидуальные условия для крупных компаний
            </div>
            <div className="pricing-plan-feature" style={{ width: "80%" }}>
              {pricingMarkSvg("#ffffff", "50px")} Отсутствие ограничений
            </div>
            <div className="pricing-plan-feature" style={{ width: "80%" }}>
              {pricingMarkSvg("#ffffff", "100px")} Гибкие решения и персональный
              подход
            </div>
            <div
              className="pricing-plan-buy"
              style={{ backgroundColor: "white", color: "#131344" }}
              onClick={this.sendEnterpriseRequest}
            >
              Отправить&nbsp;запрос
            </div>
          </div>
        </div>
      </>
    );

    return (
      <Container fluid className="p-0">
        {this.state.modalCancelSubscriptionWindowVisible && (
          <CancelSubscriptionModalWindow
            accessEndDate={this.props.workspace.plan.period_to}
            onCancel={() => {
              this.setState({
                modalCancelSubscriptionWindowVisible: false,
                autoRenewal: false,
              });
            }}
            onClose={() =>
              this.setState({
                modalCancelSubscriptionWindowVisible: false,
              })
            }
          />
        )}

        {this.state.modalPaymentWindowVisible && (
          <PricingModalForm
            plan={this.state.modalPaymentPlan}
            period={this.state.modalPaymentPeriod}
            onClose={() =>
              this.setState({
                modalPaymentWindowVisible: false,
                modalPaymentPlan: null,
                modalPaymentPeriod: null,
              })
            }
          />
        )}

        {this.props.workspace.plan.name !== "trial" && (
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <h4>Ваш тарифный план</h4>
                    <div>
                      <Link to="/workspace/settings#invoices">
                        История платежей
                      </Link>
                    </div>
                  </div>
                  <h2>{this.currentPlanName()}</h2>
                  <br />
                  Оплачен до&nbsp;
                  <b>
                    {format(
                      new Date(this.props.workspace.plan.period_to),
                      "dd MMMM yyyy",
                      {
                        locale: this.fnsLocale,
                      }
                    )}
                  </b>
                  <br />
                  Автосписание&nbsp;
                  {this.state.autoRenewal === null && (
                    <Spinner color="primary" size="sm" className="ml-1" />
                  )}
                  {this.state.autoRenewal === true && (
                    <>
                      <span>включено</span>
                      <br />
                      <span
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({
                            modalCancelSubscriptionWindowVisible: true,
                          })
                        }
                      >
                        Отменить подписку
                      </span>
                    </>
                  )}
                  {this.state.autoRenewal === false && (
                    <>
                      <b>выключено</b>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <h1 className="text-center">Тарифные планы</h1>

            {pricingPeriodToggle}

            {pricingPlansBlock}

            <div className="text-center mt-3 mb-4">
              <a
                href="https://getreview.io/pricing#comparison"
                target="blank"
                className="mr-4"
              >
                Полные характеристики тарифных планов
              </a>

              <br />

              <a
                href=""
                target="blank"
                className="mr-4"
                onClick={(e) => {
                  e.preventDefault();
                  this.openMessenger("Здравствуйте! У меня есть промо-код:");
                }}
              >
                У меня есть промо-код
              </a>
            </div>
          </Col>
        </Row>

        <br />

        <Row>
          <Col lg="12"></Col>
        </Row>

        <Row className="d-flex w-100 justify-content-center">
          <Col lg="10" className="align-self-center w-100 mb-5">
            <h1 className="mb-6 text-center">Нам доверяют</h1>

            <div className="d-flex flex-wrap justify-content-around">
              <img
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild3834-3133-4337-b630-393736363931/logo-color-text_6748.svg"
                alt="Нетология"
              />
              <img
                width="50px"
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild3738-6338-4765-b763-386139393335/Logo_Sber_Shool.svg"
                alt="Школа 21 от Сбер"
              />
              <img
                width="120px"
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild3639-6166-4533-a430-343536383761/Frame_192.svg"
                alt="Qmarketing"
              />
              <img
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild3063-3762-4161-a436-386362366333/Skoda_logo.svg"
                alt="Skoda"
              />
              <img
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild6164-3531-4561-b334-313466653638/PuzzleEnglish-logo_v.svg"
                alt="Puzzle English"
              />
              <img
                width="120px"
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild6262-3336-4265-b739-666438323231/Superstep_logo_1.svg"
                alt="SuperStep"
              />
              <img
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild6462-3532-4263-b238-323833386261/Frame.svg"
                alt="Superpet"
              />
              <img
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild6261-3931-4563-a330-326434356661/logo_1.svg"
                alt="OK Beauty"
              />
              <img
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild3438-6133-4430-a163-346530346165/smart-checkup_1.svg"
                alt="Smart CheckUp"
              />
              <img
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild3032-3338-4562-b539-386338323636/SACO_logo_black_1.svg"
                alt="SACO"
              />
              <img
                width="80px"
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild3366-3036-4134-b231-323537326366/ps-ch_1.svg"
                alt="Property Show"
              />
              <img
                width="120px"
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild6536-6234-4963-b034-636537613330/Kenguru_Logo.svg"
                alt="Кенгуру"
              />
              <img
                className="ml-3 mr-3 mb-3"
                src="https://static.tildacdn.com/tild3538-6663-4238-b032-643063343534/Fitstars_logo_1.svg"
                alt="FitStars"
              />
            </div>
          </Col>
        </Row>

        <Row className="d-flex w-100 justify-content-center">
          <Col lg="10" className="align-self-center w-100 mt-4">
            <h1 className="mb-4 text-center">Часто задаваемые вопросы</h1>

            <Card>
              <CardBody>
                <div className="pricing-faq">
                  {FAQ.map((item, index) => (
                    <div
                      key={`faq_item_${index}`}
                      className="pricing-faq-item"
                      onClick={() => this.toggleFaqItem(index)}
                    >
                      <div className="pricing-faq-question">{item[0]}</div>

                      {this.state.faqItemIndex === index && (
                        <div className="pricing-faq-answer">{item[1]}</div>
                      )}

                      <div className="pricing-faq-toggle">
                        <FontAwesomeIcon
                          icon={
                            this.state.faqItemIndex === index
                              ? faAngleUp
                              : faAngleDown
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="text-center mt-3 mb-4">
              Безопасность оплаты гарантируется провайдером{" "}
              <a href="https://www.cloudpayments.ru" target="blank">
                CloudPayments
              </a>
              .
              <br />
              Используется шифрованное SSL-соединение, протокол 3D Secure и
              стандарт безопасности PCI DSS 3.2.
              <br />
              <br />
            </div>

            <hr />

            <br />

            <div className="text-center my-4">
              <h2>Остались вопросы?</h2>
              Напиши нам в{" "}
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  this.openMessenger();
                }}
              >
                чат
              </a>{" "}
              или на почту{" "}
              <a href="mailto:corp@getreview.io">corp@getreview.io</a>
              <br />
              или позвоните по телефону{" "}
              <a href="tel:+7-495-240-80-95">+7 495 240 80 95</a>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
  workspace: state.identity.workspace,
  identity: state.identity,
});

export default connect(mapStateToProps)(Pricing);
