import React from "react";
import { connect } from "react-redux";
import { DateRange } from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";
import * as fnsLocales from "date-fns/locale";
import { subDays, format } from "date-fns";
import { Trans } from "react-i18next";

import { Button } from "reactstrap";

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    const accountLang = this.props.identity.account.lang;

    this.rdrLocale = rdrLocales[accountLang];
    this.fnsLocale = {
      ru: fnsLocales.ru,
      en: fnsLocales.enUS,
    }[accountLang];

    const selectionRange = {
      startDate: this.props.dateFrom,
      endDate: this.props.dateTo,
      key: "selection",
      locale: this.rdrLocale,
    };

    this.state = {
      hidden: true,
      currentRange: selectionRange,
      newRange: selectionRange,
    };

    this.dateRangeRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dateRangeRef.current == null) {
      return false;
    }

    try {
      const clickedOutside = !this.dateRangeRef.current.contains(event.target);

      if (clickedOutside) {
        this.resetAndClose();
      }
    } catch (error) {}
  };

  open = (event) => {
    this.setState({ hidden: false });
  };

  resetAndClose = (event) => {
    this.reset();
    this.close();
  };

  close = (event) => {
    this.setState({ hidden: true });
  };

  reset = (event) => {
    this.setState({ newRange: this.state.currentRange });
  };

  submit = (event) => {
    const { startDate, endDate } = this.state.newRange;

    this.setState({ currentRange: this.state.newRange });
    this.close();

    if (this.props.onSubmitHandler) {
      this.props.onSubmitHandler();
    }
  };

  handleSelect = (ranges) => {
    const selectionRange = ranges.selection;

    this.setState({ newRange: selectionRange });
  };

  formatDate(date) {
    return format(date, "dd MMMM yyyy", { locale: this.fnsLocale });
  }

  render() {
    const dateFrom = this.state.newRange.startDate;
    const dateTo = this.state.newRange.endDate;

    const dateFromString = this.formatDate(dateFrom);
    const dateToString = this.formatDate(dateTo);

    const dateTodayString = this.formatDate(new Date());
    const date14DaysAgoString = this.formatDate(subDays(new Date(), 13));

    let label = `${dateFromString} – ${dateToString}`;

    if (dateFromString == dateToString) {
      label = <Trans>calendar.today</Trans>;
    } else if (
      dateFromString == date14DaysAgoString &&
      dateToString == dateTodayString
    ) {
      label = <Trans values={{ count: 14 }}>calendar.last_n_days</Trans>;
    }

    return (
      <div>
        <Button
          color="light"
          style={{ marginRight: "8px", borderColor: "#ced4da" }}
          onClick={this.open}
        >
          {label}
        </Button>
        {this.state.hidden === false && (
          <div className="date_time_picker" ref={this.dateRangeRef}>
            <DateRange
              locale={this.rdrLocale}
              ranges={[this.state.newRange]}
              onChange={this.handleSelect}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              direction="horizontal"
            />

            <div style={{ height: "50px", marginTop: "5px" }}>
              <Button
                color="primary"
                style={{ marginRight: "10px", width: "140px" }}
                className="float-right"
                onClick={this.submit}
              >
                <Trans>calendar.apply</Trans>
              </Button>
              <Button
                color="light"
                style={{ marginRight: "10px" }}
                className="float-right"
                onClick={this.resetAndClose}
              >
                <Trans>calendar.cancel</Trans>
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  identity: store.identity,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  DateTimePicker
);
