import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GETREVIEW_API_HOST } from "../constants";

const GlobalStyle = createGlobalStyle`
  .getreview-widget > div > div {
    margin-left: 280px;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Header = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  background-image: linear-gradient(to right, #000a3b, #131344);
`;

const Content = styled.div`
  position: fixed;
  top: 60px;
  width: 100%;
  height: 100%;
  display: flex;
`;

const SettingsBar = styled.div`
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  width: 60px;
  z-index: 10;
`;

const SettingsBlock = styled.div`
  background: white;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  width: 220px;
  z-index: 9;
  padding: 20px;
`;

const SettingsContent = styled.div`
  background: white;
  flex-grow: 1;
`;

const SettingsTopPanel = styled.div`
  height: 60px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const WidgetPreviewBlock = styled.div`
  background: white;
  margin-top: 60px;
`;

const WidgetEditor = (props) => {
  const [widgetSettings, setWidgetSettings] = useState({});

  let { widget_id: widgetToken } = useParams();
  const project = useSelector((state) => state.projects.current);

  useEffect(() => {
    injectWidgetCode();
  }, []);

  useEffect(() => {
    console.log("updated widgetSettings", widgetSettings);

    if (typeof window.GetReview === "object") {
      const widget = window.GetReview.API.widgets[0];
      widget.updateSettings(widgetSettings);
    }
  }, [widgetSettings]);

  const closeWidget = () => {
    const widget = window.GetReview.API.widgets[0];
    widget.closeWidget();
  };

  const openWidget = () => {
    const widget = window.GetReview.API.widgets[0];
    widget.openWidget();
  };

  const injectWidgetCode = () => {
    window.GetReviewSettings = {
      previewWidgetToken: widgetToken,
      onEvent: (event) => {
        console.log("event", event);

        if (event.type === window.GetReview.Events.WIDGET_LOAD) {
          const widget = window.GetReview.API.widgets[0];
          const settings = widget.getSettings();
          setWidgetSettings(settings);
          // if (settings.widgetPositionX === "left") {
          //   widget.updateSettings({
          //     itemMarginX: settings.itemMarginX + 280,
          //   });
          // }
          // if (settings.widgetPositionY === "top") {
          //   widget.updateSettings({
          //     itemMarginY: settings.itemMarginY + 120,
          //   });
          // }
        }
      },
    };

    (function (g) {
      var s = g.createElement("script");
      s.async = true;
      s.src = `${GETREVIEW_API_HOST}/tags/${project.token}/sdk.js`;
      if (g.head) {
        g.head.appendChild(s);
      }
    })(document);
  };

  return (
    <Wrapper>
      <GlobalStyle />
      <Header></Header>
      <Content>
        <SettingsBar></SettingsBar>
        <SettingsBlock>
          Ширина
          <br />
          <input
            type="range"
            min="50"
            max="1000"
            value={widgetSettings.itemWidth || 0}
            onChange={(event) => {
              setWidgetSettings({
                ...widgetSettings,
                itemWidth: parseInt(event.target.value),
              });
            }}
          />
          <br />
          <br />
          Высота
          <br />
          <input
            type="range"
            min="50"
            max="1000"
            value={widgetSettings.itemHeight || 0}
            onChange={(event) => {
              setWidgetSettings({
                ...widgetSettings,
                itemHeight: parseInt(event.target.value),
              });
            }}
          />
          <br />
          <br />
          Закругление
          <br />
          <input
            type="range"
            min="0"
            max="100"
            value={widgetSettings.itemBorderRadius || 0}
            onChange={(event) => {
              setWidgetSettings({
                ...widgetSettings,
                itemBorderRadius: parseInt(event.target.value),
              });
            }}
          />
        </SettingsBlock>
        <SettingsContent>
          <SettingsTopPanel>
            <span
              style={{
                fontWeight: 600,
                cursor: "pointer",
                marginRight: "30px",
              }}
              onClick={closeWidget}
            >
              Режим превью
            </span>

            <span
              style={{
                fontWeight: 600,
                cursor: "pointer",
              }}
              onClick={openWidget}
            >
              Режим просмотра
            </span>
          </SettingsTopPanel>
          <WidgetPreviewBlock>
            <div
              className="getreview-widget"
              data-widget-id={widgetToken}
            ></div>
          </WidgetPreviewBlock>
        </SettingsContent>
      </Content>
    </Wrapper>
  );
};

export default WidgetEditor;
