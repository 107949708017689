import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { GETREVIEW_API_HOST } from "../../constants";
import { loadIdentity } from "../../redux/actions/identityActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";

const SettingsForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.identity);
  const [firstName, setFirstName] = useState(account.first_name);
  const [lastName, setLastName] = useState(account.last_name);
  const [phone, setPhone] = useState(account.phone);
  const [lang, setLang] = useState(account.lang);
  const [unconfirmedEmail, setUnconfirmedEmail] = useState(
    account.unconfirmed_email
  );

  const [sending, setSending] = useState(false);
  const [errorData, setErrorData] = useState();
  const [emailEditing, setEmailEditing] = useState(
    account.unconfirmed_email != null
  );

  const cancelEmailChanging = (event) => {
    setEmailEditing(false);
    setUnconfirmedEmail(null);

    if (account.unconfirmed_email != null) {
      removeUnconfirmedEmail();
    }
  };

  const removeUnconfirmedEmail = () => {
    axios
      .put(
        `${GETREVIEW_API_HOST}/api/account/remove_unconfirmed_email`,
        {},
        { withCredentials: true }
      )
      .catch((error) => {
        alert("Ошибка при отмене изменения электронной почты");
      });
  };

  const submitSettings = (event) => {
    setErrorData(null);
    setSending(true);

    const accountSettings = {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      lang: lang,
    };

    if (unconfirmedEmail != null && unconfirmedEmail !== "") {
      accountSettings.email = unconfirmedEmail;
    }

    axios
      .put(
        `${GETREVIEW_API_HOST}/api/account/update_settings`,
        accountSettings,
        {
          withCredentials: true,
        }
      )
      .then((json) => {
        dispatch(loadIdentity());
        toastr.success("", "Настройки аккаунты были успешно сохранены");

        if (account.lang !== lang) {
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error.response.data.error_code === "EmailTaken") {
          toastr.error("", "Данный email уже зарегистрирован");
        } else {
          toastr.error("", "Ошибка при сохранении настроек аккаунта");
        }
      })
      .then(() => {
        setSending(false);
      });
  };

  return (
    <Card>
      <CardBody>
        <AvForm onValidSubmit={submitSettings}>
          <Row form>
            <Col md={12}>
              <h4>Данные аккаунта</h4>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="first_name">
                  <Trans>account.first_name</Trans>
                </Label>
                <AvField
                  bsSize="lg"
                  required
                  errorMessage="Поле обязательно для заполнения"
                  type="text"
                  name="first_name"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}></Col>
            <Col md={6}>
              <FormGroup>
                <Label for="last_name">
                  <Trans>account.last_name</Trans>
                </Label>
                <AvField
                  bsSize="lg"
                  type="text"
                  name="last_name"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}></Col>
          </Row>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="phone">
                  <Trans>account.phone</Trans>
                </Label>
                <AvField
                  bsSize="lg"
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon={faLanguage}
                    style={{
                      opacity: "0.3",
                      fontSize: "25px",
                      marginRight: "10px",
                      marginTop: "-1px",
                    }}
                  />

                  <Label for="phone">
                    <Trans>account.interface_language</Trans>
                  </Label>
                </div>

                <AvField
                  bsSize="lg"
                  type="select"
                  name="lang"
                  value={account.lang}
                  onChange={(event) => setLang(event.target.value)}
                >
                  <option value="ru">{t("account.languages.ru")}</option>
                  <option value="en">{t("account.languages.en")}</option>
                </AvField>
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
              <br />

              <h4>
                <Trans>account.email</Trans>
              </h4>

              <FormGroup>
                Ваш адрес электронной почты:{" "}
                <b id="account_settings_email">{account.email}</b>
                <br />
                {emailEditing === true ? (
                  <>
                    <br />

                    <span className="text-sm">
                      Новый адрес электронной почты:
                    </span>

                    <AvField
                      required
                      errorMessage="Поле обязательно для заполнения"
                      type="email"
                      name="email"
                      value={unconfirmedEmail}
                      onChange={(event) =>
                        setUnconfirmedEmail(event.target.value)
                      }
                    />

                    {errorData && errorData.error === "email_taken" && (
                      <span style={{ color: "red" }}>
                        Данный email уже зарегистрирован
                        <br />
                      </span>
                    )}

                    {account.unconfirmed_email != null && (
                      <span className="text-sm">
                        Подтвердите новый адрес электронной почты. Кликните по
                        ссылке в отправленном письме.
                        <br />
                      </span>
                    )}

                    <span
                      className="text-sm text-link"
                      onClick={cancelEmailChanging}
                    >
                      Отменить изменение электронной почты
                    </span>
                  </>
                ) : (
                  <span
                    className="text-sm text-link"
                    onClick={() => setEmailEditing(true)}
                  >
                    Изменить
                  </span>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <br />

              {/*
              <h4>
                Подключенные аккаунты
              </h4>

              <Row>
                <Col>
                  <FacebookButton disabled={account.facebook_login === true} text={account.facebook_login === true ? <span>Facebook<br />подключен</span> : <span>Подключить<br />Facebook</span>} />
                </Col>
                <Col>
                  <GoogleButton disabled={account.google_login === true} text={account.google_login === true ? <span>Google<br />подключен</span> : <span>Подключить<br />Google</span>} />
                </Col>
              </Row>
              */}
            </Col>
          </Row>

          <Row form>
            <Col md={6}>
              <br />

              <h4>
                <Trans>account.password</Trans>
              </h4>

              <FormGroup>
                **********
                <br />
                <a href="/account/reset_password">Сбросить пароль</a>
              </FormGroup>
            </Col>
          </Row>

          <br />

          <Button
            id="account_settings_submit_button"
            type="submit"
            color="primary"
            disabled={sending}
            style={{
              width: "220px",
              padding: "0.7rem 1rem",
              position: "relative",
            }}
          >
            <Trans>buttons.save_changes</Trans>
            {sending === true && (
              <Spinner
                color="white"
                size="sm"
                className="mr-2"
                style={{ position: "absolute", right: "3px", top: "13px" }}
              />
            )}
          </Button>
        </AvForm>
      </CardBody>
    </Card>
  );
};

const Settings = () => {
  return (
    <Container fluid className="p-0">
      <Row className="mb-2 mb-xl-4">
        <Col xs="auto" className="d-none d-sm-block">
          <h3>Настройки аккаунта</h3>
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <SettingsForm />
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
