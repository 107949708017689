import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { useRouteMatch } from "react-router-dom";
import { ChromePicker } from 'react-color';
import axios from 'axios';
import { GETREVIEW_API_HOST } from "../../constants";
import ColorPicker from "../../components/ColorPicker";

import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faMobileAlt, faEdit } from "@fortawesome/free-solid-svg-icons";

import logotype from "../../assets/img/logo/logotype_normal.svg";

const Customize = () => {
  const routeMatch = useRouteMatch();

  const [widget, setWidget] = useState();
  const [widgetSettings, setWidgetSettings] = useState({});
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    fetchWidget();
  }, []);

  useEffect(() => {
    if (widget == null) {
      return;
    }

    const settings = ['width', 'height'].reduce((a, e) => (a[e] = widget[e], a), {});
    console.log('settings', settings);

    setWidgetSettings({
      width: widget.width,
      height: widget.height,
      mobile_width: 100,
      mobile_height: 135,
      border_radius: 20,
      border_width: 3,
      shadow_size: 20,
      is_mobile: false,
      customization: {}
    });

    if (window.GetReviewInstance == null) {
      writeScriptCode();
    }
  }, [widget]);

  useEffect(() => {
    applyWidgetSettings(widgetSettings);
  }, [widgetSettings]);

  useEffect(() => {
    const isMobile = activeTab === '2';

    setWidgetSettings({ ...widgetSettings, is_mobile: isMobile });
  }, [activeTab]);

  const applyWidgetSettings = (settings) => {
    if (widget == null || window.GetReviewInstance == null) {
      return;
    }

    window.GetReviewInstance.API.applySettings(widget.token, settings);
  }

  const onColorChangeHandler = () => {
    console.log('onColorChangeHandler');
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const fetchWidget = () => {
    const widgetID = routeMatch.params.widget_id;

    axios.get(`${GETREVIEW_API_HOST}/api/widgets/${widgetID}`, { withCredentials: true })
      .then(json => {
        setWidget(json.data.widget);
      })
      .catch(error => {
        console.log('error', error);
        alert('error get widget');
      })
  }

  const writeScriptCode = () => {
    console.log('writeScriptCode');
    //const scriptSrc = process.env.NODE_ENV === 'production' ? `${GETREVIEW_API_HOST}/static/bundle.js` : `${process.env.REACT_APP_LOCALPROXY_WIDGET_HOST}/bundle.js`
    const scriptSrc = 'https://widget-localproxy-1.getreview.dev/bundle.js';

    (function(g, e, t) {
      var s = g.createElement('script');
      e[t] = e[t] || function() {
        (e[t].queue = e[t].queue || []).push(arguments);
      };
      s.async = true;
      s.src = scriptSrc;
      if (g.head) { g.head.appendChild(s); }
    })(document, window, 'GetReview');
  }

  if (widget == null || widgetSettings.width == null) {
    return '';
  }

  return (
    <Container fluid className="p-0 edit-widget">
      <Row>
        <Col>
          <h2>Кастомизация</h2>
          <br />
        </Col>
      </Row>

      <Row>
        <Col lg="9">
          <div className="sticky-top">
            <div className="tab">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => { toggleTab("1") }}
                  >
                    <FontAwesomeIcon
                      icon={faDesktop}
                    />

                    <span className="ml-2">На компьютерах</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => { toggleTab("2") }}
                  >
                    <FontAwesomeIcon
                      icon={faMobileAlt}
                    />

                    <span className="ml-2">На мобильных</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent>
                <div style={{ height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ marginLeft: '-200px' }}>
                    <div className="getreview-widget" data-widget-id={widget.token} />
                  </div>
                </div>
              </TabContent>
            </div>
          </div>
        </Col>

        <Col lg="3">
          <div className="tab">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => { toggleTab("1") }}
                >
                  <FontAwesomeIcon
                    icon={faDesktop}
                  />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => { toggleTab("2") }}
                >
                  <FontAwesomeIcon
                    icon={faMobileAlt}
                  />
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                Ширина<br /><br />
                <InputRange
                  step={1}
                  maxValue={500}
                  minValue={0}
                  value={widgetSettings.width}
                  onChange={(value) => setWidgetSettings({ ...widgetSettings, width: value }) }
                />

                <br /><br />

                Высота<br /><br />
                <InputRange
                  step={1}
                  maxValue={500}
                  minValue={0}
                  value={widgetSettings.height}
                  onChange={(value) => setWidgetSettings({ ...widgetSettings, height: value }) }
                />

                <br />
              </TabPane>

              <TabPane tabId="2">
                Ширина<br /><br />
                <InputRange
                  step={1}
                  maxValue={500}
                  minValue={0}
                  value={widgetSettings.mobile_width}
                  onChange={(value) => setWidgetSettings({ ...widgetSettings, mobile_width: value }) }
                />

                <br /><br />

                Высота<br /><br />
                <InputRange
                  step={1}
                  maxValue={500}
                  minValue={0}
                  value={widgetSettings.mobile_height}
                  onChange={(value) => setWidgetSettings({ ...widgetSettings, mobile_height: value }) }
                />

                <br />
              </TabPane>
            </TabContent>
          </div>

          <div className="tab">
            <Nav tabs>
              <NavItem>
                <NavLink className="active">
                  <FontAwesomeIcon
                    icon={faDesktop}
                  />
                  &nbsp;
                  +
                  &nbsp;
                  <FontAwesomeIcon
                    icon={faMobileAlt}
                  />
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent>
              <TabPane>
                <h4 className="tab-title">Для всех устройств</h4><br />

                Цвет рамки<br />
                <ColorPicker color={'#eeeeee'} onChange={(value) => setWidgetSettings({ ...widgetSettings, customization: { ...widgetSettings.customization, border_color: value } })} />

                <br />

                Цвет рамки при наведении<br />
                <ColorPicker color={'#eeeeee'} onChange={(value) => setWidgetSettings({ ...widgetSettings, customization: { ...widgetSettings.customization, border_hover: value } })} />

                <br />

                Цвет кнопки CTA<br />
                <ColorPicker color={'#eeeeee'} onChange={(value) => setWidgetSettings({ ...widgetSettings, customization: { ...widgetSettings.customization, cta_color: value } })} />

                <br />

                Радиус закруглений<br /><br />
                <InputRange
                  step={1}
                  maxValue={200}
                  minValue={0}
                  value={widgetSettings.border_radius}
                  onChange={(value) => setWidgetSettings({ ...widgetSettings, border_radius: value }) }
                />

                <br /><br />

                Толщина рамки<br /><br />
                <InputRange
                  step={1}
                  maxValue={10}
                  minValue={0}
                  value={widgetSettings.border_width}
                  onChange={(value) => setWidgetSettings({ ...widgetSettings, border_width: value }) }
                />

                <br /><br />

                Тень<br /><br />
                <InputRange
                  step={1}
                  maxValue={30}
                  minValue={0}
                  value={widgetSettings.shadow_size}
                  onChange={(value) => setWidgetSettings({ ...widgetSettings, shadow_size: value }) }
                />

                <br />
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Customize;
