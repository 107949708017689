import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import CallToActionModal from "./CallToActionModal";
import CallToActionPreview from "./CallToActionPreview";
import CallToActionPreviewSortable from "./CallToActionPreviewSortable";

const CallToActions = (props) => {
  const [callToActions, setCallToActions] = useState(props.callToActions);
  const [modalInstance, setModalInstance] = useState(undefined);
  const [draggableCallToActionId, setDraggableCallToActionId] =
    useState(undefined);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 3,
      },
    }),
    useSensor(TouchSensor)
  );

  const handleDragStart = ({ active }) => {
    setDraggableCallToActionId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setCallToActions((previousCallToActions) => {
        const oldIndex = previousCallToActions.findIndex(
          (cta) => cta.id === active.id
        );
        const newIndex = callToActions.findIndex((cta) => cta.id === over.id);

        return arrayMove(callToActions, oldIndex, newIndex);
      });
    }

    setDraggableCallToActionId(null);
  };

  useEffect(() => {
    props.onUpdate(callToActions);
  }, [callToActions]);

  const deleteCallToAction = (index) => {
    const newCallToActions = [...callToActions];
    newCallToActions.splice(index, 1);
    setCallToActions(newCallToActions);
  };

  const addCallToAction = (instance) => {
    const newCallToActions = [...callToActions];
    newCallToActions.push(instance);
    setCallToActions(newCallToActions);
  };

  const updateCallToAction = (instance, index) => {
    const newCallToActions = [...callToActions];
    newCallToActions[index] = instance;
    setCallToActions(newCallToActions);
  };

  const draggableCallToAction = callToActions.find(
    (cta) => cta.id === draggableCallToActionId
  );

  return (
    <>
      {modalInstance !== undefined && (
        <CallToActionModal
          instance={modalInstance && modalInstance.instance}
          instanceIndex={modalInstance && modalInstance.index}
          onSuccess={(instance, index) => {
            setModalInstance(undefined);
            modalInstance === null
              ? addCallToAction(instance)
              : updateCallToAction(instance, index);
          }}
          onClose={() => setModalInstance(undefined)}
        />
      )}

      <FormGroup>
        <h4>
          Товары на видео
          <FontAwesomeIcon
            onClick={() => setModalInstance(null)}
            icon={faPlusCircle}
            style={{
              marginLeft: "12px",
              position: "relative",
              top: "1px",
              color: "#3A89FF",
              cursor: "pointer",
            }}
          />
        </h4>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={callToActions}>
            <div>
              {callToActions.map((callToAction, index) => (
                <CallToActionPreviewSortable
                  key={`callToActionPreviewSortable_${callToAction.id}`}
                  id={callToAction.id}
                  instance={callToAction}
                  instanceIndex={index}
                  isActive={callToAction.id === draggableCallToActionId}
                  onClick={() =>
                    setModalInstance({ instance: callToAction, index })
                  }
                  onDelete={deleteCallToAction}
                />
              ))}
            </div>
          </SortableContext>

          <DragOverlay>
            {draggableCallToAction && (
              <CallToActionPreview
                id={draggableCallToAction.id}
                instance={draggableCallToAction}
                isDraggable={true}
              />
            )}
          </DragOverlay>
        </DndContext>
      </FormGroup>
    </>
  );
};

export default CallToActions;
