import React, { useState, useEffect } from "react";
import axios from "axios";
import { GETREVIEW_API_HOST } from "../../../constants";
import Story from "./Story";
import NewStoryBlock from "./NewStoryBlock";
import SortableStory from "./SortableStory";
import StoryModalForm from "./StoryModalForm";

import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import { arrayMove, SortableContext } from "@dnd-kit/sortable";

const Items = (props) => {
  const [activeStoryToken, setActiveStoryToken] = useState(null);
  const [stories, setStories] = useState(props.widgetItems || []);
  const [storyModalFormOpened, setStoryModalFormOpened] = useState(false);
  const [storyModalRecord, setStoryModalRecord] = useState(null);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  useEffect(() => {
    if (props.onUpdate) {
      props.onUpdate(stories);
    }
  }, [stories]);

  const handleDragStart = ({ active }) => {
    setActiveStoryToken(active.id);
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setStories((stories) => {
        const oldIndex = stories.findIndex(
          (story) => story.token === active.id
        );
        const newIndex = stories.findIndex((story) => story.token === over.id);

        return arrayMove(stories, oldIndex, newIndex);
      });
    }

    setActiveStoryToken(null);
  }

  const openStoryModal = (story) => {
    const newStory = {
      caption: "",
      title: "",
      subtitle: "",
      newRecord: true,
    };

    setStoryModalFormOpened(true);
    setStoryModalRecord(story || newStory);
  };

  const closeStoryModal = () => {
    setStoryModalFormOpened(false);
    setStoryModalRecord(null);
  };

  const removeStory = (story) => {
    const newStories = stories.filter((s) => s.token !== story.token);
    setStories(newStories);
  };

  const updateStoryVideoStatus = (story, videoStatus) => {
    const updatedStories = [...stories];
    updatedStories.find((s) => s.token === story.token).video.status =
      videoStatus;
    setStories(updatedStories);
  };

  const onStoryModalFormComplete = (addedStories) => {
    const newStories = [...stories];

    addedStories.forEach((story) => {
      const index = newStories.findIndex((s) => s.token === story.token);

      if (index !== -1) {
        newStories[index] = story;
      } else {
        newStories.unshift(story);
      }
    });

    setStories(newStories);
  };

  return (
    <div>
      {storyModalFormOpened && (
        <StoryModalForm
          {...storyModalRecord}
          widgetType={props.widgetType}
          onClose={closeStoryModal}
          onComplete={onStoryModalFormComplete}
        />
      )}

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={stories}>
          <div className="videostories-items">
            <NewStoryBlock onClick={() => openStoryModal()} />

            {stories.map((story) => (
              <SortableStory
                key={story.token}
                {...story}
                isActive={story.token === activeStoryToken}
                onSettingsClick={() => openStoryModal(story)}
                onDeleteClick={() => removeStory(story)}
                onVideoStatusUpdate={(videoStatus) =>
                  updateStoryVideoStatus(story, videoStatus)
                }
              />
            ))}
          </div>
        </SortableContext>

        <DragOverlay>
          {activeStoryToken && (
            <Story
              {...stories.find((s) => s.token === activeStoryToken)}
              isDraggable={true}
            />
          )}
        </DragOverlay>
      </DndContext>
    </div>
  );
};

export default Items;
